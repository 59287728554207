import { createSelector } from '@ngrx/store';
import { ProjectState, selectProjectState } from 'app/store/project/project.state';
import * as fromStorehouses from './storehouses.reducer';
import { Uuid } from 'shared/common/types';

export const storehousesState = createSelector(
  selectProjectState,
  state => state.storehouses
);

export const storehouses = createSelector(
  selectProjectState,
  (state: ProjectState) => fromStorehouses.selectAll(state.storehouses)
);

export const storehouseEntities = createSelector(
  selectProjectState,
  state => fromStorehouses.selectEntities(state.storehouses)
);

export const oneStorehouse = createSelector(
  selectProjectState,
  (state: ProjectState, props: { id: Uuid }) => fromStorehouses.selectEntities(state.storehouses)[props.id]
);

export const storehousesCount = createSelector(
  selectProjectState,
  state => fromStorehouses.selectTotal(state.storehouses)
);

export const storehousesLoading = createSelector(
  selectProjectState,
  state => state.storehouses.loading
);

export const departments = createSelector(
  selectProjectState,
  state => state.storehouses.departments
);
