import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs/operators';
import { CourierService } from 'core/http/project';
import { CompletedEntityStateService } from 'app/store/completed-entity-state/completed-entity-state.service';
import { createProperties } from 'app/store/completed-entity-state/completed-entity-state';
import * as CouriersActions from './couriers.actions';

@Injectable()
export class CouriersEffects {

  private readonly properties = createProperties(
    CouriersActions.setLoading,
    CouriersActions.markCouriersLoaded,
    CouriersActions.setError,
    () => this.courierService.getAllCouriers(),
    'couriers'
  );

  getCouriers$ = createEffect(() => this.actions$.pipe(
    ofType(CouriersActions.getCouriers),
    exhaustMap(() => this.completedEntityStateService.get(this.properties)),
    map(couriers => CouriersActions.saveCouriers({ couriers }))
  ));

  constructor(
    private actions$: Actions,
    private courierService: CourierService,
    private completedEntityStateService: CompletedEntityStateService
  ) { }

}
