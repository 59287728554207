import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'shared/shared.module';
import { ReserveCheckDialogComponent } from 'shared/dialogs/reserve-check-dialog/reserve-check-dialog.component';

@NgModule({
  declarations: [
    ReserveCheckDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    ReserveCheckDialogComponent
  ]
})
export class ReserveCheckDialogModule {
}
