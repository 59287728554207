import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { hasRootAccess } from 'app/store/login/logged-user/logged-user.selectors';
import { selectFirst } from 'core/utils/rx-common';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RootGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private store$: Store
  ) {}

  canActivate(): Observable<boolean> {
    return this.store$.pipe(
      selectFirst(hasRootAccess),
      map(access => {
        if (!access) {
          this.router.navigate(['project']);
          return false;
        }

        return true;
      })
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }

}
