import { Component, Host } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-select-all',
  templateUrl: './select-all.component.html',
  styleUrls: ['./select-all.component.scss']
})
export class SelectAllComponent {

  constructor(@Host() private select: MatSelect) {}

  selectAll(): void {
    const control = this.select.ngControl.control;
    const options = this.select.options;

    const availableOptions = options.filter(o => !o.disabled);

    control?.value?.length === availableOptions.length
      ? control.setValue([])
      : control?.setValue(availableOptions.map(o => o.value));
  }

}
