import { createSelector } from '@ngrx/store';
import { ProjectState, selectProjectState } from 'app/store/project/project.state';
import * as fromStatuses from './statuses.reducer';
import { Uuid } from 'shared/common/types';

export const statusesState = createSelector(
  selectProjectState,
  state => state.statuses
);

export const statuses = createSelector(
  selectProjectState,
  (state: ProjectState) => fromStatuses.selectAll(state.statuses)
);

export const activeStatuses = createSelector(
  selectProjectState,
  state => fromStatuses.selectAll(state.statuses).filter(s => !s.isRemoved)
);

export const statusEntities = createSelector(
  selectProjectState,
  state => fromStatuses.selectEntities(state.statuses)
);

export const oneStatus = createSelector(
  selectProjectState,
  (state: ProjectState, props: { id: Uuid }) => fromStatuses.selectEntities(state.statuses)[props.id]
);

export const statusesLoading = createSelector(
  selectProjectState,
  state => state.statuses.loading
);
