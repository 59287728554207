import { NgModule } from '@angular/core';
import { Resolve, RouterModule, Routes } from '@angular/router';
import { DevLayoutComponent } from 'app/modules/dev/dev-layout.component';
import { AuthGuard } from 'core/guards/auth.guard';
import { DevGuard } from 'core/guards/dev.guard';
import { AssignedProjectsResolver, NotSawChangelogResolver } from 'project/project-routing.module';
import { Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { DevInjectable } from 'core/http/dev/dev-services.module';
import { getDevCouriers } from 'app/store/dev/couriers/couriers.actions';
import { devCouriersState } from 'app/store/dev/couriers/couriers.selectors';
import { getDevDocuments } from 'app/store/dev/documents/documents.actions';
import { devDocumentsState } from 'app/store/dev/documents/documents.selectors';
import { PageNotFoundComponent } from 'project/pages/page-not-found/page-not-found.component';
import { resetCurrentProject } from 'app/store/project/current-project/current-project.actions';

@DevInjectable()
export class DevStateResolver implements Resolve<any> {

  constructor(private store$: Store) { }

  resolve(): Observable<any> {
    this.store$.dispatch(getDevCouriers());
    this.store$.dispatch(getDevDocuments());

    return forkJoin({
      couriers: this.store$.select(devCouriersState).pipe(first(s => s.isLoaded)),
      documents: this.store$.select(devDocumentsState).pipe(first(s => s.isLoaded)),
    })
      .pipe(
        tap(() => this.store$.dispatch(resetCurrentProject()))
      );
  }

}

const routes: Routes = [
  {
    path: '',
    component: DevLayoutComponent,
    canActivate: [AuthGuard, DevGuard],
    canActivateChild: [AuthGuard, DevGuard],
    resolve: {
      changelog: NotSawChangelogResolver,
      projects: AssignedProjectsResolver,
      state: DevStateResolver
    },
    children: [
      {
        path: '',
        redirectTo: 'couriers',
        pathMatch: 'full'
      },
      {
        path: 'couriers',
        loadChildren: () => import('./modules/courier/courier.module').then(m => m.CourierModule)
      },
      {
        path: 'documents',
        loadChildren: () => import('./modules/document/document.module').then(m => m.DocumentModule)
      },
      {
        path: '**',
        component: PageNotFoundComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DevRoutingModule {
}
