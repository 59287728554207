import { NgModule } from '@angular/core';
import { NoticesLogComponent } from 'history/notices-log/notices-log.component';
import { NoticesLogRoutingModule } from 'history/notices-log/notices-log-routing.module';
import { SharedModule } from 'shared/shared.module';
import { NoticeInfoComponent } from './notice-info/notice-info.component';
import { NoticesFilterComponent } from './notices-filter/notices-filter.component';

@NgModule({
  declarations: [
    NoticesLogComponent,
    NoticeInfoComponent,
    NoticesFilterComponent
  ],
  imports: [
    NoticesLogRoutingModule,
    SharedModule
  ]
})
export class NoticesLogModule {}
