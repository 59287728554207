import { Directive, OnDestroy, OnInit, Self } from '@angular/core';
import { FormControlName } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({ selector: '[syncControl]' })
export class SyncControlDirective implements OnInit, OnDestroy {

  valueChangesSub?: Subscription;

  constructor(@Self() private formControlName: FormControlName) {}

  ngOnInit(): void {
    const next = (value: any) => {
      // Отписываемся от получения изменений чтобы не сдохнуть в рекурсии...
      if (this.valueChangesSub) {
        this.valueChangesSub.unsubscribe();
      }

      // Делаем повторное изменение FromControl для того чтобы уведомить все input о новом значении.
      this.formControlName.control.patchValue(value);

      // Снова делаем подписку на изменения.
      this.valueChangesSub = this.formControlName.control.valueChanges.subscribe(next);
    };

    next(this.formControlName.control.value);
  }

  ngOnDestroy(): void {
    if (this.valueChangesSub && !this.valueChangesSub.closed) {
      this.valueChangesSub.unsubscribe();
    }
  }

}
