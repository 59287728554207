import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsDevDirective } from 'shared/modules/directives/is-dev.directive';
import { CopyableDirective } from 'shared/modules/directives/copyable.directive';
import { IsRootDirective } from 'shared/modules/directives/is-root.directive';
import { AbsoluteDirective } from 'shared/modules/directives/absolute.directive';
import { FormControlExportDirective, FormGroupExportDirective } from './control.directive';
import { SyncControlDirective } from './sync-control.directive';
import { ProjectRouteDirective } from './project-route.directive';
import { TabHighlightDirective } from 'shared/modules/directives/tab-highlight.directive';

@NgModule({
  declarations: [
    IsRootDirective,
    IsDevDirective,
    AbsoluteDirective,
    CopyableDirective,
    FormControlExportDirective,
    FormGroupExportDirective,
    SyncControlDirective,
    ProjectRouteDirective,
    TabHighlightDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    IsRootDirective,
    IsDevDirective,
    AbsoluteDirective,
    CopyableDirective,
    FormControlExportDirective,
    FormGroupExportDirective,
    SyncControlDirective,
    ProjectRouteDirective,
    TabHighlightDirective
  ]
})
export class DirectivesModule {
}
