
import 'reflect-metadata';
import { AppModule } from 'app/app.module';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import * as Sentry from '@sentry/browser';

if (environment.production) {
  Sentry.init({
    dsn: environment.SENTRY_DNS
  });

  enableProdMode();
}


const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
bootstrap().catch(err => console.log(err));
