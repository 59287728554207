import { Action, createReducer, on } from '@ngrx/store';
import * as DocumentsActions from './documents.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { DocumentOutput } from 'core/http/root';

export interface DocumentsState extends EntityState<DocumentOutput> {
  isLoaded: boolean;
}

const adapter: EntityAdapter<DocumentOutput> = createEntityAdapter();

export const initialDocumentsState = adapter.getInitialState({
  isLoaded: false
});

const reducer = createReducer(
  initialDocumentsState,
  on(DocumentsActions.saveDocuments, (state, { documents }) => adapter.setAll(documents, state)),
  on(DocumentsActions.markDocumentsLoaded, state => ({ ...state, isLoaded: true })),
  on(DocumentsActions.updateDocumentExtensionProjectsInStore, (state, { update }) => adapter.updateOne(update, state))
);

export function documentsReducer(state: DocumentsState | undefined, action: Action): DocumentsState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectEntities,
  selectTotal
} = adapter.getSelectors();
