import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectSchedule } from 'core/http/root';
import { TimeHelper } from 'shared/common/types';

@Component({
  selector: 'app-project-schedule-dialog',
  templateUrl: './project-schedule-dialog.component.html',
  styleUrls: ['./project-schedule-dialog.component.scss']
})
export class ProjectScheduleDialogComponent {

  readonly schedule = {} as ProjectSchedule;
  readonly days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  readonly form = this.getForm();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ProjectSchedule | undefined,
    private fb: FormBuilder
  ) {
    if (!data) {
      return;
    }

    const { hour, minute } = TimeHelper.fromUtcToLocal(data.hour, data.minute);
    this.schedule = { ...data, hour, minute };

    this.form = this.getForm(this.schedule);
  }

  private getForm(initial?: ProjectSchedule): FormGroup {
    return this.fb.group({
      hour: this.fb.control(initial?.hour || 0, [Validators.required, Validators.max(23), Validators.min(0)]),
      minute: this.fb.control(initial?.minute || 0, [Validators.required, Validators.max(59), Validators.min(0)]),
      days: this.fb.control(initial?.days || [], Validators.required)
    });
  }

}
