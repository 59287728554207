import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { PoolOutput } from 'core/http/project';
import {
  CompletedEntityState,
  initialCompletedEntityState
} from 'app/store/completed-entity-state/completed-entity-state';
import * as PoolsActions from './pools.actions';

export interface PoolsState extends CompletedEntityState<PoolOutput> {}

const adapter: EntityAdapter<PoolOutput> = createEntityAdapter<PoolOutput>();

export const initialPoolsState = adapter.getInitialState(initialCompletedEntityState);

const reducer = createReducer(
  initialPoolsState,
  on(PoolsActions.savePools, (state, { pools }) => adapter.setAll(pools, state)),
  on(PoolsActions.addPoolInStore, (state, pool) => adapter.addOne(pool, state)),
  on(PoolsActions.markPoolsLoaded, state => ({ ...state, loaded: true })),
  on(PoolsActions.updatePoolInStore, (state, { update }) => adapter.updateOne(update, state)),
  on(PoolsActions.removePoolFromStore, (state, { id }) => adapter.removeOne(id, state))
);

export function poolsReducer(state: PoolsState | undefined, action: Action): PoolsState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
