import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { has, or } from 'shared/criteria/Criteria';
import { FilterSource } from 'shared/modules/table/table-utils/table-directives';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent extends FilterSource<string> implements OnInit {

  search = new FormControl('');

  @Input() appearance: 'standard' | 'outline' = 'standard';
  @Input() label = 'Поиск';
  @Input() field: string | string[] = '';
  @Input() delay = 700;

  // Если поиск строится на фильтрации данных из Store, то ставим true
  @Input() localeSearch = false;

  ngOnInit(): void {
    this.search.valueChanges
      .pipe(
        startWith(''),
        debounceTime(this.delay),
        map(value => {
          if (!value) {
            return '';
          }

          if (this.localeSearch) {
            return value;
          }

          return Array.isArray(this.field)
            ? or(...this.field.map(field => has.text(field, value)))
            : has.text(this.field, value);
        })
      )
      .subscribe(filter => this.filterChanged.emit(filter));
  }

}
