import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateOrderOutput, NotCreatedOrder, OrderOutput } from 'core/http/project';

@Component({
  selector: 'app-create-orders-result-dialog',
  templateUrl: './create-orders-result-dialog.component.html',
  styleUrls: ['./create-orders-result-dialog.component.scss']
})
export class CreateOrdersResultDialogComponent {

  readonly accepted = Array<OrderOutput>();
  readonly rejected = Array<NotCreatedOrder>();

  constructor(@Inject(MAT_DIALOG_DATA) data: CreateOrderOutput) {
    this.accepted = data.accepted;
    this.rejected = data.rejected;
  }

}
