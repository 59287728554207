import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { UserTemplatesService } from 'core/http/project/rule-set/user-templates.service';
import { LoaderService } from 'core/services/loader.service';
import { CompletedEntityStateService } from 'app/store/completed-entity-state/completed-entity-state.service';
import { createProperties } from 'app/store/completed-entity-state/completed-entity-state';
import * as FilterTemplatesActions from './filter-templates.actions';
import * as NotifierActions from '../../common-effects/notifier.effects';

@Injectable()
export class FilterTemplatesEffects {

  private readonly properties = createProperties(
    FilterTemplatesActions.setLoading,
    FilterTemplatesActions.markFilterTemplates,
    FilterTemplatesActions.setError,
    () => this.userTemplatesService.getTemplates()
  );

  getFilterTemplates$ = createEffect(() => this.actions$.pipe(
    ofType(FilterTemplatesActions.getFilterTemplates),
    exhaustMap(() => this.completedEntityStateService.get(this.properties)),
    map(filterTemplates => FilterTemplatesActions.saveFilterTemplates({ filterTemplates: filterTemplates }))
  ));

  addFilterTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(FilterTemplatesActions.addFilterTemplate),
    tap(() => this.loader.begin()),
    exhaustMap(({ template }) => this.userTemplatesService.addTemplate(template)),
    tap(() => this.loader.end()),
    switchMap(template => [
      FilterTemplatesActions.addFilterTemplateInStore({ template }),
      NotifierActions.notify({ message: 'Шаблон добавлен' })
    ])
  ));

  removeFilterTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(FilterTemplatesActions.removeFilterTemplate),
    tap(() => this.loader.begin()),
    exhaustMap(({ id }) => this.userTemplatesService.removeTemplate(id).pipe(mapTo(id))),
    tap(() => this.loader.end()),
    switchMap(id => [
      FilterTemplatesActions.removeFilterTemplateFromStore({ id }),
      NotifierActions.notify({ message: 'Шаблон удален' })
    ])
  ));

  constructor(
    private actions$: Actions,
    private userTemplatesService: UserTemplatesService,
    private loader: LoaderService,
    private completedEntityStateService: CompletedEntityStateService
  ) { }

}
