import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { DevInjectable } from 'core/http/dev/dev-services.module';
import { ProjectOutput } from 'core/http/root';

export interface DevCourierOutput {
  id: string;
  code: string;
  name: string;
  unloaderClass: string;
  trackerClass: string | null;
  cancellerClass: string;
  enabledProjects: ProjectOutput[];
  trackPatterns: string[];
}

export interface DevCourierInput {
  name: string;
  unloaderClass: string;
  cancellerClass: string | null;
  trackerClass: string | null;
  enabledProjects: string[];
  code: string;
}

@DevInjectable()
export class CourierService {

  constructor(private http: HttpClient) {}

  getAllCouriers(): Observable<DevCourierOutput[]> {
    return this.http.get<DevCourierOutput[]>(apiUrl(`/dev/couriers`));
  }

  createCourier(input: DevCourierInput): Observable<DevCourierOutput> {
    return this.http.post<DevCourierOutput>(apiUrl('/dev/courier'), input);
  }

  updateCourier(courierId: string, input: DevCourierInput): Observable<DevCourierOutput> {
    return this.http.put<DevCourierOutput>(apiUrl(`/dev/courier/${courierId}`), input);
  }

  getIntegrationClasses(): Observable<string[]> {
    return this.http.get<string[]>(apiUrl(`/dev/courier-integrations`));
  }

  getTrackerClasses(): Observable<string[]> {
    return this.http.get<string[]>(apiUrl('/dev/courier-trackers'));
  }

  getCancellerClasses(): Observable<string[]> {
    return this.http.get<string[]>(apiUrl('/dev/courier-cancellers'));
  }

  getCourier(courierId: string): Observable<DevCourierOutput> {
    return this.http.get<DevCourierOutput>(apiUrl(`/dev/courier/${courierId}`));
  }

}
