import { Component, Inject } from '@angular/core';
import { SenderOutput } from 'core/http/project/sender/sender.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CourierOutput, StorehouseOutput, UnloadingService } from 'core/http/project';
import { filter, map, mergeMap } from 'rxjs/operators';
import { UnloadingCheckDialogsService } from 'core/http/project/courier/unloading-check-dialogs.service';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { errorNotify } from 'app/store/common-effects/notifier.effects';

export interface UnloadingDialogData {
  senders$: Observable<SenderOutput[]>;
  storehouses$: Observable<StorehouseOutput[]>;
  orderCount: number;
  courier: CourierOutput;
}

@Component({
  selector: 'app-unloading-data',
  templateUrl: './unloading-data-dialog.component.html'
})
export class UnloadingDataDialogComponent {

  readonly senders$;
  readonly storehouses$;
  readonly orderCount;
  readonly courier: CourierOutput;

  private _form = new FormGroup({
    sender: new FormControl(null, Validators.required),
    storehouse: new FormControl(null, Validators.required)
  });

  get form(): FormGroup {
    return this._form;
  }

  get sendersCount$(): Observable<number> {
    return this.senders$.pipe(map(s => s.length));
  }

  get storehousesCount$(): Observable<number> {
    return this.storehouses$.pipe(map(s => s.length));
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) data: UnloadingDialogData,
    private store$: Store,
    private unloadingService: UnloadingService,
    private unloadingCheckDialogsService: UnloadingCheckDialogsService
  ) {
    this.senders$ = data.senders$;
    this.storehouses$ = data.storehouses$;
    this.orderCount = data.orderCount;
    this.courier = data.courier;
  }

  checkReserves(): Observable<{ sender: SenderOutput; storehouse: StorehouseOutput } | null> {
    const { sender, storehouse } = this.form.value as { sender: SenderOutput, storehouse: StorehouseOutput };

    return this.unloadingService.checkCourierReserve(this.courier.id, sender?.id)
      .pipe(
        mergeMap(data => this.unloadingCheckDialogsService.checkReservesIfNeed(data.with, this.orderCount)),
        filter(check => {
          if (!check) {
            this.store$.dispatch(errorNotify({ message: 'Нехватка треков в резерве' }));
            return false;
          }

          return true;
        }),
        mergeMap(() => this.unloadingService.checkCourierAssociation(this.courier.id)),
        mergeMap(data => this.unloadingCheckDialogsService.checkAssociationsIfNeed([data])),
        map(check => check ? { sender, storehouse } : null)
      );
  }

}
