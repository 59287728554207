import * as moment from 'moment';
import { Uuid } from 'shared/common/types';

export class Criteria {

  constructor(private readonly params: CriteriaParams = {}) {}

  get limit(): number | undefined {
    return this.params.limit;
  }

  get offset(): number | undefined {
    return this.params.offset;
  }

  get filter(): string | undefined {
    return this.params.filter;
  }

  get ordering(): Ordering | undefined {
    return this.params.ordering;
  }

  clone(params: CriteriaParams): Criteria {
    return new Criteria({
      limit: params.limit || this.limit,
      offset: params.offset || this.offset,
      filter: params.filter || this.filter,
      ordering: params.ordering || this.ordering
    });
  }

}

export function serializeCriteria(c: Criteria | CriteriaParams): string {
  const parts = [];

  if (c.filter) {
    parts.push(c.filter);
  }

  if (c.limit) {
    parts.push(`limit ${c.limit}`);
  }

  if (c.offset) {
    parts.push(`offset ${c.offset}`);
  }

  if (c.ordering) {
    parts.push(`ordering ${c.ordering.field}:${c.ordering.ascending}`);
  }

  return parts.join(' ');
}

export interface CriteriaParams {
  filter?: string;
  limit?: number;
  offset?: number;
  ordering?: Ordering;
}

export interface Ordering {
  field: string;
  ascending: 'asc' | 'desc';
}

export function and(...parts: Array<string | undefined>): string {
  return joinWithCondition('and', ...parts);
}

export function or(...parts: Array<string | undefined>): string {
  return joinWithCondition('or', ...parts);
}

function joinWithCondition(condition: 'and' | 'or', ...parts: Array<string | undefined>): string {
  const filtered = parts.filter(p => !!p && p.length);

  if (filtered.length === 0) {
    return '';
  }

  if (filtered.length === 1) {
    return filtered[0]!;
  }

  return '(' + filtered.join(` ${condition} `) + ')';
}

export function not(filter?: string): string | undefined {
  return !!filter ? `not ${filter}` : undefined;
}

export const ordering = {
  asc: (field: string): Ordering => ({field, ascending: 'asc'}),
  desc: (field: string): Ordering => ({field, ascending: 'desc'})
};

export const compare = {
  eq: (field: string, v?: number | string, condition?: string) => _compare(field, '=', v, condition),
  lt: (field: string, v?: number | string) => _compare(field, '<', v),
  gt: (field: string, v?: number | string) => _compare(field, '>', v),
  lte: (field: string, v?: number | string) => _compare(field, '<=', v),
  gte: (field: string, v?: number | string) => _compare(field, '>=', v),
  empty: (field: string) => `${field}:is_empty{}`
};

export const has = {
  text: (field: string, text?: string) => !!text ? `${field}:contains{${text.trim()}}` : undefined,
  values: (field: string, values: Array<number | string>) => {
    const v = values.filter(current => undefined !== current);
    return v.length ? `${field}:in{${v.join(',')}}` : undefined;
  }
};

export const between = {

  numbers: (field: string, from?: number, to?: number) => {
    if (from && to) {
      return `${field}:between{${from},${to}}`;
    } else if (from) {
      return `${field}:compare{>=${from}}`;
    } else if (to) {
      return `${field}:compare{<=${to}}`;
    }

    return undefined;
  },

  dates: (field: string, from?: moment.Moment, to?: moment.Moment) => {
    const fromDate = from ? from.format('Y-MM-DD') : '';
    const toDate = to ? to.format('Y-MM-DD') : '';

    if (fromDate && toDate) {
      return `${field}:between{${fromDate} 00:00:00,${toDate} 23:59:59}`;
    } else if (fromDate) {
      return `${field}:between{${fromDate} 00:00:00,${_endOfCurrentDay()}}`;
    } else if (toDate) {
      return `${field}:between{${_beginOfCurrentDay()},${toDate} 23:59:59}`;
    }

    return undefined;
  }

};

export const fromRelated = {
  unloadedOrdersFromUnloading: (unloadingId: string) => {
    return _fromRelated(unloadingId, 'unloaded_orders_from_unloading');
  },
  notUnloadedOrdersFromUnloading: (unloadingId: string) => {
    return _fromRelated(unloadingId, 'not_unloaded_orders_from_unloading');
  },
  ordersFromUnloadedPass: (passId: string) => {
    return _fromRelated(passId, 'unloaded_orders_from_pass');
  },
  ordersFromRejectedPass: (passId: string) => {
    return _fromRelated(passId, 'rejected_orders_from_pass');
  },
  ordersFromPrinting: (printingId: string) => {
    return _fromRelated(printingId, 'orders_from_printing');
  },
  ordersFromClaim: (claimId: string) => {
    return _fromRelated(claimId, 'orders_from_claim');
  },
  ordersFromStatusChangeGroup: (groupId: Uuid) => {
    return _fromRelated(groupId, 'orders_from_status_change_group');
  }
};

function _fromRelated(id: string, related: string): string {
  return id ? `id:from_related{${related},${id}}` : '';
}

function _beginOfCurrentDay(): string {
  return moment().format('Y-MM-DD 00:00:00');
}

function _endOfCurrentDay(): string {
  return moment().format('Y-MM-DD 23:59:59');
}

function _compare(field: string, operator: string, value?: string | number, condition: string = 'compare'): string | undefined {
  return undefined !== value ? `${field}:${condition}{${operator}${value}}` : undefined;
}
