import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs/operators';
import { DocumentService } from 'core/http/project';
import { createProperties } from 'app/store/completed-entity-state/completed-entity-state';
import { CompletedEntityStateService } from 'app/store/completed-entity-state/completed-entity-state.service';
import * as DocumentsActions from './documents.actions';

@Injectable()
export class DocumentsEffects {

  private readonly properties = createProperties(
    DocumentsActions.setLoading,
    DocumentsActions.markDocumentsLoaded,
    DocumentsActions.setError,
    () => this.documentService.getAllDocuments(),
    'documents'
  );

  getDocuments$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentsActions.getDocuments),
    exhaustMap(() => this.completedEntityStateService.get(this.properties)),
    map(documents => DocumentsActions.saveDocuments({ documents }))
  ));

  constructor(
    private actions$: Actions,
    private documentService: DocumentService,
    private completedEntityStateService: CompletedEntityStateService
  ) { }

}
