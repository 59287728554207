import { Observable, throwError } from 'rxjs';
import { catchError, first, map, switchMap, switchMapTo } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { selectFirst } from 'core/utils/rx-common';
import { loggedUser, loggedUserState, refreshing } from 'app/store/login/logged-user/logged-user.selectors';
import * as LoginActions from 'app/store/login/logged-user/logged-user.actions';
import { logout } from 'app/store/login/logged-user/logged-user.actions';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  constructor(private store$: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store$.pipe(
      selectFirst(loggedUser),
      switchMap(user => {
        if (!(user && user.accessToken)) {
          return next.handle(request);
        }

        return next.handle(this.withToken(request, user.accessToken)).pipe(
          catchError((e: HttpErrorResponse) => {
            if (e.status === 403 && e?.error?.error === 'ACCESS_TOKEN_NOT_ALIVE_EXCEPTION') {
              return this.handleExpiration(request, next);
            }

            if (e.status === 400 && e?.error?.error === 'TOKEN_NOT_FOUND_ERROR' || e?.error?.error === 'REFRESH_TOKEN_NOT_ALIVE_ERROR') {
              this.store$.dispatch(logout());
            }

            return throwError(e);
          })
        );
      })
    );
  }

  private handleExpiration(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store$.pipe(
      selectFirst(loggedUserState),
      map(s => {
        if (s.isRefreshing) {
          return;
        }

        this.store$.dispatch(LoginActions.markRefreshing({ value: true }));
        this.store$.dispatch(LoginActions.refreshToken({ token: s.token!.refreshToken }));
      }),
      switchMap(() => this.retryLater(request, next))
    );
  }

  private retryLater(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store$.select(refreshing).pipe(
      first(v => !v),
      switchMapTo(this.store$),
      selectFirst(loggedUser),
      switchMap(tokenPair => {
        return next.handle(this.withToken(request, tokenPair!.accessToken));
      })
    );
  }

  private withToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: { 'Authorization': `Bearer ${token}` }
    });
  }

}
