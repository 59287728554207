import { createAction, props } from '@ngrx/store';
import { CreateWebhookInput, UpdateWebhookInput, WebhookOutput } from 'core/http/root';
import { Criteria } from 'shared/criteria/Criteria';
import { KeyValue, Uuid } from 'shared/common/types';
import { Update } from '@ngrx/entity';

export const getWebhooks = createAction('[Webhooks Request] Get Webhooks');
export const saveWebhooks = createAction('[Webhooks State] Save Webhooks In Store', props<{ webhooks: WebhookOutput[] }>());
export const markWebhooksLoaded = createAction('[Webhooks State] Mark Webhooks Loaded');

export const createIfPossible = createAction('[Webhook Request] Has Webhook', props<{ criteria: Criteria, input: CreateWebhookInput }>());
export const createWebhook = createAction('[Webhooks Request] Create Webhook', props<{ input: CreateWebhookInput }>());
export const addWebhookInStore = createAction('[Webhooks Store] Add Webhook In Store', props<{ webhook: WebhookOutput }>());

export const updateWebhook = createAction('[Webhook Request] Update Webhook', props<{ id: Uuid, input: UpdateWebhookInput }>());
export const updateWebhookInStore = createAction('[Webhook Store] Update Webhook In Store', props<{ update: Update<WebhookOutput> }>());

export const removeWebhook = createAction('[Webhook Request] Remove Webhook', props<{ id: Uuid }>());
export const removeWebhookFromStore = createAction('[Webhook Store] Remove Webhook From Store', props<{ id: Uuid }>());

export const getEvents = createAction('[Webhook Request] Get Events');
export const saveEvents = createAction('[Webhook Store] Save Events In Store', props<{ events: KeyValue[] }>());
