import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UploadFileDialogComponent } from 'shared/dialogs/upload-file-dialog/upload-file-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-file-button',
  templateUrl: './upload-file-button.component.html',
  styleUrls: ['./upload-file-button.component.scss']
})
export class UploadFileButtonComponent {

  @Input() text?: string;
  @Input() disabled = false;
  @Output() loaded = new EventEmitter<string[]>();

  constructor(private dialog: MatDialog) { }

  openUploadDialog(): void {
    const dialogRef = this.dialog.open(UploadFileDialogComponent);

    dialogRef.afterClosed().subscribe((result: string[]) => {
      if (!result) {
        return;
      }

      this.loaded.emit(result);
    });
  }

}
