import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { tap } from 'rxjs/operators';

import * as AdditionFieldsActions from './addition-fields.actions';

@Injectable()
export class AdditionFieldsEffects {

  writeInStore$ = createEffect(() => this.actions$.pipe(
    ofType(AdditionFieldsActions.writeAdditions),
    tap(({ fields }) => localStorage.setItem('selectedAdditions', JSON.stringify(fields)))
  ), { dispatch: false });

  constructor(private actions$: Actions) { }

}
