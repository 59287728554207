import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ListActionsDirective,
  ListBodyDirective,
  ListComponent,
  ListContainerDirective, ListHeadDirective,
} from './list.component';
import { ListEagerProviderDirective, ListPaginatedProviderDirective } from 'shared/modules/list/providers';
import { MaterialModule } from 'shared/modules/material/material.module';
import { SkeletonModule } from 'shared/modules/skeleton/skeleton.module';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

@NgModule({
  declarations: [
    ListComponent,
    ListEagerProviderDirective,
    ListPaginatedProviderDirective,
    ListActionsDirective,
    ListBodyDirective,
    ListHeadDirective,
    ListContainerDirective
  ],
  exports: [
    ListComponent,
    ListEagerProviderDirective,
    ListPaginatedProviderDirective,
    ListActionsDirective,
    ListBodyDirective,
    ListHeadDirective,
    ListContainerDirective
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SkeletonModule,
    VirtualScrollerModule
  ]
})
export class ListModule {
}
