import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SmallTextColor } from 'shared/modules/typography/small-text/colorized-text.component';

export type HeadingValueType = string | number | null | undefined;

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeadingComponent {

  @Input() headTooltip?: string;
  @Input() subTooltip?: string;
  @Input() subtitleColor: SmallTextColor = 'gray';
  @Input() titleColor: SmallTextColor = 'black';
  @Input() hAlign: 'left' | 'center' | 'right' = 'left';
  @Input() titleFontWeight = '400';

  titles = Array<string>();
  subtitles = Array<string>();

  @Input() set title(value: string | string[]) {
    this.titles = normalizeHeadingValues(value);
  }

  @Input() set subtitle(value: HeadingValueType | HeadingValueType[]) {
    this.subtitles = normalizeHeadingValues(value);
  }

  trackByIndex(i: number): number {
    return i;
  }

}

function normalizeHeadingValues(value: HeadingValueType | HeadingValueType[]): string[] {
  return (Array.isArray(value) ? value : [value])
    .filter(i => i !== null && i !== undefined)
    .map(s => `${s}`.trim())
    .filter(s => s !== '');
}
