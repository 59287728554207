import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { SenderService } from 'core/http/project';
import { LoaderService } from 'core/services/loader.service';
import { CompletedEntityStateService } from 'app/store/completed-entity-state/completed-entity-state.service';
import { createProperties } from 'app/store/completed-entity-state/completed-entity-state';
import * as SendersActions from 'app/store/project/senders/senders.actions';
import * as NotifierActions from 'app/store/common-effects/notifier.effects';
import * as ProjectRouterActions from 'app/store/common-effects/router.effects';

@Injectable()
export class SendersEffects {

  private readonly properties = createProperties(
    SendersActions.setLoading,
    SendersActions.markSenderLoaded,
    SendersActions.setError,
    () => this.senderService.getAllSenders(),
    'senders'
  );

  getSenders$ = createEffect(() => this.actions$.pipe(
    ofType(SendersActions.getSenders),
    exhaustMap(() => this.completedEntityStateService.get(this.properties)),
    map(senders => SendersActions.saveSenders({ senders }))
  ));

  createSender$ = createEffect(() => this.actions$.pipe(
    ofType(SendersActions.createSender),
    tap(() => this.loader.begin()),
    exhaustMap(({ input }) => this.senderService.createSender(input)),
    tap(() => this.loader.end()),
    switchMap(sender => [
      SendersActions.addSenderInStore({ sender }),
      NotifierActions.notify({ message: 'SENDERS.CREATE.sender_created_message' }),
      ProjectRouterActions.navigateToProject({ path: ['setting', 'senders'] })
    ])
  ));

  updateSender$ = createEffect(() => this.actions$.pipe(
    ofType(SendersActions.updateSender),
    tap(() => this.loader.begin()),
    exhaustMap(({ id, input }) => this.senderService.updateSender(id, input)),
    tap(() => this.loader.end()),
    switchMap(sender => [
      SendersActions.updateSenderInStore({ update: { id: sender.id, changes: sender } }),
      NotifierActions.notify({ message: 'SENDERS.UPDATE.sender_updated_message' }),
      ProjectRouterActions.navigateToProject({ path: ['setting', 'senders'] })
    ])
  ));

  activateSender$ = createEffect(() => this.actions$.pipe(
    ofType(SendersActions.activateSender),
    tap(() => this.loader.begin()),
    exhaustMap(payload => this.senderService.activateSender(payload.id)),
    tap(() => this.loader.end()),
    switchMap(sender => [
      SendersActions.updateSenderInStore({ update: { id: sender.id, changes: sender } }),
      NotifierActions.notify({ message: 'SENDERS.TABLE.sender_activated_message' })
    ])
  ));

  deactivateSender$ = createEffect(() => this.actions$.pipe(
    ofType(SendersActions.deactivateSender),
    tap(() => this.loader.begin()),
    exhaustMap(payload => this.senderService.deactivateSender(payload.id)),
    tap(() => this.loader.end()),
    switchMap(sender => [
      SendersActions.updateSenderInStore({ update: { id: sender.id, changes: sender } }),
      NotifierActions.notify({ message: 'SENDERS.TABLE.sender_deactivated_message' })
    ])
  ));

  constructor(
    private actions$: Actions,
    private senderService: SenderService,
    private loader: LoaderService,
    private completedEntityStateService: CompletedEntityStateService
  ) { }

}
