import { Injectable } from '@angular/core';
import { ExtensionService } from 'core/http/project/extension/extension.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ExtensionActions from './extensions.actions';
import { exhaustMap, map } from 'rxjs/operators';
import { CompletedEntityStateService } from 'app/store/completed-entity-state/completed-entity-state.service';
import { createProperties } from 'app/store/completed-entity-state/completed-entity-state';

@Injectable()
export class ExtensionsEffects {

  private readonly properties = createProperties(
    ExtensionActions.setLoading,
    ExtensionActions.markExtensionsLoaded,
    ExtensionActions.setError,
    () => this.extensionsService.getAllExtensions(),
    'extensions'
  );

  getExtensions$ = createEffect(() => this.actions$.pipe(
    ofType(ExtensionActions.getExtensions),
    exhaustMap(() => this.completedEntityStateService.get(this.properties)),
    map(extensions => ExtensionActions.saveExtensions({ extensions }))
  ));

  constructor(
    private extensionsService: ExtensionService,
    private actions$: Actions,
    private completedEntityStateService: CompletedEntityStateService
  ) {}

}
