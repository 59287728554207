import { Action, createReducer, on } from '@ngrx/store';
import * as CurrentProjectActions from './current-project.actions';
import { ProjectOutput } from 'core/http/dev';

export interface CurrentProjectState {
  currentProject: ProjectOutput;
  isLoaded: boolean;
}

export const initialCurrentProjectState: CurrentProjectState = {
  currentProject: {} as ProjectOutput,
  isLoaded: false
};

const reducer = createReducer(
  initialCurrentProjectState,
  on(CurrentProjectActions.saveCurrentProject, (state, project) => ({ ...state, currentProject: project })),
  on(CurrentProjectActions.markCurrentProjectLoaded, state => ({ ...state, isLoaded: true })),
  on(CurrentProjectActions.resetCurrentProject, state => ({ currentProject: {} as ProjectOutput, isLoaded: false }))
);

export function currentProjectReducer(state: CurrentProjectState | undefined, action: Action): CurrentProjectState {
  return reducer(state, action);
}
