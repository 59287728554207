import { Directive, Input, NgIterable, TemplateRef } from '@angular/core';

@Directive({ selector: '[app-data-table-row]' })
export class DataTableRowDirective<T> {

  private _dataSource: NgIterable<T> = [];
  private _columns?: string[];

  constructor(private _templateRef: TemplateRef<{ $implicit: T }>) { }

  get template(): TemplateRef<{ $implicit: T }> {
    return this._templateRef;
  }

  @Input('app-data-table-rowFrom')
  set dataSource(value: NgIterable<T>) {
    this._dataSource = value;
  }

  get dataSource(): NgIterable<T> {
    return this._dataSource;
  }

  @Input('app-data-table-rowDisplayOnly')
  set columns(value: string[] | undefined) {
    this._columns = value;
  }

  get columns(): string[] | undefined {
    return this._columns;
  }

}
