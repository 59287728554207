import { ProjectOutput } from 'core/http/dev';
import { RootInjectable } from 'core/http/root/root-services.module';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Criteria } from 'shared/criteria/Criteria';
import { Uuid } from 'shared/common/types';

export interface RootSimpleExtensionOutput {
  id: Uuid;
  name: string;
  projects: ProjectOutput[];
}

@RootInjectable()
export class ExtensionService {

  constructor(private http: HttpClient) { }

  getAllSimpleExtensions(criteria?: Criteria): Observable<RootSimpleExtensionOutput[]> {
    return this.http.get<RootSimpleExtensionOutput[]>(apiUrl('/root/extensions/simple-extensions', criteria));
  }

  updateExtensionProjects(extensionId: string, projectsToEnabling: string[]): Observable<ProjectOutput[]> {
    return this.http.put<ProjectOutput[]>(apiUrl(`/root/extension/${extensionId}/projects`), { projectsToEnabling });
  }

}
