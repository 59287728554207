import { AfterContentInit, Component, ContentChildren, Directive, Input, QueryList, ViewChild } from '@angular/core';
import { MdePopover } from '@material-extended/mde';
import { MatButton } from '@angular/material/button';

@Directive({ selector: '[app-popover-close]' })
export class PopoverCloseDirective {
  get button(): MatButton {
    return this._button!;
  }

  constructor(private _button?: MatButton) {
    if (!_button) {
      throw Error('Button is not provided');
    }
  }
}

@Component({
  selector: 'app-popover-button',
  templateUrl: './popover-button.component.html',
  styleUrls: ['./popover-button.component.scss']
})
export class PopoverButtonComponent implements AfterContentInit {

  @Input() text?: string;
  @Input() icon?: string;
  @Input() color: 'primary' | 'warn' = 'primary';
  @Input() type?: 'stroked' | 'raised' | 'icon';
  @Input() disabled = false;

  @ViewChild(MdePopover)
  popover!: MdePopover;

  @ContentChildren(PopoverCloseDirective)
  closeButtons!: QueryList<PopoverCloseDirective>;

  constructor() {
  }

  ngAfterContentInit(): void {
    this.closeButtons!.forEach(b => {
      const events = b.button._elementRef.nativeElement as GlobalEventHandlers;
      events.onclick = () => this.popover.close.emit();
    });
  }

}
