import { Directive, Self } from '@angular/core';
import { FormControlName, FormGroupName } from '@angular/forms';

@Directive({
  selector: '[formControlName]',
  exportAs: 'control'
})
export class FormControlExportDirective extends FormControlName {
  constructor(@Self() formControlName: FormControlName) {
    super(<any>null, <any>null, <any>null, <any>null, <any>null);
    return formControlName;
  }
}

@Directive({
  selector: '[formGroupName]',
  exportAs: 'control'
})
export class FormGroupExportDirective extends FormGroupName {
  constructor(@Self() formGroupName: FormGroupName) {
    super(<any>null, <any>null, <any>null);
    return formGroupName;
  }
}
