import { createAction, props } from '@ngrx/store';
import {
  NoticeTriggerInput,
  NoticeTriggerOutput,
  NoticeType,
  TelegramChatOutput
} from 'core/http/project/notice/notice.interfaces';
import { Update } from '@ngrx/entity';
import { Uuid } from 'shared/common/types';
import { createSetErrorAction, createSetLoadingAction } from 'app/store/completed-entity-state/completed-entity-state';

export const getNotices = createAction('[Project Request] Get Notices');
export const saveNotices = createAction('[Project Store] Save Notices In Store', props<{ notices: NoticeTriggerOutput[] }>());
export const markNoticesLoaded = createAction('[Project Store] Mark Notices Loaded');

export const createNotice = createAction('[Project Request] Create Notice', props<{ noticeType: NoticeType, input: NoticeTriggerInput }>());
export const addNoticeInStore = createAction('[Project Store] Add Notice In Store', props<{ notice: NoticeTriggerOutput }>());

export const updateNotice = createAction('[Project Request] Update Notice', props<{ noticeType: NoticeType, id: Uuid, input: NoticeTriggerInput }>());
export const updateNoticeInStore = createAction('[Project Store] Update Notice In Store', props<{ update: Update<NoticeTriggerOutput> }>());

export const removeNotice = createAction('[Project Request] Remove Notice', props<{ id: Uuid }>());
export const removeNoticeFromStore = createAction('[Project Store] Remove Notice From Store', props<{ id: Uuid }>());

export const getTelegramChats = createAction('[Project Request] Get Telegram Chats');
export const saveTelegramChats = createAction('[Project Store] Save Telegram Chats', props<{ chats: TelegramChatOutput[] }>());

export const setLoading = createSetLoadingAction('[Project Store] Set Notices Loading');

export const setError = createSetErrorAction('[Project Store] Set Notices Error');
