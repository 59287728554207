import { BackgroundProgress } from 'core/http/project/background/background-task.service';
import { Action, createReducer, on } from '@ngrx/store';
import * as ReadyProgressesActions from './ready-progresses.actions';

export interface ReadyProgressesState {
  progresses: BackgroundProgress[];
}

export const initialReadyProgressesState: ReadyProgressesState = {
  progresses: []
};

const reducer = createReducer(
  initialReadyProgressesState,
  on(ReadyProgressesActions.saveReadyProgress,
    (state, { progress }) => ({ ...state, progresses: [...state.progresses, progress] }))
);

export function readyProgressesReducer(state: ReadyProgressesState | undefined, action: Action): ReadyProgressesState {
  return reducer(state, action);
}
