import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-go-back-button',
  template: `
    <button (click)="click.emit($event)" mat-flat-button>
      <mat-icon class="back-arrow">arrow_back</mat-icon>
      <span [matTooltip]="tooltipText">
        <ng-content></ng-content>
      </span>
    </button>
  `,
  styles: [`
    :host {
      display: block;
    }

    .back-arrow {
      margin-bottom: 2px;
      margin-right: 5px;
    }

    button {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 0;
      padding: 0 5px;
    }
  `]
})
export class GoBackButtonComponent {
  @Output() click = new EventEmitter<MouseEvent>();
  @Input() tooltipText?: string;
}
