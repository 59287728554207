import { Component, Input } from '@angular/core';
import { OrderOutput } from 'core/http/project';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-additional-fields',
  templateUrl: './additional-fields.component.html',
  styleUrls: ['./additional-fields.component.scss']
})
export class AdditionalFieldsComponent {

  private _additionalFields = Array<KeyValue<string, any>>();

  @Input() skeletonView = false;

  get additionalFields(): Array<KeyValue<string, any>> {
    return this._additionalFields;
  }

  @Input()
  set order(order: OrderOutput) {
    this._additionalFields = Object.entries(order.additions)
      .filter(([_, value]) => !!value)
      .map(([key, value]) => ({ key, value }));
  }

}
