import { RootInjectable } from 'core/http/root/root-services.module';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Pagination } from 'shared/common/types';
import { map } from 'rxjs/operators';

export interface ProjectStatusOutput {
  id: string;
  name: string;
  associatedState: number;
  color: string;
  priority: number;
}

@RootInjectable()
export class StatusService {

  constructor(private http: HttpClient) {}

  getProjectStatuses(projectId: string): Observable<ProjectStatusOutput[]> {
    return this.http.get<Pagination<ProjectStatusOutput>>(apiUrl(`/root/statuses/${projectId}`))
      .pipe(
        map(data => data.output)
      );
  }

}
