import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Ordering } from 'shared/criteria/Criteria';

@Component({
  selector: 'app-simple-sort',
  styles: [`
      .sort-column {
          cursor: pointer;
      }

      .sort-column:hover {
          color: rgba(24, 130, 179, 0.82);
      }

      .active-column-sort {
          color: rgba(24, 130, 179, 0.82);
      }
  `],
  template: `
    <span (click)="changeSort()" [matTooltipHideDelay]="1000" [ngClass]="{ 'active-column-sort': isActive('asc') || isActive('desc') }"
          class="sort-column" matTooltip="Отсортировать">
      <ng-content></ng-content>
      <mat-icon *ngIf="isActive('asc')" [inline]="true">arrow_upward</mat-icon>
      <mat-icon *ngIf="isActive('desc')" [inline]="true">arrow_downward</mat-icon>
    </span>
  `
})
export class SimpleSortHeaderComponent implements OnInit {

  @Input() ordering?: Ordering;
  @Output() orderingChange = new EventEmitter<Ordering>();

  @Input() field!: string;

  ngOnInit(): void {
    if (!this.field) {
      throw new Error('columnName is not provided');
    }
  }

  changeSort(): void {
    if (!this.ordering) {
      this.ordering = { field: this.field, ascending: 'desc' };
    } else if (this.ordering.ascending === 'desc') {
      this.ordering = { field: this.field, ascending: 'asc' };
    } else {
      this.ordering = undefined;
    }

    this.orderingChange.emit(this.ordering);
  }

  isActive(ascending: string): boolean | undefined {
    return this.ordering && ascending === this.ordering.ascending && this.field === this.ordering.field;
  }

}
