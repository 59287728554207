import { Component, Inject, OnInit } from '@angular/core';
import { DynamicField, SubsetField } from 'shared/modules/dynamic-form/field-types';
import { FormArray, FormGroup } from '@angular/forms';
import { mapSubset, toFormGroup } from 'shared/modules/dynamic-form/to-form-group';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface SubsetDialogData {
  fields: DynamicField[];
  value?: any;
}

@Component({
  selector: 'app-subset-dialog',
  templateUrl: './subset-dialog.component.html',
  styleUrls: ['./subset-dialog.component.scss']
})
export class SubsetDialogComponent implements OnInit {

  private _fields = Array<DynamicField>();
  readonly _form: FormGroup;

  get fields(): DynamicField[] {
    return this._fields;
  }

  get form(): FormGroup {
    return this._form;
  }

  constructor(@Inject(MAT_DIALOG_DATA) private data: SubsetDialogData) {
    this._form = toFormGroup(data.fields);
  }

  ngOnInit(): void {
    this._fields = this.data.fields;

    if (!this.data.value) {
      return;
    }

    // В FormArray нельзя просто сделать setValue, если у него не задана структура
    // Поэтому делаем все ручками
    this.data.fields
      .filter(f => f.type === 'SUBSET')
      .forEach(s => {
        const fields = (<SubsetField>s).schema;

        mapSubset(fields, this.data.value[s.name])
          .map(toFormGroup)
          .forEach(f => (<FormArray>this.form.controls[s.name]).push(f));
      });

    // Тут значения субсетов проигнорируются
    this.form.patchValue(this.data.value);
  }

}
