import { Action, createReducer, on } from '@ngrx/store';
import * as NoticesActions from './notices.actions';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { NoticeTriggerOutput, TelegramChatOutput } from 'core/http/project/notice/notice.interfaces';
import {
  CompletedEntityState,
  initialCompletedEntityState
} from 'app/store/completed-entity-state/completed-entity-state';

export interface NoticesState extends CompletedEntityState<NoticeTriggerOutput> {
  telegramChats: TelegramChatOutput[];
}

const adapter: EntityAdapter<NoticeTriggerOutput> = createEntityAdapter();

export const initialNoticesState = adapter.getInitialState({
  ...initialCompletedEntityState,
  telegramChats: Array<TelegramChatOutput>()
});

const reducer = createReducer(
  initialNoticesState,
  on(NoticesActions.saveNotices, (state, { notices }) => adapter.setAll(notices, state)),
  on(NoticesActions.saveTelegramChats, (state, { chats }) => ({ ...state, telegramChats: chats })),
  on(NoticesActions.markNoticesLoaded, state => ({ ...state, loaded: true })),
  on(NoticesActions.addNoticeInStore, (state, { notice }) => adapter.addOne(notice, state)),
  on(NoticesActions.updateNoticeInStore, (state, { update }) => adapter.updateOne(update, state)),
  on(NoticesActions.removeNoticeFromStore, (state, { id }) => adapter.removeOne(id, state)),
  on(NoticesActions.setLoading, (state, { value }) => ({ ...state, loading: value })),
  on(NoticesActions.setError, (state, { value }) => ({ ...state, error: value }))
);

export function noticesReducer(state: NoticesState | undefined, action: Action): NoticesState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
