import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, switchMap } from 'rxjs/operators';
import { DocumentService, ExtensionService } from 'core/http/root';
import { forkJoin, of } from 'rxjs';
import * as DocumentsActions from './documents.actions';
import * as NotifyEffects from 'app/store/common-effects/notifier.effects';

@Injectable()
export class DocumentsEffects {

  getDocuments$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentsActions.getDocuments),
    switchMap(() => this.documentService.getAllDocuments()),
    switchMap(documents => [
      DocumentsActions.saveDocuments({ documents }),
      DocumentsActions.markDocumentsLoaded()
    ])
  ));

  updateProjectExtensions$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentsActions.updateDocumentExtensionProjects),
    exhaustMap(({ id, projects }) => forkJoin({
      projects: this.extensionService.updateExtensionProjects(id, projects),
      id: of(id)
    })),
    switchMap(data => [
      DocumentsActions.updateDocumentExtensionProjectsInStore({
        update: { id: data.id, changes: { projects: data.projects } }
      }),
      NotifyEffects.notify({ message: 'Проекты обновлены' })
    ])
  ));

  constructor(
    private actions$: Actions,
    private documentService: DocumentService,
    private extensionService: ExtensionService
  ) {}

}
