import { Action, createReducer, on } from '@ngrx/store';
import * as CouriersActions from './couriers.actions';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { CourierOutput } from 'core/http/project';
import {
  CompletedEntityState,
  initialCompletedEntityState
} from 'app/store/completed-entity-state/completed-entity-state';

export interface CouriersState extends CompletedEntityState<CourierOutput> {}

const adapter: EntityAdapter<CourierOutput> = createEntityAdapter<CourierOutput>();

export const initialCouriersState: CouriersState = adapter.getInitialState(initialCompletedEntityState);

const reducer = createReducer(
  initialCouriersState,
  on(CouriersActions.saveCouriers, (state, payload) => adapter.setAll(payload.couriers, state)),
  on(CouriersActions.markCouriersLoaded, state => ({ ...state, loaded: true })),
  on(CouriersActions.setLoading, (state, { value }) => ({ ...state, loading: value })),
  on(CouriersActions.setError, (state, { value }) => ({ ...state, error: value }))
);

export function couriersReducer(state: CouriersState | undefined, action: Action): CouriersState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectEntities,
  selectTotal
} = adapter.getSelectors();
