import { Component } from '@angular/core';
import { CourierService, StateMatcherInput, StateMatcherOutput } from 'core/http/root';
import { ActivatedRoute } from '@angular/router';
import { ObserverFactory, SuccessNotifier } from 'shared/common/observers';
import { MatcherDialogComponent } from './matcher-dialog/matcher-dialog.component';
import { Uuid } from 'shared/common/types';
import { of, Subject, Subscription, timer } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FieldMap } from 'angular2-query-builder/dist/components';
import { Store } from '@ngrx/store';
import { navigateToRoot } from 'app/store/common-effects/router.effects';
import { EagerSource, RowClasses } from 'shared/modules/table/table-utils/table-interfaces';

@Component({
  selector: 'app-courier-states',
  templateUrl: './courier-matchers.component.html',
  styleUrls: ['./courier-matchers.component.scss']
})
export class CourierMatchersComponent {

  readonly matcherReloader = new Subject();
  readonly columns: string[] = [
    'move',
    'name',
    'state',
    'filter',
    'actions'
  ];
  readonly rowClasses: RowClasses<StateMatcherOutput> = {
    'matched-status': (matcher: StateMatcherOutput) => this.matchedWithStatus.some(m => m.id === matcher.id)
  };

  private _orderFieldMap: FieldMap = {
    'id': {
      name: 'Идентификатор',
      type: 'string'
    },
    'name': {
      name: 'Имя',
      type: 'string'
    }
  };
  private _statusForCheck = '';
  private _isCheckLocked = false;
  private _matchedWithStatus = Array<StateMatcherOutput>();
  private _courierId: string = this.route.snapshot.params.courierId;
  private _matchers: StateMatcherOutput[] = this.route.snapshot.data.matchers;
  private _priorityChangeTask?: Subscription;

  get courierId(): string {
    return this._courierId;
  }

  get matchers(): StateMatcherOutput[] {
    return this._matchers;
  }

  set matchers(value: StateMatcherOutput[]) {
    this._matchers = value;
  }

  get orderFieldMap(): FieldMap {
    return this._orderFieldMap;
  }

  get matchedWithStatus(): StateMatcherOutput[] {
    return this._matchedWithStatus;
  }

  set matchedWithStatus(value: StateMatcherOutput[]) {
    this._matchedWithStatus = value;
  }

  get isCheckLocked(): boolean {
    return this._isCheckLocked;
  }

  set isCheckLocked(value: boolean) {
    this._isCheckLocked = value;
  }

  get statusForCheck(): string {
    return this._statusForCheck;
  }

  set statusForCheck(value: string) {
    this._statusForCheck = value;
  }

  get priorityChangeTask(): Subscription | undefined {
    return this._priorityChangeTask;
  }

  set priorityChangeTask(value: Subscription | undefined) {
    this._priorityChangeTask = value;
  }

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private store$: Store,
    private observers: ObserverFactory,
    private courierService: CourierService
  ) { }

  dataSource: EagerSource<StateMatcherOutput> = () => of(this.matchers);

  goBack(): void {
    this.store$.dispatch(navigateToRoot({ path: ['extensions', 'couriers'] }));
  }

  moveUp(matcher: StateMatcherOutput): void {
    const oldIndex = this.matchers.indexOf(matcher);
    const newIndex = oldIndex - 1;

    if (newIndex < 0) {
      return;
    }

    this.move(oldIndex, newIndex);
    this.enqueuePriorityUpdate();
  }

  moveDown(matcher: StateMatcherOutput): void {
    const oldIndex = this.matchers.indexOf(matcher);
    const newIndex = oldIndex + 1;

    if (newIndex > this.matchers.length - 1) {
      return;
    }

    this.move(oldIndex, newIndex);
    this.enqueuePriorityUpdate();
  }

  runMatching(): void {
    const observer = this.observers.withHook({
      onSuccess: (matchers: StateMatcherOutput[], n: SuccessNotifier) => {
        // Устанавливаем матчеры которые проходят по условию.
        this.matchedWithStatus = matchers;
        this.isCheckLocked = true;

        // После 1.2 (совпадает с временем анимации) сек нужно очистить
        // матчеры и поставить лок на повторную проверку.
        // Иначе анимация не будет работать в след. раз.
        setTimeout(() => {
          this.matchedWithStatus = [];
          this.isCheckLocked = false;
        }, 1200);

        matchers.length > 0
          ? n(`Найдено совпадений: ${matchers.length}`)
          : n('Совпадений не найдено');
      }
    });

    this.courierService
      .checkMatching(this.courierId, this.statusForCheck)
      .subscribe(observer);
  }

  openCreateMatcherDialog(): void {
    this.dialog.open(MatcherDialogComponent).afterClosed()
      .subscribe((input: StateMatcherInput | undefined) => input && this.createMatcher(input));
  }

  openUpdateMatcherDialog(matcher: StateMatcherOutput): void {
    const data: StateMatcherInput = {
      name: matcher.name,
      state: matcher.state,
      ruleSet: matcher.ruleSet
    };

    this.dialog.open(MatcherDialogComponent, { data }).afterClosed()
      .subscribe((input: StateMatcherInput | undefined) => input && this.updateMatcher(matcher, matcher.id, input));
  }

  removeMatcher(matcher: StateMatcherOutput): void {
    const observer = this.observers.withHook({
      successMsg: 'Соответствие удалено',
      onSuccess: () => {
        this.matchers.splice(this.matchers.indexOf(matcher), 1);
        this.matcherReloader.next();
      }
    });

    this.courierService
      .removeMatcher(this.courierId, matcher.id)
      .subscribe(observer);
  }

  private enqueuePriorityUpdate(): void {
    if (this.priorityChangeTask) {
      this.priorityChangeTask.unsubscribe();
    }

    this.priorityChangeTask = timer(800)
      .subscribe(() => {
        this.updatePriorities();
      });
  }

  private updatePriorities(): void {
    const priorities = this.matchers.map((m, i) => {
      return { id: m.id, priority: i };
    });

    this.courierService.changePrioritiesMatchers(this.courierId, priorities)
      .subscribe(this.observers.withHook({ successMsg: 'Приоритеты обновленны', showLoading: false }));
  }

  private move(oldIndex: number, newIndex: number): void {
    const toSwap = this.matchers[newIndex];
    this.matchers[newIndex] = this.matchers[oldIndex];
    this.matchers[oldIndex] = toSwap;

    this.matcherReloader.next();
  }

  private createMatcher(input: StateMatcherInput): void {
    const observer = this.observers.withHook({
      successMsg: 'Соответсвие создано',
      onSuccess: (m: StateMatcherOutput) => {
        this.matchers.push(m);
        this.matcherReloader.next();
      }
    });

    this.courierService
      .createMatcher(this.courierId, input)
      .subscribe(observer);
  }

  private updateMatcher(oldMatcher: StateMatcherOutput, matcherId: Uuid, input: StateMatcherInput): void {
    const observer = this.observers.withHook({
      successMsg: 'Соответствие обновлено',
      onSuccess: () => {
        oldMatcher.name = input.name;
        oldMatcher.state = input.state;
        oldMatcher.ruleSet = input.ruleSet;
      }
    });

    this.courierService
      .updateMatcher(this.courierId, matcherId, input)
      .subscribe(observer);
  }

}
