import { Component, Input } from '@angular/core';
import {
  Compilers,
  FilterChangedEvent,
  Formatters,
  SearchBarFields
} from 'shared/modules/controls/search-bar/search-bar';
import { FormControl, FormGroup } from '@angular/forms';
import { updatePageCriteria } from 'app/store/project/page-criteria/page-criteria.actions';
import { Store } from '@ngrx/store';
import { FilterSource } from 'shared/modules/table/table-utils/table-directives';

@Component({
  selector: 'app-notices-filter',
  templateUrl: './notices-filter.component.html',
  styleUrls: ['./notices-filter.component.scss']
})
export class NoticesFilterComponent extends FilterSource {

  private _key = '';
  private _form = new FormGroup({
    triggerId: new FormControl(),
    createdAt: new FormControl(),
    recipient: new FormControl()
  });

  // readonly noticeTriggers$ = this.store$.select(notices);

  readonly fields: SearchBarFields = {
    'triggerId': {
      label: 'Триггеры уведомлений',
      compiler: Compilers.array('trigger.id'),
      formatter: Formatters.invisible()
    },
    'createdAt': {
      label: 'Создан',
      compiler: Compilers.dates('createdAt'),
      formatter: Formatters.dates()
    },
    'recipient': {
      label: 'Получатель',
      compiler: Compilers.specificText('recipient', '"contact"', t => `"${t}"`),
      formatter: Formatters.justValue()
    }
  };

  get key(): string {
    return this._key;
  }

  @Input()
  set key(value: string) {
    this._key = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  constructor(private store$: Store) {
    super();
  }

  emitFilter(event: FilterChangedEvent): void {
    this.filterChanged.emit(event.filter);

    this.store$.dispatch(updatePageCriteria({
      update: { id: this.key, changes: { formValue: this.form.value, pageIndex: 0 } }
    }));
  }

}
