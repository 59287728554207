import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CourierOutput } from 'core/http/root';
import * as CouriersActions from './couriers.actions';

export interface RootCouriersState extends EntityState<CourierOutput> {
  isLoaded: boolean;
}

const adapter: EntityAdapter<CourierOutput> = createEntityAdapter();

export const initialRootCouriersState = adapter.getInitialState({
  isLoaded: false
});

const reducer = createReducer(
  initialRootCouriersState,
  on(CouriersActions.saveRootCouriers, (state, { couriers }) => adapter.addMany(couriers, state)),
  on(CouriersActions.markRootCouriersLoaded, state => ({ ...state, isLoaded: true })),
  on(CouriersActions.updateCourierExtensionProjectsInStore, (state, { update }) => adapter.updateOne(update, state))
);

export function rootCouriersReducer(state: RootCouriersState | undefined, action: Action): RootCouriersState {
  return reducer(state, action);
}

export const {
  selectAll
} = adapter.getSelectors();
