import { NgModule } from '@angular/core';
import { SelectComponent, SelectLabelDirective } from 'shared/modules/controls/select/select.component';
import { MaterialModule } from 'shared/modules/material/material.module';
import { AngularModule } from 'shared/modules/angular/angular.module';
import { SelectSearchModule } from 'shared/modules/controls/select-search/select-search.module';
import { SelectAllModule } from 'shared/modules/controls/select-all/select-all.module';

@NgModule({
  declarations: [
    SelectComponent,
    SelectLabelDirective
  ],
  imports: [
    MaterialModule,
    AngularModule,
    SelectSearchModule,
    SelectAllModule
  ],
  exports: [
    SelectComponent,
    SelectLabelDirective
  ]
})
export class SelectModule {
}
