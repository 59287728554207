import { Component, Input } from '@angular/core';
import { ControlContainer, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GoodOutput, SenderOutput, StatusOutput, StorehouseOutput } from 'core/http/project';
import { FieldMap } from 'angular2-query-builder/dist/components';
import { DynamicField } from 'shared/modules/dynamic-form/field-types';
import { getFormGroup } from 'shared/modules/addition-fields/addition-add-dialog/get-form-group';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { additionalFieldsWithNumbers } from 'app/store/project/addition-fields/addition-fields.selectors';
import { AdditionalField } from 'app/store/project/addition-fields/addition-fields.reducer';

@Component({
  selector: 'app-addition-form',
  templateUrl: './addition-form.component.html',
  styleUrls: ['./addition-form.component.scss']
})
export class AdditionFormComponent {

  private _goods = Array<GoodOutput>();
  private _fields = Array<DynamicField>();
  private _senders = Array<SenderOutput>();
  private _statuses = Array<StatusOutput>();
  private _storehouses = Array<StorehouseOutput>();
  private _fieldMap: FieldMap = {};
  private _additionalFields$ = this.store$.select(additionalFieldsWithNumbers);
  private _types = [
    'ADDITIONS_INDEX',
    'ENTITY',
    'FILE',
    'NUMERIC',
    'RULE_SET',
    'SELECTABLE',
    'SHORT_TEXT',
    'SUBSET',
    'SWITCHABLE',
    'GROUP'
  ];
  private _mimeTypes = [
    'image/gif',
    'image/jpeg',
    'image/pjpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/vnd.microsoft.icon',
    'image/vnd.wap.wbmp',
    'image/webp'
  ];

  get fields(): DynamicField[] {
    return this._fields;
  }

  @Input()
  set fields(value: DynamicField[]) {
    this._fields = value;
  }

  @Input()
  set senders(senders: SenderOutput[]) {
    this._senders = senders;
  }

  @Input()
  set goods(goods: GoodOutput[]) {
    this._goods = goods;
  }

  @Input()
  set fieldMap(fieldMap: FieldMap) {
    this._fieldMap = fieldMap;
  }

  @Input()
  set statuses(statuses: StatusOutput[]) {
    this._statuses = statuses;
  }

  @Input()
  set storehouses(value: StorehouseOutput[]) {
    this._storehouses = value;
  }

  get statuses(): StatusOutput[] {
    return this._statuses;
  }

  get storehouses(): StorehouseOutput[] {
    return this._storehouses;
  }

  get senders(): SenderOutput[] {
    return this._senders;
  }

  get goods(): GoodOutput[] {
    return this._goods;
  }

  get fieldMap(): FieldMap {
    return this._fieldMap;
  }

  get form(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  get additionalFields$(): Observable<AdditionalField[]> {
    return this._additionalFields$;
  }

  get types(): string[] {
    return this._types;
  }

  get mimeTypes(): string[] {
    return this._mimeTypes;
  }

  get invalidName(): boolean {
    const name = this.form.controls.name.value;
    return this.fields.some(field => field.name === name);
  }

  constructor(
    private store$: Store,
    private controlContainer: ControlContainer,
    private fb: FormBuilder
  ) {
  }

  addSubset(): void {
    const newSubset = new FormGroup({
      type: this.fb.control('NUMERIC'),
      name: this.fb.control(null, Validators.required),
      description: this.fb.control(null, Validators.required),
      value: this.fb.control(0)
    });

    const formArray = this.form.controls.schema as FormArray;
    formArray.push(newSubset);
  }

  addPossibleValue(): void {
    const possibleValues = this.form.controls.values as FormArray;
    const newPossibleValue = this.fb.group({
      value: this.fb.control(null, Validators.required),
      description: this.fb.control(null, Validators.required)
    });

    possibleValues.push(newPossibleValue);
  }

  removeValue(index: number, controlName: 'schema' | 'values'): void {
    const formArray = this.form.get(controlName) as FormArray;
    formArray.removeAt(index);
  }

  changeSubsetForm(name: number, type: DynamicField['type']): void {
    const formArray = this.form.controls.schema as FormArray;
    formArray.setControl(name, getFormGroup(type, this.fb));
  }

}
