import { createSelector } from '@ngrx/store';
import { ProjectState, selectProjectState } from 'app/store/project/project.state';
import * as fromPools from './pools.reducer';
import { Uuid } from 'shared/common/types';

export const poolsState = createSelector(
  selectProjectState,
  state => state.pools
);

export const poolEntities = createSelector(
  selectProjectState,
  (state: ProjectState) => fromPools.selectEntities(state.pools)
);

export const pools = createSelector(
  selectProjectState,
  (state: ProjectState) => fromPools.selectAll(state.pools)
);

export const onePool = createSelector(
  selectProjectState,
  (state: ProjectState, props: { id: Uuid }) => fromPools.selectEntities(state.pools)[props.id]
);

export const poolsLoading = createSelector(
  selectProjectState,
  state => state.pools.loading
);
