import { NgModule } from '@angular/core';
import { SelectAllComponent } from 'shared/modules/controls/select-all/select-all.component';
import { MaterialModule } from 'shared/modules/material/material.module';

@NgModule({
  declarations: [SelectAllComponent],
  imports: [
    MaterialModule
  ],
  exports: [SelectAllComponent]
})
export class SelectAllModule {
}
