import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileInfoModule } from 'account/pages/profile-info/profile-info.module';

import { AccountRoutingModule } from './account-routing.module';
import { AccountComponent } from './componetns/account/account.component';
import { SharedModule } from 'shared/shared.module';

@NgModule({
  declarations: [AccountComponent],
  imports: [
    CommonModule,
    ProfileInfoModule,
    AccountRoutingModule,
    SharedModule
  ]
})
export class AccountModule {
}
