import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangeLogComponent } from 'shared/modules/skeleton/change-log/change-log.component';
import { AuthModule } from 'auth/auth.module';
import { RootModule } from 'root/root.module';
import { DevModule } from 'app/modules/dev/dev.module';
import { AccountModule } from 'account/account.module';
import { ProjectModule } from 'project/project.module';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => AuthModule
  },
  {
    path: 'project',
    loadChildren: () => ProjectModule
  },
  {
    path: 'root',
    loadChildren: () => RootModule
  },
  {
    path: 'dev',
    loadChildren: () => DevModule
  },
  {
    path: 'account',
    loadChildren: () => AccountModule
  },
  {
    path: 'changelog',
    component: ChangeLogComponent,
    data: { title: 'Changelog' }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
