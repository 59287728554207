import { createSelector } from '@ngrx/store';
import { ProjectState, selectProjectState } from 'app/store/project/project.state';
import * as fromSenders from './senders.reducer';
import { Uuid } from 'shared/common/types';

export const sendersState = createSelector(
  selectProjectState,
  state => state.senders
);

export const allSenders = createSelector(
  selectProjectState,
  (state: ProjectState) => fromSenders.selectAll(state.senders)
);

export const senderEntities = createSelector(
  selectProjectState,
  state => fromSenders.selectEntities(state.senders)
);

export const activatedSenders = createSelector(
  selectProjectState,
  (state: ProjectState) => fromSenders.selectAll(state.senders).filter(item => item.isActivated)
);

export const deactivatedSenders = createSelector(
  selectProjectState,
  (state: ProjectState) => fromSenders.selectAll(state.senders).filter(item => !item.isActivated)
);

export const oneSender = createSelector(
  selectProjectState,
  (state: ProjectState, props: { id: Uuid }) => fromSenders.selectEntities(state.senders)[props.id]
);

export const activatedSendersCount = createSelector(
  selectProjectState,
  state => fromSenders.selectAll(state.senders).filter(item => item.isActivated).length
);

export const sendersLoading = createSelector(
  selectProjectState,
  state => state.senders.loading
);
