import { Directive, ElementRef, Host, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectRouteDirective } from 'shared/modules/directives/project-route.directive';

@Directive({ selector: '[project-route] [tab-highlight]' })
export class TabHighlightDirective implements OnInit {

  constructor(
    @Host() private host: ProjectRouteDirective,
    private router: Router,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    // Если при загрузке страницы у нас уже выбран какой-то таб
    if (this.router.url.includes(this.host.href)) {
      this.setStyles();
    }

    // Затем слушаем ивенты
    this.router.events.subscribe(() => {
      if (this.router.url.includes(this.host.href)) {
        this.setStyles();
      }
      else {
        this.el.nativeElement.style.borderBottom = null;
        this.el.nativeElement.style.fontWeight = null;
        this.el.nativeElement.style.opacity = null;
      }
    });
  }

  private setStyles(): void {
    this.el.nativeElement.style.borderBottom = '3px solid #409ED8';
    this.el.nativeElement.style.fontWeight = '500';
    this.el.nativeElement.style.opacity = '1';
  }

}
