import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-go-back-link',
  template: `
    <a [project-route]="route" mat-button>
      <mat-icon class="back-arrow">arrow_back</mat-icon>
      <span [matTooltip]="tooltipText">
        <ng-content></ng-content>
      </span>
    </a>
  `,
  styles: [`
    :host {
      display: block;
    }

    .back-arrow {
      margin-bottom: 2px;
      margin-right: 5px;
    }

    a {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 0;
      padding: 0 5px;
    }
  `]
})
export class GoBackLinkComponent {

  private _route = Array<string>();
  private _tooltipText?: string;

  get route(): string[] {
    return this._route;
  }

  @Input()
  set route(value: string[]) {
    this._route = value;
  }

  get tooltipText(): string | undefined {
    return this._tooltipText;
  }

  @Input()
  set tooltipText(value: string | undefined) {
    this._tooltipText = value;
  }

}
