import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserOutput } from 'core/http/root';
import { ProjectOutput } from 'core/http/dev';
import { Store } from '@ngrx/store';
import { selectFirst, to404PageIfNotFound } from 'core/utils/rx-common';
import { rootProjects } from 'app/store/root/projects/projects.selectors';
import { oneUser } from 'app/store/root/users/users.selectors';
import { assignProjects } from 'app/store/root/users/users.actions';
import * as RouterActions from 'app/store/common-effects/router.effects';

type AssignedProject = ProjectOutput & { isAssigned: boolean };

@Component({
  selector: 'app-assigned-projects',
  templateUrl: './assigned-projects.component.html',
  styleUrls: ['./assigned-projects.component.scss']
})
export class AssignedProjectsComponent implements OnInit {

  private _user = {} as UserOutput;
  private _assignedProjects = Array<AssignedProject>();

  get assignedProjects(): AssignedProject[] {
    return this._assignedProjects;
  }

  get user(): UserOutput {
    return this._user;
  }

  constructor(
    private route: ActivatedRoute,
    private store$: Store
  ) { }

  ngOnInit(): void {
    this.store$.pipe(
      selectFirst(oneUser, { id: this.route.snapshot.params.managerId }),
      to404PageIfNotFound(this.store$, 'root')
    ).subscribe(user => this._user = user);

    this.store$.pipe(
      selectFirst(rootProjects),
      to404PageIfNotFound(this.store$)
    ).subscribe(data => {
      const assignedProjects = this.user.assignedProjects;

      this._assignedProjects = data.map(project => {
        return { ...project, isAssigned: !!assignedProjects.find(assigned => project.id === assigned.id) };
      });
    });
  }

  assignProjects(): void {
    const projectIds = this.assignedProjects
      .filter(p => p.isAssigned)
      .map(p => p.id);

    this.store$.dispatch(assignProjects({ userId: this.user.id, projectIds }));
  }

  goBack(): void {
    this.store$.dispatch(RouterActions.navigateToRoot({ path: ['managers'] }));
  }

}
