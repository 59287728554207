import { createSelector } from '@ngrx/store';
import { ProjectState, selectProjectState } from 'app/store/project/project.state';
import * as fromStatusChains from './status-chains.reducer';
import { Uuid } from 'shared/common/types';

export const statusChainsState = createSelector(
  selectProjectState,
  state => state.statusChains
);

export const statusChains = createSelector(
  selectProjectState,
  (state: ProjectState) => fromStatusChains.selectAll(state.statusChains)
);

export const statusChainEntities = createSelector(
  selectProjectState,
  state => fromStatusChains.selectEntities(state.statusChains)
);

export const oneStatusChain = createSelector(
  selectProjectState,
  (state: ProjectState, props: { id: Uuid }) => fromStatusChains.selectEntities(state.statusChains)[props.id]
);

export const statusChainsLoading = createSelector(
  selectProjectState,
  state => state.statusChains.loading
);
