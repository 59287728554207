import { Action, createReducer, on } from '@ngrx/store';
import * as StatusChainsActions from './status-chains.actions';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { StatusChainOutput } from 'core/http/project/status/status-chain.service';
import {
  CompletedEntityState,
  initialCompletedEntityState
} from 'app/store/completed-entity-state/completed-entity-state';

export interface StatusChainsState extends CompletedEntityState<StatusChainOutput> {}

const adapter: EntityAdapter<StatusChainOutput> = createEntityAdapter<StatusChainOutput>();

export const initialStatusChainsState = adapter.getInitialState(initialCompletedEntityState);

const reducer = createReducer(
  initialStatusChainsState,
  on(StatusChainsActions.saveStatusChains, (state, payload) => adapter.setAll(payload.chains, state)),
  on(StatusChainsActions.markStatusChainsLoaded, state => ({ ...state, loaded: true })),
  on(StatusChainsActions.addStatusChainInStore, (state, { chain }) => adapter.addOne(chain, state)),
  on(StatusChainsActions.updateStatusChainInStore, (state, { update }) => adapter.updateOne(update, state)),
  on(StatusChainsActions.removeStatusChainFromStore, (state, { id }) => adapter.removeOne(id, state)),
  on(StatusChainsActions.setLoading, (state, { value }) => ({ ...state, loading: value })),
  on(StatusChainsActions.setError, (state, { value }) => ({ ...state, error: value }))
);

export function statusChainsReducer(state: StatusChainsState | undefined, action: Action): StatusChainsState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
