import { createSelector } from '@ngrx/store';
import * as fromCouriers from './couriers.reducer';
import { selectRootState } from 'app/store/root/root.state';

export const rootCouriersState = createSelector(
  selectRootState,
  state => state.couriers
);

export const rootCouriers = createSelector(
  selectRootState,
  state => fromCouriers.selectAll(state.couriers)
);
