import { Action, createReducer, on } from '@ngrx/store';
import * as FieldMapActions from './field-map.actions';
import { FieldMap } from 'angular2-query-builder/dist/components';

export interface OrderFieldMapState {
  fieldMap: FieldMap;
  isLoaded: boolean;
}

export const initialFieldMapState: OrderFieldMapState = {
  fieldMap: {},
  isLoaded: false
};

const reducer = createReducer(
  initialFieldMapState,
  on(FieldMapActions.saveOrderFieldMap, (state, { orderFieldMap }) => ({ ...state, fieldMap: orderFieldMap })),
  on(FieldMapActions.markOrderFieldMapLoaded, state => ({ ...state, isLoaded: true }))
);

export function fieldMapReducer(state: OrderFieldMapState | undefined, action: Action): OrderFieldMapState {
  return reducer(state, action);
}
