import { GoodsState, initialGoodsState } from 'app/store/project/goods/goods.reducer';
import { initialSendersState, SendersState } from 'app/store/project/senders/senders.reducer';
import { CourierChainsState, initialCourierChainsState } from 'app/store/project/courier-chains/courier-chains.reducer';
import { CouriersState, initialCouriersState } from 'app/store/project/couriers/couriers.reducer';
import { DocumentsState, initialDocumentsState } from 'app/store/project/documents/documents.reducer';
import { initialPoolsState, PoolsState } from 'app/store/project/pools/pools.reducer';
import { initialStatusChainsState, StatusChainsState } from 'app/store/project/status-chains/status-chains.reducer';
import { initialStatusesState, StatusesState } from 'app/store/project/statuses/statuses.reducer';
import { initialStorehousesState, StorehousesState } from 'app/store/project/storehouses/storehouses.reducer';
import {
  FilterTemplatesState,
  initialFilterTemplatesState
} from 'app/store/project/filter-templates/filter-templates.reducer';
import {
  CurrentProjectState,
  initialCurrentProjectState
} from 'app/store/project/current-project/current-project.reducer';
import { initialFieldMapState, OrderFieldMapState } from 'app/store/project/field-map/field-map.reducer';
import { createFeatureSelector } from '@ngrx/store';
import {
  initialScheduledTasksState,
  ScheduledTasksState
} from 'app/store/project/scheduled-tasks/scheduled-tasks.reducer';
import { CurrenciesState, initialCurrenciesState } from 'app/store/project/currencies/currencies.reducer';
import {
  AdditionFieldsState,
  initialAdditionalFieldsState
} from 'app/store/project/addition-fields/addition-fields.reducer';
import { initialPageCriteriaState, PageCriteriaState } from 'app/store/project/page-criteria/page-criteria.reducer';
import {
  initialProgressesBackLinksState,
  ProgressesBackLinksState
} from 'app/store/project/progresses-back-links/progresses-back-links.reducer';
import {
  initialProjectResourcesState,
  ProjectResourcesState
} from 'app/store/project/project-resources/project-resources.reducer';
import { initialNoticesState, NoticesState } from 'app/store/project/notices/notices.reducer';
import { ExtensionsState, initialExtensionsState } from 'app/store/project/extensions/extensions.reducer';
import {
  initialReadyProgressesState,
  ReadyProgressesState
} from 'app/store/project/ready-progresses/ready-progresses.reducer';

export const projectFeatureKey = 'project';

export const selectProjectState = createFeatureSelector<ProjectState>(projectFeatureKey);

export interface ProjectState {
  additionFields: AdditionFieldsState;
  couriers: CouriersState;
  courierChains: CourierChainsState;
  documents: DocumentsState;
  pools: PoolsState;
  goods: GoodsState;
  scheduledTasks: ScheduledTasksState;
  senders: SendersState;
  statuses: StatusesState;
  statusChains: StatusChainsState;
  storehouses: StorehousesState;
  currentProject: CurrentProjectState;
  orderFieldMap: OrderFieldMapState;
  filterTemplates: FilterTemplatesState;
  currencies: CurrenciesState;
  pageCriteria: PageCriteriaState;
  progressesBackLinks: ProgressesBackLinksState;
  projectResources: ProjectResourcesState;
  notices: NoticesState;
  extensions: ExtensionsState;
  readyProgresses: ReadyProgressesState;
}

export const initialProjectState: ProjectState = {
  additionFields: initialAdditionalFieldsState,
  couriers: initialCouriersState,
  courierChains: initialCourierChainsState,
  documents: initialDocumentsState,
  pools: initialPoolsState,
  goods: initialGoodsState,
  scheduledTasks: initialScheduledTasksState,
  senders: initialSendersState,
  statuses: initialStatusesState,
  statusChains: initialStatusChainsState,
  storehouses: initialStorehousesState,
  currentProject: initialCurrentProjectState,
  orderFieldMap: initialFieldMapState,
  filterTemplates: initialFilterTemplatesState,
  currencies: initialCurrenciesState,
  pageCriteria: initialPageCriteriaState,
  progressesBackLinks: initialProgressesBackLinksState,
  projectResources: initialProjectResourcesState,
  notices: initialNoticesState,
  extensions: initialExtensionsState,
  readyProgresses: initialReadyProgressesState
};
