import { Component, OnInit } from '@angular/core';
import { DynamicField } from 'shared/modules/dynamic-form/field-types';
import { Observable, of, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import {
  AdditionAddDialogComponent,
  AdditionDialogDataFields
} from 'shared/modules/addition-fields/addition-add-dialog/addition-add-dialog.component';
import { FieldMap } from 'angular2-query-builder/dist/components';
import { GoodOutput, SenderOutput, StatusOutput, StorehouseOutput } from 'core/http/project';
import { AdditionsService, MutableAdditionsInput } from 'core/services/additions.service';
import { flatten, xor } from 'lodash';
import { Uuid } from 'shared/common/types';
import { LoaderService } from 'core/services/loader.service';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { storehouses } from 'app/store/project/storehouses/storehouses.selectors';
import { activatedSenders } from 'app/store/project/senders/senders.selectors';
import { goods } from 'app/store/project/goods/goods.selectors';
import { statuses } from 'app/store/project/statuses/statuses.selectors';
import { orderFieldMap } from 'app/store/project/field-map/field-map.selectors';
import { notify } from 'app/store/common-effects/notifier.effects';
import { EagerSource, RowClasses } from 'shared/modules/table/table-utils/table-interfaces';

@Component({
  selector: 'app-addition-fields',
  templateUrl: './addition-fields.component.html',
  styleUrls: ['./addition-fields.component.scss']
})
export class AdditionFieldsComponent implements OnInit {

  reloader = new Subject();
  rowClasses: RowClasses<DynamicField> = {
    'new-item-bg': additionalField => this.isNewAdditionField(additionalField),
    'for-removing-bg': additionalField => this.isFieldForRemoving(additionalField)
  };

  private _type: 'good' | 'sender' | 'status' | 'storehouse' = 'good';
  private _fieldMap$ = this.store$.select(orderFieldMap);
  private _goods$ = this.store$.select(goods);
  private _senders$ = this.store$.select(activatedSenders);
  private _statuses$ = this.store$.select(statuses);
  private _storehouses$ = this.store$.select(storehouses);
  private _allAdditionFields = new Map<string, DynamicField[]>([
    ['current', Array<DynamicField>()],
    ['new', Array<DynamicField>()],
    ['forRemoving', Array<DynamicField>()]
  ]);
  private _columns: string[] = [
    'field',
    'description',
    'value',
    'actions'
  ];

  get fieldMap$(): Observable<FieldMap> {
    return this._fieldMap$;
  }

  get goods$(): Observable<GoodOutput[]> {
    return this._goods$;
  }

  get senders$(): Observable<SenderOutput[]> {
    return this._senders$;
  }

  get statuses$(): Observable<StatusOutput[]> {
    return this._statuses$;
  }

  get storehouses$(): Observable<StorehouseOutput[]> {
    return this._storehouses$;
  }

  get allAdditionFields(): DynamicField[] {
    const array = Array.from(this._allAdditionFields.values());
    return flatten(array);
  }

  get columns(): string[] {
    return this._columns;
  }

  get currentAdditionFields(): DynamicField[] {
    return this._allAdditionFields.get('current')!;
  }

  set currentAdditionFields(value: DynamicField[]) {
    this._allAdditionFields.set('current', [...value]);
  }

  get newAdditionFields(): DynamicField[] {
    return this._allAdditionFields.get('new')!;
  }

  get additionFieldsForRemoving(): DynamicField[] {
    return this._allAdditionFields.get('forRemoving')!;
  }

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private additionsService: AdditionsService,
    private loader: LoaderService,
    private store$: Store
  ) { }

  dataSource: EagerSource<DynamicField> = () => of(this.allAdditionFields);

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this._type = data.type;
      this.currentAdditionFields = data.additions;
    });
  }

  openAddFieldDialog(): void {
    const dialogRef = this.dialog.open(AdditionAddDialogComponent, {
      width: '30vw',
      data: {
        fieldMap$: this.fieldMap$,
        goods$: this.goods$,
        senders$: this.senders$,
        additionFields: this.allAdditionFields,
        statuses$: this.statuses$,
        storehouses$: this.storehouses$
      } as AdditionDialogDataFields
    });

    dialogRef.afterClosed()
      .subscribe((field: DynamicField) => {
        if (!field) {
          return;
        }

        this.newAdditionFields.push(field);
        this.reloader.next();
      });
  }

  saveAdditionFields(): void {
    const mutableAdditions: MutableAdditionsInput = {
      toAppend: this.newAdditionFields.map(item => item.type === 'SUBSET' ? { ...item, values: [] } : item),
      toRemove: this.additionFieldsForRemoving.map(field => field.name)
    };

    this.loader.begin();

    this.additionsService.changeDefaultAdditions(mutableAdditions, this._type).subscribe(() => {
      this.currentAdditionFields.push(...this.newAdditionFields);
      this.currentAdditionFields = xor(this.currentAdditionFields, this.additionFieldsForRemoving);

      this.additionFieldsForRemoving.length = 0;
      this.newAdditionFields.length = 0;

      this.reloader.next();
      this.loader.end();
      this.store$.dispatch(notify({ message: 'Изменения сохранены' }));
    });
  }

  addFieldForRemoving(event: MatCheckboxChange, field: DynamicField): void {
    if (event.checked) {
      this.additionFieldsForRemoving.push(field);
    }
    else {
      const index = this.additionFieldsForRemoving.indexOf(field);
      this.additionFieldsForRemoving.splice(index, 1);
    }
  }

  removeNewAdditionField(field: DynamicField): void {
    const index = this.newAdditionFields.indexOf(field);
    this.newAdditionFields.splice(index, 1);

    this.reloader.next();
  }

  isNewAdditionField(field: DynamicField): boolean {
    return this.newAdditionFields.some(item => item.name === field.name);
  }

  isFieldForRemoving(field: DynamicField): boolean {
    return this.additionFieldsForRemoving.some(item => item.name === field.name);
  }

  getNameById(id: Uuid, type: 'good' | 'sender' | 'status' | 'storehouse'): Observable<string> {
    switch (type) {
      case 'good':
        return this.goods$.pipe(map(goodsList => goodsList.find(item => item.id === id)!.name));
      case 'sender':
        return this.senders$.pipe(map(sendersList => sendersList.find(item => item.id === id)!.name));
      case 'status':
        return this.statuses$.pipe(map(sendersList => sendersList.find(item => item.id === id)!.name));
      case 'storehouse':
        return this.storehouses$.pipe(map(sendersList => sendersList.find(item => item.id === id)!.name));
    }
  }
}
