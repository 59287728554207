import { Action, createReducer, on } from '@ngrx/store';
import * as ScheduledTasksActions from './scheduled-tasks.actions';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { ScheduledTaskOutput } from 'core/http/project/scheduled-tasks/scheduled-tasks.service';
import {
  CompletedEntityState,
  initialCompletedEntityState
} from 'app/store/completed-entity-state/completed-entity-state';

export interface ScheduledTasksState extends CompletedEntityState<ScheduledTaskOutput> {}

const adapter: EntityAdapter<ScheduledTaskOutput> = createEntityAdapter<ScheduledTaskOutput>();

export const initialScheduledTasksState = adapter.getInitialState(initialCompletedEntityState);

const reducer = createReducer(
  initialScheduledTasksState,
  on(ScheduledTasksActions.saveScheduledTasks, (state, { tasks }) => adapter.setAll(tasks, state)),
  on(ScheduledTasksActions.markScheduledTasksLoaded, state => ({ ...state, loaded: true })),
  on(ScheduledTasksActions.addScheduledTaskInStore, (state, { entity }) => adapter.addOne(entity, state)),
  on(ScheduledTasksActions.updateScheduledTaskInStore, (state, { update }) => adapter.updateOne(update, state)),
  on(ScheduledTasksActions.removeScheduledTaskFromStore, (state, { id }) => adapter.removeOne(id, state)),
  on(ScheduledTasksActions.setLoading, (state, { value }) => ({ ...state, loading: value })),
  on(ScheduledTasksActions.setError, (state, { value }) => ({ ...state, error: value }))
);

export function scheduledTasksReducer(state: ScheduledTasksState | undefined, action: Action): ScheduledTasksState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
