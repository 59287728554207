import { createAction, props } from '@ngrx/store';
import { StatusChainInput, StatusChainOutput } from 'core/http/project/status/status-chain.service';
import { Uuid } from 'shared/common/types';
import { Update } from '@ngrx/entity';
import { createSetErrorAction, createSetLoadingAction } from 'app/store/completed-entity-state/completed-entity-state';

export const getStatusChains = createAction('[Project Request] Get Status Chains');
export const saveStatusChains = createAction('[Project Store] Save Status Chains',
  props<{ chains: StatusChainOutput[] }>());
export const markStatusChainsLoaded = createAction('[Project Store] Mark Status Chains Loaded');

export const createStatusChain = createAction('[Project Request] Create Status Chain',
  props<{ input: StatusChainInput }>());
export const addStatusChainInStore = createAction('[Project Store] Add Status Chain In Store',
  props<{ chain: StatusChainOutput }>());

export const updateStatusChain = createAction('[Project Request] Update Status Chain',
  props<{ id: Uuid, input: StatusChainInput }>());
export const updateStatusChainInStore = createAction('[Project Store] Update Status Chain In Store',
  props<{ update: Update<StatusChainOutput> }>());

export const removeStatusChain = createAction('[Project Request] Remove Status Chain', props<{ id: Uuid }>());
export const removeStatusChainFromStore = createAction('[Project Store] Remove Status Chain From Store',
  props<{ id: Uuid }>());

export const setLoading = createSetLoadingAction('[Project Store] Set Status Chains Loading');

export const setError = createSetErrorAction('[Project Store] Set Status Chains Error');
