import { createAction, props } from '@ngrx/store';
import { CourierOutput } from 'core/http/project';
import { createSetErrorAction, createSetLoadingAction } from 'app/store/completed-entity-state/completed-entity-state';

export const getCouriers = createAction('[Couriers Request] Get Couriers');
export const saveCouriers = createAction('[Couriers Store] Save Couriers', props<{ couriers: CourierOutput[] }>());
export const markCouriersLoaded = createAction('[Couriers Store] Mark Couriers Loaded');

export const setLoading = createSetLoadingAction('[Couriers Store] Set Loading');

export const setError = createSetErrorAction('[Couriers Store] Set Error');
