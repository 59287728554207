import { ExtensionOutput } from 'core/http/project/extension/extension.service';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as ExtensionActions from './extensions.actions';
import {
  CompletedEntityState,
  initialCompletedEntityState
} from 'app/store/completed-entity-state/completed-entity-state';

export interface ExtensionsState extends CompletedEntityState<ExtensionOutput> {}

const adapter: EntityAdapter<ExtensionOutput> = createEntityAdapter();

export const initialExtensionsState = adapter.getInitialState(initialCompletedEntityState);

const reducer = createReducer(
  initialExtensionsState,
  on(ExtensionActions.saveExtensions, (state, { extensions }) => adapter.setAll(extensions, state)),
  on(ExtensionActions.markExtensionsLoaded, state => ({ ...state, loaded: true })),
  on(ExtensionActions.setLoading, (state, { value }) => ({ ...state, loading: value })),
  on(ExtensionActions.setError, (state, { value }) => ({ ...state, error: value }))
);

export function extensionsReducer(state: ExtensionsState | undefined, action: Action): ExtensionsState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
