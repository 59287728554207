import { Compilers, Formatters, SearchBarField, SearchBarFields } from 'shared/modules/controls/search-bar/search-bar';
import { Uuid } from 'shared/common/types';
import { CourierOutput, GoodOutput, SenderOutput, StatusOutput, StorehouseOutput } from 'core/http/project';
import { FormControl, FormGroup } from '@angular/forms';
import { AdditionalField } from 'app/store/project/addition-fields/addition-fields.reducer';
import { SeparatorInputData } from 'shared/modules/controls/separator-input/separator-input.component';

export function createOrderFilterForm(additional: AdditionalField[], defaultStatus?: StatusOutput): FormGroup {
  const defaultSeparator =
    (separator: SeparatorInputData['separator']): SeparatorInputData => ({ value: '', byEntry: true, separator });

  const additionalControls = additional.reduce((result, field) => {
    return { ...result, [field.name]: new FormControl(defaultSeparator(',')) };
  }, {});

  const form = new FormGroup({
    outProjectId: new FormControl(defaultSeparator(' ')),
    outOrderId: new FormControl(defaultSeparator(' ')),
    createdAt: new FormControl(),
    updatedAt: new FormControl(),
    exportedAt: new FormControl(),
    boughtOutAt: new FormControl(),
    returnedAt: new FormControl(),
    statusChangedAt: new FormControl(),
    comment: new FormControl(),
    recipient: new FormControl(defaultSeparator(',')),
    city: new FormControl(defaultSeparator(',')),
    region: new FormControl(defaultSeparator(',')),
    postcode: new FormControl(defaultSeparator(' ')),
    phone: new FormControl(defaultSeparator(' ')),
    statuses: new FormControl(),
    couriers: new FormControl(),
    senders: new FormControl(),
    track: new FormControl(defaultSeparator(' ')),
    tracking: new FormControl(defaultSeparator(',')),
    goods: new FormControl(),
    storehouse: new FormControl(),
    goodsCount: new FormControl(),
    additions: new FormGroup({
      ...additionalControls
    })
  });

  if (defaultStatus) {
    form.patchValue({ statuses: [defaultStatus.id] });
  }

  return form;
}

export function createOrderFields(
  additional: AdditionalField[],
  senders: SenderOutput[],
  couriers: CourierOutput[],
  statuses: StatusOutput[],
  goods: GoodOutput[],
  storehouses: StorehouseOutput[]
): SearchBarFields {
  const converter = (value: SeparatorInputData) => value.value;

  const additionalFields = additional.reduce((result, field) => {
    return {
      ...result,
      [`additions.${field.name}`]: {
        label: field.label,
        compiler: Compilers.containValues(`additional.${field.name}`),
        formatter: Formatters.justValue(converter)
      } as SearchBarField
    };
  }, {});

  return {
    'outProjectId': {
      label: 'ID проекта',
      compiler: Compilers.valuesFromString('lgStandingData.outProjectId'),
      formatter: Formatters.justValue(converter)
    },
    'outOrderId': {
      label: 'ID заказа',
      compiler: Compilers.valuesFromString('lgStandingData.outOrderId'),
      formatter: Formatters.justValue(converter)
    },
    'createdAt': {
      label: 'Дата создания',
      compiler: Compilers.dates('createdAt'),
      formatter: Formatters.dates()
    },
    'updatedAt': {
      label: 'Дата обновления',
      compiler: Compilers.dates('updatedAt'),
      formatter: Formatters.dates()
    },
    'exportedAt': {
      label: 'Дата выгрузки',
      compiler: Compilers.dates('logistic.createdAt'),
      formatter: Formatters.dates()
    },
    'boughtOutAt': {
      label: 'Дата выкупа',
      compiler: Compilers.dates('boughtOutAt'),
      formatter: Formatters.dates()
    },
    'returnedAt': {
      label: 'Дата возврата',
      compiler: Compilers.dates('returnedAt'),
      formatter: Formatters.dates()
    },
    'statusChangedAt': {
      label: 'Дата смены статуса',
      compiler: Compilers.dates('statusChangeAt'),
      formatter: Formatters.dates()
    },
    'comment': {
      label: 'Комментарий',
      compiler: Compilers.text('comment')
    },
    'recipient': {
      label: 'Имя получателя',
      compiler: Compilers.containOrEqual('recipient.name'),
      formatter: Formatters.justValue(converter)
    },
    'city': {
      label: 'Город',
      compiler: Compilers.containOrEqual('recipient.city'),
      formatter: Formatters.justValue(converter)
    },
    'region': {
      label: 'Регион',
      compiler: Compilers.containOrEqual('recipient.region'),
      formatter: Formatters.justValue(converter)
    },
    'postcode': {
      label: 'Индекс',
      compiler: Compilers.containValues('recipient.postcode'),
      formatter: Formatters.justValue(converter)
    },
    'phone': {
      label: 'Телефоны',
      compiler: Compilers.containOrEqual('recipient.phone'),
      formatter: Formatters.justValue(converter)
    },
    'statuses': {
      label: 'Статусы',
      compiler: Compilers.array('status', statuses.length),
      formatter: Formatters.invisible()
    },
    'couriers': {
      label: 'Курьерские службы',
      compiler: Compilers.array('logistic.courier', couriers.length),
      formatter: Formatters.array((courierId: Uuid) => {
        const found = couriers.find(c => c.id === courierId);
        return found ? found.name : 'unknown';
      })
    },
    'senders': {
      label: 'Отправители',
      compiler: Compilers.array('logistic.sender', senders.length),
      formatter: Formatters.array((senderId: Uuid) => {
        const found = senders.find(c => c.id === senderId);
        return found ? found.name : 'unknown';
      })
    },
    'track': {
      label: 'Трек номера',
      compiler: Compilers.valuesFromString('logistic.track'),
      formatter: Formatters.justValue(converter)
    },
    'tracking': {
      label: 'Статус трекинга',
      compiler: Compilers.containOrEqual('logistic.tracking.lastName'),
      formatter: Formatters.justValue(converter)
    },
    'goods': {
      label: 'Товары',
      compiler: Compilers.array('cart.goods.good', goods.length),
      formatter: Formatters.array((goodId: Uuid) => {
        const found = goods.find(c => c.id === goodId);
        return found ? found.name : 'unknown';
      })
    },
    'storehouse': {
      label: 'Склад',
      compiler: Compilers.array('storeHouse.id', storehouses.length),
      formatter: Formatters.array((storehouseId: Uuid) => {
        const found = storehouses.find(c => c.id === storehouseId);
        return found ? found.name : 'unknown';
      })
    },
    'goodsCount': {
      label: 'Количество товаров',
      compiler: Compilers.equals('cart.totalQuantity'),
      formatter: Formatters.justValue()
    },
    ...additionalFields
  };
}
