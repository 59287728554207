import { createAction, props } from '@ngrx/store';
import { GoodInput, GoodOutput } from 'core/http/project';
import { Update } from '@ngrx/entity';
import { Uuid } from 'shared/common/types';
import { createSetErrorAction, createSetLoadingAction } from 'app/store/completed-entity-state/completed-entity-state';

export const getGoods = createAction('[Project Request] Get Goods');
export const saveGoods = createAction('[Project Store] Save Goods', props<{ goods: GoodOutput[] }>());
export const markGoodsLoaded = createAction('[Project Store] Mark Goods Loaded');

export const createGood = createAction('[Project Request] Create Good', props<{ input: GoodInput }>());
export const addGoodInStore = createAction('[Project Store] Add Good In Store', props<GoodOutput>());

export const updateGood = createAction('[Project Request] Update Good', props<{ id: Uuid, input: GoodInput }>());
export const updateGoodInStore = createAction('[Project Store] Update Good In Store', props<{ update: Update<GoodOutput> }>());

export const activateGood = createAction('[Project Request] Activate Good', props<GoodOutput>());
export const deactivateGood = createAction('[Project Request] Deactivate Good', props<GoodOutput>());

export const setLoading = createSetLoadingAction('[Project Store] Set Goods Loading');

export const setError = createSetErrorAction('[Project Store] Set Goods Error');
