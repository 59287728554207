import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AuthModule } from 'auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'shared/shared.module';
import { RefreshTokenInterceptor } from 'core/interceptors/token/refresh-token.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CustomTranslateLoader } from 'core/services/custom-translate-loader';
import { LanguageService } from 'core/services/language.service';
import { BannedAccountInterceptor } from 'core/interceptors/token/banned-account.interceptor';
import { StoreModule } from '@ngrx/store';
import { appReducers } from 'app/store/app.redusers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { environment } from 'environments/environment';
import { GlobalErrorHandler } from 'core/error-handler/global-error-handler';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { NotifierEffects } from 'app/store/common-effects/notifier.effects';
import { MAT_DATE_LOCALE } from '@angular/material/core';

const NgRx = [
  StoreModule.forRoot(appReducers),
  EffectsModule.forRoot([NotifierEffects])
];

if (!environment.production) {
  NgRx.push(StoreDevtoolsModule.instrument());
}

const dbConfig: DBConfig = {
  name: 'DataBase',
  version: 1,
  objectStoresMeta: [
    {
      store: 'project-data',
      storeConfig: { autoIncrement: false, keyPath: 'type' },
      storeSchema: [
        { name: 'lastUpdate', keypath: '', options: { unique: false } },
        { name: 'appVersion', keypath: '', options: { unique: false } },
        { name: 'data', keypath: '', options: { unique: false } }
      ]
    }
  ]
};

const language = localStorage.getItem('LANGUAGE');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader
      }
    }),
    NgxIndexedDBModule.forRoot(dbConfig),
    ...NgRx
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BannedAccountInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    { provide: MAT_DATE_LOCALE, useValue: language },
    LanguageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
