import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'shared/shared.module';
import { DevRoutingModule } from './dev-routing.module';
import { DevLayoutComponent } from './dev-layout.component';
import { DevServicesModule } from 'core/http/dev/dev-services.module';
import { AuthServicesModule } from 'core/http/auth/auth-services';
import { EffectsModule } from '@ngrx/effects';
import { CouriersEffects } from 'app/store/dev/couriers/couriers.effects';
import { DocumentsEffects } from 'app/store/dev/documents/documents.effects';
import { PageNotFoundModule } from 'project/pages/page-not-found/page-not-found.module';
import { RouterEffects } from 'app/store/common-effects/router.effects';

const effects = [
  CouriersEffects,
  DocumentsEffects,
  RouterEffects
];

@NgModule({
  declarations: [DevLayoutComponent],
  imports: [
    CommonModule,
    SharedModule,
    DevRoutingModule,
    AuthServicesModule,
    DevServicesModule,
    PageNotFoundModule,
    EffectsModule.forFeature(effects)
  ],
  providers: []
})
export class DevModule {
}
