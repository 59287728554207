import { HttpClient } from '@angular/common/http';
import { Criteria } from 'shared/criteria/Criteria';
import { Observable, of } from 'rxjs';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { catchError, map } from 'rxjs/operators';
import { RootInjectable } from 'core/http/root/root-services.module';
import { KeyValue, Uuid } from 'shared/common/types';

export interface WebhookOutput {
  id: string;
  url: string;
  event: string;
  retries: number[];
  isActivated: boolean;
  eventExample: JSON;
}

export interface UpdateWebhookInput {
  url: string;
  retries: number[]; // В минутах
  isActivated: boolean;
}

export interface CreateWebhookInput {
  url: string;
  event: string;
  retries: number[];
  isActivated: boolean;
}

export interface CheckWebhookInput {
  event: EventInput;
}

export interface EventInput {
  event: string;
  payload: JSON;
  retries: number;
  createdAt: number;
}

export interface CheckWebhookOutput {
  response: ResponseOutput | null;
  request: RequestOutput | null;
  reason: string | null;
}

export interface ResponseOutput {
  body: string;
  headers: string[];
  code: number;
}

export interface RequestOutput {
  body: string;
  headers: string[];
  method: string;
}

@RootInjectable()
export class WebhookService {

  constructor(private http: HttpClient) {}

  hasWebhook(criteria: Criteria): Observable<boolean> {
    return this.http.head(apiUrl(`/root/webhook`, criteria))
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  createWebhook(input: CreateWebhookInput): Observable<WebhookOutput> {
    return this.http.post<WebhookOutput>(apiUrl(`/root/webhook`), input);
  }

  removeWebhook(webhookId: Uuid): Observable<Object> {
    return this.http.delete(apiUrl(`/root/webhook/${webhookId}`));
  }

  updateWebhook(webhookId: Uuid, input: UpdateWebhookInput): Observable<WebhookOutput> {
    return this.http.patch<WebhookOutput>(apiUrl(`/root/webhook/${webhookId}`), input);
  }

  checkWebhook(input: CheckWebhookInput, webhookId: string): Observable<CheckWebhookOutput> {
    return this.http.post<CheckWebhookOutput>(apiUrl(`/root/check-webhook/${webhookId}`), input);
  }

  getAllWebhooks(): Observable<WebhookOutput[]> {
    return this.http.get<WebhookOutput[]>(apiUrl(`/root/webhooks`));
  }

  getAllEvent(): Observable<KeyValue[]> {
    return this.http.get<KeyValue[]>(apiUrl(`/root/webhook/events`));
  }

}
