import { Action, createReducer, on } from '@ngrx/store';
import * as WebhooksActions from './webhooks.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { WebhookOutput } from 'core/http/root';
import { KeyValue } from 'shared/common/types';

export interface WebhooksState extends EntityState<WebhookOutput> {
  events: KeyValue[];
  isLoaded: boolean;
}

const adapter: EntityAdapter<WebhookOutput> = createEntityAdapter();

export const initialWebhooksState = adapter.getInitialState({
  events: [] as KeyValue[],
  isLoaded: false
});

const reducer = createReducer(
  initialWebhooksState,
  on(WebhooksActions.saveWebhooks, (state, { webhooks }) => adapter.setAll(webhooks, state)),
  on(WebhooksActions.markWebhooksLoaded, state => ({ ...state, isLoaded: true })),
  on(WebhooksActions.addWebhookInStore, (state, { webhook }) => adapter.addOne(webhook, state)),
  on(WebhooksActions.updateWebhookInStore, (state, { update }) => adapter.updateOne(update, state)),
  on(WebhooksActions.removeWebhookFromStore, (state, { id }) => adapter.removeOne(id, state)),
  on(WebhooksActions.saveEvents, (state, { events }) => ({ ...state, events }))
);

export function webhooksReducer(state: WebhooksState | undefined, action: Action): WebhooksState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
