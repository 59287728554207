import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { errorNotify, notify } from 'app/store/common-effects/notifier.effects';
import { LoaderService } from 'core/services/loader.service';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

enum HttpCodes {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,
  ServerError = 500,
  GatewayTimeout = 504
}

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) {}

  handleError(error: any): void {
    const store$ = this.injector.get(Store);
    const loader = this.injector.get(LoaderService);
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    loader.end();

    if (chunkFailedMessage.test(error.message)) {
      of(notify({message: 'Обновление на новую версию приложения'}))
        .pipe(
          tap((notification) => store$.dispatch(notification)),
          delay(4000),
          tap(() => window.location.reload()),
        )
        .subscribe();
    } else if (error instanceof HttpErrorResponse) {
      let message: string | string[];

      switch (error.status) {
        case HttpCodes.BadRequest:
          message = 'Некорректный запрос';
          break;

        case HttpCodes.Unauthorized:
          message = 'Требуется авторизация';
          break;

        case HttpCodes.Forbidden:
          message = 'Нет доступа';
          break;

        case HttpCodes.NotFound:
          message = 'Не найдено';
          break;

        case HttpCodes.UnprocessableEntity:
          const keys = Object.keys(error.error);

          message = keys.map(k => {
            const errors = error.error[k].join(', ');
            return `${k}: ${errors}`;
          });
          break;

        case HttpCodes.ServerError:
          message = 'Ошибка сервера';
          break;

        case HttpCodes.GatewayTimeout:
          message = 'Превышен лимит ожидания';
          break;

        default:
          message = 'Неизвестная ошибка';
      }

      console.error('Response body:', error);
      store$.dispatch(errorNotify({ message }));
    } else {
      console.error('An error occurred:', error);
      store$.dispatch(errorNotify({ message: error.message }));
    }
  }

}
