import { Component, Input } from '@angular/core';
import { Rule, RuleSet } from 'angular2-query-builder';
import { FieldMap } from 'angular2-query-builder/dist/components';
import { OPERATOR_LABELS } from 'shared/modules/query-builder/query-builder-utils';
import { Store } from '@ngrx/store';
import { orderFieldMap } from 'app/store/project/field-map/field-map.selectors';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

type PrettyRule = Rule & { displayValue: DisplayValue };

interface PrettyRuleSet extends RuleSet {
  rules: (PrettyRule | PrettyRuleSet)[];
}

interface DisplayValue {
  display: string;
  other: string;
  otherCount: number;
}

@Component({
  selector: 'app-rule-view',
  templateUrl: './rule-view.component.html',
  styleUrls: ['./rule-view.component.scss']
})
export class RuleViewComponent {

  @Input() orderFieldMap?: FieldMap;
  @Input() border = false;

  readonly operatorLabels = OPERATOR_LABELS;
  readonly fieldMap = this.store$.select(orderFieldMap);

  private _rule = new Observable<PrettyRuleSet>();

  get prettyRule(): Observable<PrettyRuleSet> {
    return this._rule;
  }

  @Input()
  set rule(value: RuleSet) {
    this._rule = this.fieldMap.pipe(
      map(f => ({
        ...value,
        rules: value.rules.map(r => this.getPrettyRule(r, this.orderFieldMap || f))
      } as PrettyRuleSet))
    );
  }

  constructor(private store$: Store) {}

  getPrettyRule = (rule: Rule | RuleSet, fieldMap: FieldMap): PrettyRule | PrettyRuleSet => {
    if ('rules' in rule) {
      return { ...rule, rules: rule.rules.map(r => this.getPrettyRule(r, fieldMap)) } as PrettyRuleSet;
    }

    return { ...rule, displayValue: this.getDisplayValue(rule, fieldMap) } as PrettyRule;
  };

  getDisplayValue(rule: Rule, fieldMap: FieldMap): DisplayValue {
    const options = fieldMap[rule.field]?.options;
    const defaultValues = Array.isArray(rule.value) ? rule.value : [rule.value];

    if (!options) {
      return this.buildDisplayValue(defaultValues);
    }

    const optionValues = options
      .filter(option => defaultValues.some(id => id === option.value))
      .map(option => option.name);

    return this.buildDisplayValue(optionValues);
  }

  buildDisplayValue(values: any[]): DisplayValue {
    const other = values.slice(5);
    const otherCount = other.length;
    const mainVal = values.slice(0, 5).join(', ').trim();
    const display = otherCount ? mainVal + '...' : mainVal;

    return { display, other: other.join(', '), otherCount };
  }

}
