import { Action, createReducer, on } from '@ngrx/store';
import { CurrencyRatesOutput } from 'core/http/lg-logistic-rest-api/Auth/project.service';
import * as CurrenciesActions from './currencies.actions';

export const currenciesFeatureKey = 'currencies';

export interface CurrenciesState {
  currencies: CurrencyRatesOutput;
  isLoaded: boolean;
}

export const initialCurrenciesState: CurrenciesState = {
  currencies: {} as CurrencyRatesOutput,
  isLoaded: false
};

const reducer = createReducer(
  initialCurrenciesState,
  on(CurrenciesActions.saveCurrencies, (state, { currencies }) => ({ ...state, currencies })),
  on(CurrenciesActions.markCurrenciesLoaded, (state) => ({ ...state, isLoaded: true }))
);

export function currenciesReducer(state: CurrenciesState | undefined, action: Action): CurrenciesState {
  return reducer(state, action);
}
