import { createAction, props } from '@ngrx/store';
import { RootSimpleExtensionOutput } from 'core/http/root';
import { Uuid } from 'shared/common/types';
import { Update } from '@ngrx/entity';

export const getSimpleExtensions = createAction('[Root Simple Extensions Request]');
export const saveSimpleExtensions = createAction('[Root Simple Extensions Store] Save Simple Extensions',
  props<{ extensions: RootSimpleExtensionOutput[] }>());
export const markSimpleExtensionsLoaded = createAction('[Root Simple Extensions Store] Mark Simple Extensions Loaded');

export const updateExtensionProjects = createAction('[Root Courier Request] Update Simple Extension Projects',
  props<{ id: Uuid, projects: Uuid[] }>());
export const updateExtensionProjectsInStore = createAction(
  '[Root Courier Store] Update Simple Extension Projects In Store',
  props<{ update: Update<RootSimpleExtensionOutput> }>());
