import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Criteria } from 'shared/criteria/Criteria';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { DocumentOutput } from 'core/http/project/document/document.service';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';

export interface PooledInput {
  pooledId: string | null;
  documentId: string;
  groupers: (string | number)[];
}

export interface PoolInput {
  poolName: string;
  pooledDocuments: PooledInput[];
}

export interface PooledOutput {
  pooledId: string | null;
  document: DocumentOutput;
  groupers: string[];
}

export interface PoolOutput {
  id: string;
  name: string;
  pooledDocuments: PooledOutput[];
}

@ProjectInjectable()
export class DocumentPoolService {

  constructor(private http: HttpClient) {}

  getAllDocumentPools(criteria?: Criteria): Observable<PoolOutput[]> {
    return this.http.get<PoolOutput[]>(apiUrl(`/project/document-pools`, criteria));
  }

  createPool(input: PoolInput): Observable<PoolOutput> {
    return this.http.post<PoolOutput>(apiUrl('/project/document-pool'), input);
  }

  updatePool(poolId: string, input: PoolInput): Observable<PoolOutput> {
    return this.http.patch<PoolOutput>(apiUrl(`/project/document-pool/${poolId}`), input);
  }

  removePool(poolId: string): Observable<Object> {
    return this.http.delete<Object>(apiUrl(`/project/document-pool/${poolId}`));
  }

}
