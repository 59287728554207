import { of, pipe, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

export const checkExistenceByHeadRequest = (existCode: number = 200, notExistCode: number = 404) => {
  return pipe(
    map<HttpResponse<void>, boolean>(r => {
      if (r.status === existCode) {
        return true;
      }

      throw new Error(`Unexpected response code. Expected: ${existCode}. Given: ${r.status}`);
    }),
    catchError((err: any) => {
      if (err instanceof HttpErrorResponse && err.status === notExistCode) {
        return of(false);
      }

      return throwError(err);
    })
  );
};
