import { EventEmitter, Output, Directive } from '@angular/core';
import { Uuid } from 'shared/common/types';

/**
 * Selection для хранения уникального набора выбранных сущностей.
 * Чтобы сущности можно было выбирать, им нужен уникальный идентификатор.
 * При создании selection нужно указать функцию которая будет получать этот индентификатор.
 */
@Directive()
// tslint:disable-next-line:directive-class-suffix
export class ItemSelection<TEntity = object, TIdentifier = Uuid> {

  // Событие говорящее об изменении выбранных сущностей.
  @Output() changed = new EventEmitter<TEntity[]>();
  // Функция получения индентификаторов из объектов.
  private readonly _accessor: (item: TEntity) => TIdentifier;
  // Множество идентификаторов. Если id сущности находится здесь, то сущность считается выбранной.
  private _identifiers = new Set<TIdentifier>();
  // Массив выбранных сущностей.
  private _entities = new Array<TEntity>();

  /**
   * @param accessor
   *   Функция получения идентификатора сущности.
   *   Если не передавать функцию, то будет использованна дефолтная функция которая пытается получить идентификатор с имененем "id".
   */
  constructor(accessor?: (item: TEntity) => TIdentifier) {
    this._accessor = accessor || this.createDefaultAccessor();
  }

  // Создание accessor по умолчанию.

  /**
   * @return Массив выбранных сущностей
   */
  get selected(): TEntity[] {
    return this._entities;
  }

  toggle(...entities: TEntity[]): void {
    entities.forEach(e => this.isSelected(e) ? this.deselect(e) : this.select(e));
  }

  /**
   * Отмечает переданные значения как "выбранные".
   *
   * @param entities
   *   Одна или более сущностей для установки выбора.
   */
  select(...entities: TEntity[]): void {
    for (const entity of entities) {
      // Получаем идентификатор...
      const identifier = this._accessor(entity);

      // ...если сущность с таким идентификатором уже есть...
      if (this._identifiers.has(identifier)) {
        continue; // ...то пропускаем ее.
      }

      // Добавляем идентификатор сущности в множество.
      this._identifiers.add(identifier);
      // Сохраняем сущнсоть в массив "выбранных".
      this._entities.push(entity);
    }

    // Уведомляем об изменении selection.
    this.changed.emit(this._entities);
  }

  /**
   * Убирает выбор с ранее отмеченных сущностей.
   *
   * @param entities
   *   Одна или более сущностей для снятия выбора.
   */
  deselect(...entities: TEntity[]): void {
    // Накапливаем удаленные идентификаторы.
    const removedIds = Array<any>();

    for (const entity of entities) {
      // Берем идентификатор удаляемой сущности.
      const identifier = this._accessor(entity);

      // Если такого идентификатора вдруг нет во множестве...
      if (!this._identifiers.has(identifier)) {
        continue; // ...то просто пропускаем итерацию.
      }

      // Удаляем идентификатор из множества.
      this._identifiers.delete(identifier);
      // Добавляем идентификатор в массив удаленных идентификаторов.
      removedIds.push(identifier);
    }

    // Удаляем все сущности у которых идентификаторы совпадают с removedIds.
    this._entities = this._entities.filter(value => {
      return !removedIds.includes(this._accessor(value));
    });

    // Уведомляем об изменении selection.
    this.changed.emit(this._entities);
  }

  /**
   * Очищает selection от всех ранее выбранных сущностей.
   */
  clearAll(): void {
    this._identifiers = new Set<any>();
    this._entities = new Array<any>();
    this.changed.emit([]);
  }

  /**
   * Проверяет, выбрана ли сущность в текущем selection.
   *
   * @param entity
   *   Объект сущности для проверки.
   *
   * @return True если сущность выбрана. Иначе false.
   */
  isSelected(entity: TEntity): boolean {
    const identifier = this._accessor(entity);
    return this._identifiers.has(identifier);
  }

  /**
   * Проверяет, есть ли в selection значения.
   *
   * @return True если в текущем selection есть значения. Иначе false.
   */
  hasValue(): boolean {
    return this._identifiers.size > 0;
  }

  // Ищет свойство с именем "id".
  private createDefaultAccessor(): (item: TEntity) => TIdentifier {
    return item => (item as any).id;
  }
}
