import { createSelector } from '@ngrx/store';
import * as fromDocuments from './documents.reducer';
import { DevState, selectDevState } from 'app/store/dev/dev.state';
import { Uuid } from 'shared/common/types';

export const devDocumentsState = createSelector(
  selectDevState,
  state => state.documents
);

export const devDocuments = createSelector(
  selectDevState,
  state => fromDocuments.selectAll(state.documents)
);

export const oneDevDocument = createSelector(
  selectDevState,
  (state: DevState, props: { id: Uuid }) => fromDocuments.selectEntities(state.documents)[props.id]
);

export const renderClasses = createSelector(
  selectDevState,
  state => state.documents.renderClasses
);
