import { createSelector } from '@ngrx/store';
import * as fromNotices from './notices.reducer';
import { ProjectState, selectProjectState } from 'app/store/project/project.state';
import { Uuid } from 'shared/common/types';
import { chain } from 'lodash';

export const noticesState = createSelector(
  selectProjectState,
  state => state.notices
);

export const notices = createSelector(
  selectProjectState,
  state => fromNotices.selectAll(state.notices)
);

export const oneNotice = createSelector(
  selectProjectState,
  (state: ProjectState, id: Uuid) => fromNotices.selectEntities(state.notices)[id]
);

export const telegramChats = createSelector(
  selectProjectState,
  state => state.notices.telegramChats
);

export const telegramChatsDic = createSelector(
  selectProjectState,
  state => chain(state.notices.telegramChats)
    .keyBy(c => c.id)
    .mapValues(c => c.name)
    .value()
);

export const noticesLoading = createSelector(
  selectProjectState,
  state => state.notices.loading
);
