import { NgModule } from '@angular/core';
import { UploadFileDialogComponent } from 'shared/dialogs/upload-file-dialog/upload-file-dialog.component';
import { UploadFileButtonComponent } from 'shared/modules/controls/upload-file-button/upload-file-button.component';
import { MaterialModule } from 'shared/modules/material/material.module';
import { AngularModule } from 'shared/modules/angular/angular.module';

@NgModule({
  declarations: [
    UploadFileDialogComponent,
    UploadFileButtonComponent
  ],
  imports: [
    MaterialModule,
    AngularModule
  ],
  exports: [
    UploadFileButtonComponent,
    UploadFileDialogComponent
  ],
})
export class UploadFileButtonModule {
}
