import { ActionReducerMap, createSelector } from '@ngrx/store';
import { goodsReducer } from 'app/store/project/goods/goods.reducer';
import { ProjectState, selectProjectState } from 'app/store/project/project.state';
import { sendersReducer } from 'app/store/project/senders/senders.reducer';
import { courierChainsReducer } from 'app/store/project/courier-chains/courier-chains.reducer';
import { couriersReducer } from 'app/store/project/couriers/couriers.reducer';
import { documentsReducer } from 'app/store/project/documents/documents.reducer';
import { poolsReducer } from 'app/store/project/pools/pools.reducer';
import { statusChainsReducer } from 'app/store/project/status-chains/status-chains.reducer';
import { statusesReducer } from 'app/store/project/statuses/statuses.reducer';
import { storehousesReducer } from 'app/store/project/storehouses/storehouses.reducer';
import { filterTemplatesReducer } from 'app/store/project/filter-templates/filter-templates.reducer';
import { currentProjectReducer } from 'app/store/project/current-project/current-project.reducer';
import { fieldMapReducer } from 'app/store/project/field-map/field-map.reducer';
import { scheduledTasksReducer } from 'app/store/project/scheduled-tasks/scheduled-tasks.reducer';
import { currenciesReducer } from 'app/store/project/currencies/currencies.reducer';
import { additionalFieldsReducer } from 'app/store/project/addition-fields/addition-fields.reducer';
import { pageCriteriaReducer } from 'app/store/project/page-criteria/page-criteria.reducer';
import { progressesBackLinksReducer } from 'app/store/project/progresses-back-links/progresses-back-links.reducer';
import { noticesReducer } from 'app/store/project/notices/notices.reducer';
import { projectResourcesReducer } from 'app/store/project/project-resources/project-resources.reducer';
import { extensionsReducer } from 'app/store/project/extensions/extensions.reducer';
import { readyProgressesReducer } from 'app/store/project/ready-progresses/ready-progresses.reducer';

export const projectReducers: ActionReducerMap<ProjectState, any> = {
  additionFields: additionalFieldsReducer,
  couriers: couriersReducer,
  courierChains: courierChainsReducer,
  documents: documentsReducer,
  pools: poolsReducer,
  goods: goodsReducer,
  scheduledTasks: scheduledTasksReducer,
  senders: sendersReducer,
  statuses: statusesReducer,
  statusChains: statusChainsReducer,
  storehouses: storehousesReducer,
  currentProject: currentProjectReducer,
  orderFieldMap: fieldMapReducer,
  filterTemplates: filterTemplatesReducer,
  currencies: currenciesReducer,
  pageCriteria: pageCriteriaReducer,
  progressesBackLinks: progressesBackLinksReducer,
  projectResources: projectResourcesReducer,
  notices: noticesReducer,
  extensions: extensionsReducer,
  readyProgresses: readyProgressesReducer
};

export const projectLoading = createSelector(
  selectProjectState,
  state => {
    return state.courierChains.loading
      || state.courierChains.loading
      || state.couriers.loading
      || state.documents.loading
      || state.extensions.loading
      || state.filterTemplates.loading
      || state.goods.loading
      || state.notices.loading
      || state.pools.loading
      || state.scheduledTasks.loading
      || state.senders.loading
      || state.statusChains.loading
      || state.statuses.loading
      || state.storehouses.loading;
  }
);
