import { Component } from '@angular/core';
var VerticalTimelineComponent = /** @class */ (function () {
    function VerticalTimelineComponent() {
    }
    VerticalTimelineComponent.decorators = [
        { type: Component, args: [{
                    selector: 'vertical-timeline',
                    template: "\n    <section class=\"timeline\">\n      <div>\n        <ng-content></ng-content>\n      </div>\n    </section>\n  ",
                },] },
    ];
    return VerticalTimelineComponent;
}());
export { VerticalTimelineComponent };
