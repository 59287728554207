import { HttpClient } from '@angular/common/http';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Observable } from 'rxjs';
import { Criteria, CriteriaParams } from 'shared/criteria/Criteria';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { Uuid } from 'shared/common/types';
import { DynamicField } from 'shared/modules/dynamic-form/field-types';
import { checkExistenceByHeadRequest } from 'core/utils/rx-http';
import { clone } from 'lodash';

export interface CartOutput {
  totalPrice: number;
  totalQuantity: number;
  totalWeight: number;
  goods: OrderedGoodOutput[];
}

export interface OrderedGoodOutput {
  id: Uuid;
  goodId: Uuid;
  quantity: number;
  price: number;
}

export interface OrderedGoodInput {
  alias: string;
  price: number;
  quantity: number;
}

export interface GoodOutput {
  id: Uuid;
  name: string;
  secondName: null | string;
  alias: string;
  weight: string;
  bindings: StorehouseBindingInput[];
  isActivated: boolean;
  dynamicData: DynamicField[];
  quantity: number;
  price: number;
  defaultOutsideId: string | null;
}

export interface GoodInput {
  name: string;
  secondName: string | null;
  alias: string;
  weight: number;
  bindings: StorehouseBindingInput[];
  isActivated: boolean;
  dynamicData: { [index: string]: any };
  defaultOutsideId: string | null;
}

export interface StorehouseBindingInput {
  storehouseId: Uuid;
  outsideId: Uuid;
}

@ProjectInjectable()
export class GoodService {

  constructor(private http: HttpClient) {}

  private static trimInput(input: GoodInput): GoodInput {
    const cloned = clone(input);
    return {
      ...cloned,
      secondName: !cloned.secondName ? null : cloned.secondName
    };
  }

  isGoodExist(c: CriteriaParams): Observable<boolean> {
    return this.http.head<void>(apiUrl(`/project/good`, c), { observe: 'response' })
      .pipe(checkExistenceByHeadRequest());
  }

  getAllGoods(criteria?: Criteria): Observable<GoodOutput[]> {
    return this.http.get<GoodOutput[]>(apiUrl(`/project/goods`, criteria));
  }

  createGood(input: GoodInput): Observable<GoodOutput> {
    return this.http.post<GoodOutput>(apiUrl('/project/good'), GoodService.trimInput(input));
  }

  updateGood(goodId: Uuid, input: GoodInput): Observable<GoodOutput> {
    return this.http.patch<GoodOutput>(apiUrl(`/project/good/${goodId}`), GoodService.trimInput(input));
  }

  activateGood(goodId: Uuid): Observable<GoodOutput> {
    return this.http.patch<GoodOutput>(apiUrl(`/project/good/${goodId}/activate`), null);
  }

  deactivateGood(goodId: Uuid): Observable<GoodOutput> {
    return this.http.patch<GoodOutput>(apiUrl(`/project/good/${goodId}/deactivate`), null);
  }

}
