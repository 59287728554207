import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebhooksComponent } from './webhooks/webhooks.component';
import { SharedModule } from 'shared/shared.module';
import { UpdateWebhookComponent } from './update-webhook/update-webhook.component';
import { TestWebhookComponent } from './test-webhook/test-webhook.component';
import { CreateWebhookComponent } from './create-webhook/create-webhook.component';
import { WebhooksRoutingModule } from 'root/webhooks/webhooks-routing.module';

@NgModule({
  declarations: [
    WebhooksComponent,
    UpdateWebhookComponent,
    TestWebhookComponent,
    CreateWebhookComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    WebhooksRoutingModule
  ]
})
export class WebhooksModule {
}
