import { NgModule } from '@angular/core';
import {
  ItemsSearchWrapperComponent
} from 'shared/modules/items-search-wrapper/items-search-wrapper.component';
import { AngularModule } from 'shared/modules/angular/angular.module';
import { MaterialModule } from 'shared/modules/material/material.module';
import { SearchOptionComponent } from './search-option/search-option.component';

@NgModule({
  declarations: [
    ItemsSearchWrapperComponent,
    SearchOptionComponent
  ],
  exports: [
    ItemsSearchWrapperComponent,
    SearchOptionComponent
  ],
  imports: [
    AngularModule,
    MaterialModule
  ]
})
export class ItemsSearchWrapperModule {}
