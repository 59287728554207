import { createAction, props } from '@ngrx/store';
import { ExtensionOutput } from 'core/http/project/extension/extension.service';
import { createSetErrorAction, createSetLoadingAction } from 'app/store/completed-entity-state/completed-entity-state';

export const getExtensions = createAction('[Extensions Request] Get Extensions');
export const saveExtensions = createAction('[Extensions Store] Save Extensions',
  props<{ extensions: ExtensionOutput[] }>());
export const markExtensionsLoaded = createAction('[Extensions Store] Mark Extensions Loaded');

export const setLoading = createSetLoadingAction('[Extensions Store] Set Loading');

export const setError = createSetErrorAction('[Extensions Store] Set Error');
