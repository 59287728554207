import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoutableModalComponent } from './routable-modal.component';
import { MaterialModule } from 'shared/modules/material/material.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [RoutableModalComponent],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule
  ]
})
export class RoutableModalModule {
}
