import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { catchError, debounceTime, first, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

export function asyncTrue<TValue>(checker: (current: TValue) => Observable<boolean>, old?: TValue | null): AsyncValidatorFn {
  return (c: AbstractControl): Observable<ValidationErrors | null> => {
    if (!c.valueChanges || c.value === old) {
      return of(null);
    }

    return c.valueChanges.pipe(
      debounceTime(600),
      switchMap(v => checker(v)),
      catchError(() => of(false)),
      map(exist => exist ? { unique: true } : null),
      first()
    );
  };
}
