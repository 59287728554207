import { createAction, props } from '@ngrx/store';
import { TemplateInput, TemplateOutput } from 'core/http/project/rule-set/user-templates.service';
import { Uuid } from 'shared/common/types';
import { createSetErrorAction, createSetLoadingAction } from 'app/store/completed-entity-state/completed-entity-state';

export const getFilterTemplates = createAction('[Project Request] Get Filter Templates');
export const saveFilterTemplates = createAction('[Project Store] Save Filter Templates',
  props<{ filterTemplates: TemplateOutput[] }>());
export const markFilterTemplates = createAction('[Project Store] Mark Filter Templates Loaded');

export const addFilterTemplate = createAction('[Project Store] Add Filter Template',
  props<{ template: TemplateInput }>());
export const addFilterTemplateInStore = createAction('[Project Store] Add Filter Template In Store',
  props<{ template: TemplateOutput }>());

export const removeFilterTemplate = createAction('[Project Request] Remove Filter Template', props<{ id: Uuid }>());
export const removeFilterTemplateFromStore = createAction('[Project Request] Remove Filter Template From Store',
  props<{ id: Uuid }>());

export const setLoading = createSetLoadingAction('[Project Store] Set Filter Templates Loading');

export const setError = createSetErrorAction('[Project Store] Set filter Templates Error');
