import { Component, OnInit } from '@angular/core';
import { NavLink } from 'core/types/nav-link';

@Component({
  selector: 'app-dev',
  template: `
      <app-main-layout [navLinks]="navLinks">
      </app-main-layout>`
})
export class DevLayoutComponent implements OnInit {

  navLinks: NavLink[] = [
    {
      path: '/dev/couriers',
      icon: 'accessible_forward',
      linkActiveOptions: { exact: false },
      label: 'Курьерки'
    },
    {
      path: '/dev/documents',
      icon: 'art_track',
      linkActiveOptions: { exact: false },
      label: 'Бланки'
    }
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

}
