import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import b64toBlob from 'b64-to-blob';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent implements ControlValueAccessor, OnInit {

  private _description = 'Файл';
  private _selectedExtension?: string;
  private _fileExtension = '';
  private _fileControl = new FormControl();
  private _link?: SafeResourceUrl;

  get description(): string {
    return this._description;
  }

  @Input()
  set description(value: string) {
    this._description = value;
  }

  get selectedExtension(): string | undefined {
    return this._selectedExtension;
  }

  @Input()
  set selectedExtension(value: string | undefined) {
    this._selectedExtension = value;
  }

  get fileExtension(): string {
    return this._fileExtension;
  }

  get fileControl(): FormControl {
    return this._fileControl;
  }

  get link(): SafeResourceUrl | undefined {
    return this._link;
  }

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.fileControl.valueChanges.subscribe(value => {
      this.downloadFile(this.fileExtension, value);
      this.notify();
    });
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(url?: string): void {
    this.fileControl.setValue(url || '');
    this.notify();
  }

  onFileChange(input: HTMLInputElement): void {
    if (!input.files || !input.files.length) {
      return;
    }

    const reader = new FileReader();
    const file = input.files[0];
    this._fileExtension = file.type;

    if (file.size > 5_242_880) {
      alert('Размер файла превышает 5 МБ');
      return;
    }

    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result as string;

      if (!result) {
        return;
      }

      const index = result.indexOf(',') + 1;
      const fileData = result.slice(index);
      this.fileControl.patchValue(fileData);
    };
  }

  removeFile(): void {
    this.fileControl.patchValue(null);
  }

  private downloadFile(type: string, value: string): void {
    if (!value) {
      return;
    }

    const blob = b64toBlob(value, type);
    const fileUrl = window.URL.createObjectURL(blob);
    this._link = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
  }

  private notify(): void {
    this._onChange(this.fileControl.value);
    this._onTouched();
  }

  private _onChange = (_: any) => {};
  private _onTouched = () => {};

}
