import { NgModule } from '@angular/core';
import { Resolve, RouterModule, Routes } from '@angular/router';
import { RootLayoutComponent } from 'root/root-layout.component';
import { RootGuard } from 'core/guards/root.guard';
import { AuthGuard } from 'core/guards/auth.guard';
import { RootInjectable } from 'core/http/root/root-services.module';
import { forkJoin, Observable } from 'rxjs';
import { AssignedProjectsResolver, NotSawChangelogResolver } from 'project/project-routing.module';
import { Store } from '@ngrx/store';
import { getProjects } from 'app/store/root/projects/projects.actions';
import { rootProjectsState } from 'app/store/root/projects/projects.selectors';
import { first, tap } from 'rxjs/operators';
import { getUsers } from 'app/store/root/users/users.actions';
import { usersState } from 'app/store/root/users/users.selectors';
import { getDocuments } from 'app/store/root/documents/documents.actions';
import { devDocumentsState } from 'app/store/root/documents/documents.selectors';
import { getWebhooks } from 'app/store/root/webhooks/webhooks.actions';
import { webhooksState } from 'app/store/root/webhooks/webhooks.selectors';
import { PageNotFoundComponent } from 'project/pages/page-not-found/page-not-found.component';
import { getRootCouriers } from 'app/store/root/couriers/couriers.actions';
import { rootCouriersState } from 'app/store/root/couriers/couriers.selectors';
import { getSimpleExtensions } from 'app/store/root/simple-extensions/simple-extensions.actions';
import { simpleExtensionsState } from 'app/store/root/simple-extensions/simple-extensions.selectors';
import { resetCurrentProject } from 'app/store/project/current-project/current-project.actions';

@RootInjectable()
export class RootStateResolver implements Resolve<any> {

  constructor(private store$: Store) { }

  resolve(): Observable<any> {
    this.store$.dispatch(getProjects());
    this.store$.dispatch(getUsers());
    this.store$.dispatch(getDocuments());
    this.store$.dispatch(getWebhooks());
    this.store$.dispatch(getRootCouriers());
    this.store$.dispatch(getSimpleExtensions());

    return forkJoin({
      projects: this.store$.select(rootProjectsState).pipe(first(s => s.isLoaded)),
      users: this.store$.select(usersState).pipe(first(s => s.isLoaded)),
      documents: this.store$.select(devDocumentsState).pipe(first(s => s.isLoaded)),
      webhooks: this.store$.select(webhooksState).pipe(first(s => s.isLoaded)),
      couriers: this.store$.select(rootCouriersState).pipe(first(s => s.isLoaded)),
      simpleExtensions: this.store$.select(simpleExtensionsState).pipe(first(s => s.isLoaded))
    }).pipe(
      tap(() => this.store$.dispatch(resetCurrentProject()))
    );
  }

}

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, RootGuard],
    canActivateChild: [AuthGuard, RootGuard],
    component: RootLayoutComponent,
    resolve: {
      changelog: NotSawChangelogResolver,
      projects: AssignedProjectsResolver,
      state: RootStateResolver
    },
    children: [
      {
        path: 'managers',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
      },
      {
        path: 'invite',
        loadChildren: () => import('./invite/invite.module').then(m => m.InviteModule)
      },
      {
        path: 'extensions',
        loadChildren: () => import('./extensions/extensions.module').then(m => m.ExtensionsModule)
      },
      {
        path: 'integration',
        loadChildren: () => import('./webhooks/webhooks.module').then(m => m.WebhooksModule)
      },
      {
        path: 'association',
        loadChildren: () => import('./association/association.module').then(m => m.AssociationModule)
      },
      {
        path: 'projects',
        loadChildren: () => import('./project/project.module').then(m => m.ProjectModule)
      },
      {
        path: '**',
        component: PageNotFoundComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RootRoutingModule {
}
