import { Component, Input, OnDestroy } from '@angular/core';
import { PoolOutput } from 'core/http/project/document/document-pool.service';
import { SenderOutput } from 'core/http/project/sender/sender.service';
import {
  BackgroundTaskRunner,
  PrintingArgs,
  SinglePrintingArgs,
  SingleUnloadingArgs,
  UnloadingArgs
} from 'shared/common/background-task-runner';
import {
  CourierChainOutput,
  CourierOutput,
  DocumentOutput,
  StatusChangeGroupInput,
  StatusOutput,
  StorehouseOutput,
  UnloadingService
} from 'core/http/project';
import { StatusChainOutput } from 'core/http/project/status/status-chain.service';
import { Buttons, ConfirmActionService } from 'shared/common/confirm-action.service';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { UnloadingDataDialogComponent } from 'shared/dialogs/sender-select-dialog/unloading-data-dialog.component';
import { filterNil } from 'core/utils/rx-common';
import { GrouperDialogComponent } from 'shared/dialogs/grouper-dialog/grouper-dialog.component';
import { Store } from '@ngrx/store';
import { documents, summary } from 'app/store/project/documents/documents.selectors';
import { courierChains } from 'app/store/project/courier-chains/courier-chains.selectors';
import { pools } from 'app/store/project/pools/pools.selectors';
import { activatedSenders } from 'app/store/project/senders/senders.selectors';
import { activeStatuses } from 'app/store/project/statuses/statuses.selectors';
import { statusChains } from 'app/store/project/status-chains/status-chains.selectors';
import { storehouses } from 'app/store/project/storehouses/storehouses.selectors';
import { couriers } from 'app/store/project/couriers/couriers.selectors';
import { UnloadingCheckDialogsService } from 'core/http/project/courier/unloading-check-dialogs.service';
import { errorNotify, notify } from 'app/store/common-effects/notifier.effects';
import { ClipboardService } from 'ngx-clipboard';
import { toFilter } from 'shared/modules/query-builder/query-builder-utils';
import { Uuid } from 'shared/common/types';
import { BackgroundTaskService, ProgressStatuses } from 'core/http/project/background/background-task.service';
import { SummaryDialogComponent } from 'shared/dialogs/summary-dialog/summary-dialog.component';
import { OrderCodeDialogComponent } from 'shared/dialogs/order-code-dialog/order-code-dialog.component';
import { and } from 'shared/criteria/Criteria';

type StatusGroup = { group: string, statuses: StatusOutput[] };
type SelectedUnloadingData = [SenderOutput, StorehouseOutput];
type DocumentGroup = { group: string, documents: DocumentOutput[] };

@Component({
  selector: 'app-order-actions',
  templateUrl: './order-actions.component.html',
  styleUrls: ['./order-actions.component.scss']
})
export class OrderActionsComponent implements OnDestroy {

  @Input() orderCount = 0;
  @Input() filter = '';
  @Input() backLink = Array<string>();

  readonly destroy = new ReplaySubject(1);
  readonly pools$ = this.store$.select(pools);
  readonly summary$ = this.store$.select(summary);
  readonly couriers$ = this.store$.select(couriers);
  readonly statusChains$ = this.store$.select(statusChains);
  readonly courierChains$ = this.store$.select(courierChains);
  readonly statusGroups$ = this.store$.select(activeStatuses)
    .pipe(
      map(data => _.chain(data)
        .groupBy(status => status.associatedState)
        .map((s, g) => ({ group: g.toString(), statuses: s }) as StatusGroup)
        .value()
      )
    );
  readonly documentGroups$ = this.store$.select(documents)
    .pipe(
      map(data => _.chain(data)
        .groupBy(d => (d.courier && d.courier.name) || 'Дополнительно')
        .map((d, g) => ({ documents: d, group: g }))
        .value()
      )
    );

  get disable(): boolean {
    return this.orderCount === 0;
  }

  constructor(
    private taskRunner: BackgroundTaskRunner,
    private dialog: MatDialog,
    private confirmDialog: ConfirmActionService,
    private confirmation: ConfirmActionService,
    private unloadingService: UnloadingService,
    private unloadingCheckDialogsService: UnloadingCheckDialogsService,
    private store$: Store,
    private clipboard: ClipboardService,
    private backgroundService: BackgroundTaskService
  ) { }

  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }

  copyFilter(): void {
    this.clipboard.copy(this.filter);
    this.store$.dispatch(notify({ message: 'Критерия скопирована в буфер' }));
  }

  showCode(): void {
    this.dialog.open(OrderCodeDialogComponent, { data: this.filter });
  }

  openSummaryDialog(documentId: Uuid): void {
    const response = this.unloadingService.getSummary(this.filter, documentId)
      .pipe(
        switchMap(progress => this.backgroundService.listenProgress(progress.id)),
        filter(p => p.status === ProgressStatuses.READY || p.status === ProgressStatuses.FAILURE),
        switchMap(p => p.status === ProgressStatuses.FAILURE
          ? this.backgroundService.getCommandError(p.id)
          : this.backgroundService.getResult<{ content: string }>(p.id)
        ),
        filter(res => {
          if (!('content' in res)) {
            this.store$.dispatch(errorNotify({ message: res.message }));
            this.dialog.closeAll();
            return false;
          }

          return true;
        }),
        map(res => (<{ content: string }>res).content)
      );

    const data = () => response;

    this.dialog.open(SummaryDialogComponent, { data });
  }

  cancelUnloading(): void {
    this.confirmDialog
      .show(`Отменить выгрузку для ${this.orderCount} заказов?`, [
        Buttons.warn('Отмена'),
        Buttons.primary('Подтвердить', () => this.taskRunner.runCancellation(this.filter, this.backLink))
      ]);
  }

  resendTracks(f: string): void {
    this.confirmDialog
      .show(`Переотправить треки для ${this.orderCount} заказов?`, [
        Buttons.warn('Отмена'),
        Buttons.primary('Подтвердить', () => this.taskRunner.resendTracks(f, this.backLink))
      ]);
  }

  resendStatuses(f: string): void {
    this.confirmDialog
      .show(`Переотправить статусы для ${this.orderCount} заказов?`, [
        Buttons.warn('Отмена'),
        Buttons.primary('Подтвердить', () => this.taskRunner.resendStatuses(f, this.backLink))
      ]);
  }

  onStatusClicked = (status: StatusOutput) => this.confirmation.ask({
    message: {
      content: 'ORDERS.COMMON.STATUS_BUTTON.confirm_status_change_message',
      args: { count: this.orderCount, status: status.name }
    },
    buttons: [
      Buttons.warn('ORDERS.COMMON.STATUS_BUTTON.cancel_status_change_button'),
      Buttons.primary('ORDERS.COMMON.STATUS_BUTTON.run_status_change_button', () => {
        this.taskRunner.runStatusChange([{ toStatusId: status.id, orderFilter: this.filter }]);
      })
    ]
  });

  onStatusChainClicked = (chain: StatusChainOutput) => this.confirmation.ask({
    message: {
      content: 'ORDERS.COMMON.STATUS_BUTTON.chain_status_change_message',
      args: { count: this.orderCount, chain: chain.name }
    },
    buttons: [
      Buttons.warn('ORDERS.COMMON.STATUS_BUTTON.cancel_status_change_button'),
      Buttons.primary('ORDERS.COMMON.STATUS_BUTTON.run_status_change_button', () => {
        const groups: StatusChangeGroupInput[] = chain.chainedStatuses
          .map(c => ({
            toStatusId: c.status.id,
            orderFilter: and(this.filter, toFilter(c.ruleSet))
          }));

        this.taskRunner.runStatusChange(groups);
      })
    ]
  });

  onCourierChainClicked(chain: CourierChainOutput): void {
    const chainUnloading$ = this.unloadingService.checkChainReserves(chain.id).pipe(
      mergeMap(reserves => this.unloadingCheckDialogsService.checkReservesIfNeed(reserves.with, this.orderCount)),
      mergeMap(() => this.unloadingService.checkChainAssociations(chain.id)),
      mergeMap(associations => this.unloadingCheckDialogsService.checkAssociationsIfNeed(associations)),
      tap(() => this.beginUnloading({ type: 'CHAIN_UNLOADING', chain }))
    );

    chainUnloading$.pipe(takeUntil(this.destroy)).subscribe();
  }

  onCourierClicked(courier: CourierOutput): void {
    this.getUnloadingData$(courier)
      .pipe(takeUntil(this.destroy))
      .subscribe(([sender, storehouse]) => {
        if (!sender || !storehouse) {
          return;
        }

        const data: SingleUnloadingArgs = {
          type: 'SINGLE_UNLOADING',
          courier: courier,
          sender: sender,
          storehouse: storehouse
        };

        this.beginUnloading(data);
      });
  }

  onPoolPrinting(pool: PoolOutput): void {
    this.confirmation.ask({
      message: {
        content: 'ORDERS.COMMON.PRINTING_BUTTON.confirm_pool_printing_message',
        args: { pool: pool.name, count: this.orderCount }
      },
      buttons: [
        Buttons.warn('ORDERS.COMMON.PRINTING_BUTTON.cancel_printing_button'),
        Buttons.primary('ORDERS.COMMON.PRINTING_BUTTON.run_printing_button', () => {
          this.beginPrinting({ type: 'POOL_PRINTING', pool });
        })
      ]
    });
  }

  onSinglePrinting(document: DocumentOutput): void {
    this.dialog
      .open(GrouperDialogComponent, { minWidth: 500 })
      .afterClosed()
      .pipe(
        filterNil(),
        takeUntil(this.destroy)
      )
      .subscribe(groupers => {
        if (groupers === null) {
          return;
        }

        const data: SinglePrintingArgs = {
          type: 'SINGLE_PRINTING',
          document,
          groupers
        };

        this.beginPrinting(data);
      });
  }

  trackByStatusGroup(i: number, item: StatusGroup): string {
    return item.group;
  }

  trackByDocumentGroup(i: number, item: DocumentGroup): string {
    return item.group;
  }

  trackById(i: number, item: CourierOutput | DocumentOutput | StatusOutput | StatusChainOutput): string {
    return item.id;
  }

  private beginUnloading(args: UnloadingArgs): void {
    return this.taskRunner.runUnloading(this.filter, args, this.backLink);
  }

  private beginPrinting(args: PrintingArgs): void {
    this.taskRunner.runPrinting(this.filter, args, this.backLink);
  }

  private getUnloadingData$(courier: CourierOutput): Observable<SelectedUnloadingData> {
    const senders$ = this.store$.select(activatedSenders);
    const storehouses$ = this.store$.select(storehouses);

    const dialogRef = this.dialog.open(UnloadingDataDialogComponent, {
      data: { senders$, storehouses$, orderCount: this.orderCount, courier }
    });

    return dialogRef.afterClosed().pipe(
      filterNil(),
      switchMap((data: Observable<{ sender: SenderOutput; storehouse: StorehouseOutput }>) => data),
      map(({ sender, storehouse }) => [sender, storehouse])
    );
  }

}
