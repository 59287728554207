import { Action, createReducer, on } from '@ngrx/store';
import * as StatusesActions from './statuses.actions';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { StatusOutput } from 'core/http/project';
import {
  CompletedEntityState,
  initialCompletedEntityState
} from 'app/store/completed-entity-state/completed-entity-state';

export interface StatusesState extends CompletedEntityState<StatusOutput> {}

function sortByPriority(a: StatusOutput, b: StatusOutput): number {
  return a.priority - b.priority;
}

const adapter: EntityAdapter<StatusOutput> = createEntityAdapter<StatusOutput>({
  sortComparer: sortByPriority
});

export const initialStatusesState: StatusesState = adapter.getInitialState(initialCompletedEntityState);

const reducer = createReducer(
  initialStatusesState,
  on(StatusesActions.saveStatuses, (state, payload) => adapter.setAll(payload.statuses, state)),
  on(StatusesActions.markStatusesLoaded, state => ({ ...state, loaded: true })),
  on(StatusesActions.addStatusInStore, (state, { status }) => adapter.addOne(status, state)),
  on(StatusesActions.updateStatusInStore, (state, { update }) => adapter.updateOne(update, state)),
  on(StatusesActions.removeStatusFromStore, (state, { id }) => adapter.removeOne(id, state)),
  on(StatusesActions.changePriorityInStore, (state, { updates }) => adapter.updateMany(updates, state)),
  on(StatusesActions.setLoading, (state, { value }) => ({ ...state, loading: value })),
  on(StatusesActions.setError, (state, { value }) => ({ ...state, error: value }))
);

export function statusesReducer(state: StatusesState | undefined, action: Action): StatusesState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
