import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss']
})
export class ChangeLogComponent implements OnInit {

  private _changes$ = new Observable();

  get changes$(): Observable<unknown> {
    return this._changes$;
  }

  constructor(
    private store$: Store,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    const changes = CHANGELOG_VERSIONS.map(({ version, date }) => {
      return this.http.get(`/assets/changelog/${version}.md`, { responseType: 'text' }).pipe(
        map(markdown => ({ version, date, content: markdown }))
      );
    });

    this._changes$ = forkJoin(changes);

    localStorage.setItem('user-saw', 'true');
  }

}

export const CHANGELOG_VERSIONS = [
  { version: '4.1.1', date: '19.10.2020' },
  { version: '4.1.0', date: '13.10.2020' },
  { version: '4.0.6', date: '05.10.2020' },
  { version: '4.0.5', date: '28.09.2020' },
  { version: '4.0.4', date: '20.09.2020' },
  { version: '4.0.3', date: '15.09.2020' },
  { version: '4.0.2', date: '11.08.2020' },
  { version: '4.0.1', date: '08.07.2020' },
  { version: '4.0.0', date: '01.06.2020' },
  { version: '3.8.0', date: '14.05.2020' },
  { version: '3.7.0', date: '25.04.2020' },
  { version: '3.6.0', date: '14.04.2020' },
  { version: '3.5.0', date: '08.04.2020' },
  { version: '3.4.0', date: '02.04.2020' },
  { version: '3.3.0', date: '17.03.2020' },
  { version: '3.0.1', date: '15.02.2020' },
  { version: '3.0.0', date: '27.01.2020' },
  { version: '2.1.0', date: '05.12.2019' },
  { version: '2.0.0', date: '25.10.2019' },
  { version: '1.2.0', date: '21.10.2019' },
  { version: '1.1.0', date: '10.10.2019' },
  { version: '1.0.0', date: '01.10.2019' }
];
