import { initialProjectsState, ProjectsState } from 'app/store/root/projects/projects.reducer';
import { createFeatureSelector } from '@ngrx/store';
import { initialUsersState, UsersState } from 'app/store/root/users/users.reducer';
import { DocumentsState, initialDocumentsState } from 'app/store/root/documents/documents.reducer';
import { initialWebhooksState, WebhooksState } from 'app/store/root/webhooks/webhooks.reducer';
import { initialRootCouriersState, RootCouriersState } from 'app/store/root/couriers/couriers.reducer';
import {
  initialSimpleExtensionsState,
  SimpleExtensionsState
} from 'app/store/root/simple-extensions/simple-extensions.reducer';

const rootFeatureKey = 'root';

export const selectRootState = createFeatureSelector<RootState>(rootFeatureKey);

export interface RootState {
  projects: ProjectsState;
  users: UsersState;
  documents: DocumentsState;
  webhooks: WebhooksState;
  couriers: RootCouriersState;
  simpleExtensions: SimpleExtensionsState;
}

export const initialRootState: RootState = {
  projects: initialProjectsState,
  users: initialUsersState,
  documents: initialDocumentsState,
  webhooks: initialWebhooksState,
  couriers: initialRootCouriersState,
  simpleExtensions: initialSimpleExtensionsState
};
