import { Uuid } from 'shared/common/types';
import { RuleSet } from 'angular2-query-builder';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Dictionary } from '@ngrx/entity';

export const EMAIL_REGEXP = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i;
export const TELEGRAM_REGEXP = /\s?[-\d][\d]*\s?/g;

export type NoticeType = 'COURIER_CHAIN_NOTICE_TRIGGER' | 'SCHEDULED_NOTICE_TRIGGER';
export type DayOfWeek = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';
export type Month = 'Jan' | 'Feb' | 'Mar' | 'Apr' | 'May' | 'Jun' | 'Jul' | 'Aug' | 'Sep' | 'Oct' | 'Nov' | 'Dec';
export type NoticeVariable = CourierChainVariable | ScheduledNoticeVariable;

export type NoticeTriggerOutput = ChainNoticeOutput | ScheduledNoticeOutput;
export type NoticeTriggerInput = CourierChainNoticeTriggerInput | ScheduledNoticeTriggerInput;

export type ChainNoticeOutput = CourierChainNoticeTrigger & NoticeOutput;
export type ScheduledNoticeOutput = ScheduledNoticeTrigger & NoticeOutput;

export type CourierChainVariable = NoticeSequenceVariable | VariableValue;

export type CourierChainNoticeTriggerInput = { chainId: Uuid; variables: CourierChainVariable[] } & NoticeInput;
export type ScheduledNoticeTriggerInput =
  { schedule: NoticeSchedule; variables: ScheduledNoticeVariable[] }
  & NoticeInput;

interface NoticeSequence {
  startFrom: number;
  seqName: string;
}

interface NoticeSequenceVariable {
  type: 'notice_sequence';
  name: string;
  value: NoticeSequence;
}

interface VariableValue {
  type: 'summary'
    | 'courier_name'
    | 'serial'
    | 'sender_name'
    | 'exported_at'
    | 'document_link'
    | 'pool_link';
  name: string;
  value: Uuid;
}

// Уведомление для выгрузок
interface CourierChainNoticeTrigger {
  type: 'COURIER_CHAIN_NOTICE_TRIGGER';
  chainId: Uuid;                          // Цепочка с которой связано уведомление
  filter: RuleSet;
  // chainedCouriers: Uuid[];                // Курьерки из цепочки для которые будут отправлены уведомления
  variables: CourierChainVariable[];
}

// Все переменные доступные для произвольных заказов
export interface ScheduledNoticeVariable {
  type: 'pool_link' | 'document_link' | 'summary';
  name: string;
  value: Uuid;
}

// Время запуска уведомления для произвольных заказов
export interface NoticeSchedule {
  hour: number;
  minute: number;
  days: Array<DayOfWeek>;
  months: Array<Month>;
}

// Уведомление для произвольных заказов
interface ScheduledNoticeTrigger {
  type: 'SCHEDULED_NOTICE_TRIGGER';
  schedule: NoticeSchedule;                 // Расписание по которому запускается уведомление
  filter: RuleSet;                           // Фильтр заказов для которых нужно собрать уведомление
  variables: ScheduledNoticeVariable[];
}

// Получатель
export interface NoticeRecipient {
  type: 'TELEGRAM' | 'EMAIL';
  contact: string;
}

// Общий Output для уведомления выгрузки и заказов
interface NoticeOutput {
  id: Uuid;
  template: string;
  recipients: NoticeRecipient[];
  createdAt: string;
  updatedAt: string | null;
  subject: string;
}

// Общий Input для уведомления выгрузки и заказов
interface NoticeInput {
  subject: string;
  recipients: NoticeRecipient[];
  template: string;
  filter: RuleSet;
}

export interface TelegramChatOutput {
  id: number;
  name: string;
}

export function isInvalidMail(email: string): boolean {
  return !EMAIL_REGEXP.test(email.trim());
}

export function isInvalidTelegram(telegram: string): boolean {
  return !telegram.trim().match(TELEGRAM_REGEXP);
}

export function getContactLabel(recipient: NoticeRecipient, chats: Observable<Dictionary<string>>): Observable<string> {
  if (recipient.type === 'EMAIL') {
    return of('');
  }

  return chats.pipe(
    map(c => c[recipient.contact] || 'Неизвестный чат')
  );
}
