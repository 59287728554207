import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PopoverButtonComponent,
  PopoverCloseDirective
} from 'shared/modules/controls/popover-button/popover-button.component';
import { ButtonGroupComponent } from 'shared/modules/controls/button-group/button-group.component';
import { YesNoComponent } from 'shared/modules/controls/yes-no/yes-no.component';
import { GoBackButtonComponent } from 'shared/modules/controls/go-back-button/go-back-button.component';
import { ToggleGroupComponent } from 'shared/modules/controls/toggle-group/toggle-group.component';
import { MaterialModule } from 'shared/modules/material/material.module';
import { MdePopoverModule } from '@material-extended/mde';
import { LayoutModule } from 'shared/modules/layout/layout.module';
import { DatetimeRangeComponent } from 'shared/modules/controls/datetime-range/datetime-range.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FilterWithPopoverComponent } from './filter-with-popover/filter-with-popover.component';
import { RuleViewComponent } from './filter-with-popover/rule-view/rule-view.component';
import { UploadFileButtonModule } from 'shared/modules/controls/upload-file-button/upload-file-button.module';
import { AsyncValidationLabelComponent } from './async-validation-label/async-validation-label.component';
import { TranslateModule } from '@ngx-translate/core';
import { SearchBarModule } from 'shared/modules/controls/search-bar/search-bar.module';
import { CountableButtonComponent } from './countable-button/countable-button.component';
import { DeletePopoverComponent } from './delete-popover/delete-popover.component';
import { SelectSearchModule } from 'shared/modules/controls/select-search/select-search.module';
import { TypographyModule } from 'shared/modules/typography/typography.module';
import { ScrollableListComponent } from 'shared/modules/controls/scrollable-list/scrollable-list.component';
import { SelectModule } from 'shared/modules/controls/select/select.module';
import { SelectAllModule } from 'shared/modules/controls/select-all/select-all.module';
import { GoBackLinkComponent } from 'shared/modules/controls/go-back-button/go-back-link.component';
import { DirectivesModule } from 'shared/modules/directives/directives.module';
import { SeparatorInputComponent, SeparatorInputLabelDirective } from './separator-input/separator-input.component';
import { AngularModule } from 'shared/modules/angular/angular.module';

@NgModule({
  declarations: [
    ToggleGroupComponent,
    YesNoComponent,
    GoBackButtonComponent,
    ButtonGroupComponent,
    PopoverButtonComponent,
    PopoverCloseDirective,
    DatetimeRangeComponent,
    FilterWithPopoverComponent,
    RuleViewComponent,
    AsyncValidationLabelComponent,
    CountableButtonComponent,
    DeletePopoverComponent,
    ScrollableListComponent,
    GoBackLinkComponent,
    SeparatorInputComponent,
    SeparatorInputLabelDirective
  ],
  imports: [
    CommonModule,
    MaterialModule,
    MdePopoverModule,
    LayoutModule,
    ReactiveFormsModule,
    TranslateModule,
    UploadFileButtonModule,
    SearchBarModule,
    SelectSearchModule,
    TypographyModule,
    SelectModule,
    SelectAllModule,
    DirectivesModule,
    AngularModule
  ],
  exports: [
    ToggleGroupComponent,
    YesNoComponent,
    GoBackButtonComponent,
    ButtonGroupComponent,
    PopoverButtonComponent,
    PopoverCloseDirective,
    DatetimeRangeComponent,
    SelectAllModule,
    FilterWithPopoverComponent,
    AsyncValidationLabelComponent,
    UploadFileButtonModule,
    RuleViewComponent,
    SearchBarModule,
    CountableButtonComponent,
    DeletePopoverComponent,
    ScrollableListComponent,
    SelectSearchModule,
    SelectModule,
    GoBackLinkComponent,
    SeparatorInputComponent,
    SeparatorInputLabelDirective
  ]
})
export class ControlsModule {
}
