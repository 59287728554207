import { ProjectInjectable } from 'core/http/project/project-services.module';
import { HttpClient } from '@angular/common/http';
import { Uuid } from 'shared/common/types';
import { Observable } from 'rxjs';
import {
  ChainNoticeOutput,
  CourierChainNoticeTriggerInput,
  NoticeTriggerOutput,
  ScheduledNoticeOutput,
  ScheduledNoticeTriggerInput,
  TelegramChatOutput
} from 'core/http/project/notice/notice.interfaces';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { BackgroundProgress } from 'core/http/project/background/background-task.service';

@ProjectInjectable()
export class NoticeService {

  constructor(private http: HttpClient) {}

  getTriggers(): Observable<NoticeTriggerOutput[]> {
    return this.http.get<NoticeTriggerOutput[]>(apiUrl('/project/notice-triggers'));
  }

  createCourierChainTrigger(input: CourierChainNoticeTriggerInput): Observable<ChainNoticeOutput> {
    return this.http.post<ChainNoticeOutput>(apiUrl('/project/notice-triggers/courier-chain'), input);
  }

  updateCourierChainTrigger(triggerId: Uuid, input: CourierChainNoticeTriggerInput): Observable<ChainNoticeOutput> {
    return this.http.put<ChainNoticeOutput>(apiUrl(`/project/notice-triggers/${triggerId}/courier-chain`), input);
  }

  createScheduledTrigger(input: ScheduledNoticeTriggerInput): Observable<ScheduledNoticeOutput> {
    return this.http.post<ScheduledNoticeOutput>(apiUrl('/project/notice-triggers/scheduled'), input);
  }

  updateScheduledTrigger(triggerId: Uuid, input: ScheduledNoticeTriggerInput): Observable<ScheduledNoticeOutput> {
    return this.http.put<ScheduledNoticeOutput>(apiUrl(`/project/notice-triggers/${triggerId}/scheduled`), input);
  }

  removeNotice(id: Uuid): Observable<Object> {
    return this.http.delete<Object>(apiUrl(`/project/notice-triggers/${id}`));
  }

  resendNotice(unloadingId: Uuid, chainNoticeId: Uuid, destinations: string[]): Observable<BackgroundProgress> {
    return this.http.post<BackgroundProgress>(apiUrl(`/project/unloading/${unloadingId}/chain-notice/${chainNoticeId}`),
      { destinations });
  }

  getTelegramChats(): Observable<TelegramChatOutput[]> {
    return this.http.get<TelegramChatOutput[]>(apiUrl('/project/courier-chains/telegram-chats'));
  }

}
