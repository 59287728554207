import { createSelector } from '@ngrx/store';
import * as fromDocuments from './documents.reducer';
import { selectRootState } from 'app/store/root/root.state';

export const devDocumentsState = createSelector(
  selectRootState,
  state => state.documents
);

export const rootDocuments = createSelector(
  selectRootState,
  state => fromDocuments.selectAll(state.documents)
);
