import { initialLoggedUserState, LoggedUserState } from 'app/store/login/logged-user/logged-user.state';
import * as LoginActions from 'app/store/login/logged-user/logged-user.actions';
import { Action, createReducer, on } from '@ngrx/store';

const reducer = createReducer(
  initialLoggedUserState,
  on(LoginActions.logInSuccess, (state, { token }) => ({ ...state, token })),
  on(LoginActions.resetLoggedUser, state => ({ ...state, token: null })),
  on(LoginActions.markRefreshing, (state, { value }) => ({ ...state, isRefreshing: value }))
);

export function loggedUserReducer(state: LoggedUserState | undefined, action: Action): LoggedUserState {
  return reducer(state, action);
}
