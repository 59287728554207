import { Component, Input } from '@angular/core';
import { ChangeOutput } from 'core/http/project';
import { TimeHelper } from 'shared/common/types';
import * as moment from 'moment';

@Component({
  selector: 'app-order-timeline',
  templateUrl: './order-timeline.component.html',
  styleUrls: ['./order-timeline.component.scss']
})
export class OrderTimelineComponent {

  private _changes = Array<ChangeOutput>();

  get changes(): ChangeOutput[] {
    return this._changes;
  }

  @Input()
  set changes(value: ChangeOutput[]) {
    this._changes = value;
  }

  constructor() { }

  fromUtcToLocal(date: Date): string {
    const h = moment(date).format('H');
    const mm = moment(date).format('mm');
    const { hour, minute } = TimeHelper.fromUtcToLocal(+h, +mm);

    return prettifyTime(hour, minute);
  }
}

export function prettifyTime(hour: number, minute: number): string {
  return `${prettifyNumber(hour)}:${prettifyNumber(minute)}`;
}

function prettifyNumber(number: number): string {
  return number.toString().length < 2 ? `0${number}` : number.toString();
}
