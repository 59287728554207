import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { ProjectInjectable } from 'core/http/project/project-services.module';

@ProjectInjectable()
export class OrderCodeService {

  constructor(private http: HttpClient) {}

  generateOrderCode(filter: string): Observable<string> {
    return this.http.post<string>(apiUrl('/project/dev/get-order-code'),
      { orderFilter: filter },
      { responseType: 'text' as 'json' });
  }
}
