import { EntityState } from '@ngrx/entity';
import { ActionCreator, createAction, Creator, props } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProjectResourceType } from 'core/http/project/resource-updates/resource-updates.service';
import { NotAllowedCheck, TypedAction } from '@ngrx/store/src/models';

type CustomActionCreator<T extends string, P extends object> = ActionCreator<T, (props: P & NotAllowedCheck<P>) => P & TypedAction<T>>;

export interface CompletedEntityState<T> extends EntityState<T> {
  loaded: boolean;
  loading: boolean;
  error: Error;
}

export const initialCompletedEntityState = {
  loaded: false,
  loading: false,
  error: {} as Error
};

export interface SetStateProperties<EntityType,
  AType extends string = string,
  BType extends string = string,
  CType extends string = string,
  AAction extends Creator = Creator<[{ value: boolean }], { type: AType }>,
  BAction extends Creator = Creator<[{ value: Error }], { type: BType }>,
  > {
  setLoading: ActionCreator<AType, AAction>;
  setLoaded: ActionCreator<CType, () => TypedAction<CType>>;
  setError: ActionCreator<BType, BAction>;
  request: () => Observable<EntityType>;
  cacheKey?: ProjectResourceType;
}

export function createProperties<EntityType,
  AType extends string = string,
  BType extends string = string,
  CType extends string = string,
  AAction extends Creator = Creator<[{ value: boolean }], { type: AType }>,
  BAction extends Creator = Creator<[{ value: Error }], { type: BType }>,
  >(
  setLoading: ActionCreator<AType, AAction>,
  setLoaded: ActionCreator<CType, () => TypedAction<CType>>,
  setError: ActionCreator<BType, BAction>,
  request: () => Observable<EntityType>,
  cacheKey?: ProjectResourceType
): SetStateProperties<EntityType, AType, BType, CType, AAction, BAction> {
  return {
    setLoading,
    setLoaded,
    setError,
    request,
    cacheKey
  };
}

export function createSetLoadingAction<T extends string>(type: T): CustomActionCreator<T, { value: boolean }> {
  return createAction(type, props<{ value: boolean }>());
}

export function createSetErrorAction<T extends string>(type: T): CustomActionCreator<T, { value: Error }> {
  return createAction(type, props<{ value: Error }>());
}
