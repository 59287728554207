import { Injectable, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RefreshTokenInterceptor } from 'core/interceptors/token/refresh-token.interceptor';
import { BannedAccountInterceptor } from 'core/interceptors/token/banned-account.interceptor';

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BannedAccountInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    }
  ]
})
export class RootServicesModule {
}

export function RootInjectable(): any {
  return Injectable({ providedIn: RootServicesModule });
}
