import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterModule, Routes } from '@angular/router';
import { UserComponent } from 'root/user/user/user.component';
import { AssignedStatusesComponent } from 'root/user/assigned-statuses/assigned-statuses.component';
import { AssignedProjectsComponent } from 'root/user/assigned-projects/assigned-projects.component';
import { RootInjectable } from 'core/http/root/root-services.module';
import { ProjectStatusOutput, StatusService, UserService } from 'core/http/root';
import { Observable } from 'rxjs';
import { StatusOutput } from 'core/http/project';

@RootInjectable()
export class ProjectStatusesResolver implements Resolve<ProjectStatusOutput[]> {
  constructor(private statusService: StatusService) {
  }

  resolve(snapshot: ActivatedRouteSnapshot): Observable<ProjectStatusOutput[]> {
    return this.statusService.getProjectStatuses(snapshot.params.projectId);
  }
}

@RootInjectable()
export class AssignedStatusesResolver implements Resolve<StatusOutput[]> {
  constructor(private userService: UserService) {
  }

  resolve(snapshot: ActivatedRouteSnapshot): Observable<StatusOutput[]> {
    return this.userService.getAssignedStatuses(snapshot.params.managerId, snapshot.params.projectId);
  }
}

const routes: Routes = [
  {
    path: '',
    component: UserComponent,
    data: { title: 'Админка - Пользователи' }
  },
  {
    path: ':managerId/project/:projectId/assigned-statuses',
    component: AssignedStatusesComponent,
    resolve: {
      statuses: ProjectStatusesResolver,
      assignedStatuses: AssignedStatusesResolver
    },
    data: { title: 'Админка - Назначенные статусы' }
  },
  {
    path: ':managerId/assigned-projects',
    component: AssignedProjectsComponent,
    data: { title: 'Админка - Назначенные проекты' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule {}
