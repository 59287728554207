import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotComponent } from 'auth/components/forgot/forgot.component';
import { AuthComponent } from 'auth/components/auth/auth.component';
import { LoginComponent } from './components/login/login.component';
import { RecoveryComponent } from './components/recovery/recovery.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { NoAuthGuard } from 'core/guards/no-auth.guard';
import { RegistrationInviteGuard } from 'core/guards/registration-invite.guard';
import { RecoveryInviteGuard } from 'core/guards/recovery-invite.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent,
        data: { title: 'Вход в систему' }
      },
      {
        path: 'forgot',
        component: ForgotComponent,
        data: { title: 'Запрос на восстановление пароля' }
      },
      {
        path: 'recovery',
        component: RecoveryComponent,
        canActivate: [RecoveryInviteGuard, NoAuthGuard],
        data: { title: 'Восстановление пароля' }
      },
      {
        path: 'registration/:token',
        component: RegistrationComponent,
        canActivate: [RegistrationInviteGuard],
        data: { title: 'Регистрация в системе' }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
