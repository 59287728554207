import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RuleSet } from 'angular2-query-builder';
import { QueryBuilderDialogService } from 'shared/modules/query-builder/query-builder-dialog/query-builder-dialog.service';
import { FieldMap } from 'angular2-query-builder/dist/components';
import { TemplateOutput } from 'core/http/project/rule-set/user-templates.service';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { filterTemplates } from 'app/store/project/filter-templates/filter-templates.selectors';

@Component({
  selector: 'app-user-filters',
  templateUrl: './user-filters.component.html',
  styleUrls: ['./user-filters.component.scss']
})
export class UserFiltersComponent {

  readonly templates$ = this.store$.select(filterTemplates);

  @Input() fieldMap: FieldMap = {};

  @Output() applied = new EventEmitter<RuleSet>();
  @Output() reset = new EventEmitter();

  new?: TemplateOutput;
  selected?: TemplateOutput;

  constructor(
    private queryBuilder: QueryBuilderDialogService,
    private store$: Store
  ) {}

  openFilterDialog(): void {
    let toChange: RuleSet = !this.new ? { condition: 'and', rules: [] } : this.new.ruleSet;

    if (this.selected) {
      toChange = _.cloneDeep(this.selected.ruleSet);
    }

    const dialogRef = this.queryBuilder.open('order', this.fieldMap, toChange);

    dialogRef.subscribe(ruleSet => {
      if (!ruleSet) {
        return;
      }

      const template: TemplateOutput = { id: '', name: 'Новый фильтр', ruleSet, type: 'order' };
      this.applySelected(template);

      this.new = template;
    });
  }

  resetSelected(): void {
    this.selected = undefined;
    this.reset.emit();
  }

  applySelected(template: TemplateOutput): void {
    this.selected = template;
    this.applied.emit(template.ruleSet);
  }

}
