import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { ProjectScheduleDialogComponent } from 'settings/current-project/project-schedule-dialog/project-schedule-dialog.component';
import { first, mergeAll, reduce } from 'rxjs/operators';
import { ProjectSchedule } from 'core/http/root';
import { TimeHelper } from 'shared/common/types';
import { Observable, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LabelPipe } from 'shared/modules/pipes/label.pipe';

@Component({
  selector: 'app-withdrawn-select',
  templateUrl: './withdrawn-select.component.html',
  styleUrls: ['./withdrawn-select.component.scss'],
  providers: [LabelPipe]
})
export class WithdrawnSelectComponent implements OnInit {

  get schedules(): FormControl {
    return (<FormGroup>this.container.control).controls.withdrawnSchedules as FormControl;
  }

  get schedulesValue(): ProjectSchedule[] {
    return this.schedules.value;
  }

  constructor(
    private container: ControlContainer,
    private dialog: MatDialog,
    private labelPipe: LabelPipe
  ) { }

  ngOnInit(): void {
  }

  openScheduleDialog(i?: number): void {
    const data = i !== undefined
      ? this.schedulesValue[i]
      : undefined;

    this.dialog.open(ProjectScheduleDialogComponent, { data })
      .afterClosed().pipe(first())
      .subscribe((schedule: ProjectSchedule | null) => {
        if (!schedule) {
          return;
        }

        const { hour, minute } = TimeHelper.fromLocalToUtc(schedule.hour, schedule.minute);
        const formatted = { ...schedule, hour, minute };

        const value = [...this.schedulesValue];

        i !== undefined
          ? value.splice(i, 1, formatted)
          : value.push(formatted);

        this.schedules.setValue(value);
      });
  }

  convertTime(h: number, m: number): string {
    const { hour, minute } = TimeHelper.fromUtcToLocal(h, m);

    const prettyMinute = minute <= 9 && minute >= 0 ? `0${minute}` : minute;
    return `${hour}:${prettyMinute}`;
  }

  removeSchedule(i: number): void {
    this.schedules.setValue(this.schedulesValue.filter((_, idx) => i !== idx));
  }

  getDaysLabel(days: string[]): Observable<string> {
    if (days.length === 7) {
      return of('Каждый день');
    }
    const days$ = days.map(day => this.labelPipe.transform(day, 'days-of-week'));

    return of(...days$).pipe(
      mergeAll(),
      reduce((prev, curr) => prev + ', ' + curr)
    );
  }

}
