import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from 'shared/modules/table/table/table.component';
import { SelectionComponent } from 'shared/modules/table/selection/selection.component';
import { SortingPopoverComponent } from 'shared/modules/table/sorting-popover/sorting-popover.component';
import { SimpleSortHeaderComponent } from 'shared/modules/table/simple-sort-header/simple-sort-header.component';
import { MaterialModule } from 'shared/modules/material/material.module';
import { ColumnSelectComponent } from 'shared/modules/table/column-select/column-select.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SkeletonModule } from 'shared/modules/skeleton/skeleton.module';
import { TranslateModule } from '@ngx-translate/core';
import { ControlsModule } from 'shared/modules/controls/controls.module';
import {
  DisableTableDirective,
  PopoverSortDirective,
  TableColumnDirective,
  TableHeaderDirective,
  TableRowDirective
} from 'shared/modules/table/table-utils/table-directives';

@NgModule({
  declarations: [
    TableColumnDirective,
    TableComponent,
    TableHeaderDirective,
    TableRowDirective,
    DisableTableDirective,
    SelectionComponent,
    PopoverSortDirective,
    SortingPopoverComponent,
    SimpleSortHeaderComponent,
    ColumnSelectComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SkeletonModule,
    ReactiveFormsModule,
    TranslateModule,
    ControlsModule
  ],
  exports: [
    TableColumnDirective,
    TableComponent,
    TableHeaderDirective,
    TableRowDirective,
    DisableTableDirective,
    SelectionComponent,
    PopoverSortDirective,
    SortingPopoverComponent,
    SimpleSortHeaderComponent,
    ColumnSelectComponent
  ]
})
export class TableModule {
}
