import { Action, createReducer, on } from '@ngrx/store';
import * as FilterTemplatesActions from './filter-templates.actions';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { TemplateOutput } from 'core/http/project/rule-set/user-templates.service';
import {
  CompletedEntityState,
  initialCompletedEntityState
} from 'app/store/completed-entity-state/completed-entity-state';

export interface FilterTemplatesState extends CompletedEntityState<TemplateOutput> {}

const adapter: EntityAdapter<TemplateOutput> = createEntityAdapter<TemplateOutput>();

export const initialFilterTemplatesState = adapter.getInitialState(initialCompletedEntityState);

const reducer = createReducer(
  initialFilterTemplatesState,
  on(FilterTemplatesActions.saveFilterTemplates,
    (state, { filterTemplates }) => adapter.setAll(filterTemplates, state)),
  on(FilterTemplatesActions.markFilterTemplates, state => ({ ...state, loaded: true })),
  on(FilterTemplatesActions.addFilterTemplateInStore, (state, { template }) => adapter.addOne(template, state)),
  on(FilterTemplatesActions.removeFilterTemplateFromStore, (state, { id }) => adapter.removeOne(id, state)),
  on(FilterTemplatesActions.setLoading, (state, { value }) => ({ ...state, loading: value })),
  on(FilterTemplatesActions.setError, (state, { value }) => ({ ...state, error: value }))
);

export function filterTemplatesReducer(state: FilterTemplatesState | undefined, action: Action): FilterTemplatesState {
  return reducer(state, action);
}

export const { selectAll } = adapter.getSelectors();
