import { Injectable, NgModule } from '@angular/core';
import { Resolve, RouterModule, Routes } from '@angular/router';
import { AccountComponent } from 'account/componetns/account/account.component';
import { ProfileInfoComponent } from 'account/pages/profile-info/components/profile-info/profile-info.component';
import { AuthGuard } from 'core/guards/auth.guard';
import { UserOutput, UserService } from 'core/http/root';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { selectFirst } from 'core/utils/rx-common';
import { loggedUser } from 'app/store/login/logged-user/logged-user.selectors';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserResolver implements Resolve<UserOutput> {

  constructor(
    private store$: Store,
    private userService: UserService
  ) {}

  resolve(): Observable<UserOutput> {
    return this.store$.pipe(
      selectFirst(loggedUser),
      switchMap(token => {
        if (!token) {
          return of({} as UserOutput);
        }

        return this.userService.getUser(token.userInfo.id);
      })
    );
  }

}

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: AccountComponent,
    children: [
      {
        path: '',
        redirectTo: 'profile-info'
      },
      {
        path: 'profile-info',
        component: ProfileInfoComponent,
        resolve: {
          user: UserResolver
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {
}
