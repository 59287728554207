import { createAction, props } from '@ngrx/store';
import { SenderInput, SenderOutput } from 'core/http/project';
import { Update } from '@ngrx/entity';
import { Uuid } from 'shared/common/types';
import { createSetErrorAction, createSetLoadingAction } from 'app/store/completed-entity-state/completed-entity-state';

export const getSenders = createAction('[Project Request] Get Senders');
export const saveSenders = createAction('[Project Store] Save Senders', props<{ senders: SenderOutput[] }>());
export const markSenderLoaded = createAction('[Project Store] Mark Senders Loaded');

export const createSender = createAction('[Project Request] Create Sender', props<{ input: SenderInput }>());
export const addSenderInStore = createAction('[Project Store] Add Sender In Store', props<{ sender: SenderOutput }>());

export const updateSender = createAction('[Project Request] Update Sender', props<{ id: Uuid, input: SenderInput }>());
export const updateSenderInStore = createAction('[Project Store] Update Sender In Store',
  props<{ update: Update<SenderOutput> }>());

export const activateSender = createAction('[Project Request] Activate Sender', props<{ id: Uuid }>());
export const deactivateSender = createAction('[Project Request] Deactivate Sender', props<{ id: Uuid }>());

export const setLoading = createSetLoadingAction('[Project Store] Set Senders Loading');

export const setError = createSetErrorAction('[Project Store] Set Senders Error');
