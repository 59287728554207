import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from 'core/http/lg-logistic-rest-api/Auth/registration.service';
import { ObserverFactory } from 'shared/common/observers';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent {

  private readonly params = { ...this.route.snapshot.queryParams } as {
    recoveryId: string
  };

  private _recoveryForm = new FormGroup({
    newPassword: new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(6)
      ])
    )
  });

  private _visiblePassword = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private observer: ObserverFactory,
    private registrationService: RegistrationService
  ) {}

  get visiblePassword(): boolean {
    return this._visiblePassword;
  }

  get recoveryForm(): FormGroup {
    return this._recoveryForm;
  }

  onRecovery(): void {
    if (this.recoveryForm.invalid) {
      return;
    }

    this.registrationService
      .recoveryPassword(this.recoveryForm.value.newPassword, this.params.recoveryId)
      .subscribe(
        this.observer.withHook({
          successMsg: 'Пароль успешно сменен',
          onSuccess: () => {
            this.router.navigate(['/', 'auth', 'login']);
          }
        })
      );
  }

  toggleVisible(): void {
    this._visiblePassword = !this._visiblePassword;
  }

}
