import { ProjectInjectable } from 'core/http/project/project-services.module';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Pagination, Uuid } from 'shared/common/types';
import { NoticeRecipient } from 'core/http/project/notice/notice.interfaces';
import { Criteria } from 'shared/criteria/Criteria';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';

export interface NoticeLogOutput {
  id: Uuid;
  status: 'PENDING' | 'SENT' | 'ERROR';
  triggerId: Uuid;
  recipient: NoticeRecipient;
  text: string;
  createdAt: string;
  updatedAt: string | null;
  subject: string | null;
}

@ProjectInjectable()
export class NoticesLogService {

  constructor(private http: HttpClient) {}

  getNotices(c?: Criteria): Observable<Pagination<NoticeLogOutput>> {
    return this.http.get<Pagination<NoticeLogOutput>>(apiUrl('/project/notices', c));
  }

  getNotice(noticeId: Uuid): Observable<NoticeLogOutput> {
    return this.http.get<NoticeLogOutput>(apiUrl(`/project/notices/${noticeId}`));
  }

}
