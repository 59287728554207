import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'shared/shared.module';
import { UnloadingDataDialogComponent } from './unloading-data-dialog.component';

@NgModule({
  declarations: [UnloadingDataDialogComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    UnloadingDataDialogComponent
  ]
})
export class UnloadingDataDialogModule {
}
