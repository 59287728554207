import { createSelector } from '@ngrx/store';
import { ProjectState, selectProjectState } from 'app/store/project/project.state';

export const currenciesState = createSelector(
  selectProjectState,
  state => state.currencies
);

export const currencies = createSelector(
  selectProjectState,
  state => state.currencies.currencies
);

export const currency = createSelector(
  selectProjectState,
  (state: ProjectState, props: { key: string }) => {
    const curr = state.currencies.currencies.rates.find(c => c.key === props.key);

    return curr && +curr.value || 0;
  }
);
