import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import { CurrentProjectService } from 'core/http/project/current-project.service';
import { LoaderService } from 'core/services/loader.service';
import * as NotifierActions from 'app/store/common-effects/notifier.effects';
import * as CurrentProjectActions from './current-project.actions';

@Injectable()
export class CurrentProjectEffects {

  getCurrentProject$ = createEffect(() => this.actions$.pipe(
    ofType(CurrentProjectActions.getCurrentProject),
    switchMap(() => this.currentProjectService.getCurrentProject()),
    switchMap(project => [
      CurrentProjectActions.saveCurrentProject(project),
      CurrentProjectActions.markCurrentProjectLoaded()
    ])
  ));

  updateCurrentProject$ = createEffect(() => this.actions$.pipe(
    ofType(CurrentProjectActions.updateCurrentProject),
    tap(() => this.loader.begin()),
    switchMap(project => this.currentProjectService.updateCurrentProject(project)),
    tap(() => this.loader.end()),
    switchMap(project => [
      CurrentProjectActions.saveCurrentProject(project),
      NotifierActions.notify({ message: 'Настройки проекта обновлены' })
    ])
  ));

  constructor(
    private actions$: Actions,
    private currentProjectService: CurrentProjectService,
    private loader: LoaderService
  ) { }

}
