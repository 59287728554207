import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebhooksComponent } from 'root/webhooks/webhooks/webhooks.component';
import { TestWebhookComponent } from 'root/webhooks/test-webhook/test-webhook.component';
import { CreateWebhookComponent } from 'root/webhooks/create-webhook/create-webhook.component';
import { UpdateWebhookComponent } from 'root/webhooks/update-webhook/update-webhook.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'webhooks'
  },
  {
    path: 'webhooks',
    component: WebhooksComponent,
    data: { title: 'Админка - Вебхуки' }
  },
  {
    path: 'webhooks/testing/:webhookId',
    component: TestWebhookComponent,
    data: { title: 'Админка - Тестирование вебхука' }
  },
  {
    path: 'webhooks/create',
    component: CreateWebhookComponent,
    data: { title: 'Админка - Создание вебхука' }
  },
  {
    path: 'webhooks/update/:webhookId',
    component: UpdateWebhookComponent,
    data: { title: 'Админка - Редактирование вебхука' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WebhooksRoutingModule {

}
