import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from 'core/services/loader.service';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { LanguageService } from 'core/services/language.service';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { errorNotify } from 'app/store/common-effects/notifier.effects';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private subject$ = new Subject();

  constructor(
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private store$: Store,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.subscribeForLoaderChange();
    this.subscribeForTitleChange();

    this.languageService.initialize();
  }

  ngOnDestroy(): void {
    this.subject$.next();
    this.subject$.complete();
  }

  get loading$(): Observable<boolean> {
    return this.loaderService.isLoading$;
  }

  /**
   * Подписывается на события роутера для того чтобы показывать
   * loader при переходе с роута на роут.
   */
  private subscribeForLoaderChange(): void {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationStart),
        tap(() => this.loaderService.begin()),
        takeUntil(this.subject$)
      )
      .subscribe();

    this.router.events
      .pipe(
        filter(e => {
          return e instanceof NavigationEnd ||
            e instanceof NavigationError ||
            e instanceof NavigationCancel;
        }),
        tap(e => {
          if (e instanceof NavigationError) {
            this.store$.dispatch(errorNotify({ message: 'Ошибка навигации' }));
          }

          this.loaderService.end();
        }),
        takeUntil(this.subject$)
      )
      .subscribe();
  }

  /**
   * Подписывается на события роутера для того чтобы менять
   * текст в имени закладки.
   */
  private subscribeForTitleChange(): void {
    const getLastChild = (r: ActivatedRoute): ActivatedRoute => {
      return r.firstChild ? getLastChild(r.firstChild) : r;
    };

    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map(() => getLastChild(this.route)),
        filter(r => r.outlet === 'primary'),
        mergeMap(r => r.data),
        takeUntil(this.subject$)
      )
      .subscribe(data => this.title.setTitle(data.title));
  }

}
