import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { merge, pickBy } from 'lodash';

type ComponentConstructor = new (...args: any[]) => any;

type RoutableModalConfig = Route & {
  matDialogConfig?: MatDialogConfig
};

/**
 * Функция для создания роута на модальное окно.
 *
 * @param config
 *   Конфигурация роута.
 *   Так же можно задать MatDialogConfig ля конфигурирования отобржения диалога.
 */
export function routableModal(config: RoutableModalConfig): Route {
  return merge(config, {
    component: RoutableModalComponent,
    data: {
      __dialog: config.component,
      __config: config.matDialogConfig
    }
  });
}

@Component({ selector: 'app-routable-modal', template: '' })
export class RoutableModalComponent implements OnInit, OnDestroy {

  private modalSubject$ = new Subject();
  private _value: any;

  get value(): any {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
  }

  constructor(private dialog: MatDialog, private route: ActivatedRoute, private router: Router) { }

  ngOnDestroy(): void {
    this.modalSubject$.next();
    this.modalSubject$.complete();
  }

  ngOnInit(): void {
    this.route.data
      .pipe(
        map(d => '__dialog' in d ? d : throwError('Dialog not specified')),
        map(data => ({
            dialog: data.__dialog as ComponentConstructor,
            config: ({ ...data.__config } || {}) as MatDialogConfig,
            data: pickBy(data, (v, property) => property !== '__dialog' && property !== '__config')
          })
        ),
        switchMap(({ dialog, data, config }) => {
          const dialogRef = this.dialog.open(dialog, merge(config, {
            // Помимо зарезолвенных данных, передаем ActivatedRoute, т.к. из компонента он будет недоступен
            data: { ...data, route: this.route }, // Все зарезолвленые данные будут заинжекчены как MAT_DIALOG_DATA
            disableClose: true // Выключаем дефолтное поведение закрытия при клике по overlay
          }));

          // Когда будут кликать по overlay мы будем закрывать модалку.
          dialogRef.backdropClick()
            .pipe(takeUntil(this.modalSubject$))
            .subscribe(() => dialogRef.close());

          return dialogRef.afterClosed();
        }),
        tap(value => {
          this._value = value;

          // Тут достаем родительский роут.
          //
          // в pathFromRoot содержатся все роуты по которым мы переходили
          // чтобы дойти до RoutableModalComponent
          //
          // Например там будет:
          // 1. DebtsComponent     /project
          // 2. OrderPageComponent /project/:project-name
          // 3. OrderInfoComponent /project/:project-name/order-info/:id
          //
          // и тут [this.route.pathFromRoot.length - 2] получает /project/:project-name
          // чтобы вернуться на него после закрытия модалки.
          const parentRoute = this.route.pathFromRoot[this.route.pathFromRoot.length - 2];

          // Уходим на родительский роут.
          this.router.navigate(['./'], { relativeTo: parentRoute });
        }),
        // Отписка от модалки при разрушении компонента.
        takeUntil(this.modalSubject$)
      )
      .subscribe();
  }

}
