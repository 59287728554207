import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { StatusChainService } from 'core/http/project/status/status-chain.service';
import { LoaderService } from 'core/services/loader.service';
import { CompletedEntityStateService } from 'app/store/completed-entity-state/completed-entity-state.service';
import { createProperties } from 'app/store/completed-entity-state/completed-entity-state';
import * as StatusChainsActions from './status-chains.actions';
import * as NotifierActions from 'app/store/common-effects/notifier.effects';
import * as ProjectRouterActions from 'app/store/common-effects/router.effects';


@Injectable()
export class StatusChainsEffects {

  private readonly properties = createProperties(
    StatusChainsActions.setLoading,
    StatusChainsActions.markStatusChainsLoaded,
    StatusChainsActions.setError,
    () => this.statusChainService.getAllStatusChains(),
    'statusChains'
  );

  getStatusChains$ = createEffect(() => this.actions$.pipe(
    ofType(StatusChainsActions.getStatusChains),
    exhaustMap(() => this.completedEntityStateService.get(this.properties)),
    map(chains => StatusChainsActions.saveStatusChains({ chains }))
  ));

  createStatusChain$ = createEffect(() => this.actions$.pipe(
    ofType(StatusChainsActions.createStatusChain),
    tap(() => this.loader.begin()),
    exhaustMap(({ input }) => this.statusChainService.createStatusChain(input)),
    tap(() => this.loader.end()),
    switchMap(chain => [
      StatusChainsActions.addStatusChainInStore({ chain }),
      NotifierActions.notify({ message: 'Цепочка создана' }),
      ProjectRouterActions.navigateToProject({ path: ['setting', 'status-chains'] })
    ])
  ));

  updateStatusChain$ = createEffect(() => this.actions$.pipe(
    ofType(StatusChainsActions.updateStatusChain),
    tap(() => this.loader.begin()),
    exhaustMap(({ id, input }) => this.statusChainService.updateStatusChain(id, input)),
    tap(() => this.loader.end()),
    switchMap(chain => [
      StatusChainsActions.updateStatusChainInStore({ update: { id: chain.id, changes: chain } }),
      NotifierActions.notify({ message: 'Цепочка обновлена' }),
      ProjectRouterActions.navigateToProject({ path: ['setting', 'status-chains'] })
    ])
  ));

  removeStatusChain$ = createEffect(() => this.actions$.pipe(
    ofType(StatusChainsActions.removeStatusChain),
    tap(() => this.loader.begin()),
    exhaustMap(({ id }) => this.statusChainService.removeStatusChain(id).pipe(mapTo(id))),
    tap(() => this.loader.end()),
    switchMap(id => [
      StatusChainsActions.removeStatusChainFromStore({ id }),
      NotifierActions.notify({ message: 'Цепочка удалена' })
    ])
  ));

  constructor(
    private actions$: Actions,
    private statusChainService: StatusChainService,
    private loader: LoaderService,
    private completedEntityStateService: CompletedEntityStateService
  ) { }

}
