import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { DevInjectable } from 'core/http/dev/dev-services.module';
import { CourierOutput } from 'core/http/project';
import { ProjectOutput } from 'core/http/root';

export interface DevDocumentInput {
  name: string;
  renderClass: string;
  courierId: string | null;
  enabledProjects: string[];
  type: 'POST_BLANK' | 'SUMMARY' | 'REVISION';
}

export interface DevDocumentOutput {
  id: string;
  name: string;
  renderClass: string;
  enabledProjects: ProjectOutput[];
  courier: CourierOutput | null;
  type: 'POST_BLANK' | 'SUMMARY' | 'REVISION';
}

@DevInjectable()
export class DocumentService {

  constructor(private http: HttpClient) {}

  getAllDocuments(): Observable<DevDocumentOutput[]> {
    return this.http.get<DevDocumentOutput[]>(apiUrl('/dev/documents'));
  }

  createDocument(input: DevDocumentInput): Observable<DevDocumentOutput> {
    return this.http.post<DevDocumentOutput>(apiUrl(`/dev/document`), input);
  }

  updateDocument(documentId: string, input: DevDocumentInput): Observable<DevDocumentOutput> {
    return this.http.put<DevDocumentOutput>(apiUrl(`/dev/document/${documentId}`), input);
  }

  getRenderClasses(): Observable<string[]> {
    return this.http.get<string[]>(apiUrl('/dev/document-renders'));
  }

}
