import { createSelector } from '@ngrx/store';
import { ProjectState, selectProjectState } from 'app/store/project/project.state';
import * as fromCourierChains from './courier-chains.reducer';
import { Uuid } from 'shared/common/types';

export const courierChainsState = createSelector(
  selectProjectState,
  state => state.courierChains
);

export const courierChains = createSelector(
  selectProjectState,
  state => fromCourierChains.selectAll(state.courierChains)
);

export const courierChainEntities = createSelector(
  selectProjectState,
  state => fromCourierChains.selectEntities(state.courierChains)
);

export const oneCourierChain = createSelector(
  selectProjectState,
  (state: ProjectState, props: { id: Uuid }) => fromCourierChains.selectEntities(state.courierChains)[props.id]
);

export const courierChainsLoading = createSelector(
  selectProjectState,
  state => state.courierChains.loading
);
