import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, switchMap, tap } from 'rxjs/operators';
import { TokenService } from 'core/http/lg-logistic-rest-api/Auth/token.service';
import { of } from 'rxjs';
import * as NotifyActions from 'app/store/common-effects/notifier.effects';
import * as LoginActions from 'app/store/login/logged-user/logged-user.actions';
import * as AssignedProjectsActions from 'app/store/login/assigned-projects/assigned-projects.actions';
import * as CurrentProjectActions from 'app/store/project/current-project/current-project.actions';
import * as RouterActions from '../../common-effects/router.effects';

@Injectable()
export class LoggedUserEffects {

  login$ = createEffect(() => this.actions$.pipe(
    ofType(LoginActions.logIn),
    exhaustMap(({ username, password }) => this.tokenService.obtain(username, password)
      .pipe(
        tap(token => localStorage.setItem('user', JSON.stringify(token))),
        switchMap(token => [
          LoginActions.logInSuccess({ token }),
          RouterActions.navigate({ path: ['/project'] })
        ]),
        catchError(() => of(NotifyActions.errorNotify({ message: 'Ошибка авторизации' })))
      ))
  ));

  refreshToken$ = createEffect(() => this.actions$.pipe(
    ofType(LoginActions.refreshToken),
    switchMap(({ token }) => this.tokenService.refresh(token)),
    switchMap(newToken => [
      LoginActions.markRefreshing({ value: false }),
      LoginActions.logInSuccess({ token: newToken })
    ]),
    catchError(() => of(LoginActions.logout()))
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(LoginActions.logout),
    tap(() => localStorage.clear()),
    switchMap(() => [
      AssignedProjectsActions.resetAssignedProjects(),
      CurrentProjectActions.resetCurrentProject(),
      LoginActions.resetLoggedUser(),
      RouterActions.navigate({ path: ['auth', 'login'] })
    ])
  ));

  constructor(
    private actions$: Actions,
    private tokenService: TokenService
  ) { }

}
