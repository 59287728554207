import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectFirst } from 'core/utils/rx-common';
import { currentProject } from 'app/store/project/current-project/current-project.selectors';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectInterceptor implements HttpInterceptor {

  constructor(private store$: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store$
      .pipe(
        selectFirst(currentProject),
        map(p => {
          if (!p || Object.keys(p).length === 0) {
            throw new Error('Current project is missing');
          }

          return request.clone({
            setHeaders: {
              ['Project-Id']: p.id
            }
          });
        }),
        switchMap(r => {
          return next.handle(r);
        })
      );
  }
}
