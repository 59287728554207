import { Injectable } from '@angular/core';
import { QueryBuilderModule } from 'shared/modules/query-builder/query-builder.module';
import { MatDialog } from '@angular/material/dialog';
import {
  QueryBuilderDialogComponent,
  QueryBuilderDialogData
} from 'shared/modules/query-builder/query-builder-dialog/query-builder-dialog.component';
import { RuleSet } from 'angular2-query-builder';
import { FieldMap } from 'angular2-query-builder/dist/components';
import { FilterType } from 'core/http/project/rule-set/user-templates.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: QueryBuilderModule })
export class QueryBuilderDialogService {

  constructor(private matDialog: MatDialog) { }

  open(type: FilterType, fieldMap: FieldMap, ruleSet: RuleSet): Observable<RuleSet> {

    const data: QueryBuilderDialogData = { type, fieldMap, ruleSet };
    const dialogRef = this.matDialog.open(QueryBuilderDialogComponent, { data, autoFocus: false, width: '1000px' });

    return dialogRef.afterClosed();
  }

}
