import { createFeatureSelector } from '@ngrx/store';
import { DevCouriersState, initialDevCouriersState } from 'app/store/dev/couriers/couriers.reducer';
import { DevDocumentsState, initialDevDocumentsState } from 'app/store/dev/documents/documents.reducer';

const devFeatureKey = 'dev';

export const selectDevState = createFeatureSelector<DevState>(devFeatureKey);

export interface DevState {
  couriers: DevCouriersState;
  documents: DevDocumentsState;
}

export const initialDevState: DevState = {
  couriers: initialDevCouriersState,
  documents: initialDevDocumentsState,
};
