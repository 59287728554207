import { ActionReducerMap, combineReducers } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { rootReducer } from 'app/store/root/root.reducer';
import { projectReducers } from './project/project.reducer';
import { loginReducer } from 'app/store/login/login.reducer';
import { devReducers } from 'app/store/dev/dev.reducer';

export const appReducers: ActionReducerMap<AppState, any> = {
  login: combineReducers(loginReducer),
  project: combineReducers(projectReducers),
  root: combineReducers(rootReducer),
  dev: combineReducers(devReducers)
};
