import { HttpClient } from '@angular/common/http';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Observable } from 'rxjs';
import { Criteria } from 'shared/criteria/Criteria';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { CourierOutput } from 'core/http/project';
import { DynamicField } from 'shared/modules/dynamic-form/field-types';

export interface DocumentOutput {
  id: string;
  name: string;
  courier: CourierOutput;
  type: 'POST_BLANK' | 'REVISION' | 'SUMMARY';
}

export interface DocumentSettingOutput {
  id: string;
  name: string;
  renderSettings: DynamicField[];
  isSecondGoodsNameUsed: boolean;
  isSenderGroupingUsed: boolean;
}

export interface DocumentShortOutput {
  id: string;
  name: string;
}

export interface UpdateDocumentSettingInput {
  renderSettings: any;
  secondGoodsNameUsage: boolean;
}


@ProjectInjectable()
export class DocumentService {

  constructor(private http: HttpClient) {}

  getAllDocuments(c?: Criteria): Observable<DocumentOutput[]> {
    return this.http.get<DocumentOutput[]>(apiUrl('/project/documents', c));
  }

  getDocumentSetting(documentId: string): Observable<DocumentSettingOutput> {
    return this.http.get<DocumentSettingOutput>(apiUrl(`/project/document/${documentId}/setting`));
  }

  updateDocumentSetting(documentId: string, updateSetting: UpdateDocumentSettingInput): Observable<DocumentSettingOutput> {
    return this.http.put<DocumentSettingOutput>(apiUrl(`/project/document/${documentId}/setting`), updateSetting);
  }

}
