import { ProjectInjectable } from 'core/http/project/project-services.module';
import { Observable } from 'rxjs';
import { BackgroundProgress } from 'core/http/project/background/background-task.service';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Pagination, Uuid } from 'shared/common/types';
import { HttpClient } from '@angular/common/http';
import { Criteria } from 'shared/criteria/Criteria';
import { OrderOutput } from 'core/http/project';
import { LoggedUserOutput } from 'core/http/lg-logistic-rest-api/Auth/token.service';

export interface StatusChangeGroupInput {
  toStatusId: Uuid;
  orderFilter: string;
}

export interface StatusChangeGroupOutput {
  id: Uuid;
  count: number;
  fromStatusId: Uuid;
  toStatusId: Uuid;
}

export interface StatusChangeOutput {
  id: Uuid;
  whoChange: LoggedUserOutput | null;
  groups: StatusChangeGroupOutput[];
  createdAt: string;
}

@ProjectInjectable()
export class StatusesHistoryService {

  constructor(private http: HttpClient) {}

  beginStatusChange(groups: StatusChangeGroupInput[]): Observable<BackgroundProgress> {
    return this.http.post<BackgroundProgress>(apiUrl('/project/status-changes'), { groups });
  }

  getStatusChanges(c: Criteria): Observable<Pagination<StatusChangeOutput>> {
    return this.http.get<Pagination<StatusChangeOutput>>(apiUrl('/project/status-changes', c));
  }

  getStatusChange(statusChangeId: Uuid): Observable<StatusChangeOutput> {
    return this.http.get<StatusChangeOutput>(apiUrl(`/project/status-changes/${statusChangeId}`));
  }

  getStatusChangeGroupOrders(changeGroupId: Uuid, c: Criteria): Observable<Pagination<OrderOutput>> {
    const url = apiUrl(`/project/status-change-groups/${changeGroupId}/orders`, c);
    return this.http.get<Pagination<OrderOutput>>(url);
  }

  getStatusChangeGroup(changeGroupId: Uuid): Observable<StatusChangeGroupOutput> {
    return this.http.get<StatusChangeGroupOutput>(apiUrl(`/project/status-change-groups/${changeGroupId}`));
  }

}
