import { Component, Input } from '@angular/core';
import { Pair } from 'core/http/project';

@Component({
  selector: 'app-key-value-view',
  templateUrl: './key-value-view.component.html',
  styleUrls: ['./key-value-view.component.scss']
})
export class KeyValueViewComponent {

  private _pairs = Array<Pair>();

  get pairs(): Pair[] {
    return this._pairs;
  }

  @Input()
  set pairs(value: Pair[]) {
    this._pairs = value;
  }

  constructor() { }

  isArray(value: any | any[]): boolean {
    return Array.isArray(value);
  }

}
