import { Component, OnInit } from '@angular/core';
import {
  UpdateExtensionProjectsDialogComponent,
  UpdateExtensionProjectsDialogData,
  UpdateExtensionProjectsDialogResult
} from 'root/extensions/update-extension-projects-dialog/update-extension-projects-dialog.component';
import { Criteria } from 'shared/criteria/Criteria';
import { DocumentOutput } from 'core/http/root';
import { ProjectOutput } from 'core/http/dev';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { arrayFilter, selectFirst } from 'core/utils/rx-common';
import { rootProjects } from 'app/store/root/projects/projects.selectors';
import { rootDocuments } from 'app/store/root/documents/documents.selectors';
import { Subject } from 'rxjs';
import { EagerSource } from 'shared/modules/table/table-utils/table-interfaces';
import { updateDocumentExtensionProjects } from 'app/store/root/documents/documents.actions';

@Component({
  selector: 'app-document-modules',
  templateUrl: './document-extensions.component.html',
  styleUrls: ['./document-extensions.component.scss']
})
export class DocumentExtensionsComponent implements OnInit {

  readonly reloader = new Subject();
  readonly columns: string[] = [
    'document',
    'courier',
    'projects'
  ];

  private projects = Array<ProjectOutput>();

  constructor(
    private dialog: MatDialog,
    private store$: Store
  ) { }

  ngOnInit(): void {
    this.store$.pipe(selectFirst(rootProjects))
      .subscribe(data => this.projects = data);
  }

  dataSource: EagerSource<DocumentOutput> = (c: Criteria) => {
    return this.store$.select(rootDocuments).pipe(
      arrayFilter(document => document.name.toLowerCase().includes(c.filter!.toLowerCase()))
    );
  };

  openDocumentEditor(document: DocumentOutput): void {
    const data: UpdateExtensionProjectsDialogData = {
      allProjects: this.projects,
      enabledProjects: document.projects
    };

    this.dialog.open(UpdateExtensionProjectsDialogComponent, { data }).afterClosed()
      .subscribe((r: UpdateExtensionProjectsDialogResult | undefined) => {
        if (!r) {
          return;
        }

        this.store$.dispatch(updateDocumentExtensionProjects({
          id: document.id,
          projects: r.enabledProjects.map(c => c.id)
        }));

        this.reloader.next();
      });
  }
}
