import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({ selector: '[appBadge]' })
export class BadgeDirective implements OnInit {
  private _value = 0;
  private _size: '12px' | '14px' | '16px' = '12px';

  get appBadge(): number {
    return this._value;
  }

  @Input()
  set appBadge(value: number) {
    this._value = value;
  }

  get size(): '12px' | '14px' | '16px' {
    return this._size;
  }

  @Input('appBadgeSize')
  set size(value: '12px' | '14px' | '16px') {
    this._size = value;
  }

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    const div = document.createElement('div');
    div.style.alignContent = 'center';
    div.style.background = 'var(--primary-color)';
    div.style.borderRadius = '50px';
    div.style.color = 'white';
    div.style.display = this.appBadge ? 'grid' : 'none';
    div.style.fontSize = this.size;
    div.style.height = '11px';
    div.style.minWidth = '11px';
    div.style.padding = '5px';
    div.style.position = 'absolute';
    div.style.right = '0';
    div.style.top = '0';
    div.style.transform = 'translate(40%, -50%)';
    div.style.textAlign = 'center';

    div.innerText = this.appBadge.toString();
    this.el.nativeElement.appendChild(div);
  }
}
