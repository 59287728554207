import { Injectable } from '@angular/core';
import { exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GoodService } from 'core/http/project';
import { LoaderService } from 'core/services/loader.service';
import { CompletedEntityStateService } from 'app/store/completed-entity-state/completed-entity-state.service';
import { createProperties } from 'app/store/completed-entity-state/completed-entity-state';
import * as GoodsActions from './goods.actions';
import * as ProjectRoutingActions from '../../common-effects/router.effects';
import * as NotifierActions from 'app/store/common-effects/notifier.effects';

@Injectable()
export class GoodsEffects {

  private readonly properties = createProperties(
    GoodsActions.setLoading,
    GoodsActions.markGoodsLoaded,
    GoodsActions.setError,
    () => this.goodService.getAllGoods(),
    'goods'
  );

  getGoods$ = createEffect(() => this.actions$.pipe(
    ofType(GoodsActions.getGoods),
    exhaustMap(() => this.completedEntityStateService.get(this.properties)),
    map(goods => GoodsActions.saveGoods({ goods: goods }))
  ));

  updateGood$ = createEffect(() => this.actions$.pipe(
    ofType(GoodsActions.updateGood),
    tap(() => this.loader.begin()),
    exhaustMap(({ id, input }) => this.goodService.updateGood(id, input)),
    tap(() => this.loader.end()),
    switchMap(good => [
      GoodsActions.updateGoodInStore({ update: { id: good.id, changes: good } }),
      NotifierActions.notify({ message: 'Товар обновлен' }),
      ProjectRoutingActions.navigateToProject({ path: ['good'] })
    ])
  ));

  createGood$ = createEffect(() => this.actions$.pipe(
    ofType(GoodsActions.createGood),
    tap(() => this.loader.begin()),
    exhaustMap(({ input }) => this.goodService.createGood(input)),
    tap(() => this.loader.end()),
    switchMap(good => [
      GoodsActions.addGoodInStore(good),
      NotifierActions.notify({ message: 'Товар создан' }),
      ProjectRoutingActions.navigateToProject({ path: ['good'] })
    ])
  ));

  activateGood$ = createEffect(() => this.actions$.pipe(
    ofType(GoodsActions.activateGood),
    tap(() => this.loader.begin()),
    exhaustMap(good => this.goodService.activateGood(good.id)),
    tap(() => this.loader.end()),
    switchMap(good => [
      GoodsActions.updateGoodInStore({ update: { id: good.id, changes: good } }),
      NotifierActions.notify({ message: 'Товар активирован' })
    ])
  ));

  deactivateGood$ = createEffect(() => this.actions$.pipe(
    ofType(GoodsActions.deactivateGood),
    tap(() => this.loader.begin()),
    exhaustMap(good => this.goodService.deactivateGood(good.id)),
    tap(() => this.loader.end()),
    switchMap(good => [
      GoodsActions.updateGoodInStore({ update: { id: good.id, changes: good } }),
      NotifierActions.notify({ message: 'Товар деактивирован' })
    ])
  ));

  constructor(
    private actions$: Actions,
    private goodService: GoodService,
    private loader: LoaderService,
    private completedEntityStateService: CompletedEntityStateService
  ) { }

}
