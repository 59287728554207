import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { LoggedUserState } from 'app/store/login/logged-user/logged-user.state';
import { logIn } from 'app/store/login/logged-user/logged-user.actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

  constructor(private store$: Store<LoggedUserState>) {
  }

  login(): void {
    if (!this.loginForm.valid) {
      return;
    }

    this.store$.dispatch(logIn(this.loginForm.value));
  }

}
