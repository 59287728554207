import { createAction, props } from '@ngrx/store';
import { DevDocumentInput, DevDocumentOutput } from 'core/http/dev';
import { Uuid } from 'shared/common/types';
import { Update } from '@ngrx/entity';

export const getDevDocuments = createAction('[Dev Documents Request] Get Dev Documents');
export const saveDevDocuments = createAction('[Dev Documents Store] Save Dev Documents In Store', props<{ documents: DevDocumentOutput[] }>());
export const markDevDocumentsLoaded = createAction('[Dev Documents Store] Mark Dev Documents Loaded');

export const createDevDocument = createAction('[Dev Documents Request] Create Document', props<{ input: DevDocumentInput }>());
export const addDevDocumentInStore = createAction('[Dev Documents Store] Add Document In Store', props<{ document: DevDocumentOutput }>());

export const updateDevDocument = createAction('[Dev Documents Request] Update Document', props<{ id: Uuid, input: DevDocumentInput }>());
export const updateDevDocumentInStore = createAction('[Dev Documents Store] Update Document In Store', props<{ update: Update<DevDocumentOutput> }>());

export const getRenderClasses = createAction('[Dev Documents Request] Get Render Classes');
export const saveRenderClasses = createAction('[Dev Documents Store] Save Render Classes In Store', props<{ renderClasses: string[] }>());
