import { Observable } from 'rxjs';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { HttpClient } from '@angular/common/http';
import { DynamicField } from 'shared/modules/dynamic-form/field-types';

export interface MutableAdditionsInput {
  toAppend: DynamicField[];
  toRemove: string[];
}

@ProjectInjectable()
export class AdditionsService {

  constructor(private http: HttpClient) { }

  getGoodFieldsForCourier(courierId: string): Observable<DynamicField[]> {
    return this.http.get<any[]>(apiUrl(`/project/addition-fields/good/courier/${courierId}`));
  }

  getDefaultAdditions(type: string): Observable<DynamicField[]> {
    return this.http.get<Array<any>>(apiUrl(`/project/current/additions/${type}`));
  }

  changeDefaultAdditions(input: MutableAdditionsInput, type: 'good' | 'sender' | 'status' | 'storehouse'): Observable<DynamicField[]> {
    return this.http
      .put<DynamicField[]>(apiUrl(`/project/current/additions/${type}`), {
        toRemove: input.toRemove,
        toAppend: input.toAppend
      });
  }

  getGoodFieldsForDocument(documentId: string): Observable<DynamicField[]> {
    return this.http.get<DynamicField[]>(apiUrl(`/project/addition-fields/good/document/${documentId}`));
  }

  getSenderFieldsForCourier(courierId: string): Observable<DynamicField[]> {
    return this.http.get<DynamicField[]>(apiUrl(`/project/addition-fields/sender/courier/${courierId}`));
  }

  getSenderFieldsForDocuments(documentId: string): Observable<DynamicField[]> {
    return this.http.get<DynamicField[]>(apiUrl(`/project/addition-fields/sender/document/${documentId}`));
  }

}
