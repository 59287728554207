import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CourierOutput, GoodOutput, SenderOutput, StatusOutput, StorehouseOutput } from 'core/http/project';
import { FilterChangedEvent, SearchBarFields } from 'shared/modules/controls/search-bar/search-bar';
import { FormGroup } from '@angular/forms';
import {
  createOrderFields,
  createOrderFilterForm
} from 'order/pages/order-page/components/order-filter/order-filter-form';
import { SearchBarComponent } from 'shared/modules/controls/search-bar/search-bar.component';
import { toFilter } from 'shared/modules/query-builder/query-builder-utils';
import { RuleSet } from 'angular2-query-builder';
import { FieldMap } from 'angular2-query-builder/dist/components';
import { AdditionalField } from 'app/store/project/addition-fields/addition-fields.reducer';
import { Store } from '@ngrx/store';
import { updatePageCriteria } from 'app/store/project/page-criteria/page-criteria.actions';
import { Observable, Subscription, zip } from 'rxjs';
import { FilterSource } from 'shared/modules/table/table-utils/table-directives';

@Component({
  selector: 'app-order-filter',
  templateUrl: './order-filter.component.html',
  styleUrls: ['./order-filter.component.scss']
})
export class OrderFilterComponent extends FilterSource implements OnInit, OnDestroy {

  @ViewChild(SearchBarComponent)
  private _searchBar?: SearchBarComponent;

  @Output()
  userFilter = new EventEmitter<string>();

  private _subscription = new Subscription();

  private _key = '';
  private _statuses$ = new Observable<Array<StatusOutput>>();
  private _couriers$ = new Observable<Array<CourierOutput>>();
  private _senders$ = new Observable<Array<SenderOutput>>();
  private _storehouses$ = new Observable<Array<StorehouseOutput>>();
  private _goods$ = new Observable<Array<GoodOutput>>();
  private _additional = Array<AdditionalField>();
  private _fields: SearchBarFields = {};
  private _form?: FormGroup;
  private _fieldMap: FieldMap = {};

  get key(): string {
    return this._key;
  }

  @Input()
  set key(value: string) {
    this._key = value;
  }

  get searchBar(): SearchBarComponent {
    if (!this._searchBar) {
      throw new Error('SearchBar is not initialized yet');
    }

    return this._searchBar;
  }

  get form(): FormGroup {
    if (!this._form) {
      throw new Error('FormGroup is not initialized yet');
    }

    return this._form;
  }

  get fields(): SearchBarFields {
    return this._fields;
  }

  @Input()
  set statuses$(s: Observable<StatusOutput[]>) {
    this._statuses$ = s;
  }

  get statuses$(): Observable<StatusOutput[]> {
    return this._statuses$;
  }

  @Input()
  set couriers$(c: Observable<CourierOutput[]>) {
    this._couriers$ = c;
  }

  get couriers$(): Observable<CourierOutput[]> {
    return this._couriers$;
  }

  @Input()
  set senders$(s: Observable<SenderOutput[]>) {
    this._senders$ = s;
  }

  get senders$(): Observable<SenderOutput[]> {
    return this._senders$;
  }

  @Input()
  set storehouses$(s: Observable<StorehouseOutput[]>) {
    this._storehouses$ = s;
  }

  get storehouses$(): Observable<StorehouseOutput[]> {
    return this._storehouses$;
  }

  @Input()
  set goods$(g: Observable<GoodOutput[]>) {
    this._goods$ = g;
  }

  get goods$(): Observable<GoodOutput[]> {
    return this._goods$;
  }

  @Input()
  set additional(a: AdditionalField[]) {
    this._additional = a;
  }

  get additional(): AdditionalField[] {
    return this._additional;
  }

  get fieldMap(): FieldMap {
    return this._fieldMap;
  }

  @Input()
  set fieldMap(value: FieldMap) {
    this._fieldMap = value;
  }

  constructor(private store$: Store) {
    super();
  }

  ngOnInit(): void {
    this._subscription = zip(
      this.senders$,
      this.couriers$,
      this.statuses$,
      this.goods$,
      this.storehouses$
    )
      .subscribe(([senders, couriers, statuses, goods, storehouses]) => {
        this._fields = createOrderFields(
          this.additional,
          senders,
          couriers,
          statuses,
          goods,
          storehouses
        );

        const defaultStatus = statuses[0];

        if (this._form) {
          this.form.reset();
          this.form.patchValue({ statuses: [defaultStatus.id] });
          return;
        }

        this._form = createOrderFilterForm(this.additional, defaultStatus);
      });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  emitFilter(event: FilterChangedEvent): void {
    this.filterChanged.emit(event.filter);

    if (event.type === 'INIT') {
      return;
    }

    this.store$.dispatch(updatePageCriteria({
      update: { id: this.key, changes: { formValue: this.form.value, pageIndex: 0 } }
    }));
  }

  reset(...fields: string[]): void {
    this.searchBar.resetField(...fields);
  }

  reload(): void {
    this.searchBar.onFilterChanged();
  }

  changeUserFilter(filter: RuleSet): void {
    this.userFilter.emit(toFilter(filter));
  }

  resetUserFilter(): void {
    this.userFilter.emit('');
  }

}
