import { Directive, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLinkWithHref } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { Subscription } from 'rxjs';
import { filterNil } from 'core/utils/rx-common';
import { Store } from '@ngrx/store';
import { currentProject } from 'app/store/project/current-project/current-project.selectors';
import { ProjectOutput } from 'core/http/root';

@Directive({ selector: 'a [project-route]' })
export class ProjectRouteDirective extends RouterLinkWithHref implements OnInit, OnDestroy, OnChanges {

  private _routeParts = Array<string>();
  private _subscription$?: Subscription;
  private _currentProject = {} as ProjectOutput;
  private _router: Router;
  private _locationStrategy: LocationStrategy;

  @Input('project-route')
  set routeParts(routeParts: string[]) {
    this._routeParts = routeParts;
  }

  get routeParts(): string[] {
    return this._routeParts;
  }

  set subscription$(value: Subscription | undefined) {
    this._subscription$ = value;
  }

  get subscription$(): Subscription | undefined {
    return this._subscription$;
  }

  constructor(
    route: ActivatedRoute,
    router: Router,
    locationStrategy: LocationStrategy,
    private store$: Store
  ) {
    super(router, route, locationStrategy);

    this._router = router;
    this._locationStrategy = locationStrategy;
  }

  ngOnInit(): void {
    this.subscription$ = this.store$.select(currentProject)
      .pipe(filterNil())
      .subscribe(project => {
        this._currentProject = project;

        this.routerLink = `/project/${project.route}/${this.routeParts.join('/')}`;
        this.href = this._locationStrategy.prepareExternalUrl(this._router.serializeUrl(this.urlTree));
      });
  }

  ngOnDestroy(): any {
    this.subscription$ && this.subscription$.unsubscribe();
  }

  ngOnChanges(): any {
    this.routerLink = `/project/${this._currentProject.route}/${this.routeParts.join('/')}`;
    this.href = this._locationStrategy.prepareExternalUrl(this._router.serializeUrl(this.urlTree));
  }

}
