import { Component, Input } from '@angular/core';
import { OrderOutput } from 'core/http/project';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { currentProject } from 'app/store/project/current-project/current-project.selectors';
import { statusEntities } from 'src/app/store/project/statuses/statuses.selectors';
import { storehouseEntities } from 'src/app/store/project/storehouses/storehouses.selectors';
import { goodEntities } from 'src/app/store/project/goods/goods.selectors';
import { courierEntities } from 'src/app/store/project/couriers/couriers.selectors';
import { senderEntities } from 'app/store/project/senders/senders.selectors';

@Component({
  selector: 'app-order-description',
  templateUrl: './order-description.component.html',
  styleUrls: ['./order-description.component.scss']
})
export class OrderDescriptionComponent {

  @Input() order!: OrderOutput;

  readonly statuses$ = this.store$.select(statusEntities);
  readonly storehouses$ = this.store$.select(storehouseEntities);
  readonly goods$ = this.store$.select(goodEntities);
  readonly couriers$ = this.store$.select(courierEntities);
  readonly senders$ = this.store$.select(senderEntities);
  readonly currency$ = this.store$.select(currentProject)
    .pipe(
      map(item => item.currencyCode)
    );

  get lastUpdated(): string {
    return this.order.updatedAt ? moment(this.order.updatedAt).fromNow() : 'never';
  }

  constructor(private store$: Store) { }

}
