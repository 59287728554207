import { createSelector } from '@ngrx/store';
import { ProjectState, selectProjectState } from 'app/store/project/project.state';

export const orderFieldMapState = createSelector(
  selectProjectState,
  state => state.orderFieldMap
);

export const orderFieldMap = createSelector(
  selectProjectState,
  (state: ProjectState) => state.orderFieldMap.fieldMap
);
