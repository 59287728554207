import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PrettyDebt, PrettyMonthCut } from 'core/http/lg-logistic-rest-api/Auth/project.service';
import { ProjectOutput } from 'core/http/dev';
import { transition, trigger, useAnimation } from '@angular/animations';
import { closeAnimation, expandAnimation } from 'shared/common/animations';

@Component({
  selector: 'app-debts-row',
  templateUrl: './debts-row.component.html',
  styleUrls: ['./debts-row.component.scss'],
  animations: [
    trigger('expandedPanel', [
      transition('void => *', [
        useAnimation(expandAnimation)
      ]),
      transition('* => void', [
        useAnimation(closeAnimation)
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DebtsRowComponent {

  private _debt = {} as PrettyDebt;

  private _project = {} as ProjectOutput;
  private _oneProject = false;
  private _visible = false;

  get oneProject(): boolean {
    return this._oneProject;
  }

  @Input()
  set oneProject(value: boolean) {
    this._oneProject = value;
  }

  get project(): ProjectOutput {
    return this._project;
  }

  @Input()
  set project(value: ProjectOutput) {
    this._project = value;
  }

  get debt(): PrettyDebt {
    return this._debt;
  }

  @Input()
  set debt(value: PrettyDebt) {
    this._debt = value;
  }

  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    this._visible = value;
  }

  trackByIndex(i: number): number {
    return i;
  }

  trackById(i: number, debt: PrettyMonthCut): string {
    return debt.courier.id;
  }

}
