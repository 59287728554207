import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { OrderFieldMapService } from 'core/http/project/rule-set/order-field-map.service';
import * as FieldMapActions from './field-map.actions';

@Injectable()
export class FieldMapEffects {

  getOrderFieldMap$ = createEffect(() => this.actions$.pipe(
    ofType(FieldMapActions.getOrderFieldMap),
    switchMap(() => this.fieldMapService.getOrderFieldMap()),
    switchMap(orderFieldMap => [
      FieldMapActions.saveOrderFieldMap({ orderFieldMap }),
      FieldMapActions.markOrderFieldMapLoaded()
    ])
  ));

  constructor(
    private actions$: Actions,
    private fieldMapService: OrderFieldMapService
  ) { }

}
