import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable, of, throwError } from 'rxjs';
import { catchError, exhaustMap, tap } from 'rxjs/operators';
import { SetStateProperties } from 'app/store/completed-entity-state/completed-entity-state';
import { cacheable } from 'core/utils/rx-common';

@Injectable({ providedIn: 'root' })
export class CompletedEntityStateService {

  constructor(
    private store$: Store,
    private dbService: NgxIndexedDBService
  ) {}

  get<T>(properties: SetStateProperties<T>): Observable<T> {
    return properties.cacheKey ? this.getFromCache(properties) : this.getFromRequest(properties);
  }

  private getFromRequest<T>(properties: SetStateProperties<T>): Observable<T> {
    return of(1).pipe(
      tap(() => this.store$.dispatch(properties.setLoading({ value: true }))),
      exhaustMap(() => properties.request()),
      tap(() => this.store$.dispatch(properties.setLoading({ value: false }))),
      catchError(err => {
        this.store$.dispatch(properties.setError(err));
        return throwError(err);
      }),
      tap(() => this.store$.dispatch(properties.setLoaded()))
    );
  }

  private getFromCache<T>(properties: SetStateProperties<T>): Observable<T> {
    return of(1).pipe(
      tap(() => this.store$.dispatch(properties.setLoading({ value: true }))),
      cacheable(properties.cacheKey!, this.store$, this.dbService, () => properties.request()),
      tap(() => this.store$.dispatch(properties.setLoading({ value: false }))),
      catchError(err => {
        this.store$.dispatch(properties.setError(err));
        return throwError(err);
      }),
      tap(() => this.store$.dispatch(properties.setLoaded()))
    );
  }

}
