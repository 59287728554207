import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TokenService } from 'core/http/lg-logistic-rest-api/Auth/token.service';
import { Store } from '@ngrx/store';
import { selectFirst } from 'core/utils/rx-common';
import { loggedUser } from 'app/store/login/logged-user/logged-user.selectors';
import { switchMap } from 'rxjs/operators';
import { logout } from 'app/store/login/logged-user/logged-user.actions';
import { PrettyDebt } from 'core/http/lg-logistic-rest-api/Auth/project.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent {

  private _debts = this.route.snapshot.data.debts;

  get debts(): PrettyDebt[] {
    return this._debts;
  }

  constructor(
    private route: ActivatedRoute,
    private tokenService: TokenService,
    private store$: Store
  ) { }

  logout(): void {
    this.store$.pipe(
      selectFirst(loggedUser),
      switchMap(user => {
        if (!(user && user.accessToken)) {
          throw new Error('Access token is missing');
        }

        return this.tokenService.revoke(user.accessToken);
      })
    ).subscribe(() => this.store$.dispatch(logout()));
  }

}
