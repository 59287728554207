import { createAction, props } from '@ngrx/store';
import { PoolInput, PoolOutput } from 'core/http/project';
import { Uuid } from 'shared/common/types';
import { Update } from '@ngrx/entity';

export const getPools = createAction('[Project Request] Get Pools');
export const savePools = createAction('[Project Store] Save Pools', props<{ pools: PoolOutput[] }>());
export const markPoolsLoaded = createAction('[Project Store] Mark Pools Loaded');

export const createPool = createAction('[Project Request] Create Pool', props<PoolInput>());
export const addPoolInStore = createAction('[Project Store] Add Pool In Store', props<PoolOutput>());

export const updatePool = createAction('[Project Request] Update Pool', props<{ id: Uuid, input: PoolInput }>());
export const updatePoolInStore = createAction('[Project Store] Update Pool In Store', props<{ update: Update<PoolOutput> }>());

export const removePool = createAction('[Project Request] Remove Pool', props<{ id: Uuid }>());
export const removePoolFromStore = createAction('[Project Store] Remove Pool From Store', props<{ id: Uuid }>());

export const setLoading = createAction('[Project Store] Set Pools Loading');

export const setError = createAction('[Project Store] Set Pools Error');
