import { animate, animation, style } from '@angular/animations';

export const expandAnimation = animation([
  style({ opacity: 0, height: 0 }),
  animate('.3s ease-out', style({ opacity: 1, height: '*' }))
]);

export const closeAnimation = animation([
  animate('.3s ease-in', style({ opacity: 0, height: 0 }))
]);

export const showAnimation = animation([
  style({ opacity: 0 }),
  animate('.3s', style({ opacity: 1 }))
]);

export const hideAnimation = animation([
  style({ opacity: 1 }),
  animate('.3s', style({ opacity: 0 }))
]);
