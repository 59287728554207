import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectStatusOutput, UserOutput } from 'core/http/root';
import { ProjectOutput } from 'core/http/dev';
import { Store } from '@ngrx/store';
import { selectFirst, to404PageIfNotFound } from 'core/utils/rx-common';
import { oneUser } from 'app/store/root/users/users.selectors';
import { oneRootProject } from 'app/store/root/projects/projects.selectors';
import { assignStatus } from 'app/store/root/users/users.actions';
import { navigateToRoot } from 'app/store/common-effects/router.effects';
import { forkJoin, Observable } from 'rxjs';

type AssignedStatus = ProjectStatusOutput & { isAssigned: boolean };

@Component({
  selector: 'app-assigned-statuses',
  templateUrl: './assigned-statuses.component.html',
  styleUrls: ['./assigned-statuses.component.scss']
})
export class AssignedStatusesComponent implements OnInit {

  private _user = new Observable<UserOutput>();
  private _project = new Observable<ProjectOutput>();
  private _assignedStatuses = Array<AssignedStatus>();

  get project(): Observable<ProjectOutput> {
    return this._project;
  }

  get user(): Observable<UserOutput> {
    return this._user;
  }

  get assignedStatuses(): AssignedStatus[] {
    return this._assignedStatuses;
  }

  constructor(
    private route: ActivatedRoute,
    private store$: Store
  ) { }

  ngOnInit(): void {
    const managerId = this.route.snapshot.params.managerId;
    const projectId = this.route.snapshot.params.projectId;

    this._user = this.store$.pipe(
      selectFirst(oneUser, { id: managerId }),
      to404PageIfNotFound(this.store$, 'root')
    );

    this._project = this.store$.pipe(
      selectFirst(oneRootProject, { id: projectId }),
      to404PageIfNotFound(this.store$, 'root')
    );

    const statuses = this.route.snapshot.data.statuses;
    const assignedStatuses = this.route.snapshot.data.assignedStatuses;

    this._assignedStatuses = statuses.map((status: AssignedStatus) => {
      return {
        ...status,
        isAssigned: !!assignedStatuses.find((assigned: AssignedStatus) => status.id === assigned.id)
      };
    });
  }

  assignStatuses(): void {
    const statusIds = this.assignedStatuses
      .filter(s => s.isAssigned)
      .map(s => s.id);

    forkJoin({ user: this.user, project: this.project })
      .subscribe(({ user, project }) =>
        this.store$.dispatch(assignStatus({ userId: user.id, projectId: project.id, statusIds }))
      );
  }

  goBack(): void {
    this.store$.dispatch(navigateToRoot({ path: ['managers'] }));
  }
}
