import { NgModule } from '@angular/core';
import { StatusesHistoryComponent } from 'history/statuses-history/statuses-history.component';
import { StatusesHistoryRoutingModule } from 'history/statuses-history/statuses-history-routing.module';
import { StatusChangeOrdersComponent } from './status-change-orders/status-change-orders.component';
import { SharedModule } from 'shared/shared.module';
import { StatusChangeComponent } from './status-change/status-change.component';
import { OrderPageModule } from 'order/pages/order-page/order-page.module';

@NgModule({
  imports: [
    StatusesHistoryRoutingModule,
    SharedModule,
    OrderPageModule
  ],
  declarations: [StatusesHistoryComponent, StatusChangeOrdersComponent, StatusChangeComponent]
})
export class StatusesHistoryModule {
}
