import { Component, Input } from '@angular/core';
import { TrackingOutput } from 'core/http/project';
import { of } from 'rxjs';

@Component({
  selector: 'app-tracking-details',
  templateUrl: './tracking-details.component.html',
  styleUrls: ['./tracking-details.component.scss']
})
export class TrackingDetailsComponent {

  private _tracking?: TrackingOutput;
  private readonly _columns = Array<string>(
    'status',
    'createdAt',
    'assignedAt',
    'extra'
  );

  get tracking(): TrackingOutput | undefined {
    return this._tracking;
  }

  @Input()
  set tracking(value: TrackingOutput | undefined) {
    this._tracking = value;
  }

  dataSource = () => of(this.tracking ? this.tracking.statuses : []);

  get columns(): string[] {
    return this._columns;
  }

}
