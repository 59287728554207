import { Component, HostBinding, Input } from '@angular/core';

export type SmallTextColor = 'black' | 'gray' | 'white' | 'primary' | 'warn';

@Component({
  selector: 'app-colorized-text',
  template: `<span [ngClass]="textColor"><ng-content></ng-content></span>`,
  styles: [`
    :host {
      display: inline-block;
    }

    /* noinspection CssUnusedSymbol */
    .gray {
      color: rgba(0, 0, 0, 0.5) !important;
    }

    /* noinspection CssUnusedSymbol */
    .white {
      color: white !important;
    }

    /* noinspection CssUnusedSymbol */
    .primary {
      color: var(--primary-color) !important;
      /*color: rgba(64, 158, 216, 0.7) !important;*/
    }

    /* noinspection CssUnusedSymbol */
    .warn {
      color: rgba(216, 25, 24, 0.7) !important;
    }
  `]
})
export class ColorizedTextComponent {
  @Input() textColor: SmallTextColor = 'gray';

  @Input()
  @HostBinding('style.text-align')
  align: 'center' | 'left' | 'right' = 'left';
}
