import { Component } from '@angular/core';

@Component({
  selector: 'app-async-validation-label',
  templateUrl: './async-validation-label.component.html',
  styleUrls: ['./async-validation-label.component.scss']
})
export class AsyncValidationLabelComponent {

}
