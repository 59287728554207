import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarComponent } from 'shared/modules/skeleton/snackbar/snackbar.component';
import { first } from 'rxjs/operators';

export interface DispatchCases {
  default?: string;

  [errorName: string]: string | undefined;
}

@Injectable({ providedIn: 'root' })
export class NotifierService {

  constructor(private snackBar: MatSnackBar, private translateService: TranslateService) {}

  dispatchHttpError(response: HttpErrorResponse): void {
    this.dispatch(response.error.message, 'error-color');
  }

  dispatchError(message?: string | string[]): void {
    this.dispatch(message || 'ERROR.unexpected_error', 'error-color');
  }

  dispatchNotification(message: string): void {
    this.dispatch(message, 'success-color');
  }

  switchCaseDispatch(type: string, errorCases: DispatchCases): void {
    this.dispatchError(errorCases[type] || errorCases.default || 'ERROR.unexpected_error');
  }

  private dispatch(message: string | string[], panelClass: 'success-color' | 'error-color', duration: number = 3000): void {
    this.translateService.get(message || 'undefined')
      .pipe(first())
      .subscribe(t => {
        const msg = typeof t === 'string' ? t : Object.values(t);

        this.snackBar.openFromComponent(SnackbarComponent, { data: msg, duration, panelClass });
      });
  }

}
