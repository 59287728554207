import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QueryBuilderComponent } from './query-builder.component';
import { MaterialModule } from 'shared/modules/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QueryBuilderModule as QBModule } from 'angular2-query-builder';
import { QueryBuilderDialogComponent } from './query-builder-dialog/query-builder-dialog.component';
import { AppendQueryPopoverComponent } from './query-builder-dialog/save-query-popover/append-query-popover.component';
import { PasteQueryFromSavedComponent } from './query-builder-dialog/paste-query-from-saved/paste-query-from-saved.component';
import { UploadFileButtonModule } from 'shared/modules/controls/upload-file-button/upload-file-button.module';
import { SimpleStringQueryInputComponent } from './simple-string-query-input/simple-string-query-input.component';
import { ChipStringQueryInputComponent } from './chip-string-query-input/chip-string-query-input.component';
import { SimpleNumberQueryInputComponent } from './simple-number-query-input/simple-number-query-input.component';
import { ChipNumberQueryInputComponent } from './chip-number-query-input/chip-number-query-input.component';
import { SingleEntityQueryInputComponent } from './single-entity-query-input/single-entity-query-input.component';
import { MultipleEntityQueryInputComponent } from './multiple-entity-query-input/multiple-entity-query-input.component';
import { DatetimeQueryInputComponent } from './datetime-query-input/datetime-query-input.component';
import { SelectModule } from 'shared/modules/controls/select/select.module';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { SelectSearchModule } from 'shared/modules/controls/select-search/select-search.module';
import { RoutableQueryBuilderComponent } from './routable-query-builder/routable-query-builder.component';
import { DirectivesModule } from 'shared/modules/directives/directives.module';
import { TypographyModule } from 'shared/modules/typography/typography.module';

@NgModule({
  declarations: [
    QueryBuilderComponent,
    QueryBuilderDialogComponent,
    AppendQueryPopoverComponent,
    PasteQueryFromSavedComponent,
    SimpleStringQueryInputComponent,
    ChipStringQueryInputComponent,
    SimpleNumberQueryInputComponent,
    ChipNumberQueryInputComponent,
    SingleEntityQueryInputComponent,
    MultipleEntityQueryInputComponent,
    DatetimeQueryInputComponent,
    RoutableQueryBuilderComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    QBModule,
    FormsModule,
    ReactiveFormsModule,
    UploadFileButtonModule,
    SelectModule,
    VirtualScrollerModule,
    SelectSearchModule,
    DirectivesModule,
    TypographyModule
  ],
  exports: [
    QueryBuilderComponent,
    QueryBuilderDialogComponent,
  ]
})
export class QueryBuilderModule {
}
