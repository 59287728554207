import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-scrollable-list',
  templateUrl: './scrollable-list.component.html',
  styleUrls: ['./scrollable-list.component.scss']
})
export class ScrollableListComponent {

  private _count = 0;

  get count(): number {
    return this._count > 5 ? this._count : 0;
  }

  get itemsLength(): number {
    return this._count;
  }

  @Input()
  set count(value: number) {
    this._count = value;
  }

}
