import { Directive, HostBinding, Input } from '@angular/core';
import { DataTableComponent } from 'shared/modules/data-table/data-table.component';

@Directive({ selector: '[app-data-table-column]' })
export class DataTableColumnDirective<T> {

  private _cellName?: string;

  constructor(private _parent: DataTableComponent<T>) {}

  @Input('app-data-table-column')
  set cellName(value: string | undefined) {
    this._cellName = value;
  }

  get cellName(): string | undefined {
    return this._cellName;
  }

  @HostBinding('style.display')
  get visibility(): string {
    if (!this.cellName || !this._parent.tableRow.columns) {
      return 'table-cell';
    }

    return this._parent.tableRow.columns.includes(this.cellName) ? 'table-cell' : 'none';
  }

}
