import { createAction, props } from '@ngrx/store';
import { CreateStatusInput, StatusOutput, StatusPriorityInput, UpdateStatusInput } from 'core/http/project';
import { Uuid } from 'shared/common/types';
import { Update } from '@ngrx/entity';
import { createSetErrorAction, createSetLoadingAction } from 'app/store/completed-entity-state/completed-entity-state';

export const getStatuses = createAction('[Statuses Request] Get Statuses');
export const saveStatuses = createAction('[Statuses Store] Save Statuses', props<{ statuses: StatusOutput[] }>());
export const markStatusesLoaded = createAction('[Statuses Store] Mark Statuses Loaded');

export const createStatus = createAction('[Statuses Request] Create Status', props<{ input: CreateStatusInput }>());
export const addStatusInStore = createAction('[Statuses Store] Add Status In Store', props<{ status: StatusOutput }>());

export const updateStatus = createAction('[Statuses Request] Update Status',
  props<{ id: Uuid, input: UpdateStatusInput }>());
export const updateStatusInStore = createAction('[Statuses Store] Update Status In Store',
  props<{ update: Update<StatusOutput> }>());

export const removeStatus = createAction('[Statuses Request] Remove Status', props<{ id: Uuid }>());
export const removeStatusFromStore = createAction('[Statuses Store] Remove Status From Store', props<{ id: Uuid }>());

export const restoreStatus = createAction('[Statuses Request] Restore Status', props<{ id: Uuid }>());

export const changePriority = createAction('[Statuses Request] Change Priority',
  props<{ input: StatusPriorityInput[] }>());
export const changePriorityInStore = createAction('[Statuses Store] Change Priority In Store',
  props<{ updates: Update<StatusOutput>[] }>());

export const setLoading = createSetLoadingAction('[Statuses Store] Set Loading');

export const setError = createSetErrorAction('[Statuses Store] Set Error');
