import { Component } from '@angular/core';
import * as XLSX from 'xlsx';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Dic } from 'shared/common/types';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss']
})
export class UploadFileDialogComponent {

  uploadForm = new FormGroup({
    row: new FormControl(1, Validators.required),
    column: new FormControl('A', Validators.required)
  });

  constructor(private dialogRef: MatDialogRef<UploadFileDialogComponent>) { }

  uploadFile(fileInfo: File): void {
    const reader = new FileReader();
    reader.readAsBinaryString(fileInfo);

    reader.onload = () => {
      const workBook = XLSX.read(reader.result, { type: 'binary' });
      const worksheetName = workBook.SheetNames[0];
      const worksheetRaw = workBook.Sheets[worksheetName];
      const { row, column } = this.uploadForm.value;

      const worksheet = XLSX.utils
        .sheet_to_json<Dic<unknown>>(worksheetRaw, { header: 'A' })
        .slice(row - 1)
        .filter((item: Dic<unknown>) => !!item[column])
        .map((item: Dic<unknown>) => String(item[column]));

      this.dialogRef.close(worksheet);
    };
  }

}
