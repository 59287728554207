import { Action, createReducer, on } from '@ngrx/store';
import * as ProjectsActions from './projects.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProjectOutput } from 'core/http/dev';

export interface ProjectsState extends EntityState<ProjectOutput> {
  isLoaded: boolean;
}

const adapter: EntityAdapter<ProjectOutput> = createEntityAdapter();

export const initialProjectsState = adapter.getInitialState({
  isLoaded: false
});

const reducer = createReducer(
  initialProjectsState,
  on(ProjectsActions.saveProjects, (state, { projects }) => adapter.setAll(projects, state)),
  on(ProjectsActions.markProjectsLoaded, state => ({ ...state, isLoaded: true })),
  on(ProjectsActions.addRootProjectInStore, (state, { project }) => adapter.addOne(project, state)),
  on(ProjectsActions.updateRootProjectInStore, (state, { update }) => adapter.updateOne(update, state))
);

export function projectsReducer(state: ProjectsState | undefined, action: Action): ProjectsState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
