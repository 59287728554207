import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  LayoutActionsDirective,
  LayoutComponent,
  LayoutContentDirective,
  LayoutHeaderDirective
} from 'shared/modules/layout/layout.component';
import { SkeletonModule } from 'shared/modules/skeleton/skeleton.module';


@NgModule({
  declarations: [
    LayoutComponent,
    LayoutHeaderDirective,
    LayoutActionsDirective,
    LayoutContentDirective
  ],
  imports: [
    CommonModule,
    SkeletonModule
  ],
  exports: [
    LayoutComponent,
    LayoutHeaderDirective,
    LayoutActionsDirective,
    LayoutContentDirective
  ]
})
export class LayoutModule {
}
