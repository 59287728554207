import { createAction, props } from '@ngrx/store';
import { ProjectOutput } from 'core/http/dev';
import { UpdateCurrentProjectInput } from 'core/http/project/current-project.service';

export const getCurrentProject = createAction('[Current Project Request] Get Current Project');
export const saveCurrentProject = createAction('[Current Project State] Save Current Project', props<ProjectOutput>());
export const markCurrentProjectLoaded = createAction('[Current Project State] Mark Current Project Loaded');

export const updateCurrentProject = createAction('[Current Project Http] Update Current Project', props<UpdateCurrentProjectInput>());

export const resetCurrentProject = createAction('[CurrentProject State] Reset Current Project');
