import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelPipe } from 'shared/modules/pipes/label.pipe';
import { ProjectCurrencyPipe } from 'shared/modules/pipes/project-currency.pipe';
import { SafeHtmlPipe } from 'shared/modules/pipes/safe-html.pipe';
import { MarkdownPipe } from 'shared/modules/pipes/markdown.pipe';
import { SafeUrlPipe } from './safe-url.pipe';

@NgModule({
  declarations: [
    LabelPipe,
    ProjectCurrencyPipe,
    MarkdownPipe,
    SafeHtmlPipe,
    SafeUrlPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LabelPipe,
    ProjectCurrencyPipe,
    MarkdownPipe,
    SafeHtmlPipe,
    SafeUrlPipe
  ]
})
export class PipesModule {
}
