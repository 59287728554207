import { Component } from '@angular/core';
import { StatusChangeOutput, StatusesHistoryService } from 'core/http/project/status/statuses-history.service';
import { PaginatedData } from 'shared/common/types';
import { Criteria } from 'shared/criteria/Criteria';
import { Store } from '@ngrx/store';
import { statusEntities } from 'app/store/project/statuses/statuses.selectors';

@Component({
  selector: 'app-statuses-history',
  templateUrl: './statuses-history.component.html',
  styleUrls: ['./statuses-history.component.scss']
})
export class StatusesHistoryComponent {

  readonly statuses$ = this.store$.select(statusEntities);

  constructor(
    private statusesHistoryService: StatusesHistoryService,
    private store$: Store
  ) { }

  dataSource: PaginatedData<StatusChangeOutput> = (c: Criteria) => {
    const criteria = c.clone({ ordering: { field: 'createdAt', ascending: 'desc' } });

    return this.statusesHistoryService.getStatusChanges(criteria);
  };

}
