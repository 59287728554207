import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'shared/shared.module';
import { ProjectRoutingModule } from './project-routing.module';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ProjectLayoutComponent } from './pages/project/project-layout.component';
import { ProjectServicesModule } from 'core/http/project/project-services.module';
import { AuthServicesModule } from 'core/http/auth/auth-services';
import { EffectsModule } from '@ngrx/effects';
import { GoodsEffects } from 'app/store/project/goods/goods.effects';
import { SendersEffects } from 'app/store/project/senders/senders.effects';
import { CourierChainsEffects } from 'app/store/project/courier-chains/courier-chains.effects';
import { CouriersEffects } from 'app/store/project/couriers/couriers.effects';
import { DocumentsEffects } from 'app/store/project/documents/documents.effects';
import { PoolsEffects } from 'app/store/project/pools/pools.effects';
import { StatusChainsEffects } from 'app/store/project/status-chains/status-chains.effects';
import { StatusesEffects } from 'app/store/project/statuses/statuses.effects';
import { StorehousesEffects } from 'app/store/project/storehouses/storehouses.effects';
import { FilterTemplatesEffects } from 'app/store/project/filter-templates/filter-templates.effects';
import { CurrentProjectEffects } from 'app/store/project/current-project/current-project.effects';
import { FieldMapEffects } from 'app/store/project/field-map/field-map.effects';
import { RouterEffects } from 'app/store/common-effects/router.effects';
import { ScheduledTasksEffects } from 'app/store/project/scheduled-tasks/scheduled-tasks.effects';
import { GrouperDialogModule } from 'shared/dialogs/grouper-dialog/grouper-dialog.module';
import { AssociationDialogModule } from 'shared/dialogs/association-dialog/association-dialog.module';
import { UnloadingDataDialogModule } from 'shared/dialogs/sender-select-dialog/unloading-data-dialog.module';
import { ReserveCheckDialogModule } from 'shared/dialogs/reserve-check-dialog/reserve-check-dialog.module';
import { PageNotFoundModule } from 'project/pages/page-not-found/page-not-found.module';
import { CurrenciesEffects } from 'app/store/project/currencies/currencies.effects';
import { AdditionFieldsEffects } from 'app/store/project/addition-fields/addition-fields.effects';
import { LoggedUserEffects } from 'app/store/login/logged-user/logged-user.effects';
import { StatusesHistoryModule } from 'history/statuses-history/statuses-history.module';
import { NoticesEffects } from 'app/store/project/notices/notices.effects';
import { NoticesLogModule } from 'history/notices-log/notices-log.module';
import { ProjectResourcesEffects } from 'app/store/project/project-resources/project-resources.effects';
import { ExtensionsEffects } from 'app/store/project/extensions/extensions.effects';

const effects = [
  GoodsEffects,
  SendersEffects,
  CourierChainsEffects,
  CouriersEffects,
  DocumentsEffects,
  PoolsEffects,
  StatusChainsEffects,
  StatusesEffects,
  StorehousesEffects,
  FilterTemplatesEffects,
  CurrentProjectEffects,
  FieldMapEffects,
  RouterEffects,
  ScheduledTasksEffects,
  CurrenciesEffects,
  AdditionFieldsEffects,
  LoggedUserEffects,
  NoticesEffects,
  ProjectResourcesEffects,
  ExtensionsEffects
];

@NgModule({
  declarations: [
    ProjectsComponent,
    ProjectLayoutComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ProjectRoutingModule,
    ProjectServicesModule,
    AuthServicesModule,
    PageNotFoundModule,
    StatusesHistoryModule,
    NoticesLogModule,
    //
    GrouperDialogModule,
    AssociationDialogModule,
    UnloadingDataDialogModule,
    ReserveCheckDialogModule,
    //
    EffectsModule.forFeature(effects)
  ]
})
export class ProjectModule {}
