import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { ProjectInjectable } from 'core/http/project/project-services.module';

export type ProjectResourceType = 'couriers'
  | 'courierChains'
  | 'documents'
  | 'documentPools'
  | 'goods'
  | 'scheduledTasks'
  | 'senders'
  | 'statuses'
  | 'statusChains'
  | 'storehouses'
  | 'noticeTriggers'
  | 'extensions';

export interface ProjectResourceChanges {
  goods: string;
  senders: string;
  statuses: string;
  couriers: string;
  documents: string;
  documentPools: string;
  storehouses: string;
  scheduledTasks: string;
  noticeTriggers: string;
  courierChains: string;
  statusChains: string;
  extensions: string;
}

export interface CachedData<T> {
  type: string;
  appVersion: string;
  lastUpdate: string;
  data: T | null;
}

@ProjectInjectable()
export class ResourceUpdatesService {

  constructor(private http: HttpClient) {}

  getResourceChanges(): Observable<ProjectResourceChanges> {
    return this.http.get<ProjectResourceChanges>(apiUrl('/project/resource-updates'));
  }

}
