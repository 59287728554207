import { createAction, props } from '@ngrx/store';
import { Department, StorehouseInput, StorehouseOutput } from 'core/http/project';
import { Uuid } from 'shared/common/types';
import { Update } from '@ngrx/entity';
import { createSetErrorAction, createSetLoadingAction } from 'app/store/completed-entity-state/completed-entity-state';

export const getStorehouses = createAction('[Project Request] Get Storehouses');
export const saveStorehouses = createAction('[Project Store] Save Storehouses', props<{ storehouses: StorehouseOutput[] }>());
export const markStorehouseLoaded = createAction('[Project Store] Mark Storehouses Loaded');

export const createStorehouse = createAction('[Project Request] Create Storehouse', props<StorehouseInput>());
export const addStorehouseInStore = createAction('[Project Store] Add Storehouse In Store', props<StorehouseOutput>());

export const updateStorehouse = createAction('[Project Request] Update Storehouse', props<{ id: Uuid, input: StorehouseInput }>());
export const updateStorehouseInStore = createAction('[Project Store] Update Storehouse In Store', props<{ update: Update<StorehouseOutput> }>());

export const removeStorehouse = createAction('[Project Request] Remove Storehouse', props<{ id: Uuid }>());
export const removeStorehouseFromStore = createAction('[Project Store] Remove Storehouse From Store', props<{ id: Uuid }>());

export const setLoading = createSetLoadingAction('[Project Store] Set Storehouses Loading');

export const setError = createSetErrorAction('[Project Store] Set Storehouses Error');

export const getDepartments = createAction('[Project Request] Get Departments');
export const saveDepartments = createAction('[Project Store] Save Departments', props<{ departments: Department[] }>());
