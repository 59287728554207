import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserOutput } from 'core/http/root';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss']
})
export class ProfileInfoComponent {

  private _user = this.route.snapshot.data.user;

  get user(): UserOutput {
    return this._user;
  }

  constructor(private route: ActivatedRoute) { }

  selectLanguage(lang: string): void {
    localStorage.setItem('LANGUAGE', lang);
    location.reload();
  }

}
