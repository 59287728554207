import { createSelector } from '@ngrx/store';
import { RootState, selectRootState } from 'app/store/root/root.state';
import * as fromProjects from '../projects/projects.reducer';
import { Uuid } from 'shared/common/types';

export const rootProjectsState = createSelector(
  selectRootState,
  state => state.projects
);

export const rootProjects = createSelector(
  selectRootState,
  state => fromProjects.selectAll(state.projects)
);

export const oneRootProject = createSelector(
  selectRootState,
  (state: RootState, props: { id: Uuid }) => fromProjects.selectEntities(state.projects)[props.id]
);
