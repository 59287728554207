import { ProjectInjectable } from 'core/http/project/project-services.module';
import { HttpClient } from '@angular/common/http';
import { Uuid } from 'shared/common/types';
import { Observable, timer } from 'rxjs';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { exhaustMap, takeWhile } from 'rxjs/operators';


export enum ProgressStatuses {
  PROCESSING = 'PROCESSING',
  READY = 'READY',
  FAILURE = 'FAILURE',
  TERMINATED = 'TERMINATED',
  INDETERMINATE = 'INDETERMINATE'
}

export enum Commands {
  CLEARING_TRACK_RESERVE = 'CLEARING_TRACK_RESERVE_TASK',
  PRINTING = 'PRINTING_TASK',
  UNLOADING = 'UNLOADING_TASK',
  TRACK_RESERVATION_TASK = 'TRACK_RESERVATION_TASK',
  CANCEL_TRANSFER_TO_COURIER = 'CANCEL_TRANSFER_TO_COURIER_TASK',
  CHAIN_UNLOADING = 'CHAIN_UNLOADING_TASK',
  POOL_PRINTING = 'POOL_PRINTING_TASK',
  UPDATE_COVERAGE = 'UPDATE_COVERAGE_TASK',
  REMOVE_COVERAGE = 'REMOVE_COVERAGE_TASK',
  ADDING_TRACKING = 'ADDING_TRACKING_TASK',
  MANUAL_TRACKING = 'MANUAL_TRACKING_TASK',
  POOL_SENDING = 'POOL_SENDING_TASK',
  SUMMARY_SENDING = 'SUMMARY_SENDING_TASK',
  NOTICE_SENDING_TASK = 'NOTICE_SENDING_TASK',
  STATUS_CHANGE_TASK = 'STATUS_CHANGE_TASK',
  REPORT_REJECTED_ORDERS_TASK = 'REPORT_REJECTED_ORDERS_TASK'
}

export interface CommandError {
  error: string;
  message: string;
}

export interface BackgroundProgress {
  id: string;
  type: Commands;
  status: string;
  totalSteps: number;
  completedSteps: number;
  progresses?: BackgroundProgress[];
  createdAt: string;
  stoppedAt: string;
  groups: string[];
  cancelled: boolean;
}

@ProjectInjectable()
export class BackgroundTaskService {

  constructor(private http: HttpClient) {}

  getResult<T>(progressId: Uuid): Observable<T> {
    return this.http.get<T>(apiUrl(`/project/task/${progressId}/result`));
  }

  getCommandProgress(progressId: Uuid): Observable<BackgroundProgress> {
    return this.http.get<BackgroundProgress>(apiUrl(`/project/task/${progressId}/progress`));
  }

  getCommandError(progressId: Uuid): Observable<CommandError> {
    return this.http.get<CommandError>(apiUrl(`/project/task/${progressId}/error`));
  }

  getProjectProgress(): Observable<BackgroundProgress[]> {
    return this.http.get<BackgroundProgress[]>(apiUrl(`/project/task/progresses`));
  }

  listenProgress(progressId: Uuid): Observable<BackgroundProgress> {
    return timer(0, 3000).pipe(
      exhaustMap(() => this.getCommandProgress(progressId)),
      takeWhile(progress => progress.status === ProgressStatuses.PROCESSING, true)
    );
  }

  listenAllProgresses(): Observable<BackgroundProgress[]> {
    return timer(0, 3000).pipe(
      exhaustMap(() => this.getProjectProgress())
    );
  }

  cancelBackgroundCommand(taskId: Uuid): Observable<Object> {
    return this.http.patch<Object>(apiUrl(`/project/task/${taskId}/cancel`), {});
  }

}
