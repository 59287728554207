import * as DocumentsActions from './documents.actions';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { DocumentOutput } from 'core/http/project';
import { Action, createReducer, on } from '@ngrx/store';
import {
  CompletedEntityState,
  initialCompletedEntityState
} from 'app/store/completed-entity-state/completed-entity-state';

export interface DocumentsState extends CompletedEntityState<DocumentOutput> {}

const adapter: EntityAdapter<DocumentOutput> = createEntityAdapter<DocumentOutput>();

export const initialDocumentsState = adapter.getInitialState(initialCompletedEntityState);

const reducer = createReducer(
  initialDocumentsState,
  on(DocumentsActions.saveDocuments, (state, payload) => adapter.setAll(payload.documents, state)),
  on(DocumentsActions.markDocumentsLoaded, state => ({ ...state, loaded: true })),
  on(DocumentsActions.setLoading, (state, { value }) => ({ ...state, loading: value })),
  on(DocumentsActions.setError, (state, { value }) => ({ ...state, error: value }))
);

export function documentsReducer(state: DocumentsState | undefined, action: Action): DocumentsState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
