import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-flag-icon',
  templateUrl: './flag-icon.component.html',
  styleUrls: ['./flag-icon.component.scss']
})
export class FlagIconComponent {

  private _code = '';

  @Input()
  set code(value: string) {
    this._code = value;
  }

  get flagPath(): string {
    if (!this._code) {
      return '';
    }

    return `/assets/flags/${this._code.toLowerCase()}.svg`;
  }

  get style(): string {
    return `background-image: url(${this.flagPath})`;
  }

}
