import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-timeline-card-text',
  templateUrl: './timeline-card-text.component.html',
  styleUrls: ['./timeline-card-text.component.scss']
})
export class TimelineCardTextComponent {

  @Input() oldValue!: string | null;
  @Input() newValue!: string | null;
  @Input() type!: string;

}
