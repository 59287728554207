import { Action, createReducer, on } from '@ngrx/store';
import * as AdditionFieldsActions from './addition-fields.actions';

export interface AdditionalField {
  name: string | number;
  label: string;
}

const additionFieldsWithStrings: AdditionalField[] = [
  { name: 'additional1', label: 'Доп. поле 1' },
  { name: 'additional2', label: 'Доп. поле 2' },
  { name: 'additional3', label: 'Доп. поле 3' },
  { name: 'additional4', label: 'Доп. поле 4' },
  { name: 'additional5', label: 'Доп. поле 5' },
  { name: 'additional6', label: 'Доп. поле 6' },
  { name: 'additional7', label: 'Доп. поле 7' },
  { name: 'additional8', label: 'Доп. поле 8' },
  { name: 'additional9', label: 'Доп. поле 9' },
  { name: 'additional10', label: 'Доп. поле 10' },
  { name: 'additional11', label: 'Доп. поле 11' },
  { name: 'additional12', label: 'Доп. поле 12' },
  { name: 'additional13', label: 'Доп. поле 13' },
  { name: 'additional14', label: 'Доп. поле 14' },
  { name: 'additional15', label: 'Доп. поле 15' }
];

const additionFieldsWithNumbers: AdditionalField[] = [
  { name: 1, label: 'Доп. поле 1' },
  { name: 2, label: 'Доп. поле 2' },
  { name: 3, label: 'Доп. поле 3' },
  { name: 4, label: 'Доп. поле 4' },
  { name: 5, label: 'Доп. поле 5' },
  { name: 6, label: 'Доп. поле 6' },
  { name: 7, label: 'Доп. поле 7' },
  { name: 8, label: 'Доп. поле 8' },
  { name: 9, label: 'Доп. поле 9' },
  { name: 10, label: 'Доп. поле 10' },
  { name: 11, label: 'Доп. поле 11' },
  { name: 12, label: 'Доп. поле 12' },
  { name: 13, label: 'Доп. поле 13' },
  { name: 14, label: 'Доп. поле 14' },
  { name: 15, label: 'Доп. поле 15' }
];

const json = localStorage.getItem('selectedAdditions');

export interface AdditionFieldsState {
  additionFieldsWithStrings: AdditionalField[];
  additionFieldsWithNumbers: AdditionalField[];
  selectedAdditions: AdditionalField[];
}

export const initialAdditionalFieldsState: AdditionFieldsState = {
  additionFieldsWithStrings: additionFieldsWithStrings,
  additionFieldsWithNumbers: additionFieldsWithNumbers,
  selectedAdditions: json ? JSON.parse(json) : []
};

const reducer = createReducer(
  initialAdditionalFieldsState,
  on(AdditionFieldsActions.writeAdditions, (state, { fields }) => ({ ...state, selectedAdditions: fields }))
);

export function additionalFieldsReducer(state: AdditionFieldsState | undefined, action: Action): AdditionFieldsState {
  return reducer(state, action);
}
