import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileInfoComponent } from './components/profile-info/profile-info.component';
import { SharedModule } from 'shared/shared.module';

@NgModule({
  declarations: [ProfileInfoComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    ProfileInfoComponent
  ]
})
export class ProfileInfoModule {
}
