import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { StatusService } from 'core/http/project';
import { LoaderService } from 'core/services/loader.service';
import * as StatusesActions from './statuses.actions';
import * as NotifierActions from 'app/store/common-effects/notifier.effects';
import * as ProjectRouterActions from 'app/store/common-effects/router.effects';
import { createProperties } from 'app/store/completed-entity-state/completed-entity-state';
import { CompletedEntityStateService } from 'app/store/completed-entity-state/completed-entity-state.service';


@Injectable()
export class StatusesEffects {

  private readonly properties = createProperties(
    StatusesActions.setLoading,
    StatusesActions.markStatusesLoaded,
    StatusesActions.setError,
    () => this.statusService.getAllStatuses(),
    'statuses'
  );

  getStatuses$ = createEffect(() => this.actions$.pipe(
    ofType(StatusesActions.getStatuses),
    exhaustMap(() => this.completedEntityStateService.get(this.properties)),
    map(statuses => StatusesActions.saveStatuses({ statuses }))
  ));

  createStatus$ = createEffect(() => this.actions$.pipe(
    ofType(StatusesActions.createStatus),
    tap(() => this.loader.begin()),
    exhaustMap(({ input }) => this.statusService.createStatus(input)),
    tap(() => this.loader.end()),
    switchMap(status => [
      StatusesActions.addStatusInStore({ status }),
      NotifierActions.notify({ message: 'Статус создан' }),
      ProjectRouterActions.navigateToProject({ path: ['setting', 'statuses'] })
    ])
  ));

  updateStatus$ = createEffect(() => this.actions$.pipe(
    ofType(StatusesActions.updateStatus),
    tap(() => this.loader.begin()),
    exhaustMap(({ id, input }) => this.statusService.updateStatus(id, input)),
    tap(() => this.loader.end()),
    switchMap(status => [
      StatusesActions.updateStatusInStore({ update: { id: status.id, changes: status } }),
      NotifierActions.notify({ message: 'Статус обновлен' }),
      ProjectRouterActions.navigateToProject({ path: ['setting', 'statuses'] })
    ])
  ));

  removeStatus$ = createEffect(() => this.actions$.pipe(
    ofType(StatusesActions.removeStatus),
    tap(() => this.loader.begin()),
    exhaustMap(({ id }) => this.statusService.removeStatus(id).pipe(mapTo(id))),
    tap(() => this.loader.end()),
    switchMap(id => [
      StatusesActions.updateStatusInStore({
        update: { id: id, changes: { isRemoved: true } }
      }),
      NotifierActions.notify({ message: 'Статус удален' })
    ])
  ));

  restoreStatus$ = createEffect(() => this.actions$.pipe(
    ofType(StatusesActions.restoreStatus),
    tap(() => this.loader.begin()),
    exhaustMap(({ id }) => this.statusService.restoreStatus(id).pipe(mapTo(id))),
    tap(() => this.loader.end()),
    switchMap(id => [
      StatusesActions.updateStatusInStore({
        update: { id: id, changes: { isRemoved: false } }
      }),
      NotifierActions.notify({ message: 'Статус восстановлен' })
    ])
  ));

  changePriority$ = createEffect(() => this.actions$.pipe(
    ofType(StatusesActions.changePriority),
    exhaustMap(({ input }) => this.statusService.changePriority(input)),
    map(statuses => StatusesActions.changePriorityInStore({
      updates: statuses.map(item => ({ id: item.id, changes: { priority: item.priority } }))
    }))
  ));

  constructor(
    private actions$: Actions,
    private statusService: StatusService,
    private loader: LoaderService,
    private completedEntityStateService: CompletedEntityStateService
  ) { }

}
