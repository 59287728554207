import { HttpClient } from '@angular/common/http';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Observable } from 'rxjs';
import { RootInjectable } from 'core/http/root/root-services.module';
import { StorehouseOutput } from 'core/http/project';
import { Uuid } from 'shared/common/types';
import { DayOfWeek } from 'core/http/project/notice/notice.interfaces';

export type Withdrawn = 'WITHDRAWN_DISABLED' | 'WITHDRAWN_AFTER_UNLOADING' | 'SCHEDULED_WITHDRAWN';

export interface ProjectInput {
  name: string;
  isoCode: string;
  currency: string;
  withdrawnType: Withdrawn;
  withdrawnSchedules: ProjectSchedule[];
}

export interface DefaultStatusOutput {
  id: string;
  name: string;
  associatedState: string;
  color: string;
  priority: number;
}

export interface ProjectOutput {
  id: string;
  name: string;
  route: string;
  isoCode: string;
  currencyCode: string;
  isActive: boolean;
  defaultStatus: DefaultStatusOutput | null;
  defaultStorehouse: StorehouseOutput | null;
  withdrawnType: Withdrawn;
  withdrawnSchedules: Array<ProjectSchedule>;
}

export interface ProjectSchedule {
  hour: number;
  minute: number;
  days: Array<DayOfWeek>;
}

@RootInjectable()
export class ProjectService {

  constructor(private http: HttpClient) { }

  getProjects(): Observable<ProjectOutput[]> {
    return this.http.get<ProjectOutput[]>(apiUrl(`/root/projects`));
  }

  getProject(projectId: Uuid): Observable<ProjectOutput> {
    return this.http.get<ProjectOutput>(apiUrl(`/root/project/${projectId}`));
  }

  createProject(input: ProjectInput): Observable<ProjectOutput> {
    return this.http.post<ProjectOutput>(apiUrl('/root/project'), input);
  }

  updateProject(projectId: Uuid, input: ProjectInput): Observable<ProjectOutput> {
    return this.http.put<ProjectOutput>(apiUrl(`/root/project/${projectId}`), input);
  }

  activateProject(projectId: Uuid): Observable<ProjectOutput> {
    return this.http.get<ProjectOutput>(apiUrl(`/root/project/activate/${projectId}`));
  }

  deactivateProject(projectId: Uuid): Observable<ProjectOutput> {
    return this.http.get<ProjectOutput>(apiUrl(`/root/project/deactivate/${projectId}`));
  }

}
