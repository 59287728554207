import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { WebhookService } from 'core/http/root';
import { LoaderService } from 'core/services/loader.service';
import { forkJoin, of } from 'rxjs';
import * as WebhooksActions from './webhooks.actions';
import * as RouterAction from 'app/store/common-effects/router.effects';
import * as NotifyActions from 'app/store/common-effects/notifier.effects';

@Injectable()
export class WebhooksEffects {

  getWebhooks$ = createEffect(() => this.actions$.pipe(
    ofType(WebhooksActions.getWebhooks),
    switchMap(() => this.webhookService.getAllWebhooks()),
    switchMap(webhooks => [
      WebhooksActions.saveWebhooks({ webhooks }),
      WebhooksActions.getEvents()
    ])
  ));

  createIfPossible$ = createEffect(() => this.actions$.pipe(
    ofType(WebhooksActions.createIfPossible),
    tap(() => this.loader.begin()),
    exhaustMap(({ criteria, input }) => {
      return forkJoin({
        input: of(input),
        response: this.webhookService.hasWebhook(criteria)
      });
    }),
    tap(() => this.loader.end()),
    map(data => {
      return !data.response
        ? WebhooksActions.createWebhook({ input: data.input })
        : NotifyActions.errorNotify({ message: 'Вебхук для данного события уже существует' });
    })
  ));

  createWebhook$ = createEffect(() => this.actions$.pipe(
    ofType(WebhooksActions.createWebhook),
    tap(() => this.loader.begin()),
    exhaustMap(({ input }) => this.webhookService.createWebhook(input)),
    tap(() => this.loader.end()),
    switchMap(webhook => [
      WebhooksActions.addWebhookInStore({ webhook }),
      RouterAction.navigateToRoot({ path: ['integration', 'webhooks'] }),
      NotifyActions.notify({ message: 'Вебхук создан' })
    ])
  ));

  updateWebhook$ = createEffect(() => this.actions$.pipe(
    ofType(WebhooksActions.updateWebhook),
    tap(() => this.loader.begin()),
    exhaustMap(({ id, input }) => this.webhookService.updateWebhook(id, input)),
    tap(() => this.loader.end()),
    switchMap(webhook => [
      WebhooksActions.updateWebhookInStore({ update: { id: webhook.id, changes: webhook } }),
      RouterAction.navigateToRoot({ path: ['integration', 'webhooks'] }),
      NotifyActions.notify({ message: 'Вебхук обновлен' })
    ])
  ));

  removeWebhook$ = createEffect(() => this.actions$.pipe(
    ofType(WebhooksActions.removeWebhook),
    tap(() => this.loader.begin()),
    exhaustMap(({ id }) => this.webhookService.removeWebhook(id).pipe(mapTo(id))),
    tap(() => this.loader.end()),
    switchMap(id => [
      WebhooksActions.removeWebhookFromStore({ id }),
      NotifyActions.notify({ message: 'Вебхук удален' })
    ])
  ));

  getEvents$ = createEffect(() => this.actions$.pipe(
    ofType(WebhooksActions.getEvents),
    switchMap(() => this.webhookService.getAllEvent()),
    switchMap(events => [
      WebhooksActions.saveEvents({ events }),
      WebhooksActions.markWebhooksLoaded()
    ])
  ));

  constructor(
    private actions$: Actions,
    private webhookService: WebhookService,
    private loader: LoaderService
  ) {}

}
