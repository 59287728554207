import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterType, TemplateOutput } from 'core/http/project/rule-set/user-templates.service';
import { Store } from '@ngrx/store';
import { filterTemplates } from 'app/store/project/filter-templates/filter-templates.selectors';
import { arrayFilter } from 'core/utils/rx-common';

@Component({
  selector: 'app-paste-query-from-saved',
  templateUrl: './paste-query-from-saved.component.html',
  styleUrls: ['./paste-query-from-saved.component.scss']
})
export class PasteQueryFromSavedComponent {

  @Output() pasted = new EventEmitter<TemplateOutput>();
  @Output() removed = new EventEmitter<TemplateOutput>();
  @Input() type: FilterType = 'order';

  readonly templates$ = this.store$.select(filterTemplates)
    .pipe(
      arrayFilter(t => t.type === this.type)
    );

  constructor(private store$: Store) {}

  pasteTemplate(template: TemplateOutput): void {
    this.pasted.emit(template);
  }

  removeTemplate(template: TemplateOutput): void {
    this.removed.emit(template);
  }

}
