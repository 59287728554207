import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, switchMap, tap } from 'rxjs/operators';
import { DocumentService } from 'core/http/dev';
import { LoaderService } from 'core/services/loader.service';
import * as DocumentsActions from './documents.actions';
import * as NotifyActions from 'app/store/common-effects/notifier.effects';
import * as ProjectRouterActions from 'app/store/common-effects/router.effects';

@Injectable()
export class DocumentsEffects {

  getDevDocuments$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentsActions.getDevDocuments),
    switchMap(() => this.documentService.getAllDocuments()),
    switchMap(documents => [
      DocumentsActions.saveDevDocuments({ documents }),
      DocumentsActions.getRenderClasses()
    ])
  ));

  createDevDocument$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentsActions.createDevDocument),
    tap(() => this.loader.begin()),
    exhaustMap(({ input }) => this.documentService.createDocument(input)),
    tap(() => this.loader.end()),
    switchMap(document => [
      DocumentsActions.addDevDocumentInStore({ document }),
      NotifyActions.notify({ message: 'Бланк создан' }),
      ProjectRouterActions.navigateToDev({ path: ['documents'] })
    ])
  ));

  updateDevDocument$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentsActions.updateDevDocument),
    tap(() => this.loader.begin()),
    exhaustMap(({ id, input }) => this.documentService.updateDocument(id, input)),
    tap(() => this.loader.end()),
    switchMap(document => [
      DocumentsActions.updateDevDocumentInStore({ update: { id: document.id, changes: document } }),
      NotifyActions.notify({ message: 'Бланк обновлен' }),
      ProjectRouterActions.navigateToDev({ path: ['documents'] })
    ])
  ));

  getRenderClasses$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentsActions.getRenderClasses),
    switchMap(() => this.documentService.getRenderClasses()),
    switchMap(renderClasses => [
      DocumentsActions.saveRenderClasses({ renderClasses }),
      DocumentsActions.markDevDocumentsLoaded()
    ])
  ));

  constructor(
    private actions$: Actions,
    private documentService: DocumentService,
    private loader: LoaderService
  ) {}

}
