import { RootInjectable } from 'core/http/root/root-services.module';
import { HttpClient } from '@angular/common/http';
import { Criteria } from 'shared/criteria/Criteria';
import { Observable } from 'rxjs';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Uuid } from 'shared/common/types';
import { ProjectOutput } from 'core/http/dev';
import { RuleSet } from 'angular2-query-builder';


export interface CourierAssociationInput {
  name: string;
  courierId: string;
  associationId: string | null;
}

export interface CourierOutput {
  id: string;
  name: string;
  projects: ProjectOutput[];
  outsideId: string | null;
}

export interface StateMatcherOutput {
  id: Uuid;
  name: string;
  state: number;
  priority: number;
  ruleSet: RuleSet;
}

export interface StateMatcherInput {
  state: number;
  name: string;
  ruleSet: RuleSet;
}

export interface StateMatcherPriorityInput {
  id: Uuid;
  priority: number;
}

@RootInjectable()
export class CourierService {

  constructor(private http: HttpClient) {}

  getAllCouriers(criteria?: Criteria): Observable<CourierOutput[]> {
    return this.http.get<CourierOutput[]>(apiUrl(`/root/extensions/courier`, criteria));
  }

  updateAssociations(associations: Array<{ courierId: string, associationId: string | null }>): Observable<Object> {
    return this.http.patch(apiUrl('/root/courier-associations'), associations);
  }

  getMatchers(courierId: string): Observable<StateMatcherOutput[]> {
    return this.http.get<StateMatcherOutput[]>(apiUrl(`/root/courier/${courierId}/state-matchers`));
  }

  createMatcher(courierId: string, input: StateMatcherInput): Observable<StateMatcherOutput> {
    return this.http.post<StateMatcherOutput>(apiUrl(`/root/courier/${courierId}/state-matcher`), input);
  }

  updateMatcher(courierId: string, matcherId: string, input: StateMatcherInput): Observable<StateMatcherOutput> {
    return this.http.put<StateMatcherOutput>(apiUrl(`/root/courier/${courierId}/state-matcher/${matcherId}`), input);
  }

  removeMatcher(courierId: string, matcherId: string): Observable<void> {
    return this.http.delete<void>(apiUrl(`/root/courier/${courierId}/state-matcher/${matcherId}`));
  }

  checkMatching(courierId: string, status: string): Observable<StateMatcherOutput[]> {
    return this.http.get<StateMatcherOutput[]>(apiUrl(`/root/courier/${courierId}/state-matching/${status}`));
  }

  changePrioritiesMatchers(courierId: string, input: StateMatcherPriorityInput[]): Observable<StateMatcherOutput[]> {
    return this.http.post<StateMatcherOutput[]>(apiUrl(`/root/courier/${courierId}/priorities`), input);
  }

}
