import { Directive, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EagerData, EMPTY_PAGINATION, PaginatedData, Pagination } from 'shared/common/types';
import { Criteria, Ordering } from 'shared/criteria/Criteria';
import { map, tap } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';

export interface ListData<T> {
  items: T[];
  length: number;
}

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class DataLoader<T> {

  @Output() loading = new EventEmitter();
  @Output() loaded = new EventEmitter<ListData<T>>();

  protected abstract loadImpl(c: Criteria): Observable<ListData<T>>;

  protected doLoad(c: Criteria): Observable<ListData<T>> {
    this.loading.emit();

    return this.loadImpl(c).pipe(
      tap(data => this.loaded.emit(data))
    );
  }

}

class EagerContext<TItem> {
  constructor(readonly $implicit: TItem, readonly index: number) {
  }
}

@Directive({ selector: '[app-list-eager-provider]' })
export class ListEagerProviderDirective<TItem> extends DataLoader<TItem> {

  constructor(private _template: TemplateRef<EagerContext<TItem>>) {
    super();
  }

  @Input('app-list-eager-providerOf')
  set of(provider: EagerData<TItem>) {
    this._provider = provider;
  }

  get template(): TemplateRef<EagerContext<TItem>> {
    return this._template;
  }

  loadAll(filter: string, ordering?: Ordering): void {
    this.doLoad(new Criteria({ filter, ordering })).subscribe();
  }

  protected loadImpl(c: Criteria): Observable<ListData<TItem>> {
    const criteria = new Criteria({ filter: c.filter, ordering: c.ordering });

    return this._provider(criteria).pipe(
      map((data: TItem[]) => ({ items: data, length: data.length }))
    );
  }

  private _provider: EagerData<TItem> = () => of(Array<TItem>());

}

class PaginatedContext<TItem> {
  constructor(readonly $implicit: TItem, readonly collection: PaginatedData<TItem>) {}
}

@Directive({ selector: '[app-list-paginated-provider]' })
export class ListPaginatedProviderDirective<TItem> extends DataLoader<TItem> {

  private _offset = 0;
  private _pageIndex = 0;
  private _limit = 20;
  private _total = 0;
  private _sizes = [5, 10, 25, 50];
  private _pageChanged = new EventEmitter<PageEvent>();

  get limit(): number {
    return this._limit;
  }

  @Input('app-list-paginated-providerLimit')
  set limit(limit: number) {
    this._limit = limit;
  }

  get total(): number {
    return this._total;
  }

  get sizes(): number[] {
    return this._sizes;
  }

  @Input('app-list-paginated-providerSizes')
  set sizes(sizes: number[]) {
    this._sizes = sizes;
  }

  get template(): TemplateRef<PaginatedContext<TItem>> {
    return this._template;
  }

  @Input('app-list-paginated-providerOf')
  set of(provider: PaginatedData<TItem>) {
    this._provider = provider;
  }

  get pageIndex(): number {
    return this._pageIndex;
  }

  @Input('app-list-paginated-providerIndex')
  set pageIndex(value: number) {
    this._pageIndex = value;
  }

  @Output()
  get pageChanged(): EventEmitter<PageEvent> {
    return this._pageChanged;
  }

  constructor(private _template: TemplateRef<PaginatedContext<TItem>>) {
    super();
  }

  loadPage(pageEvent: PageEvent | null, filter: string, ordering?: Ordering): void {
    // Если произошел ивент, перезаписываем переменные
    // С помощью this.pageChanged.emit(pageEvent) они перезапишутся в Сторе
    // Иначе берем переменные, переданные через инпуты
    if (null !== pageEvent) {
      this.limit = pageEvent.pageSize;

      this._offset = pageEvent.pageIndex !== 0
        ? pageEvent.pageSize * pageEvent.pageIndex
        : pageEvent.pageIndex;

      this.pageChanged.emit(pageEvent);
    }
    else {
      this._offset = this.pageIndex !== 0
        ? this.limit * this.pageIndex
        : this.pageIndex;
    }

    this.doLoad(new Criteria({ filter, ordering, limit: this._limit, offset: this._offset }))
      .pipe(tap(loaded => this._total = loaded.length))
      .subscribe();
  }

  protected loadImpl(c: Criteria): Observable<ListData<TItem>> {
    return this._provider(c).pipe(
      map((data: Pagination<TItem>) => ({ items: data.output, length: data.totalCount }))
    );
  }

  private _provider: PaginatedData<TItem> = () => of(EMPTY_PAGINATION);

}
