import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import { MdePopover } from '@material-extended/mde';
import { Ordering } from 'shared/criteria/Criteria';
import { ENTER } from '@angular/cdk/keycodes';
import { PopoverSortDirective } from 'shared/modules/table/table-utils/table-directives';

@Component({
  selector: 'app-sorting-popover',
  styleUrls: ['./sorting-popover.component.scss'],
  templateUrl: './sorting-popover.component.html'
})
export class SortingPopoverComponent implements AfterContentInit {

  @ViewChild('sortPopover')
  medPopover!: MdePopover;

  @ContentChildren(PopoverSortDirective)
  sortingList!: QueryList<PopoverSortDirective>;

  @Input() ordering?: Ordering;
  @Output() orderingChange = new EventEmitter<Ordering>();

  @Input() columnName!: string;

  private _fields!: string[];

  ngAfterContentInit(): void {
    this._fields = this.sortingList.toArray().map(p => p.field);
  }

  triggerClose(event: KeyboardEvent): void {
    if (event.keyCode === ENTER) {
      this.medPopover.close.emit();
    }
  }

  changeSort(field: string, ascending: 'asc' | 'desc'): void {
    if (this.ordering && this.ordering.ascending === ascending && this.ordering.field === field) {
      this.ordering = undefined;
    } else {
      this.ordering = { field, ascending };
    }

    this.orderingChange.emit(this.ordering);
    this.medPopover.close.emit();
  }

  getColor(field: string, asc: string): 'primary' | null {
    if (this.ordering && field === this.ordering.field && asc === this.ordering.ascending) {
      return 'primary';
    }

    return null;
  }

  isActive(ascending: string): boolean | undefined {
    return this.ordering && ascending === this.ordering.ascending && this._fields.includes(this.ordering.field);
  }

}
