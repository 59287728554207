import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'shared/shared.module';
import { AssociationDialogComponent } from './association-dialog.component';

@NgModule({
  declarations: [AssociationDialogComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [AssociationDialogComponent]
})
export class AssociationDialogModule {
}
