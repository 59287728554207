import { ProjectInjectable } from 'core/http/project/project-services.module';
import { FieldMap } from 'angular2-query-builder/dist/components';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as CourierSelectors from 'app/store/project/couriers/couriers.selectors';
import * as SenderSelectors from 'app/store/project/senders/senders.selectors';
import * as StatusSelectors from 'app/store/project/statuses/statuses.selectors';
import * as GoodSelectors from 'app/store/project/goods/goods.selectors';
import { selectFirst } from 'core/utils/rx-common';
import { additionalFieldsWithStrings } from 'app/store/project/addition-fields/addition-fields.selectors';
import { isEqual } from 'lodash';

@ProjectInjectable()
export class OrderFieldMapService {

  private _fieldMap: FieldMap = {};

  constructor(private store$: Store) {}

  getOrderFieldMap(): Observable<FieldMap> {
    const transformer = map((entities: { id: string, name: string }[]) => {
      return entities.map(e => ({ value: e.id, name: e.name }));
    });

    const queries = combineLatest([
      this.store$.select(CourierSelectors.couriersState).pipe(
        filter(s => s.loaded),
        switchMap(() => this.store$.pipe(selectFirst(CourierSelectors.couriers))),
        transformer
      ),
      this.store$.select(StatusSelectors.statusesState).pipe(
        filter(s => s.loaded),
        switchMap(() => this.store$.pipe(selectFirst(StatusSelectors.activeStatuses))),
        transformer
      ),
      this.store$.select(SenderSelectors.sendersState).pipe(
        filter(s => s.loaded),
        switchMap(() => this.store$.pipe(selectFirst(SenderSelectors.activatedSenders))),
        transformer
      ),
      this.store$.select(GoodSelectors.goodsState).pipe(
        filter(s => s.loaded),
        switchMap(() => this.store$.pipe(selectFirst(GoodSelectors.goods))),
        transformer
      ),
      this.store$.select(additionalFieldsWithStrings).pipe(
        first(),
        map(fields => {
          return fields.reduce((result, field) => ({
            ...result, [`additional.${field.name}`]: { name: field.label, type: 'string' }
          }), {});
        })
      )
    ]);

    return queries.pipe(
      map(([couriers, statuses, senders, goods, additions]) => {
        return {
          'comment': {
            name: 'Комментарий',
            type: 'string'
          },
          'shippingCost': {
            name: 'Цена доставки',
            type: 'number'
          },
          'lgStandingData.outProjectId': {
            name: 'Id проекта',
            type: 'number'
          },
          'lgStandingData.outOrderId': {
            name: 'Id заказа',
            type: 'number'
          },
          'cart.goods[*].good.id': {
            name: 'Товары',
            type: 'entity',
            operators: ['=', 'in', 'not in'],
            options: goods
          },
          'cart.goods[*].good.name': {
            name: 'Наименование товара',
            type: 'string'
          },
          'cart.goods[*].good.alias': {
            name: 'Артикул товара',
            type: 'string'
          },
          'cart.totalQuantity': {
            name: 'Кол-во товара',
            type: 'string'
          },
          'cart.goods[*].quantity': {
            name: 'Кол-во товара (deprecated)',
            type: 'string'
          },
          'recipient.phone': {
            name: 'Телефон',
            type: 'string',
            operators: ['=', 'in', 'not in']
          },
          'recipient.postcode': {
            name: 'Почтовый индекс получателя',
            type: 'string'
          },
          'recipient.region': {
            name: 'Регион получателя',
            type: 'string'
          },
          'recipient.city': {
            name: 'Город получателя',
            type: 'string'
          },
          'recipient.address': {
            name: 'Адрес получателя',
            type: 'string'
          },
          'status.id': {
            name: 'Статус',
            type: 'entity',
            operators: ['=', 'in', 'not in'],
            options: statuses
          },
          'status.name': {
            name: 'Имя статуса',
            type: 'string',
            operators: ['=', 'in', 'not in']
          },
          'logistic.courier.id': {
            name: 'Курьерские службы',
            type: 'entity',
            operators: ['=', 'in', 'not in'],
            options: couriers
          },
          'logistic.courier.name': {
            name: 'Наименование курьерской службы',
            type: 'entity',
            operators: ['=', 'in', 'not in'],
            options: couriers
          },
          'logistic.sender.id': {
            name: 'Отправитель',
            type: 'entity',
            operators: ['=', 'in', 'not in'],
            options: senders
          },
          'createdAt': {
            name: 'Дата создания',
            type: 'datetime',
            operators: ['=', '!=', '>', '>=', '<', '<=']
          },
          'updatedAt': {
            name: 'Дата обновления',
            type: 'datetime',
            operators: ['=', '!=', '>', '>=', '<', '<=']
          },
          'logistic.createdAt': {
            name: 'Дата выгрузки',
            type: 'datetime',
            operators: ['=', '!=', '>', '>=', '<', '<=']
          },
          'boughtOutAt': {
            name: 'Дата выкупа',
            type: 'datetime',
            operators: ['=', '!=', '>', '>=', '<', '<=']
          },
          'returnedAt': {
            name: 'Дата возврата',
            type: 'datetime',
            operators: ['=', '!=', '>', '>=', '<', '<=']
          },
          'statusChangeAt': {
            name: 'Дата смены статуса',
            type: 'datetime',
            operators: ['=', '!=', '>', '>=', '<', '<=']
          },
          ...additions
        };
      }),
      filter(fieldMap => !isEqual(fieldMap, this._fieldMap)),
      tap(fieldMap => this._fieldMap = fieldMap)
    );
  }

}
