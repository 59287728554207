import { Component, Input } from '@angular/core';
import { CartOutput, OrderedGoodOutput, OrderOutput } from 'core/http/project';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromGoods from 'app/store/project/goods/goods.selectors';
import { Uuid } from 'shared/common/types';
import { map } from 'rxjs/operators';

type PrettyGood = OrderedGoodOutput & { name$: Observable<string> };
type PrettyCart = CartOutput & { goods: PrettyGood[] };
type PrettyOrder = OrderOutput & { cart: PrettyCart };

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {

  private _skeletonView = false;
  private _prettyOrder = {} as PrettyOrder;

  get prettyOrder(): PrettyOrder {
    return this._prettyOrder;
  }

  @Input()
  set order(value: OrderOutput) {
    const prettyGoods: PrettyGood[] = value.cart.goods.map(g => {
      const name$ = this.getGoodName$(g.goodId);

      return { ...g, name$ };
    });

    this._prettyOrder = { ...value, cart: { ...value.cart, goods: prettyGoods } };
  }

  get skeletonView(): boolean {
    return this._skeletonView;
  }

  @Input()
  set skeletonView(value: boolean) {
    this._skeletonView = value;
  }

  constructor(private store$: Store) { }

  getGoodName$(id: Uuid): Observable<string> {
    return this.store$.select(fromGoods.oneGood, { id: id })
      .pipe(
        map(item => item ? item.name : 'Не найдено')
      );
  }
}
