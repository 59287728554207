import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Dic } from 'shared/common/types';
import { DynamicField } from 'shared/modules/dynamic-form/field-types';
import { flatten } from '@angular/compiler';

// Берет DynamicField[] и создает из них FormGroup к которому будем привязываться в шаблоне
export const toFormGroup = (fields: DynamicField[]): FormGroup => {
  const controls = fields
    .reduce((form, field) => ({ ...form, [field.name]: toControl(field) }), {} as Dic<AbstractControl>);

  return new FormGroup(controls);
};

// Берет DynamicField и превращает его в AbstractControl, навешивает валидацию и прочее...
const toControl = (field: DynamicField): AbstractControl => {
  switch (field.type) {
    case 'FILE':
      return new FormControl(field.value);

    case 'NUMERIC':
      return new FormControl(field.value);

    case 'SHORT_TEXT':
      return new FormControl(field.value);

    case 'SWITCHABLE':
      return new FormControl(field.value);

    case 'RULE_SET':
      return new FormControl(field.value);

    case 'ADDITIONS_INDEX':
      return new FormControl(field.value);

    case 'ENTITY':
      return new FormControl(field.id);

    case 'SELECTABLE':
      return new FormControl(field.selected);

    case 'SUBSET':
      return new FormArray(
        mapSubset(field.schema, field.values)
          .map(toFormGroup)
      );

    case 'GROUP':
      return toFormGroup(mapGroup(field.schema, field.values));
  }
};

// Берет schema и forms из SubsetField и превращает это все в DynamicField[][]
export const mapSubset = (schema: DynamicField[], forms: Array<Dic<any>>, toDisplay?: boolean): DynamicField[][] => {
  // Индексируем schema по name полю
  const indexedSchema = schema.reduce((result, field) => ({ ...result, [field.name]: field }), {} as Dic<DynamicField>);

  // Перегоняем forms: Array<Dic<any>> в schema: DynamicField[]
  return forms.map(form => {
    // Берем все ключи из формы
    const names = Object.keys(form);

    // И перегоняем их в DynamicField[]
    return names.map(name => {
      const field = indexedSchema[name];

      switch (field.type) {
        case 'ADDITIONS_INDEX':
          return { ...field, value: form[name] };

        case 'FILE':
          return { ...field, value: form[name] };

        case 'NUMERIC':
          return { ...field, value: form[name] };

        case 'SELECTABLE':
          return { ...field, selected: form[name] };

        case 'SWITCHABLE':
          return { ...field, value: form[name] };

        case 'RULE_SET':
          return { ...field, value: form[name] };

        case 'ENTITY':
          return { ...field, id: form[name] };

        case 'SHORT_TEXT':
          return { ...field, value: form[name] };

        case 'SUBSET':
          return { ...field, values: toDisplay ? flatten(mapSubset(field.schema, form[name])) : form[name] };

        case 'GROUP':
          return { ...field, values: mapGroup(field.schema, field.values) };
      }
    });
  });
};

export const mapGroup = (schema: DynamicField[], values: Dic<any>): DynamicField[] => {
  return schema.map(item => ({ ...item, value: values[item.name] }));
};
