import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, switchMap } from 'rxjs/operators';
import { ExtensionService } from 'core/http/root';
import { forkJoin, of } from 'rxjs';
import * as SimpleExtensionsActions from './simple-extensions.actions';
import * as NotifyEffects from 'app/store/common-effects/notifier.effects';

@Injectable()
export class SimpleExtensionsEffects {

  getSimpleExtensions$ = createEffect(() => this.actions$.pipe(
    ofType(SimpleExtensionsActions.getSimpleExtensions),
    switchMap(() => this.extensionService.getAllSimpleExtensions()),
    switchMap(extensions => [
      SimpleExtensionsActions.saveSimpleExtensions({ extensions }),
      SimpleExtensionsActions.markSimpleExtensionsLoaded()
    ])
  ));

  updateProjectExtensions$ = createEffect(() => this.actions$.pipe(
    ofType(SimpleExtensionsActions.updateExtensionProjects),
    exhaustMap(({ id, projects }) => forkJoin({
      projects: this.extensionService.updateExtensionProjects(id, projects),
      id: of(id)
    })),
    switchMap(data => [
      SimpleExtensionsActions.updateExtensionProjectsInStore({
        update: { id: data.id, changes: { projects: data.projects } }
      }),
      NotifyEffects.notify({ message: 'Проекты обновлены' })
    ])
  ));

  constructor(
    private actions$: Actions,
    private extensionService: ExtensionService
  ) {}

}
