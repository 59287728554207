import { FormControl, FormGroup } from '@angular/forms';

// for backward compatibility
const shortRelativeSyntaxRegex = /^(?<sign>[+\-])(?<value>\d+)\s(?<unit>months|days|minutes)$/;

// actual syntax format
const fullRelativeSyntaxRegex = /^(?<sign>[+\-])(?<value>\d+)\s(?<unit>months|days|minutes)\s(?<time>\d{2}:\d{2}:\d{2})$/;

export type RelativeDate = {
  time: string;
  sign: '+' | '-';
  value: string;
  unit: 'days' | 'months' | 'years'
};

export const defaultRelativeDate: RelativeDate = {
  time: '00:00:00',
  sign: '-',
  value: '0',
  unit: 'days',
};

const getRelativeValues = (parsed: { [name: string]: string }): RelativeDate => {
  return {
    sign: (parsed.sign ?? '-') as '+' | '-',
    value: parsed.value ?? '0',
    unit: (parsed.unit ?? 'days') as 'days' | 'years' | 'months',
    time: parsed.time ?? '00:00:00'
  };
};

const tryParseRelativeDate = (value: string) => {
  const fromFull = fullRelativeSyntaxRegex.exec(value)?.groups;

  if (undefined !== fromFull) {
    return getRelativeValues(fromFull);
  }

  const fromShort = shortRelativeSyntaxRegex.exec(value)?.groups;

  if (undefined !== fromShort) {
    return getRelativeValues(fromShort);
  }

  return undefined;
};

// Sometimes seconds doesn't come
export const fixSecondsInTime = (time: string) => {
  return /^\d{2}:\d{2}$/.test(time) ? `${time}:00` : time;
};

export const formForRelativeDate = (value: string) => {
  const parsed = tryParseRelativeDate(value) ?? defaultRelativeDate;

  return new FormGroup({
    sign: new FormControl(parsed.sign),
    value: new FormControl(parsed.value),
    unit: new FormControl(parsed.unit),
    time: new FormControl(parsed.time),
  });
};
