import { Rule } from 'angular2-query-builder';
import { Component, Input, OnChanges } from '@angular/core';
import { BACKSPACE, COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import * as _ from 'lodash';

@Component({
  selector: 'app-chip-number-query-input',
  templateUrl: './chip-number-query-input.component.html',
  styleUrls: ['./chip-number-query-input.component.scss']
})
export class ChipNumberQueryInputComponent implements OnChanges {

  private _rule?: Rule;
  private _onChange?: () => void;
  private _separators = [ENTER, COMMA];

  ngOnChanges(): void {
    // Если значение rule уже массив из чисел, то ничего не будем делать.
    if (Array.isArray(this.rule.value) && this.rule.value.every(n => typeof n === 'number')) {
      return;
    }

    // Иначе проинициализируем пустым массивом.
    this.rule.value = Array<number>();
  }

  get separators(): number[] {
    return this._separators;
  }

  get rule(): Rule {
    if (!this._rule) {
      throw new Error(`Required input 'rule' is not specified`);
    }

    return this._rule;
  }

  @Input()
  set rule(rule: Rule) {
    this._rule = rule;
  }

  get onChange(): () => void {
    if (!this._onChange) {
      throw new Error(`Required input 'onChange' is not specified`);
    }

    return this._onChange;
  }

  @Input()
  set onChange(onChange: () => void) {
    this._onChange = onChange;
  }

  /**
   * @param ctrlPressed
   * @param keyCode
   *
   * @return Возвращает true если введенный символ цифра или комбуха из хоткеев. Иначе false.
   */
  isTrustedKey(ctrlPressed: boolean, keyCode: number): boolean {
    if (BACKSPACE === keyCode || SPACE === keyCode) {
      return true;
    }

    const char = String.fromCharCode(keyCode);

    // Разрешения на Ctrl+C/A/V/X
    const ctrlActions = () => ctrlPressed && ['C', 'A', 'V', 'X'].includes(char.toUpperCase());
    // Ввод разрешен только для циферок
    const isNumber = () => !isNaN(char as any);

    return ctrlActions() || isNumber();
  }

  remove(value: number): void {
    const values: number[] = this.rule.value;
    this.rule.value = values.filter(v => v !== value);

    this.onChange();
  }

  append(mode: 'single' | 'many', input: HTMLInputElement): void {
    const values = mode === 'many'
      ? input.value.split(' ')
      : [input.value];

    this.appendValues(values);
    input.value = '';
  }

  paste(event: ClipboardEvent): void {
    event.preventDefault();

    if (!event.clipboardData) {
      return;
    }

    const values = event.clipboardData
      .getData('text')
      .split(/\n+|\s+/);

    this.appendValues(values);
  }

  appendValues(values: string[]): void {
    const numericValues = values.map(v => parseInt(v, 10))
      .filter(n => !isNaN(n));

    const oldValues: number[] = this.rule.value;
    this.rule.value = _.uniq(oldValues.concat(numericValues));

    this.onChange();
  }

}

