import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicField } from 'shared/modules/dynamic-form/field-types';
import { FieldMap } from 'angular2-query-builder/dist/components';
import { GoodOutput, SenderOutput, StatusOutput, StorehouseOutput } from 'core/http/project';
import { getFormGroup } from 'shared/modules/addition-fields/addition-add-dialog/get-form-group';
import { Observable } from 'rxjs';

export interface AdditionDialogDataFields {
  fieldMap$: Observable<FieldMap>;
  goods$: Observable<GoodOutput[]>;
  senders$: Observable<SenderOutput[]>;
  statuses$: Observable<StatusOutput[]>;
  storehouses$: Observable<StorehouseOutput[]>;
  additionFields: DynamicField[];
}

@Component({
  selector: 'app-addition-add-dialog',
  templateUrl: './addition-add-dialog.component.html',
  styleUrls: ['./addition-add-dialog.component.scss']
})
export class AdditionAddDialogComponent {

  private _types = [
    'ADDITIONS_INDEX',
    'ENTITY',
    'FILE',
    'NUMERIC',
    'RULE_SET',
    'SELECTABLE',
    'SHORT_TEXT',
    'SUBSET',
    'SWITCHABLE',
    'GROUP'
  ];
  private _form = this.fb.group({
    type: this.fb.control('NUMERIC'),
    name: this.fb.control(null, Validators.required),
    description: this.fb.control(null, Validators.required),
    value: this.fb.control(0)
  });

  private _dialogData: AdditionDialogDataFields;

  get form(): FormGroup {
    return this._form;
  }

  set form(f: FormGroup) {
    this._form = f;
  }

  get types(): string[] {
    return this._types;
  }

  get dialogData(): AdditionDialogDataFields {
    return this._dialogData;
  }

  get invalidName(): boolean {
    const name = this.form.controls.name.value;
    return this.data.additionFields.some(field => field.name === name);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AdditionDialogDataFields,
    private fb: FormBuilder
  ) {
    this._dialogData = data;
  }

  changeFormGroup(type: DynamicField['type']): void {
    this.form = getFormGroup(type, this.fb, this.form.controls.name.value, this.form.controls.description.value);
  }

}
