import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { RegistrationService } from 'core/http/lg-logistic-rest-api/Auth/registration.service';
import { Router } from '@angular/router';
import { ObserverFactory } from 'shared/common/observers';
import { LanguageService } from 'core/services/language.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent {

  forgotForm = new FormGroup({
    email: new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.email
      ])
    )
  });

  constructor(
    private router: Router,
    private languageService: LanguageService,
    private registrationService: RegistrationService,
    private observer: ObserverFactory
  ) {}

  sendRecovery(): void {
    if (!this.forgotForm.valid) {
      return;
    }

    const input = {
      redirectUrl: environment.FORGOT_URL,
      email: this.forgotForm.value.email,
      locale: this.languageService.currentLanguage
    };

    this.registrationService.sendRecovery(input).subscribe(
      this.observer.withHook({
        successMsg: 'Ссылка для восстановления пароля отправлена на Ваш email',
        onSuccess: () => {
          this.router.navigate(['/', 'auth', 'login']);
        }
      })
    );
  }

}
