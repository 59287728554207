import { HttpClient, HttpParams } from '@angular/common/http';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Pagination } from 'core/types/pagination';
import { forkJoin, Observable } from 'rxjs';
import { Criteria } from 'shared/criteria/Criteria';
import { map } from 'rxjs/operators';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { CourierOutput, OrderOutput, SenderOutput } from 'core/http/project';
import { Uuid } from 'shared/common/types';
import { DataItem, Series } from '@swimlane/ngx-charts';
import { uniqBy } from 'lodash';
import * as moment from 'moment';
import {
  getTermData,
  MonthCutOutput,
  MonthsDebtsInput,
  PrettyMonthCut
} from 'core/http/lg-logistic-rest-api/Auth/project.service';
import { LoggedUserOutput } from 'core/http/lg-logistic-rest-api/Auth/token.service';
import { selectFirst } from 'core/utils/rx-common';
import { Store } from '@ngrx/store';
import { currentProject } from 'app/store/project/current-project/current-project.selectors';

export interface ClaimOrderOutput {
  id: string;
  reimbursementAmount: number;
  originOrder: OrderOutput;
}

export enum ClaimStatus {
  IN_PROCESS = 0,
  ACCEPTED = 1,
  DECLINED = 2,
  PAID = 3
}

export interface ClaimOutput {
  id: string;
  sequenceId: number;
  externalId: string;
  sender: SenderOutput;
  courier: CourierOutput;
  user: LoggedUserOutput;
  project: string;
  status: ClaimStatus;
  createdAt: string;
  orderTotalPrice: number;
  orderCount: number;
  totalReimbursementAmount: number;
  comment: string | null;
}

export interface CreateClaimInput {
  comment: string;
  orderFilter: string;
}

export interface ClaimCountOutput {
  inProcess: number;
  accepted: number;
  declined: number;
  paid: number;
}

export interface ClaimOrdersCountOutput {
  type: 1 | 2 | 3;
  count: number;
  createdAt: string;
}

export interface DebtResponse {
  courierId: string;
  debts: Array<{
    unloadedAt: string;
    debt: number;
    count: number
  }>;
}

export interface GetCourierDebtInput {
  createdAt: string;
  unloadedFrom: string;
  unloadedTo: string;
}

export interface TransferredUnpaidCountOutput {
  claimId: Uuid;
  unpaidCount: number;
}

@ProjectInjectable()
export class ReconciliationService {

  constructor(
    private http: HttpClient,
    private store$: Store
  ) { }

  getMonthDebts(input: MonthsDebtsInput): Observable<PrettyMonthCut[]> {
    const params = new HttpParams({
      fromObject: { ...input }
    });

    return forkJoin({
      monthCuts: this.http.get<MonthCutOutput[]>(apiUrl('/project/analytic/debt'), { params }),
      projectId: this.store$.pipe(selectFirst(currentProject), map(p => p.id))
    })
      .pipe(
        map(debt => getTermData(debt, this.store$))
      );
  }

  getClaimCount(): Observable<ClaimCountOutput> {
    return this.http.get<ClaimCountOutput>(apiUrl(`/project/claim-count`));
  }

  getAllClaims(criteria: Criteria): Observable<Pagination<ClaimOutput[]>> {
    return this.http.get<Pagination<ClaimOutput[]>>(apiUrl(`/project/claims`, criteria));
  }

  getClaim(claimId: string): Observable<ClaimOutput> {
    return this.http.get<ClaimOutput>(apiUrl(`/project/claim/${claimId}`));
  }

  getOrdersToClaim(criteria?: Criteria): Observable<Pagination<OrderOutput[]>> {
    return this.http.get<Pagination<OrderOutput[]>>(apiUrl(`/project/orders-to-claim`, criteria));
  }

  getTransferredUnpaidCount(params: string): Observable<TransferredUnpaidCountOutput[]> {
    return this.http.get<TransferredUnpaidCountOutput[]>(apiUrl(`/project/transferred-unpaid-count?${params}`));
  }

  createClaim(input: CreateClaimInput): Observable<ClaimOutput> {
    return this.http.post<ClaimOutput>(apiUrl(`/project/claim`), input);
  }

  removeClaim(claimId: Uuid): Observable<void> {
    return this.http.delete<void>(apiUrl(`/project/claim/${claimId}`));
  }

  getClaimOrders(claimId: string, criteria?: Criteria): Observable<Pagination<OrderOutput[]>> {
    return this.http.get<Pagination<OrderOutput[]>>(apiUrl(`/project/claim/${claimId}/claimed-orders`, criteria));
  }

  getClaimOrdersCount(filter?: string): Observable<Series[]> {
    return this.http.get<ClaimOrdersCountOutput[]>(
      apiUrl(`/project/analytic/claim?filter=${filter} ordering createdAt:asc`))
      .pipe(
        map(claimsData => {
          const series = claimsData.map(claim => {
            const formattedDate = moment(claim.createdAt).format('YYYY-MM-DD');

            return {
              name: formattedDate,
              series: claimsData
                .filter(item => moment(item.createdAt).format('YYYY-MM-DD') === formattedDate)
                .map(item => ({ value: item.count, name: item.type } as DataItem))
            } as Series;
          });

          return uniqBy(series, 'name');
        })
      );
  }

  changeClaimedAmount(claimId: string, reimbursementAmount: number, criteria: string): Observable<ClaimOrderOutput> {
    return this.http.patch<ClaimOrderOutput>(apiUrl(`/project/claim/${claimId}/claimed-amount`),
      { reimbursementAmount, criteria });
  }

  changeClaimStatus(claimId: string, status: ClaimStatus): Observable<never> {
    return this.http.patch<never>(apiUrl(`/project/claim/${claimId}/status`), { status });
  }

  changeComment(claimId: string, comment: string): Observable<never> {
    return this.http.patch<never>(apiUrl(`/project/claim/${claimId}/comment`), { comment });
  }

  getProjectCouriersDebts(input: GetCourierDebtInput): Observable<Series[]> {
    const params = new HttpParams({
      fromObject: { ...input }
    });

    return this.http
      .get<DebtResponse[]>(apiUrl(`/project/debts`), { params })
      .pipe(
        map(debt => {
          return debt.map(debts => ({
            name: debts.courierId,
            series: debts.debts.map(item => ({
              value: item.debt,
              name: new Date(item.unloadedAt),
              extra: item.count
            } as DataItem))
          } as Series));
        })
      );
  }

  deleteOrderFromClaim(claimId: Uuid, criteria: string): Observable<Object> {
    return this.http.post<Object>(apiUrl(`/project/claim/${claimId}/order-delete`), { orderFilter: criteria });
  }

}
