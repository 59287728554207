import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, switchMap, tap } from 'rxjs/operators';
import { CourierService } from 'core/http/dev';
import * as CouriersActions from './couriers.actions';
import { LoaderService } from 'core/services/loader.service';
import { forkJoin } from 'rxjs';
import * as NotifyActions from 'app/store/common-effects/notifier.effects';
import * as ProjectRouterActions from 'app/store/common-effects/router.effects';

@Injectable()
export class CouriersEffects {

  getCouriers$ = createEffect(() => this.actions$.pipe(
    ofType(CouriersActions.getDevCouriers),
    switchMap(() => this.courierService.getAllCouriers()),
    switchMap(couriers => [
      CouriersActions.saveCouriers({ couriers }),
      CouriersActions.getClasses()
    ])
  ));

  createCourier$ = createEffect(() => this.actions$.pipe(
    ofType(CouriersActions.createCourier),
    tap(() => this.loader.begin()),
    exhaustMap(({ input }) => this.courierService.createCourier(input)),
    tap(() => this.loader.end()),
    switchMap(courier => [
      CouriersActions.addCourierInStore({ courier }),
      NotifyActions.notify({ message: 'Курьерская служба создана' }),
      ProjectRouterActions.navigateToDev({ path: ['couriers'] })
    ])
  ));

  updateCourier$ = createEffect(() => this.actions$.pipe(
    ofType(CouriersActions.updateCourier),
    tap(() => this.loader.begin()),
    exhaustMap(({ id, input }) => this.courierService.updateCourier(id, input)),
    tap(() => this.loader.end()),
    switchMap(courier => [
      CouriersActions.updateCourierInStore({ update: { id: courier.id, changes: courier } }),
      NotifyActions.notify({ message: 'Курьерская служба обновлена' }),
      ProjectRouterActions.navigateToDev({ path: ['couriers'] })
    ])
  ));

  getClasses$ = createEffect(() => this.actions$.pipe(
    ofType(CouriersActions.getClasses),
    switchMap(() => forkJoin({
      integrationClasses: this.courierService.getIntegrationClasses(),
      trackerClasses: this.courierService.getTrackerClasses(),
      cancellerClasses: this.courierService.getCancellerClasses()
    })),
    switchMap(classes => [
      CouriersActions.saveClasses({ ...classes }),
      CouriersActions.markCouriersLoaded()
    ])
  ));

  constructor(
    private actions$: Actions,
    private courierService: CourierService,
    private loader: LoaderService
  ) { }

}
