import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { Uuid } from 'shared/common/types';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Criteria } from 'shared/criteria/Criteria';
import { DynamicField } from 'shared/modules/dynamic-form/field-types';

export interface ExtensionOutput {
  id: Uuid;
  name: string;
}

export interface ExtensionSettingsOutput {
  extension: ExtensionOutput;
  settings: DynamicField[];
}

@ProjectInjectable()
export class ExtensionService {

  constructor(private http: HttpClient) {}

  getAllExtensions(criteria?: Criteria): Observable<ExtensionOutput[]> {
    return this.http.get<ExtensionOutput[]>(apiUrl(`/project/extensions`, criteria));
  }

  getExtensionSettings(extensionId: Uuid): Observable<ExtensionSettingsOutput> {
    return this.http.get<ExtensionSettingsOutput>(apiUrl(`/project/extension/${extensionId}`));
  }

  updateExtensionSettings(extensionId: Uuid, settings: { [prop: string]: any }): Observable<ExtensionSettingsOutput> {
    return this.http.put<ExtensionSettingsOutput>(apiUrl(`/project/extension/${extensionId}`), settings);
  }

}
