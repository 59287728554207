import {
  AfterContentInit,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { ListData, ListEagerProviderDirective, ListPaginatedProviderDirective } from 'shared/modules/list/providers';
import { Ordering } from 'shared/criteria/Criteria';
import { Subject, Subscription } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { FilterSource } from 'shared/modules/table/table-utils/table-directives';

@Directive({
  selector: 'app-list-container'
})
export class ListContainerDirective {
}

@Directive({
  selector: 'app-list-head'
})
export class ListHeadDirective {
}

@Directive({
  selector: 'app-list-body'
})
export class ListBodyDirective {
}

@Directive({
  selector: 'app-list-actions'
})
export class ListActionsDirective {
}

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent<T> implements AfterContentInit, OnDestroy {

  @ContentChild(ListEagerProviderDirective)
  eagerProvider?: ListEagerProviderDirective<T>;

  @ContentChild(ListPaginatedProviderDirective)
  paginatedProvider?: ListPaginatedProviderDirective<T>;

  private _pageChanged = new EventEmitter<PageEvent>();
  private _loading = false;
  private _data = Array<T>();
  private _reloader = new Subject();
  private _filter = '';
  private _ordering?: Ordering;
  private _noDataText = 'Данные отсутствуют';
  private _virtualScroll = false;
  private _subscription = new Subscription();

  get loading(): boolean {
    return this._loading;
  }

  get reloader(): Subject<any> {
    return this._reloader;
  }

  @Input()
  set reloader(value: Subject<any>) {
    this._reloader = value;
  }

  get data(): Array<T> {
    return this._data;
  }

  @ViewChild(FilterSource)
  private _filterSource!: FilterSource;

  get filterSource(): FilterSource {
    return this._filterSource;
  }

  @Input()
  set filterSource(value: FilterSource) {
    this._filterSource = value;
  }

  get filter(): string {
    return this._filter;
  }

  set filter(filter: string) {
    this._filter = filter;
  }

  get ordering(): Ordering | undefined {
    return this._ordering;
  }

  @Input()
  set ordering(ordering: Ordering | undefined) {
    this._ordering = ordering;
  }

  get noDataText(): string {
    return this._noDataText;
  }

  @Input()
  set noDataText(value: string) {
    this._noDataText = value;
  }

  get virtualScroll(): boolean {
    return this._virtualScroll;
  }

  @Input()
  set virtualScroll(value: boolean) {
    this._virtualScroll = value;
  }

  @Output()
  get pageChanged(): EventEmitter<PageEvent> {
    return this._pageChanged;
  }

  ngAfterContentInit(): void {
    this.paginatedProvider?.pageChanged.subscribe((e: PageEvent) => this.pageChanged.emit(e));

    const loader = this.eagerProvider || this.paginatedProvider;

    if (!loader) {
      throw new Error('Loader is not specified');
    }

    loader.loading
      .subscribe(() => this._loading = true);

    loader.loaded
      .subscribe((data: ListData<T>) => {
        this._data = data.items;
        this._loading = false;
      });

    if (this.filterSource) {
      this.filterSource.filterChanged
        .subscribe((filter: string) => {
          this.filter = filter;

          this.reloader.next();
        });
    }

    if (loader instanceof ListEagerProviderDirective) {
      this._subscription = this.reloader
        .subscribe(() => loader.loadAll(this.filter, this.ordering));
    }

    if (loader instanceof ListPaginatedProviderDirective) {
      this._subscription = this.reloader
        .subscribe(() => loader.loadPage(null, this.filter, this.ordering));
    }

    !this.filterSource && this.reloader.next();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  trackByIndex(i: number): number {
    return i;
  }

}
