import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GrouperDialogComponent } from 'shared/dialogs/grouper-dialog/grouper-dialog.component';
import { PoolOutput } from 'core/http/project/document/document-pool.service';
import { PrintingArgs, SinglePrintingArgs } from 'shared/common/background-task-runner';
import { Buttons, ConfirmActionService } from 'shared/common/confirm-action.service';
import { DocumentOutput } from 'core/http/project';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { filterNil } from 'core/utils/rx-common';

type DocumentGroup = { group: string, documents: DocumentOutput[] };

@Component({
  selector: 'app-printing-button',
  templateUrl: './printing-button.component.html',
  styleUrls: ['./printing-button.component.scss']
})
export class PrintingButtonComponent {

  private _orderCount = 0;
  private _isIconButton = false;
  private _documentGroups = Array<DocumentGroup>();
  private _pools = Array<PoolOutput>();
  private _withoutBadge = false;
  private _disabled = false;

  @Output() clicked = new EventEmitter<PrintingArgs>();

  constructor(
    private dialog: MatDialog,
    private confirmation: ConfirmActionService
  ) { }

  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
  }

  get disabled(): boolean {
    return this._disabled || (this._documentGroups.length === 0 && this._pools.length === 0);
  }

  @Input()
  set orderCount(c: number) {
    this._orderCount = c;
  }

  get orderCount(): number {
    return this._orderCount;
  }

  @Input()
  set isIconButton(is: boolean) {
    this._isIconButton = is;
  }

  get isIconButton(): boolean {
    return this._isIconButton;
  }

  get documentGroups(): DocumentGroup[] {
    return this._documentGroups;
  }

  get pools(): PoolOutput[] {
    return this._pools;
  }

  get withoutBadge(): boolean {
    return this._withoutBadge;
  }

  @Input()
  set withoutBadge(value: boolean) {
    this._withoutBadge = value;
  }

  @Input()
  set toPrint(data: [DocumentOutput[], PoolOutput[]]) {
    const [documents, pools] = data;

    this._pools = pools;
    this._documentGroups = _.chain(documents)
      .groupBy(d => (d.courier && d.courier.name) || 'Дополнительно')
      .map((d, g) => ({ documents: d, group: g }))
      .value();
  }

  @Input()
  set pools(value: PoolOutput[]) {
    this._pools = value;
  }

  @Input()
  set documents(value: DocumentOutput[]) {
    this._documentGroups = _.chain(value)
      .groupBy(d => (d.courier && d.courier.name) || 'Дополнительно')
      .map((d, g) => ({ documents: d, group: g }))
      .value();
  }

  onSinglePrinting(document: DocumentOutput): void {
    this.dialog
      .open(GrouperDialogComponent, { minWidth: 500 })
      .afterClosed()
      .pipe(filterNil())
      .subscribe(groupers => {
        const data: SinglePrintingArgs = {
          type: 'SINGLE_PRINTING',
          document,
          groupers
        };
        this.clicked.emit(data);
      });
  }

  onPoolPrinting(pool: PoolOutput): void {
    this.confirmation.ask({
      message: {
        content: 'ORDERS.COMMON.PRINTING_BUTTON.confirm_pool_printing_message',
        args: { pool: pool.name, count: this.orderCount }
      },
      buttons: [
        Buttons.warn('ORDERS.COMMON.PRINTING_BUTTON.cancel_printing_button'),
        Buttons.primary('ORDERS.COMMON.PRINTING_BUTTON.run_printing_button', () => {
          this.clicked.emit({ type: 'POOL_PRINTING', pool });
        })
      ]
    });
  }

}
