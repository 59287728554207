import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { DocumentPoolService } from 'core/http/project';
import { LoaderService } from 'core/services/loader.service';
import { CompletedEntityStateService } from 'app/store/completed-entity-state/completed-entity-state.service';
import { createProperties } from 'app/store/completed-entity-state/completed-entity-state';
import * as PoolsActions from './pools.actions';
import * as NotifierActions from 'app/store/common-effects/notifier.effects';
import * as ProjectRouterActions from '../../common-effects/router.effects';

@Injectable()
export class PoolsEffects {

  private readonly properties = createProperties(
    PoolsActions.setLoading,
    PoolsActions.markPoolsLoaded,
    PoolsActions.setError,
    () => this.poolService.getAllDocumentPools(),
    'documentPools'
  );

  getPools$ = createEffect(() => this.actions$.pipe(
    ofType(PoolsActions.getPools),
    exhaustMap(() => this.completedEntityStateService.get(this.properties)),
    map(pools => PoolsActions.savePools({ pools }))
  ));

  createPool$ = createEffect(() => this.actions$.pipe(
    ofType(PoolsActions.createPool),
    tap(() => this.loader.begin()),
    exhaustMap(input => this.poolService.createPool(input)),
    tap(() => this.loader.end()),
    switchMap(pool => [
      PoolsActions.addPoolInStore(pool),
      NotifierActions.notify({ message: 'Пул документов создан' }),
      ProjectRouterActions.navigateToProject({ path: ['setting', 'pools'] })
    ])
  ));

  updatePool$ = createEffect(() => this.actions$.pipe(
    ofType(PoolsActions.updatePool),
    tap(() => this.loader.begin()),
    exhaustMap(({ id, input }) => this.poolService.updatePool(id, input)),
    tap(() => this.loader.end()),
    switchMap(pool => [
      PoolsActions.updatePoolInStore({ update: { id: pool.id, changes: pool } }),
      NotifierActions.notify({ message: 'Пул документов обновлен' }),
      ProjectRouterActions.navigateToProject({ path: ['setting', 'pools'] })
    ])
  ));

  removePool$ = createEffect(() => this.actions$.pipe(
    ofType(PoolsActions.removePool),
    tap(() => this.loader.begin()),
    exhaustMap(({ id }) => this.poolService.removePool(id).pipe(mapTo(id))),
    tap(() => this.loader.end()),
    switchMap(id => [
      PoolsActions.removePoolFromStore({ id }),
      NotifierActions.notify({ message: 'Пул документов удален' })
    ])
  ));

  constructor(
    private actions$: Actions,
    private poolService: DocumentPoolService,
    private loader: LoaderService,
    private completedEntityStateService: CompletedEntityStateService
  ) { }

}
