import { RootInjectable } from 'core/http/root/root-services.module';
import { HttpClient } from '@angular/common/http';
import { Criteria } from 'shared/criteria/Criteria';
import { Observable } from 'rxjs';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { CourierOutput } from 'core/http/root/courier.service';
import { ProjectOutput } from 'core/http/dev';

export interface DocumentOutput {
  id: string;
  name: string;
  courier: CourierOutput;
  projects: ProjectOutput[];
  type: 'POST_BLANK' | 'REVISION' | 'SUMMARY';
}

@RootInjectable()
export class DocumentService {

  constructor(private http: HttpClient) {}

  getAllDocuments(criteria?: Criteria): Observable<DocumentOutput[]> {
    return this.http.get<DocumentOutput[]>(apiUrl(`/root/extensions/document`, criteria));
  }

}
