import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecipientContactComponent } from 'shared/modules/order-table/recipient-primary/recipient-contact.component';
import { TrackingComponent } from 'shared/modules/order-table/tracking/tracking.component';
import { OrderIdComponent } from 'shared/modules/order-table/order-id/order-id.component';
import { CurrentStatusComponent } from 'shared/modules/order-table/current-status/current-status.component';
import { OrderColumnsContainerComponent } from 'shared/modules/order-table/order-columns-container/order-columns-container.component';
import { MaterialModule } from 'shared/modules/material/material.module';
import { TypographyModule } from 'shared/modules/typography/typography.module';
import { CartComponent } from 'shared/modules/order-table/cart/cart.component';
import { UnloadingViewComponent } from 'shared/modules/order-table/unloading/unloading-view.component';
import { TableModule } from 'shared/modules/table/table.module';
import { PipesModule } from 'shared/modules/pipes/pipes.module';
import { PrintingButtonComponent } from 'shared/modules/order-table/printing-button/printing-button.component';
import { AdditionalFieldsComponent } from 'shared/modules/order-table/additional-fields/additional-fields.component';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'shared/modules/directives/directives.module';
import { AdditionalFieldsSearchComponent } from './additional-fields-search/additional-fields-search.component';
import { RouterModule } from '@angular/router';
import { AngularModule } from 'shared/modules/angular/angular.module';
import { OrderDatesComponent } from './order-dates/order-dates.component';
import { ControlsModule } from 'shared/modules/controls/controls.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { StorehouseViewComponent } from './storehouse-view/storehouse-view.component';

@NgModule({
  declarations: [
    RecipientContactComponent,
    TrackingComponent,
    OrderIdComponent,
    CurrentStatusComponent,
    UnloadingViewComponent,
    OrderColumnsContainerComponent,
    CartComponent,
    PrintingButtonComponent,
    AdditionalFieldsComponent,
    AdditionalFieldsSearchComponent,
    OrderDatesComponent,
    StorehouseViewComponent
  ],
  imports: [
    CommonModule,
    AngularModule,
    MaterialModule,
    TypographyModule,
    TableModule,
    PipesModule,
    DirectivesModule,
    TranslateModule,
    RouterModule,
    ControlsModule,
    NgxSkeletonLoaderModule
  ],
  exports: [
    RecipientContactComponent,
    TrackingComponent,
    OrderIdComponent,
    CurrentStatusComponent,
    UnloadingViewComponent,
    OrderColumnsContainerComponent,
    CartComponent,
    PrintingButtonComponent,
    AdditionalFieldsComponent,
    AdditionalFieldsSearchComponent,
    OrderDatesComponent
  ]
})
export class OrderTableModule {
}
