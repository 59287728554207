import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { SenderOutput } from 'core/http/project';
import { Action, createReducer, on } from '@ngrx/store';
import * as SendersActions from 'app/store/project/senders/senders.actions';
import {
  CompletedEntityState,
  initialCompletedEntityState
} from 'app/store/completed-entity-state/completed-entity-state';

export interface SendersState extends CompletedEntityState<SenderOutput> {}

const adapter: EntityAdapter<SenderOutput> = createEntityAdapter<SenderOutput>();

export const initialSendersState = adapter.getInitialState(initialCompletedEntityState);

const reducer = createReducer(
  initialSendersState,
  on(SendersActions.saveSenders, (state, payload) => adapter.setAll(payload.senders, state)),
  on(SendersActions.markSenderLoaded, state => ({ ...state, loaded: true })),
  on(SendersActions.addSenderInStore, (state, { sender }) => adapter.addOne(sender, state)),
  on(SendersActions.updateSenderInStore, (state, { update }) => adapter.updateOne(update, state)),
  on(SendersActions.setLoading, (state, { value }) => ({ ...state, loading: value })),
  on(SendersActions.setError, (state, { value }) => ({ ...state, error: value }))
);

export function sendersReducer(state: SendersState | undefined, action: Action): SendersState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
