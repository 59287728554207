import { Component, Directive, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface SeparatorInputData {
  value: string;
  separator: ',' | ' ';
  byEntry?: boolean;
}

@Directive({ selector: 'app-separator-label' })
export class SeparatorInputLabelDirective {
}

@Component({
  selector: 'app-separator-input',
  templateUrl: './separator-input.component.html',
  styleUrls: ['./separator-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SeparatorInputComponent),
      multi: true
    }
  ]
})
export class SeparatorInputComponent implements OnInit, ControlValueAccessor {

  readonly form = new FormGroup({
    value: new FormControl(),
    separator: new FormControl(),
    byEntry: new FormControl()
  });

  private _separatorToggle = true;
  private _entryToggle = true;

  @Input()
  byEntry = false;

  get separator(): string {
    return this._separatorToggle ? ' ' : ',';
  }

  get separatorLabel(): string {
    return this._separatorToggle ? '_' : ',';
  }

  get labelSuffix(): string {
    return this._separatorToggle ? 'через пробел' : 'через запятую';
  }

  get containsIcon(): string {
    return this.entryToggle ? 'read_more' : 'drag_handle';
  }

  get containsTooltip(): string {
    return this.entryToggle ? 'По вхождению' : 'По строгому равенству';
  }

  get entryToggle(): boolean {
    return this._entryToggle;
  }

  set entryToggle(value: boolean) {
    this._entryToggle = value;
  }

  constructor() { }

  ngOnInit(): void {
    this.form.valueChanges.subscribe(() => this.notify());
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(value?: SeparatorInputData): void {
    this._separatorToggle = value?.separator === ' ';
    this._entryToggle = value?.byEntry ?? true;

    this.form.setValue({
      value: value?.value || '',
      separator: value?.separator ?? ' ',
      byEntry: value?.byEntry ?? true
    });
  }

  toggleSeparator(): void {
    this._separatorToggle = !this._separatorToggle;
    this.form.patchValue({ separator: this.separator });
  }

  toggleEntry(): void {
    this._entryToggle = !this._entryToggle;
    this.form.patchValue({ byEntry: this.entryToggle });
  }

  private notify(): void {
    this._onChange(this.form.value);
    this._onTouched();
    // console.log(this.form.value);
  }

  private _onChange = (_: any) => {};
  private _onTouched = () => {};

}
