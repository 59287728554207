import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterModule, Routes } from '@angular/router';
import { StatusesHistoryComponent } from 'history/statuses-history/statuses-history.component';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { StatusChangeOutput, StatusesHistoryService } from 'core/http/project/status/statuses-history.service';
import { Observable } from 'rxjs';
import { StatusChangeOrdersComponent } from 'history/statuses-history/status-change-orders/status-change-orders.component';
import { StatusChangeComponent } from 'history/statuses-history/status-change/status-change.component';

@ProjectInjectable()
export class StatusChangeResolver implements Resolve<StatusChangeOutput> {

  constructor(private statusesHistoryService: StatusesHistoryService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<StatusChangeOutput> {
    const id = route.params.changeGroupId;

    return this.statusesHistoryService.getStatusChange(id);
  }

}

const routes: Routes = [
  {
    path: '',
    component: StatusesHistoryComponent,
    data: { title: 'История смены статусов' }
  },
  {
    path: ':changeGroupId',
    component: StatusChangeComponent,
    resolve: {
      statusChange: StatusChangeResolver
    },
    data: { title: 'Группы статусов' },
    children: [
      {
        path: ':statusChangeId',
        component: StatusChangeOrdersComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StatusesHistoryRoutingModule {
}
