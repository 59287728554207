import { createAction, props } from '@ngrx/store';
import { PageCriteria } from 'app/store/project/page-criteria/page-criteria.reducer';
import { Update } from '@ngrx/entity';

export const addEmptyPageCriteria = createAction('[Page Criteria Store] Add Empty Page Criteria In Store',
  props<{ key: string }>());

export const addPageCriteria = createAction('[Page Criteria Store] Add Page Criteria In Store',
  props<{ criteria: PageCriteria }>());

export const updatePageCriteria = createAction('[Page Criteria Store] Update Page Criteria',
  props<{ update: Update<PageCriteria> }>());

export const resetAllPageCriteria = createAction('[Page Criteria Store] Reset All Page Criteria');
