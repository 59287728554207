import { HttpClient } from '@angular/common/http';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Observable } from 'rxjs';
import { CriteriaParams } from 'shared/criteria/Criteria';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { Uuid } from 'shared/common/types';
import { map } from 'rxjs/operators';
import { chain, Dictionary } from 'lodash';
import { BackgroundProgress } from 'core/http/project/background/background-task.service';

export interface CreateStatusInput {
  name: string;
  color: string;
  associatedState: string;
}

export interface StatusOutput {
  id: Uuid;
  name: string;
  associatedState: number;
  priority: number;
  isRemoved: boolean;
}

export interface StatusPriorityInput {
  statusId: Uuid;
  priority: number;
}

export interface UpdateStatusInput {
  name: string;
}

export interface StatusCountByOrders {
  orderCount: number;
  statusId: Uuid;
}

@ProjectInjectable()
export class StatusService {

  constructor(private http: HttpClient) {}

  getAllStatuses(criteria?: CriteriaParams): Observable<StatusOutput[]> {
    return this.http.get<StatusOutput[]>(apiUrl(`/project/statuses`, criteria));
  }

  createStatus(input: CreateStatusInput): Observable<StatusOutput> {
    return this.http.post<StatusOutput>(apiUrl('/project/status'), input);
  }

  updateStatus(statusId: Uuid, input: UpdateStatusInput): Observable<StatusOutput> {
    return this.http.patch<StatusOutput>(apiUrl(`/project/status/${statusId}`), input);
  }

  changePriority(statuses: StatusPriorityInput[]): Observable<StatusOutput[]> {
    return this.http.patch<StatusOutput[]>(apiUrl('/project/statuses/priority'), { priorities: statuses });
  }

  removeStatus(statusId: Uuid): Observable<Object> {
    return this.http.delete(apiUrl(`/project/status/${statusId}`));
  }

  restoreStatus(statusId: Uuid): Observable<Object> {
    return this.http.patch<Object>(apiUrl(`/project/status/restore/${statusId}`), {});
  }

  getStatusCountByOrders(): Observable<Dictionary<number>> {
    return this.http.get<StatusCountByOrders[]>(apiUrl('/project/statuses/order-count')).pipe(
      map(counts => chain(counts)
        .keyBy(c => c.statusId)
        .mapValues(c => c.orderCount)
        .value())
    );
  }

  resendStatuses(orderFilter: string): Observable<BackgroundProgress> {
    return this.http.post<BackgroundProgress>(apiUrl('/project/status-changes/resend'), { orderFilter });
  }

}
