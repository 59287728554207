import { createSelector } from '@ngrx/store';
import * as fromScheduledTasks from './scheduled-tasks.reducer';
import { ProjectState, selectProjectState } from 'app/store/project/project.state';
import { Uuid } from 'shared/common/types';

export const scheduledTasksState = createSelector(
  selectProjectState,
  state => state.scheduledTasks
);

export const scheduledTasks = createSelector(
  selectProjectState,
  state => fromScheduledTasks.selectAll(state.scheduledTasks)
);

export const oneScheduledTask = createSelector(
  selectProjectState,
  (state: ProjectState, props: { id: Uuid }) => fromScheduledTasks.selectEntities(state.scheduledTasks)[props.id]
);

export const scheduledTasksLoading = createSelector(
  selectProjectState,
  state => state.scheduledTasks.loading
);
