import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'core/services/loader.service';
import { Store } from '@ngrx/store';
import { currentProject } from 'app/store/project/current-project/current-project.selectors';
import { ProjectState } from 'app/store/project/project.state';
import { finalize, first, map, switchMap } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';

export interface NavigationOptions {
  withLoading?: boolean;
  relativeTo?: ActivatedRoute | null;
  state?: any;
}

const defaultOptions: NavigationOptions = {
  withLoading: true,
  relativeTo: null
};

@Injectable({ providedIn: 'root' })
abstract class LoadableRouter {

  constructor(private router: Router, private loader: LoaderService) {}

  protected abstract getBasePath(): Observable<string[]>;

  navigateTo(params: any[], options: NavigationOptions = {}): Promise<boolean> {
    const o = { ...defaultOptions, ...options };

    return this.getBasePath()
      .pipe(
        switchMap(path => {
          const route = !o.relativeTo ? path.concat(...params) : params;

          o.withLoading && this.loader.begin();

          return from(this.router.navigate(route, { relativeTo: o.relativeTo }));
        }),
        finalize(() => o.withLoading && this.loader.end())
      )
      .toPromise();
  }
}

@Injectable({ providedIn: 'root' })
export class DevRouter extends LoadableRouter {
  protected getBasePath(): Observable<string[]> {
    return of(['dev']);
  }
}

@Injectable({ providedIn: 'root' })
export class RootRouter extends LoadableRouter {
  protected getBasePath(): Observable<string[]> {
    return of(['root']);
  }
}

@Injectable({ providedIn: 'root' })
export class ProjectRouter extends LoadableRouter {

  constructor(
    private store$: Store<ProjectState>,
    router: Router,
    loader: LoaderService
  ) {
    super(router, loader);
  }

  protected getBasePath(): Observable<string[]> {
    return this.store$.select(currentProject).pipe(
      first(),
      map(p => (['project', p.route]))
    );
  }

}
