import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'shared/modules/material/material.module';
import { TypographyModule } from 'shared/modules/typography/typography.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LayoutModule } from 'shared/modules/layout/layout.module';
import { TranslateModule } from '@ngx-translate/core';
import { AngularModule } from 'shared/modules/angular/angular.module';
import { DebtsRowComponent } from './debts-row/debts-row.component';
import { DebtsComponent } from 'shared/modules/debts/debts/debts.component';

@NgModule({
  declarations: [
    DebtsRowComponent,
    DebtsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TypographyModule,
    NgxChartsModule,
    LayoutModule,
    TranslateModule,
    AngularModule
  ],
  exports: [
    DebtsComponent
  ]
})
export class DebtsModule {
}
