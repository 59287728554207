import { Component, OnInit } from '@angular/core';
import { ProjectOutput } from 'core/http/dev';
import { Invite, UserService } from 'core/http/root';
import { ObserverFactory } from 'shared/common/observers';
import { environment } from 'environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { Role } from 'core/http/lg-logistic-rest-api/Auth/token.service';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectFirst } from 'core/utils/rx-common';
import { rootProjects } from 'app/store/root/projects/projects.selectors';
import { notify } from 'app/store/common-effects/notifier.effects';
import { navigateToRoot } from 'app/store/common-effects/router.effects';

type SelectableProject = ProjectOutput & { isSelected: boolean };

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

  private _role: Role = 'ROLE_MANAGER';
  private _inviteLinks = Array<string>();
  private _selectedProjects = Array<SelectableProject>();

  get selectedProjects(): SelectableProject[] {
    return this._selectedProjects;
  }

  set selectedProjects(value: SelectableProject[]) {
    this._selectedProjects = value;
  }

  get inviteLinks(): string[] {
    return this._inviteLinks;
  }

  get role(): Role {
    return this._role;
  }

  set role(value: Role) {
    this._role = value;
  }

  constructor(
    private userService: UserService,
    private observers: ObserverFactory,
    private clipboardService: ClipboardService,
    private store$: Store
  ) { }

  ngOnInit(): void {
    this.store$.pipe(selectFirst(rootProjects))
      .subscribe(data => this.selectedProjects = data.map(p => ({ ...p, isSelected: false })));
  }

  generateInvite(): void {
    const projectIds = this.selectedProjects
      .filter(p => p.isSelected)
      .map(p => p.id);

    this.userService
      .invite(this.role, projectIds)
      .pipe(map(i => this.generateLink(i)))
      .subscribe(
        this.observers.withHook({
          successMsg: 'Ссылка сгенерирована',
          onSuccess: (link: string) => {
            this.inviteLinks.push(link);
            this.resetSelectedProjects();
          }
        })
      );
  }

  goBack(): void {
    this.store$.dispatch(navigateToRoot({ path: ['managers'] }));
  }

  copyInvite(inviteIdx: number): void {
    this.clipboardService.copyFromContent(this.inviteLinks[inviteIdx]);
    this.store$.dispatch(notify({ message: 'Ссылка скопирована' }));
  }

  resetSelectedProjects(): void {
    this.selectedProjects = this.selectedProjects.map(p => ({ ...p, isSelected: false }));
  }

  generateLink(invite: Invite): string {
    return `${environment.REGISTRATION_URL}/${invite.id}`;
  }
}

