import { createSelector } from '@ngrx/store';
import { ProjectState, selectProjectState } from 'app/store/project/project.state';
import * as fromFilterTemplates from './filter-templates.reducer';

export const filterTemplatesState = createSelector(
  selectProjectState,
  state => state.filterTemplates
);

export const filterTemplates = createSelector(
  selectProjectState,
  (state: ProjectState) => fromFilterTemplates.selectAll(state.filterTemplates)
);

export const filterTemplatesLoading = createSelector(
  selectProjectState,
  state => state.filterTemplates.loading
);
