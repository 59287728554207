import { Component, Input } from '@angular/core';
import { OrderOutput, RecipientOutput } from 'core/http/project';

@Component({
  selector: 'app-recipient-primary',
  templateUrl: './recipient-contact.component.html',
  styleUrls: ['./recipient-contact.component.scss']
})
export class RecipientContactComponent {

  private _order = {} as OrderOutput;
  private _address = '';

  @Input() skeletonView = false;

  @Input()
  set order(value: OrderOutput) {
    this._address = this.compileAddress(value.recipient);
    this._order = value;
  }

  get order(): OrderOutput {
    return this._order;
  }

  get address(): string {
    return this._address;
  }

  private compileAddress(recipient: RecipientOutput): string {
    return Object.entries(recipient)
      .filter(e => e[0] !== 'name' && e[0] !== 'email' && e[0] !== 'phone')
      .filter(e => e[1] !== null)
      .map(e => e[1])
      .join(', ');
  }
}
