import { Component, Inject } from '@angular/core';
import { StateMatcherInput } from 'core/http/root';
import { FieldMap } from 'angular2-query-builder/dist/components';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-matcher-dialog',
  templateUrl: './matcher-dialog.component.html',
  styleUrls: ['./matcher-dialog.component.scss']
})
export class MatcherDialogComponent {

  input: StateMatcherInput = this.data ? { ...this.data } : {
    state: 1,
    ruleSet: { condition: 'and', rules: [] },
    name: ''
  };

  orderFieldMap: FieldMap = {
    'id': {
      name: 'Идентификатор',
      type: 'string'
    },
    'name': {
      name: 'Имя',
      type: 'string'
    }
  };

  constructor(@Inject(MAT_DIALOG_DATA) private data: StateMatcherInput | null) { }

  get isMatcherValid(): boolean {
    return !!this.input.ruleSet && !!this.input.name;
  }

}
