import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadingComponent } from 'shared/modules/typography/heading/heading.component';
import { ChipComponent } from 'shared/modules/typography/chip/chip.component';
import { ColorizedTextComponent } from 'shared/modules/typography/small-text/colorized-text.component';
import { MaterialModule } from 'shared/modules/material/material.module';
import { DateComponent } from 'shared/modules/typography/dates/date.component';
import { ProjectViewComponent } from 'shared/modules/typography/project/project-view.component';
import { HintComponent } from './hint/hint.component';
import { BadgeDirective } from 'shared/modules/typography/badge/badge.directive';
import { FlagIconComponent } from './flag-icon/flag-icon.component';

@NgModule({
  declarations: [
    HeadingComponent,
    ChipComponent,
    ColorizedTextComponent,
    DateComponent,
    ProjectViewComponent,
    HintComponent,
    BadgeDirective,
    FlagIconComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    HeadingComponent,
    ChipComponent,
    ColorizedTextComponent,
    DateComponent,
    ProjectViewComponent,
    HintComponent,
    BadgeDirective,
    FlagIconComponent
  ]
})
export class TypographyModule {
}
