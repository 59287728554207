import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-countable-button',
  templateUrl: './countable-button.component.html',
  styleUrls: ['./countable-button.component.scss']
})
export class CountableButtonComponent {

  @Input() count = 0;
  @Input() asIcon = false;
  @Input() disabled = false;
  @Input() withoutBadge = false;

  @Output() clicked = new EventEmitter();

}
