import { Component, OnInit } from '@angular/core';
import { NavLink } from 'core/types/nav-link';

@Component({
  selector: 'app-account',
  template: `
    <app-main-layout [navLinks]="navLinks">
    </app-main-layout>`,
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  navLinks: NavLink[] = [
    {
      path: 'profile-info',
      icon: 'account_box',
      linkActiveOptions: { exact: true },
      label: 'Profile'
    }
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

}
