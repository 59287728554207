import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { RootSimpleExtensionOutput } from 'core/http/root';
import * as SimpleExtensionsActions from './simple-extensions.actions';

export interface SimpleExtensionsState extends EntityState<RootSimpleExtensionOutput> {
  isLoaded: boolean;
}

const adapter: EntityAdapter<RootSimpleExtensionOutput> = createEntityAdapter();

export const initialSimpleExtensionsState = adapter.getInitialState({
  isLoaded: false
});

const reducer = createReducer(
  initialSimpleExtensionsState,
  on(SimpleExtensionsActions.saveSimpleExtensions, (state, { extensions }) => adapter.addMany(extensions, state)),
  on(SimpleExtensionsActions.markSimpleExtensionsLoaded, state => ({ ...state, isLoaded: true })),
  on(SimpleExtensionsActions.updateExtensionProjectsInStore, (state, { update }) => adapter.updateOne(update, state))
);

export function simpleExtensionsReducer(state: SimpleExtensionsState | undefined, action: Action): SimpleExtensionsState {
  return reducer(state, action);
}

export const {
  selectAll
} = adapter.getSelectors();
