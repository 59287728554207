import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { forkJoin, Observable, of } from 'rxjs';
import { CourierOutput, SenderOutput } from 'core/http/project';
import { map, switchMap } from 'rxjs/operators';
import { KeyValue, Uuid } from 'shared/common/types';
import { CriteriaParams, ordering } from 'shared/criteria/Criteria';
import { Store } from '@ngrx/store';
import { arrayMap, selectFirst } from 'core/utils/rx-common';
import { loggedUser } from 'app/store/login/logged-user/logged-user.selectors';
import { ProjectOutput } from 'core/http/root';
import { flatten } from '@angular/compiler';
import * as _ from 'lodash';
import { currency } from 'app/store/project/currencies/currencies.selectors';
import { oneActiveAssignedProject } from 'app/store/login/assigned-projects/assigned-projects.selectors';
import * as moment from 'moment';

export type PrettyDebtByMonth = DebtByMonthOutput & LocalDebtByMonthData;

export interface PrettyDebt {
  projectId: Uuid;
  monthCuts: PrettyMonthCut[];
  totalData: LocalDebtsByMonthData[];
}

export interface CurrencyRatesOutput {
  createdAt: Date;
  rates: KeyValue[];
}

interface DebtOutput {
  projectId: Uuid;
  monthCuts: MonthCutOutput[];
}

export interface MonthCutOutput {
  courier: CourierOutput;
  sender: SenderOutput | null;
  debtsByMonth: DebtByMonthOutput[];
}

export interface PrettyMonthCut {
  courier: CourierOutput;
  sender: SenderOutput | null;
  debtsByMonth: PrettyDebtByMonth[];
}

interface LocalDebtsByMonthData {
  totalCount: number;
  totalSum: number;
  title: string | string[];
  gridColumn: number;
}

interface LocalDebtByMonthData {
  title: string | string[];
  gridColumn: number;
}

interface DebtByMonthOutput {
  year: number;
  month: number;
  totalSum: number;
  totalCount: number;
}

export interface MonthsDebtsInput {
  cutDate: string;
  unloadedFrom: string;
  unloadedTo: string;
}

@Injectable({ providedIn: 'root' })
export class ProjectService {

  constructor(
    private http: HttpClient,
    private store$: Store
  ) { }

  getProjects(): Observable<ProjectOutput[]> {
    return this.store$.pipe(
      selectFirst(loggedUser),
      switchMap(user => {
        if (!user || !(user && user.accessToken)) {
          throw new Error('Access token is missing');
        }

        const criteria: CriteriaParams = {
          ordering: { ...ordering.asc('name') }
        };

        return this.http.get<ProjectOutput[]>(apiUrl(`/auth/projects?filter=ordering name:asc`, criteria), {
          headers: {
            'Authorization': `Bearer ${user.accessToken}`,
            'Content-Type': 'application/json'
          }
        });
      })
    );
  }

  getMonthsDebts(input: MonthsDebtsInput): Observable<PrettyDebt[]> {
    const params = new HttpParams({
      fromObject: { ...input }
    });

    return this.http.get<DebtOutput[]>(apiUrl('/auth/projects-months-debts'), { params })
      .pipe(
        arrayMap(debt => {
          const summary = debt.monthCuts;
          const numbers = flatten(summary.map(s => s.debtsByMonth.map(d => d.month)));
          const monthNumbers = _.uniq(numbers);

          const totalData = this.getTotalData(monthNumbers, debt);

          const termData = getTermData(debt, this.store$);

          return { ...debt, monthCuts: termData, totalData } as PrettyDebt;
        })
      );
  }

  getRates(): Observable<CurrencyRatesOutput> {
    return this.http.get<CurrencyRatesOutput>(apiUrl('/auth/currency-rates'));
  }

  private getTotalData(monthNumbers: number[], debt: DebtOutput): LocalDebtsByMonthData[] {
    const { monthCuts, projectId } = debt;

    return monthNumbers
      .map(n => {
        const totalSum = this.getTotal(monthCuts, n, 'totalSum');
        const totalCount = this.getTotal(monthCuts, n, 'totalCount');
        const title = getTitle(totalSum, projectId, this.store$);
        const gridColumn = getGridColumn(n);

        return { totalSum, totalCount, title, gridColumn } as LocalDebtsByMonthData;
      });
  }

  private getTotal(summary: MonthCutOutput[], number: number, field: 'totalCount' | 'totalSum'): number {
    const arr = summary.map(d => d.debtsByMonth.find(i => i.month === number)![field]);

    return arr.reduce((prev, curr) => prev += curr);
  }

}

export function getTermData(debt: DebtOutput, store$: Store): PrettyMonthCut[] {
  const { monthCuts, projectId } = debt;

  return monthCuts.map(s => {
    const debtsByMonth = s.debtsByMonth.map(d => {
      const title = getTitle(d.totalSum, projectId, store$);
      const gridColumn = getGridColumn(d.month);

      return { ...d, title, gridColumn } as PrettyDebtByMonth;
    });

    return { ...s, debtsByMonth };
  });
}

export function getTitle(total: number, projectId: Uuid, store$: Store): string | string[] {
  let title: string | string[] = [];

  const fixedTotal = total.toFixed(0);

  store$.pipe(
    selectFirst(oneActiveAssignedProject, projectId),
    switchMap(project => forkJoin({
      code: of(project?.currencyCode),
      rate: store$.pipe(selectFirst(currency, { key: 'USD' + project?.currencyCode }))
    })),
    map(({ code, rate }) => {
      if (!code) {
        return '';
      }

      if (code === 'USD') {
        return `${fixedTotal} USD`;
      }

      const alternative = `${(total / rate).toFixed(0)} USD`;

      return [`${fixedTotal} ${code}`, alternative];
    })
  )
    .subscribe(t => title = t);


  return title;
}

export function getGridColumn(number: number): number {
  const sixMonthsAgo = moment().subtract(5, 'months').month() + 1;

  // Если номер месяца меньше, чем у месяца пол года назад
  // прибавляем 12 месяцев
  // Затем прибавляем 2, т.к. в таблице долгов колонки сдвинуты на 2 ячейки
  return number >= sixMonthsAgo
    ? (number - sixMonthsAgo + 2)
    : (number + 12 - sixMonthsAgo + 2);
}
