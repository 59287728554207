import { createAction, props } from '@ngrx/store';
import { CourierChainInput, CourierChainOutput } from 'core/http/project';
import { Uuid } from 'shared/common/types';
import { Update } from '@ngrx/entity';
import { createSetErrorAction, createSetLoadingAction } from 'app/store/completed-entity-state/completed-entity-state';

export const getCourierChains = createAction('[Courier Chains Request] Get Courier Chains');
export const saveCourierChains = createAction('[Courier Chains State] Save Courier Chains', props<{ chains: CourierChainOutput[] }>());
export const markCourierChainsLoaded = createAction('[Courier Chains State] Mark Courier Chains Loaded');

export const createCourierChain = createAction('[Courier Chains Request] Create Courier Chain ', props<CourierChainInput>());
export const addCourierChain = createAction('[Courier Chains State] Add Courier Chain In Store', props<CourierChainOutput>());

export const removeCourierChain = createAction('[Courier Chains Request] Remove Courier Chain', props<{ id: Uuid }>());
export const removeCourierChainFromStore = createAction('[Courier Chains State] Remove Courier Chain From Store', props<{ id: Uuid }>());

export const updateCourierChain = createAction('[Courier Chains Request] Update Courier Chain', props<{ id: Uuid, input: CourierChainInput }>());
export const updateCourierChainInStore = createAction('[Courier Chains State] Update Courier Chain In Store', props<{ update: Update<CourierChainOutput> }>());

export const setLoading = createSetLoadingAction('[Courier Chains Store] Set Loading');

export const setError = createSetErrorAction('[Courier Chains Store] Set Error');
