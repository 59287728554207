import { NgModule } from '@angular/core';
import { SelectSearchComponent } from 'shared/modules/controls/select-search/select-search.component';
import { MaterialModule } from 'shared/modules/material/material.module';
import { AngularModule } from 'shared/modules/angular/angular.module';

@NgModule({
  declarations: [SelectSearchComponent],
  imports: [
    MaterialModule,
    AngularModule
  ],
  exports: [SelectSearchComponent]
})
export class SelectSearchModule {
}
