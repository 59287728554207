import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourierExtensionsComponent } from './courier-extensions/courier-extensions.component';
import { DocumentExtensionsComponent } from './document-extensions/document-extensions.component';
import { SharedModule } from 'shared/shared.module';
import { UpdateExtensionProjectsDialogComponent } from './update-extension-projects-dialog/update-extension-projects-dialog.component';
import { CourierMatchersComponent } from './courier-extensions/matchers/courier-matchers.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatcherDialogComponent } from './courier-extensions/matchers/matcher-dialog/matcher-dialog.component';
import { ExtensionsRoutingModule } from 'root/extensions/extensions-routing.module';
import { SimpleExtensionsComponent } from './simple-extensions/simple-extensions.component';

@NgModule({
  declarations: [
    CourierExtensionsComponent,
    DocumentExtensionsComponent,
    UpdateExtensionProjectsDialogComponent,
    CourierMatchersComponent,
    MatcherDialogComponent,
    SimpleExtensionsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DragDropModule,
    ExtensionsRoutingModule
  ],
  providers: []
})
export class ExtensionsModule {
}
