import { createAction, props } from '@ngrx/store';
import { DocumentOutput } from 'core/http/project';
import { createSetErrorAction, createSetLoadingAction } from 'app/store/completed-entity-state/completed-entity-state';

export const getDocuments = createAction('[Documents Request] Get Documents');
export const saveDocuments = createAction('[Documents Store] Save Documents', props<{ documents: DocumentOutput[] }>());
export const markDocumentsLoaded = createAction('[Documents Store] Mark Documents Loaded');

export const setLoading = createSetLoadingAction('[Documents Store] Set Loading');

export const setError = createSetErrorAction('[Documents Store] Set Error');
