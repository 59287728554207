import { Pipe, PipeTransform } from '@angular/core';
import { Dic } from 'shared/common/types';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

const UNKNOWN_TYPE = 'UNKNOWN';

const GROUPERS: Dic<string> = {
  ['comment']: 'Комментарий',
  ['lgStandingData.outProjectId']: 'Project id',
  ['lgStandingData.outProjectName']: 'Project name',
  ['status.name']: 'Статус',
  ['logistic.courier']: 'Курьерская служба',
  ['cart.totalQuantity']: 'Общее кол-во',
  ['recipient.postcode']: 'Почтовый индекс',
  ['recipient.region']: 'Регион',
  ['recipient.city']: 'Город',
  ['product.name']: 'Название продукта',
  ['additional.additional1']: 'Доп. поле 1',
  ['additional.additional2']: 'Доп. поле 2',
  ['additional.additional3']: 'Доп. поле 3',
  ['additional.additional4']: 'Доп. поле 4',
  ['additional.additional5']: 'Доп. поле 5',
  ['additional.additional6']: 'Доп. поле 6',
  ['additional.additional7']: 'Доп. поле 7',
  ['additional.additional8']: 'Доп. поле 8',
  ['additional.additional9']: 'Доп. поле 9',
  ['additional.additional10']: 'Доп. поле 10',
  ['additional.additional11']: 'Доп. поле 11',
  ['additional.additional12']: 'Доп. поле 12',
  ['additional.additional13']: 'Доп. поле 13',
  ['additional.additional14']: 'Доп. поле 14',
  ['additional.additional15']: 'Доп. поле 15'
};

const STATUSES_UNCHANGED: Dic<string> = {
  TRANSFERRED: 'STATUSES_UNCHANGED.transferred',
  RETURNED: 'STATUSES_UNCHANGED.returned',
  ERROR: 'STATUSES_UNCHANGED.error',
  ALREADY_IN_STATUS: 'STATUSES_UNCHANGED.already_in_status',
  INVALID_STATUS: 'STATUSES_UNCHANGED.invalid_status'
};

const ORDER_STATES: Dic<string> = {
  1: 'ORDER_STATES.in_process',
  2: 'ORDER_STATES.sent',
  3: 'ORDER_STATES.awaiting_recipient',
  4: 'ORDER_STATES.delivered',
  5: 'ORDER_STATES.returned',
  6: 'ORDER_STATES.paid',
  7: 'ORDER_STATES.error',
  8: 'ORDER_STATES.return',
  9: 'ORDER_STATES.ready_to_send',
  10: 'ORDER_STATES.out_of_delivery_area',
  11: 'ORDER_STATES.lost'
};

const COURIER_STATES: Dic<string> = {
  1: 'COURIER_STATES.accepted',
  2: 'COURIER_STATES.sent',
  3: 'COURIER_STATES.ready_for_delivery',
  4: 'COURIER_STATES.delivered',
  5: 'COURIER_STATES.returned',
  6: 'COURIER_STATES.paid',
  8: 'COURIER_STATES.return',
  9: 'COURIER_STATES.out_of_delivery_area',
  10: 'COURIER_STATES.lost'
};

const CLAIM_STATES: Dic<string> = {
  0: 'CLAIM_STATES.in_process',
  1: 'CLAIM_STATES.accepted',
  2: 'CLAIM_STATES.canceled',
  3: 'CLAIM_STATES.paid'
};

const CLAIM_ORDERS_STATES: Dic<string> = {
  1: 'CLAIM_ORDERS_STATES.not_paid_yet',
  2: 'CLAIM_ORDERS_STATES.payment_after_claim',
  3: 'CLAIM_ORDERS_STATES.return_after_claim'
};

const DOCUMENTS: Dic<string> = {
  POST_BLANK: 'DOCUMENT_TYPES.post',
  REVISION: 'DOCUMENT_TYPES.revise',
  SUMMARY: 'DOCUMENT_TYPES.summary'
};

const SENDING_STATUS: Dic<string> = {
  WITHOUT: 'SENDING_STATUS.disconnected',
  PENDING: 'SENDING_STATUS.in_process',
  FAILURE: 'SENDING_STATUS.error',
  SUCCESS: 'SENDING_STATUS.sent'
};

const DAYS_OF_WEEK: Dic<string> = {
  Mon: 'DAYS_OF_WEEK.monday',
  Tue: 'DAYS_OF_WEEK.tuesday',
  Wed: 'DAYS_OF_WEEK.wednesday',
  Thu: 'DAYS_OF_WEEK.thursday',
  Fri: 'DAYS_OF_WEEK.friday',
  Sat: 'DAYS_OF_WEEK.saturday',
  Sun: 'DAYS_OF_WEEK.sunday',
  null: 'DAYS_OF_WEEK.everyday'
};

const FULL_DAYS_OF_WEEK: Dic<string> = {
  Monday: 'DAYS_OF_WEEK.monday',
  Tuesday: 'DAYS_OF_WEEK.tuesday',
  Wednesday: 'DAYS_OF_WEEK.wednesday',
  Thursday: 'DAYS_OF_WEEK.thursday',
  Friday: 'DAYS_OF_WEEK.friday',
  Saturday: 'DAYS_OF_WEEK.saturday',
  Sunday: 'DAYS_OF_WEEK.sunday',
  null: 'DAYS_OF_WEEK.everyday'
};

const CUT_DAYS_OF_WEEK: Dic<string> = {
  Mon: 'CUT_DAYS_OF_WEEK.mon',
  Tue: 'CUT_DAYS_OF_WEEK.tue',
  Wed: 'CUT_DAYS_OF_WEEK.wed',
  Thu: 'CUT_DAYS_OF_WEEK.thu',
  Fri: 'CUT_DAYS_OF_WEEK.fri',
  Sat: 'CUT_DAYS_OF_WEEK.sat',
  Sun: 'CUT_DAYS_OF_WEEK.sun',
  null: 'CUT_DAYS_OF_WEEK.eve'
};

const MONTHS: Dic<string> = {
  Jan: 'MONTHS.jan',
  Feb: 'MONTHS.feb',
  Mar: 'MONTHS.mar',
  Apr: 'MONTHS.apr',
  May: 'MONTHS.may',
  Jun: 'MONTHS.jun',
  Jul: 'MONTHS.jul',
  Aug: 'MONTHS.aug',
  Sep: 'MONTHS.sep',
  Oct: 'MONTHS.oct',
  Nov: 'MONTHS.nov',
  Dec: 'MONTHS.dec'
};

const RECIPIENT_CHANGES: Dic<string> = {
  RECIPIENT_NAME_CHANGED: 'CHANGE_FIELDS.name',
  RECIPIENT_PHONE_CHANGED: 'CHANGE_FIELDS.phone',
  RECIPIENT_EMAIL_CHANGED: 'CHANGE_FIELDS.email',
  RECIPIENT_COUNTRY_CHANGED: 'CHANGE_FIELDS.country',
  RECIPIENT_REGION_CHANGED: 'CHANGE_FIELDS.region',
  RECIPIENT_CITY_CHANGED: 'CHANGE_FIELDS.city',
  RECIPIENT_POSTCODE_CHANGED: 'CHANGE_FIELDS.postcode',
  RECIPIENT_ADDRESS_CHANGED: 'CHANGE_FIELDS.address',
  RECIPIENT_HOUSE_CHANGED: 'CHANGE_FIELDS.house',
  RECIPIENT_FLAT_CHANGED: 'CHANGE_FIELDS.flat',
  COMMENT_CHANGED: 'CHANGE_FIELDS.comment',
  STATUS_TIMESTAMP_TYPE: 'CHANGE_FIELDS.last_status_change',
  BOUGHT_OUT_TIMESTAMP: 'CHANGE_FIELDS.last_buyout',
  RETURNED_AT_TIMESTAMP: 'CHANGE_FIELDS.last_returned',
  APPENDED_TO_CLAIM: 'CHANGE_FIELDS.appended_to_claim',
  REMOVED_FROM_CLAIM: 'CHANGE_FIELDS.removed_from_claim',
  REIMBURSEMENT_AMOUNT_CHANGED: 'CHANGE_FIELDS.reimbursement_amount',
  STATUS_CHANGED: 'CHANGE_FIELDS.status',
  ORDER_EXPORTED: 'CHANGE_FIELDS.order_exported',
  ORDER_EXPORT_CANCELLED: 'CHANGE_FIELDS.order_export_cancelled',
  GOOD_QUANTITY_CHANGED: 'CHANGE_FIELDS.good_quantity',
  GOOD_REMOVED: 'CHANGE_FIELDS.good_removed',
  GOOD_APPENDED: 'CHANGE_FIELDS.good_appended'
};

const USER_ROLES: Dic<string> = {
  ROLE_MANAGER: 'USER_ROLES.manager',
  ROLE_ROOT_ACCESS: 'USER_ROLES.admin',
  ROLE_ORDER_SUPPLIER: 'USER_ROLES.supplier',
  ROLE_DEVELOPER: 'USER_ROLES.developer'
};

type TypeGroup = 'claim-states'
  | 'order-states'
  | 'groupers'
  | 'documents'
  | 'statuses-unchanged'
  | 'sending-status'
  | 'courier-states'
  | 'days-of-week'
  | 'full-days-of-week'
  | 'cut-days-of-week'
  | 'changes'
  | 'claim-orders-state'
  | 'user-roles'
  | 'months'
  | string;

@Pipe({ name: 'label' })
export class LabelPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(type: string, group: TypeGroup): Observable<string> {
    return this.translateService.get(getLabel(group, type));
  }
}

function getLabel(group: TypeGroup, type: string): string {
  switch (group) {
    case 'groupers':
      return +type ? `Доп. поле ${type}` : GROUPERS[type];

    case 'order-states':
      return ORDER_STATES[type];

    case 'courier-states':
      return COURIER_STATES[type];

    case 'documents':
      return DOCUMENTS[type];

    case 'claim-states':
      return CLAIM_STATES[type];

    case 'statuses-unchanged':
      return STATUSES_UNCHANGED[type];

    case 'sending-status':
      return SENDING_STATUS[type];

    case 'days-of-week':
      return DAYS_OF_WEEK[type];

    case 'full-days-of-week':
      return FULL_DAYS_OF_WEEK[type];

    case 'cut-days-of-week':
      return CUT_DAYS_OF_WEEK[type];

    case 'changes':
      return RECIPIENT_CHANGES[type];

    case 'claim-orders-state':
      return CLAIM_ORDERS_STATES[type];

    case 'user-roles':
      return USER_ROLES[type];

    case 'months':
      return MONTHS[type];
  }

  return UNKNOWN_TYPE;
}
