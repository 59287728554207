import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CustomTranslateLoader extends TranslateLoader {

  constructor(private http: HttpClient) {
    super();
  }

  getTranslation(lang: string): Observable<any> {
    const language = localStorage.getItem('LANGUAGE');
    return this.http.get(`../assets/translates/${language}.json`);
  }
}
