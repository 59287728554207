import { AfterContentInit, Component, ContentChild, Directive, Input } from '@angular/core';

@Directive({ selector: 'app-layout-title' })
export class LayoutHeaderDirective {
  @Input() align: 'start' | 'end' | 'center' = 'start';
}

@Directive({ selector: 'app-layout-actions' })
export class LayoutActionsDirective {
  @Input() align: 'start' | 'end' | 'center' = 'center';
}

@Directive({ selector: 'app-layout-content' })
export class LayoutContentDirective {
}

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements AfterContentInit {

  @Input() hAlignment: 'start' | 'end' | 'center' = 'center';
  @Input() vAlignment: 'top' | 'middle' | 'bottom' | 'around' | 'between' = 'top';

  @Input() columnCount = 6;

  @ContentChild(LayoutHeaderDirective)
  title?: LayoutHeaderDirective;

  @ContentChild(LayoutActionsDirective)
  actions?: LayoutActionsDirective;

  @ContentChild(LayoutContentDirective)
  content?: LayoutContentDirective;

  ngAfterContentInit(): void {
    if (!this.content) {
      throw new Error('Content not defined');
    }
  }

}
