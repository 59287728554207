import { Component, EventEmitter, HostListener, Output, ViewChild } from '@angular/core';
import { TokenService } from 'core/http/lg-logistic-rest-api/Auth/token.service';
import { Store } from '@ngrx/store';
import { loggedUser } from 'app/store/login/logged-user/logged-user.selectors';
import { map, switchMap } from 'rxjs/operators';
import { LoggedUserState } from 'app/store/login/logged-user/logged-user.state';
import { selectFirst } from 'core/utils/rx-common';
import { allAssignedProjects } from 'app/store/login/assigned-projects/assigned-projects.selectors';
import { currentProject } from 'app/store/project/current-project/current-project.selectors';
import { logout } from 'app/store/login/logged-user/logged-user.actions';
import { navigate } from 'app/store/common-effects/router.effects';
import { notify } from 'app/store/common-effects/notifier.effects';
import { ClipboardService } from 'ngx-clipboard';
import { ProjectOutput } from 'core/http/root';
import { SelectSearchComponent } from 'shared/modules/controls/select-search/select-search.component';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @ViewChild(MatSelect)
  projectSelect!: MatSelect;

  readonly username$ = this.store$.select(loggedUser).pipe(map(user => user && user.userInfo.fullName));
  readonly projects$ = this.store$.select(allAssignedProjects);
  readonly selectedProject$ = this.store$.select(currentProject);

  @Output() clickEmitter = new EventEmitter<void>();

  constructor(
    private tokenService: TokenService,
    private store$: Store<LoggedUserState>,
    private clipboard: ClipboardService
  ) { }

  @HostListener('document:keydown.alt.shift.p') open(): void {
    this.projectSelect.open();
  }

  navigateFromMouse(event: MouseEvent, project: ProjectOutput): void {
    if (event.which !== 2) {
      return;
    }

    event.preventDefault();
    window.open(`/project/${project.route}/orders`);
  }

  navigateToProject(route: string): void {
    this.store$.dispatch(navigate({ path: ['/', 'project', route, 'orders'] }));
  }

  copyProjectId(): void {
    this.selectedProject$.subscribe(p => {
      this.clipboard.copy(p.id);
      this.store$.dispatch(notify({ message: 'ID текущего проекта скопирован в буфер' }));
    });
  }

  copyUserId(): void {
    this.store$
      .pipe(selectFirst(loggedUser))
      .subscribe(user => {
        if (!user) {
          return;
        }

        this.clipboard.copy(user.userInfo.id);
        this.store$.dispatch(notify({ message: 'ID текущего пользователя скопирован в буфер' }));
      });
  }

  logout(): void {
    this.store$.pipe(
      selectFirst(loggedUser),
      switchMap(user => {
        if (!(user && user.accessToken)) {
          throw new Error('Access token is missing');
        }

        return this.tokenService.revoke(user.accessToken);
      })
    ).subscribe(() => this.store$.dispatch(logout()));
  }

  focus(search: SelectSearchComponent): void {
    search.input.nativeElement.focus();
  }
}
