import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectFirst } from 'core/utils/rx-common';
import { selectedAdditions } from 'app/store/project/addition-fields/addition-fields.selectors';
import { writeAdditions } from 'app/store/project/addition-fields/addition-fields.actions';
import { AdditionalField } from 'app/store/project/addition-fields/addition-fields.reducer';

@Component({
  selector: 'app-additional-fields-search',
  templateUrl: './additional-fields-search.component.html',
  styleUrls: ['./additional-fields-search.component.scss']
})
export class AdditionalFieldsSearchComponent implements OnInit {

  private _additionalFields = Array<AdditionalField>();
  private _extendedMode = false;
  private _selectedAdditions = Array<AdditionalField>();

  @Input()
  set additionalFields(f: AdditionalField[]) {
    this._additionalFields = f;
  }

  get additionalFields(): AdditionalField[] {
    return this._additionalFields;
  }

  get extendedMode(): boolean {
    return this._extendedMode;
  }

  @Input()
  set extendedMode(value: boolean) {
    this._extendedMode = value;
  }

  get form(): FormGroup {
    const group = this.controlContainer.control;

    if (!(group instanceof FormGroup)) {
      throw new Error('Invalid ControlContainer given');
    }

    return group;
  }

  get selectedAdditions(): AdditionalField[] {
    return this._selectedAdditions;
  }

  set selectedAdditions(value: AdditionalField[]) {
    this._selectedAdditions = value;
  }

  constructor(
    private controlContainer: ControlContainer,
    private store$: Store
  ) { }

  ngOnInit(): void {
    this.store$.pipe(selectFirst(selectedAdditions))
      .subscribe(fields => this.selectedAdditions = fields);
  }

  writeInStorage(fields: AdditionalField[]): void {
    this.store$.dispatch(writeAdditions({ fields }));
  }

  compareByName(f1: AdditionalField, f2: AdditionalField): boolean {
    return f1 && f2 && f1.name === f2.name;
  }

}
