import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from './search-bar.component';
import { MaterialModule } from 'shared/modules/material/material.module';
import { AngularModule } from 'shared/modules/angular/angular.module';

@NgModule({
  declarations: [
    SearchBarComponent
  ],
  exports: [
    SearchBarComponent
  ],
  imports: [
    CommonModule,
    AngularModule,
    MaterialModule
  ]
})
export class SearchBarModule {
}
