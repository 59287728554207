import { Action, createReducer, on } from '@ngrx/store';
import * as CouriersActions from './couriers.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { DevCourierOutput } from 'core/http/dev';

export interface DevCouriersState extends EntityState<DevCourierOutput> {
  isLoaded: boolean;
  integrationClasses: string[];
  trackerClasses: string[];
  cancellerClasses: string[];
}

const adapter: EntityAdapter<DevCourierOutput> = createEntityAdapter();

export const initialDevCouriersState = adapter.getInitialState({
  isLoaded: false,
  integrationClasses: Array<string>(),
  trackerClasses: Array<string>(),
  cancellerClasses: Array<string>()
});

const reducer = createReducer(
  initialDevCouriersState,
  on(CouriersActions.saveCouriers, (state, { couriers }) => adapter.setAll(couriers, state)),
  on(CouriersActions.saveClasses, (state, classes) => ({ ...state, ...classes })),
  on(CouriersActions.markCouriersLoaded, state => ({ ...state, isLoaded: true })),
  on(CouriersActions.addCourierInStore, (state, { courier }) => adapter.addOne(courier, state)),
  on(CouriersActions.updateCourierInStore, (state, { update }) => adapter.updateOne(update, state))
);

export function devCouriersReducer(state: DevCouriersState | undefined, action: Action): DevCouriersState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
