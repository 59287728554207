import { Component, Inject } from '@angular/core';
import { TrackReserveOutput } from 'core/http/project';
import { of } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EagerSource } from 'shared/modules/table/table-utils/table-interfaces';

export interface ReserveCheckDialogData {
  reserves: TrackReserveOutput[];
  orderCount: number;
}

export type ReserveCheckDialogResult = 'INTERRUPT_UNLOADING' | 'RESUME_UNLOADING';

const HANDICAP = 100;

@Component({
  selector: 'app-reserve-check-dialog',
  templateUrl: './reserve-check-dialog.component.html',
  styleUrls: ['./reserve-check-dialog.component.scss']
})
export class ReserveCheckDialogComponent {

  columns: string[] = [
    'courier',
    'count'
  ];

  constructor(@Inject(MAT_DIALOG_DATA) private data: ReserveCheckDialogData) { }

  dataSource: EagerSource<TrackReserveOutput> = () => of(this.data.reserves);

  get resumeResult(): ReserveCheckDialogResult {
    return 'RESUME_UNLOADING';
  }

  get interruptResult(): ReserveCheckDialogResult {
    return 'INTERRUPT_UNLOADING';
  }

  isCritical(reserve: TrackReserveOutput): boolean {
    return reserve.remainingCount < this.data.orderCount;
  }

  isWarning(reserve: TrackReserveOutput): boolean {
    return !this.isCritical(reserve) && (reserve.remainingCount - HANDICAP) < this.data.orderCount;
  }

  getTooltip(reserve: TrackReserveOutput): string | null {
    if (reserve.remainingCount === 0) {
      return 'Треки закончились';
    }

    if (this.isCritical(reserve)) {
      return 'Треков не хватит';
    }

    if (this.isWarning(reserve)) {
      return 'Треки скоро закончатся';
    }

    return null;
  }

}
