import { Component, Inject } from '@angular/core';
import { RuleSet } from 'angular2-query-builder';
import { FieldMap } from 'angular2-query-builder/dist/components';
import { FilterType, TemplateOutput } from 'core/http/project/rule-set/user-templates.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { addFilterTemplate, removeFilterTemplate } from 'app/store/project/filter-templates/filter-templates.actions';

export interface QueryBuilderDialogData {
  type: FilterType;
  fieldMap: FieldMap;
  ruleSet: RuleSet;
}

@Component({
  selector: 'app-query-builder-dialog',
  templateUrl: './query-builder-dialog.component.html',
  styleUrls: ['./query-builder-dialog.component.scss']
})
export class QueryBuilderDialogComponent {

  private readonly _fieldMap: FieldMap;
  readonly type: FilterType;
  private _ruleSet: RuleSet;

  get fieldMap(): FieldMap {
    return this._fieldMap;
  }

  get ruleSet(): RuleSet {
    return this._ruleSet;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) data: QueryBuilderDialogData,
    private store$: Store
  ) {
    this._fieldMap = data.fieldMap;
    this._ruleSet = data.ruleSet;
    this.type = data.type;
  }

  append(name: string, ruleSet: RuleSet): void {
    this.store$.dispatch(addFilterTemplate({ template: { name, ruleSet, type: this.type } }));
  }

  remove(template: TemplateOutput): void {
    this.store$.dispatch(removeFilterTemplate({ id: template.id }));
  }

  paste(template: TemplateOutput): void {
    this._ruleSet = template.ruleSet;
  }

}
