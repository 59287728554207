import { Component, Input, OnInit } from '@angular/core';
import { Rule } from 'angular2-query-builder';
import { FormGroup } from '@angular/forms';
import {
  fixSecondsInTime,
  defaultRelativeDate,
  formForRelativeDate,
  RelativeDate
} from 'shared/modules/query-builder/datetime-query-input/relative-date';

@Component({
  selector: 'app-datetime-query-input',
  templateUrl: './datetime-query-input.component.html',
  styleUrls: ['./datetime-query-input.component.scss']
})
export class DatetimeQueryInputComponent implements OnInit {

  private _rule?: Rule;
  private _onChange?: () => void;

  private _form = new FormGroup({});

  get form(): FormGroup {
    return this._form;
  }

  get rule(): Rule {
    if (!this._rule) {
      throw new Error(`Required input 'rule' is not specified`);
    }

    return this._rule;
  }

  @Input()
  set rule(rule: Rule) {
    this._rule = rule;
  }

  get onChange(): () => void {
    if (!this._onChange) {
      throw new Error(`Required input 'onChange' is not specified`);
    }

    return this._onChange;
  }

  @Input()
  set onChange(onChange: () => void) {
    this._onChange = onChange;
  }

  ngOnInit(): void {
    const relativeDate = typeof this.rule.value === 'string'
      ? this.rule.value
      : '';

    this._form = formForRelativeDate(relativeDate);

    const setValue = (v: RelativeDate) => {
      this.rule.value = `${v.sign}${v.value} ${v.unit} ${fixSecondsInTime(v.time)}`;
    };

    if (!this.rule.value) {
      setValue(defaultRelativeDate);
    }

    this.form.valueChanges.subscribe(setValue);
  }

}
