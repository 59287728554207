import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { CouriersChainService } from 'core/http/project';
import { LoaderService } from 'core/services/loader.service';
import { createProperties } from 'app/store/completed-entity-state/completed-entity-state';
import { CompletedEntityStateService } from 'app/store/completed-entity-state/completed-entity-state.service';
import * as CourierChainsActions from './courier-chains.actions';
import * as NotifierActions from '../../common-effects/notifier.effects';
import * as ProjectRouterActions from '../../common-effects/router.effects';

@Injectable()
export class CourierChainsEffects {

  readonly properties = createProperties(
    CourierChainsActions.setLoading,
    CourierChainsActions.markCourierChainsLoaded,
    CourierChainsActions.setError,
    () => this.couriersChainService.getAllChains(),
    'courierChains'
  );

  getCourierChains$ = createEffect(() => this.actions$.pipe(
    ofType(CourierChainsActions.getCourierChains),
    exhaustMap(() => this.entityService.get(this.properties)),
    map(chains => CourierChainsActions.saveCourierChains({ chains }))
  ));

  createCourierChain$ = createEffect(() => this.actions$.pipe(
    ofType(CourierChainsActions.createCourierChain),
    tap(() => this.loader.begin()),
    switchMap(chain => this.couriersChainService.createChain(chain)),
    tap(() => this.loader.end()),
    switchMap(chain => [
      CourierChainsActions.addCourierChain(chain),
      NotifierActions.notify({ message: 'Цепочка курьерских служб создана' }),
      ProjectRouterActions.navigateToProject({ path: ['setting', 'courier-chains'] })
    ])
  ));

  removeCourierChain$ = createEffect(() => this.actions$.pipe(
    ofType(CourierChainsActions.removeCourierChain),
    exhaustMap(payload => this.couriersChainService.removeChain(payload.id).pipe(mapTo(payload.id))),
    switchMap(id => [
      CourierChainsActions.removeCourierChainFromStore({ id }),
      NotifierActions.notify({ message: 'Цепочка курьерских служб удалена' })
    ])
  ));

  updateCourierChain$ = createEffect(() => this.actions$.pipe(
    ofType(CourierChainsActions.updateCourierChain),
    tap(() => this.loader.begin()),
    switchMap(payload => this.couriersChainService.updateChain(payload.id, payload.input)),
    tap(() => this.loader.end()),
    switchMap(chain => [
      CourierChainsActions.updateCourierChainInStore({ update: { id: chain.id, changes: chain } }),
      NotifierActions.notify({ message: 'Цепочка курьерских служб обновлена' }),
      ProjectRouterActions.navigateToProject({ path: ['setting', 'courier-chains'] })
    ])
  ));

  constructor(
    private actions$: Actions,
    private couriersChainService: CouriersChainService,
    private loader: LoaderService,
    private entityService: CompletedEntityStateService
  ) {}

}
