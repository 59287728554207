import { Component } from '@angular/core';
import { NavLink } from 'core/types/nav-link';

@Component({
  selector: 'app-root',
  template: `
      <app-main-layout [navLinks]="navLinks">
      </app-main-layout>`,
  styleUrls: ['./root-layout.component.scss']
})
export class RootLayoutComponent {

  navLinks: NavLink[] = [
    {
      path: '/root/managers',
      icon: 'supervised_user_circle',
      linkActiveOptions: { exact: true },
      label: 'Пользователи'
    },
    {
      path: '/root/projects',
      icon: 'casino',
      linkActiveOptions: { exact: false },
      label: 'Проекты'
    },
    {
      path: '/root/extensions',
      icon: 'build',
      linkActiveOptions: { exact: false },
      label: 'Модули',
      children: [
        {
          path: '/root/extensions/couriers',
          linkActiveOptions: { exact: false },
          label: 'Курьерки'
        },
        {
          path: '/root/extensions/documents',
          linkActiveOptions: { exact: false },
          label: 'Документы'
        },
        {
          path: '/root/extensions/simple-extensions',
          linkActiveOptions: { exact: false },
          label: 'Расширения'
        }
      ]
    },
    {
      path: '/root/integration',
      icon: 'settings_input_hdmi',
      linkActiveOptions: { exact: false },
      label: 'Вебхуки'
    },
    {
      path: '/root/association',
      icon: 'accessibility_new',
      linkActiveOptions: { exact: false },
      label: 'Ассоциации'
    }
  ];

}
