import { createSelector } from '@ngrx/store';
import { ProjectState, selectProjectState } from '../project.state';
import * as fromGoods from 'app/store/project/goods/goods.reducer';
import { Uuid } from 'shared/common/types';

export const goodsState = createSelector(
  selectProjectState,
  state => state.goods
);

export const goods = createSelector(
  selectProjectState,
  (state: ProjectState) => fromGoods.selectAll(state.goods)
);

export const goodEntities = createSelector(
  selectProjectState,
  state => fromGoods.selectEntities(state.goods)
);

export const oneGood = createSelector(
  selectProjectState,
  (state: ProjectState, props: { id: Uuid }) => fromGoods.selectEntities(state.goods)[props.id]
);

export const goodsLoading = createSelector(
  selectProjectState,
  state => state.goods.loading
);
