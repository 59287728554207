import { Injectable } from '@angular/core';
import { CriteriaParams } from 'shared/criteria/Criteria';
import { Observable } from 'rxjs';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { HttpClient } from '@angular/common/http';
import { Uuid } from 'shared/common/types';
import { RuleSet } from 'angular2-query-builder';

export interface TemplateOutput {
  id: Uuid;
  type: FilterType;
  name: string;
  ruleSet: RuleSet;
}

export interface TemplateInput {
  type: FilterType;
  name: string;
  ruleSet: RuleSet;
}

export type FilterType = 'order' | 'parcel_status';

@Injectable({ providedIn: 'root' })
export class UserTemplatesService {

  constructor(private http: HttpClient) {}

  getTemplates(c?: CriteriaParams): Observable<TemplateOutput[]> {
    return this.http.get<TemplateOutput[]>(apiUrl(`/filter/templates`, c));
  }

  addTemplate(input: TemplateInput): Observable<TemplateOutput> {
    return this.http.post<TemplateOutput>(apiUrl(`/filter/template`), input);
  }

  removeTemplate(templateId: string): Observable<void> {
    return this.http.delete<void>(apiUrl(`/filter/template/${templateId}`));
  }

}
