import { BehaviorSubject, Observable } from 'rxjs';

const IS_OPEN_KEY = 'side-nav-is-open';
const IS_OVERLAPPED_KEY = 'side-nav-is-overlapped';

export class SidenavState {

  private readonly _isOpen: BehaviorSubject<boolean>;
  private readonly _isOverlapped: BehaviorSubject<boolean>;

  constructor() {
    const [isOpen, isOverlapped] = this.restoreState();

    this._isOpen = new BehaviorSubject(isOpen);
    this._isOverlapped = new BehaviorSubject(isOverlapped);

    this._isOpen.subscribe(state => localStorage.setItem(IS_OPEN_KEY, state.toString()));
    this._isOverlapped.subscribe(state => localStorage.setItem(IS_OVERLAPPED_KEY, state.toString()));
  }

  get isOpen(): Observable<boolean> {
    return this._isOpen;
  }

  get isOverlapped(): Observable<boolean> {
    return this._isOverlapped;
  }

  toggleOpenState(state?: boolean): void {
    this._isOpen.next(undefined !== state ? state : !this._isOpen.value);
  }

  toggleOverlapState(state?: boolean): void {
    this._isOverlapped.next(undefined !== state ? state : !this._isOverlapped.value);
  }

  private restoreState(): boolean[] {
    const isOpenRaw = localStorage.getItem(IS_OPEN_KEY);
    const isOpen = isOpenRaw === null ? true : isOpenRaw === 'true';

    const isOverlappedRaw = localStorage.getItem(IS_OVERLAPPED_KEY);
    const isOverlapped = isOverlappedRaw === null ? false : isOverlappedRaw === 'true';

    return [isOpen, isOverlapped];
  }

}
