import { createAction, props } from '@ngrx/store';
import { TokenPairOutput } from 'core/http/lg-logistic-rest-api/Auth/token.service';

export const logIn = createAction('[Login Request] Log In Action', props<{ username: string, password: string }>());
export const logInSuccess = createAction('[Login Store] Log In Success Action', props<{ token: TokenPairOutput }>());

export const resetLoggedUser = createAction('[Login Store] Reset Logged User');

export const refreshToken = createAction('[Login Request] Refresh Token', props<{ token: string }>());
export const markRefreshing = createAction('[Login Store] Mark Refreshing', props<{ value: boolean }>());

export const logout = createAction('[Login Store] Log Out');
