import { HttpClient } from '@angular/common/http';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Observable } from 'rxjs';
import { Criteria } from 'shared/criteria/Criteria';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { DynamicField } from 'shared/modules/dynamic-form/field-types';

export interface CourierOutput {
  id: string;
  name: string;
}

export interface StatusPairInput {
  statusId: string | number;
  courierState: number | string;
}

export interface CourierSettingsInput {
  apiSettings: any;
  statusAfterUnloadingId: string | null;
  statusPairs: StatusPairInput[];
  coverageUsage: boolean;
  reserveUsage: boolean;
  secondGoodsNameUsage: boolean;
  trackingMetadata: TrackingMetadata | null;
}

export interface TrackingMetadata {
  extraField: string;
  additionalIdx: number;
}

export interface StatusShortOutput {
  id: string;
  name: string;
}

export interface StatusPairOutput {
  courierState: number;
  status: StatusShortOutput;
}

export interface CourierSettingsOutput {
  id: string;
  name: string;
  apiSettings: DynamicField[];
  statusPairs: StatusPairOutput[];
  statusAfterUnloading: StatusShortOutput | null;
  isCoverageUsed: boolean;
  isReserveUsed: boolean;
  isSecondGoodsNameUsed: boolean;
  trackingMetadata: TrackingMetadata | null;
}

export interface StatusPairInput {
  statusId: string | number;
  courierState: number | string;
}

@ProjectInjectable()
export class CourierService {

  constructor(private http: HttpClient) {}

  getAllCouriers(criteria?: Criteria): Observable<CourierOutput[]> {
    return this.http.get<CourierOutput[]>(apiUrl(`/project/couriers`, criteria));
  }

  getCourierSetting(courierId: string): Observable<CourierSettingsOutput> {
    return this.http.get<CourierSettingsOutput>(apiUrl(`/project/courier/${courierId}/setting`));
  }

  updateCourierSetting(courierId: string, updateSetting: CourierSettingsInput): Observable<CourierSettingsOutput> {
    return this.http.put<CourierSettingsOutput>(apiUrl(`/project/courier/${courierId}/setting`), updateSetting);
  }

}
