import { ActionReducerMap } from '@ngrx/store';
import { RootState } from 'app/store/root/root.state';
import { projectsReducer } from 'app/store/root/projects/projects.reducer';
import { usersReducer } from 'app/store/root/users/users.reducer';
import { documentsReducer } from 'app/store/root/documents/documents.reducer';
import { webhooksReducer } from 'app/store/root/webhooks/webhooks.reducer';
import { rootCouriersReducer } from 'app/store/root/couriers/couriers.reducer';
import { simpleExtensionsReducer } from 'app/store/root/simple-extensions/simple-extensions.reducer';

export const rootReducer: ActionReducerMap<RootState, any> = {
  projects: projectsReducer,
  users: usersReducer,
  documents: documentsReducer,
  webhooks: webhooksReducer,
  couriers: rootCouriersReducer,
  simpleExtensions: simpleExtensionsReducer
};
