import { createSelector } from '@ngrx/store';
import { ProjectState, selectProjectState } from 'app/store/project/project.state';
import * as fromCouriers from './couriers.reducer';
import { Uuid } from 'shared/common/types';

export const couriersState = createSelector(
  selectProjectState,
  state => state.couriers
);

export const couriers = createSelector(
  selectProjectState,
  (state: ProjectState) => fromCouriers.selectAll(state.couriers)
);

export const courierEntities = createSelector(
  selectProjectState,
  state => fromCouriers.selectEntities(state.couriers)
);

export const oneCourier = createSelector(
  selectProjectState,
  (state: ProjectState, props: { id: Uuid }) => fromCouriers.selectEntities(state.couriers)[props.id]
);

export const couriersCount = createSelector(
  selectProjectState,
  state => fromCouriers.selectTotal(state.couriers)
);

export const couriersLoading = createSelector(
  selectProjectState,
  state => state.couriers.loading
);
