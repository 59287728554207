import { Component, Inject } from '@angular/core';
import { RuleSet } from 'angular2-query-builder';
import { addFilterTemplate, removeFilterTemplate } from 'app/store/project/filter-templates/filter-templates.actions';
import { TemplateOutput } from 'core/http/project/rule-set/user-templates.service';
import { Store } from '@ngrx/store';
import { orderFieldMap } from 'app/store/project/field-map/field-map.selectors';
import { Uuid } from 'shared/common/types';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface RoutableQueryBuilderData {
  chainedId: Uuid;
  ruleSet: RuleSet;
}

@Component({
  selector: 'app-routable-query-builder',
  templateUrl: './routable-query-builder.component.html',
  styleUrls: ['./routable-query-builder.component.scss']
})
export class RoutableQueryBuilderComponent {

  readonly chainedId: Uuid;
  readonly fieldMap$ = this.store$.select(orderFieldMap);

  private _ruleSet: RuleSet;

  get ruleSet(): RuleSet {
    return this._ruleSet;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { data: RoutableQueryBuilderData },
    private store$: Store
  ) {
    this.chainedId = data.data.chainedId;
    this._ruleSet = data.data.ruleSet;
  }

  append(name: string, ruleSet: RuleSet): void {
    this.store$.dispatch(addFilterTemplate({ template: { name, ruleSet, type: 'order' } }));
  }

  remove(template: TemplateOutput): void {
    this.store$.dispatch(removeFilterTemplate({ id: template.id }));
  }

  paste(template: TemplateOutput): void {
    this._ruleSet = template.ruleSet;
  }

}
