import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-yes-no',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.scss']
})
export class YesNoComponent implements OnInit {

  @Input() actionName = 'Сохранить';
  @Input() cancelName = 'Отмена';
  @Input() actionIcon?: string;
  @Input() disableReason = false;
  @Input() actionType = 'submit';

  @Output() action = new EventEmitter();
  @Output() cancel = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
