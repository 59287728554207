import { HttpClient } from '@angular/common/http';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Observable } from 'rxjs';
import { Criteria } from 'shared/criteria/Criteria';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { SenderOutput } from 'core/http/project/sender/sender.service';
import { CourierOutput } from 'core/http/project/courier/courier.service';
import { Uuid } from 'shared/common/types';
import { StorehouseOutput } from 'core/http/project';
import { RuleSet } from 'angular2-query-builder';

export type SplitterType = 'NULL' | 'PERCENT' | 'COUNT';

export type Splitter = {
  type: SplitterType,
  value: number
};

export interface CourierChainOutput {
  id: Uuid;
  name: string;
  chainedCouriers: ChainedCourierOutput[];
  mergeSame: boolean;
  optionIdx: number | null;
}

export interface ChainedCourierOutput {
  priority: number;
  chainedId?: Uuid;
  sender: SenderOutput;
  courier: CourierOutput;
  storehouse: StorehouseOutput | null;
  ruleSet: RuleSet;
  orderings: ChainedOrdering[];
  splitter: Splitter;
  interruptionIfFailureUsed: boolean;
  metadata: string | null;
  unloadedCancellationUsed: boolean;
}

export interface CourierChainInput {
  chainName: string;
  chainedCouriers: ChainedInput[];
  optionIdx: number | null;
}

export interface ChainedInput {
  chainedId?: Uuid;
  priority: number;
  courierId: Uuid;
  senderId: Uuid;
  storehouseId: Uuid | null;
  ruleSet: RuleSet;
  splitter: Splitter;
  interruptionIfFailureUsage: boolean;
  metadata: string | null;
  orderings: ChainedOrdering[];
  unloadedCancellationUsage: boolean;
}

export interface ChainedOrdering {
  field: string;
  ascending: string;
}

export interface ChainScheduledTaskCount {
  chainId: Uuid;
  tasksCount: number;
}

export interface ChainNoticeCount {
  chainId: Uuid;
  noticeCount: number;
}

@ProjectInjectable()
export class CouriersChainService {

  constructor(private http: HttpClient) { }

  getAllChains(criteria?: Criteria): Observable<CourierChainOutput[]> {
    return this.http.get<CourierChainOutput[]>(apiUrl(`/project/courier-chains`, criteria));
  }

  createChain(input: CourierChainInput): Observable<CourierChainOutput> {
    return this.http.post<CourierChainOutput>(apiUrl('/project/courier-chain'), this.withPriorities(input));
  }

  updateChain(chainId: Uuid, input: CourierChainInput): Observable<CourierChainOutput> {
    return this.http.patch<CourierChainOutput>(apiUrl(`/project/courier-chain/${chainId}`), this.withPriorities(input));
  }

  getNoticeChainCount(): Observable<ChainNoticeCount[]> {
    return this.http.get<ChainNoticeCount[]>(apiUrl(`/project/courier-chains/notice-count`));
  }

  removeChain(chainId: Uuid): Observable<Object> {
    return this.http.delete(apiUrl(`/project/courier-chain/${chainId}`));
  }

  private withPriorities(input: CourierChainInput): CourierChainInput {
    const chainedCouriers = input.chainedCouriers.map((chained, index) => ({ ...chained, priority: index }));
    return { ...input, chainedCouriers };
  }

}
