import { createSelector } from '@ngrx/store';
import { ProjectState, selectProjectState } from 'app/store/project/project.state';
import * as fromExtensions from './extensions.reducer';
import { Uuid } from 'shared/common/types';

export const extensionsState = createSelector(
  selectProjectState,
  state => state.extensions
);

export const extensions = createSelector(
  selectProjectState,
  state => fromExtensions.selectAll(state.extensions)
);

export const oneExtension = createSelector(
  selectProjectState,
  (state: ProjectState, id: Uuid) => fromExtensions.selectEntities(state.extensions)[id]
);

export const extensionsLoading = createSelector(
  selectProjectState,
  state => state.extensions.loading
);
