import { Component, Inject, Injectable } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

export interface ConfirmButton {
  name: string;
  handler?: (e?: MouseEvent) => void;
  color: ThemePalette;
}

export type AskConfig = {
  message: string | { content: string, args?: _.Dictionary<string | number> },
  buttons: ConfirmButton[],
};

@Injectable({
  providedIn: 'root'
})
export class ConfirmActionService {

  constructor(private dialog: MatDialog, private translateService: TranslateService) { }

  show(message: string, buttons: ConfirmButton[]): void {
    this.dialog.open(ConfirmActionModalComponent, {
      data: { message, buttons }
    });
  }

  ask(config: AskConfig): void {
    if (typeof config.message === 'string') {
      this.translateService.get(config.message)
        .subscribe(message => this.show(message, config.buttons));
    } else {
      this.translateService.get(config.message.content, config.message.args)
        .subscribe(message => this.show(message, config.buttons));
    }
  }

  info(message: string): void {
    this.dialog.open(ConfirmActionModalComponent, {
      data: { message, buttons: [Buttons.primary('OK')] }
    });
  }
}

export const Buttons = {
  warn: (name: string, handler?: (e?: MouseEvent) => void): ConfirmButton => ({ name, handler, color: 'warn' }),
  primary: (name: string, handler?: (e?: MouseEvent) => void): ConfirmButton => ({ name, handler, color: 'primary' })
};

@Component({
  selector: 'app-confirm-action',
  template: `
    <mat-dialog-content>
      <p>{{ data.message }}</p>
    </mat-dialog-content>
    <app-button-group>
      <button mat-raised-button [color]="button.color" *ngFor="let button of data.buttons"
              (click)="handleClick(button, $event)">{{ button.name | translate }}</button>
    </app-button-group>
  `,
})
export class ConfirmActionModalComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { message: string, buttons: ConfirmButton[] },
    public dialogRef: MatDialogRef<ConfirmActionModalComponent>
  ) {}

  handleClick(button: ConfirmButton, e: MouseEvent): void {
    this.dialogRef.close();

    if (button.handler) {
      return button.handler(e);
    }
  }
}
