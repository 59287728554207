import { forkJoin, Observable, of, OperatorFunction } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as ProjectRoutingActions from '../../store/common-effects/router.effects';
import { projectResource } from 'app/store/project/project-resources/project-resources.selectors';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { currentProject } from 'app/store/project/current-project/current-project.selectors';
import { CHANGELOG_VERSIONS } from 'shared/modules/skeleton/change-log/change-log.component';
import { CachedData, ProjectResourceType } from 'core/http/project/resource-updates/resource-updates.service';

type ArrEnumFn<TSource, TOutput> = (current: TSource, index: number, collection: TSource[]) => TOutput;

export const filterNil = <T>() => (source: Observable<T>) => source.pipe(
  filter((value: T): value is NonNullable<T> => value !== null && value !== undefined)
);

export const arrayMap = <TSource, TOutput>(fn: ArrEnumFn<TSource, TOutput>) => {
  return (source: Observable<TSource[]>) => source.pipe(
    map(array => array.map(fn))
  );
};

export const arrayFilter = <TSource, TOutput>(fn: ArrEnumFn<TSource, TOutput>) => {
  return (source: Observable<TSource[]>) => source.pipe(
    map(array => array.filter(fn))
  );
};

export const to404PageIfNotFound = <T>(store: Store, section: 'project' | 'root' | 'dev' = 'project') => {
  return (source: Observable<T>) => source.pipe(
    tap(value => {
      if (value) {
        return;
      }

      switch (section) {
        case 'project':
          store.dispatch(ProjectRoutingActions.navigateToProject({ path: ['not-found'] }));
          break;
        case 'root':
          store.dispatch(ProjectRoutingActions.navigateToRoot({ path: ['not-found'] }));
          break;
        case 'dev':
          store.dispatch(ProjectRoutingActions.navigateToDev({ path: ['not-found'] }));
          break;
      }
    }),
    filter((value: T): value is NonNullable<T> => value !== null && value !== undefined)
  );
};

export const selectFirst = <T, Props, K>(selector: (state: T, props: Props) => K, props?: Props) => {
  return (source: Observable<T>) => source.pipe(
    select(selector, props),
    first()
  );
};

export const cacheable = <K>(
  type: ProjectResourceType,
  store$: Store,
  dbService: NgxIndexedDBService,
  request: () => Observable<K>
): OperatorFunction<unknown, K> => {
  return (source: Observable<unknown>) => source.pipe(
    switchMap(() => {
      return store$.pipe(
        selectFirst(currentProject),
        switchMap(project => forkJoin({
          date: store$.pipe(selectFirst(projectResource, type)),
          projectId: of(project.id),
          cached: dbService.getByKey('project-data', `${type}-${project.id}`) as Observable<CachedData<K>>
        })),
        map(({ date, cached, projectId }) => {
          const actualVersion = CHANGELOG_VERSIONS[0].version;
          const defaultValue: CachedData<K> = {
            type: `${type}-${projectId}`,
            data: null,
            lastUpdate: date,
            appVersion: actualVersion
          };

          if (!cached) {
            return defaultValue;
          }

          if (cached.lastUpdate !== date) {
            return defaultValue;
          }

          if (cached.appVersion !== actualVersion) {
            return defaultValue;
          }

          return cached as CachedData<K>;
        }),
        switchMap(data => {
          if (data.data === null) {
            return request().pipe(
              tap(response => dbService.update('project-data', { ...data, data: response }))
            );
          }

          return of(data.data as K);
        })
      );
    })
  );
};
