import { Component, OnInit } from '@angular/core';
import { StatusChangeOutput, StatusesHistoryService } from 'core/http/project/status/statuses-history.service';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { statusEntities } from 'app/store/project/statuses/statuses.selectors';
import { Uuid } from 'shared/common/types';

@Component({
  selector: 'app-status-change',
  templateUrl: './status-change.component.html',
  styleUrls: ['./status-change.component.scss']
})
export class StatusChangeComponent implements OnInit {

  readonly statuses$ = this.store$.select(statusEntities);
  private _statusChangeId?: Uuid | null;
  private _statusChange = {} as StatusChangeOutput;

  get statusChangeId(): Uuid | undefined | null {
    return this._statusChangeId;
  }

  get statusChange(): StatusChangeOutput {
    return this._statusChange;
  }

  constructor(
    private statusesHistoryService: StatusesHistoryService,
    private route: ActivatedRoute,
    private store$: Store
  ) { }

  ngOnInit(): void {
    // Через pipe не получается :(
    this.route.url.subscribe(() => {
      this.route.firstChild?.params
        .subscribe(p => this._statusChangeId = p.statusChangeId);
    });

    this.route.data.subscribe(data => this._statusChange = data.statusChange);
  }

}
