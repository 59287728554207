import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { ProjectLayoutComponent } from './pages/project/project-layout.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { AuthGuard } from 'core/guards/auth.guard';
import { AuthInjectable } from 'core/http/auth/auth-services';
import { ProjectOutput } from 'core/http/dev';
import { MonthsDebtsInput, PrettyDebt, ProjectService } from 'core/http/lg-logistic-rest-api/Auth/project.service';
import { forkJoin, Observable, of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { Store } from '@ngrx/store';
import { getSenders } from 'app/store/project/senders/senders.actions';
import { getGoods } from 'app/store/project/goods/goods.actions';
import { getCourierChains } from 'app/store/project/courier-chains/courier-chains.actions';
import { getCouriers } from 'app/store/project/couriers/couriers.actions';
import { getDocuments } from 'app/store/project/documents/documents.actions';
import { getPools } from 'app/store/project/pools/pools.actions';
import { getStatusChains } from 'app/store/project/status-chains/status-chains.actions';
import { getStatuses } from 'app/store/project/statuses/statuses.actions';
import { getStorehouses } from 'app/store/project/storehouses/storehouses.actions';
import { getFilterTemplates } from 'app/store/project/filter-templates/filter-templates.actions';
import {
  markCurrentProjectLoaded,
  saveCurrentProject
} from 'app/store/project/current-project/current-project.actions';
import { getOrderFieldMap } from 'app/store/project/field-map/field-map.actions';
import { couriersState } from 'app/store/project/couriers/couriers.selectors';
import { statusChainsState } from 'app/store/project/status-chains/status-chains.selectors';
import { storehousesState } from 'app/store/project/storehouses/storehouses.selectors';
import { sendersState } from 'app/store/project/senders/senders.selectors';
import { documentsState } from 'app/store/project/documents/documents.selectors';
import { courierChainsState } from 'app/store/project/courier-chains/courier-chains.selectors';
import { poolsState } from 'app/store/project/pools/pools.selectors';
import { statusesState } from 'app/store/project/statuses/statuses.selectors';
import { ProjectState } from 'app/store/project/project.state';
import { goodsState } from 'app/store/project/goods/goods.selectors';
import { orderFieldMapState } from 'app/store/project/field-map/field-map.selectors';
import { filterTemplatesState } from 'app/store/project/filter-templates/filter-templates.selectors';
import { getScheduledTasks } from 'app/store/project/scheduled-tasks/scheduled-tasks.actions';
import { scheduledTasksState } from 'app/store/project/scheduled-tasks/scheduled-tasks.selectors';
import { PageNotFoundComponent } from 'project/pages/page-not-found/page-not-found.component';
import { getAssignedProjects } from 'app/store/login/assigned-projects/assigned-projects.actions';
import { selectFirst } from 'core/utils/rx-common';
import {
  allAssignedProjects,
  assignedProjectsState
} from 'app/store/login/assigned-projects/assigned-projects.selectors';
import { getCurrencies } from 'app/store/project/currencies/currencies.actions';
import { currenciesState } from 'app/store/project/currencies/currencies.selectors';
import { CurrenciesState } from 'app/store/project/currencies/currencies.reducer';
import * as moment from 'moment';
import { navigate } from 'app/store/common-effects/router.effects';
import { resetAllPageCriteria } from 'app/store/project/page-criteria/page-criteria.actions';
import { CHANGELOG_VERSIONS } from 'shared/modules/skeleton/change-log/change-log.component';
import { getProjectResources } from 'app/store/project/project-resources/project-resources.actions';
import { projectResourcesState } from 'app/store/project/project-resources/project-resources.selectors';
import { currentProjectState } from 'app/store/project/current-project/current-project.selectors';
import { getNotices } from 'app/store/project/notices/notices.actions';
import { noticesState } from 'app/store/project/notices/notices.selectors';
import { getExtensions } from 'app/store/project/extensions/extensions.actions';
import { extensionsState } from 'app/store/project/extensions/extensions.selectors';

@AuthInjectable()
export class ProjectByRoute implements Resolve<Observable<ProjectOutput>> {

  constructor(private store$: Store) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProjectOutput> {
    const projectRoute = route.params.route;

    return this.store$.pipe(
      selectFirst(allAssignedProjects),
      map(projects => {
        const project = projects.find(p => p.route === projectRoute);

        if (!project) {
          throw new Error(`Project route '${projectRoute}' is not consider to existing projects`);
        }

        this.store$.dispatch(saveCurrentProject(project));
        this.store$.dispatch(markCurrentProjectLoaded());

        return project;
      })
    );
  }

}

@Injectable({ providedIn: 'root' })
export class NotSawChangelogResolver implements Resolve<any> {

  constructor(private store$: Store) {}

  resolve(): Observable<any> {
    const saw = localStorage.getItem('user-saw');

    const now = moment().format('DD.MM.YYYY');
    const lastRealise = CHANGELOG_VERSIONS[0].date;
    const dayAfterRealise = moment(lastRealise, 'DD.MM.YYYY').add(1, 'day').format('DD.MM.YYYY');

    const realiseIsToday = now === lastRealise || now === dayAfterRealise;
    const result = realiseIsToday && !saw;

    result && this.store$.dispatch(navigate({ path: ['/', 'changelog'] }));

    return of(null);
  }

}

@Injectable({ providedIn: 'root' })
export class AssignedProjectsResolver implements Resolve<any> {

  constructor(private store$: Store) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    this.store$.dispatch(getAssignedProjects());

    return this.store$.select(assignedProjectsState).pipe(first(s => s.isLoaded));
  }

}

@AuthInjectable()
export class MonthsDebtsResolver implements Resolve<PrettyDebt[]> {

  constructor(private projectService: ProjectService) {}

  resolve(): Observable<PrettyDebt[]> {
    const today = moment().format('YYYY-MM-DD');
    const sixMonthsAgo = moment()
      .subtract(5, 'months')
      .set('date', 1)
      .format('YYYY-MM-DD');

    const input: MonthsDebtsInput = {
      cutDate: today,
      unloadedFrom: sixMonthsAgo,
      unloadedTo: today
    };

    return this.projectService.getMonthsDebts(input);
  }

}

@ProjectInjectable()
export class ProjectStateResolver implements Resolve<any> {

  constructor(private store$: Store<ProjectState>) { }

  resolve(): Observable<any> {
    this.store$.dispatch(getProjectResources());
    this.store$.dispatch(resetAllPageCriteria());

    return forkJoin({
      resources: this.store$.select(projectResourcesState).pipe(first(s => s.isLoaded)),
      currentProject: this.store$.select(currentProjectState).pipe(first(s => s.isLoaded))
    }).pipe(
      tap(() => {
        this.store$.dispatch(getCourierChains());
        this.store$.dispatch(getCouriers());
        this.store$.dispatch(getDocuments());
        this.store$.dispatch(getPools());
        this.store$.dispatch(getSenders());
        this.store$.dispatch(getStatusChains());
        this.store$.dispatch(getStatuses());
        this.store$.dispatch(getStorehouses());
        this.store$.dispatch(getGoods());
        this.store$.dispatch(getOrderFieldMap());
        this.store$.dispatch(getFilterTemplates());
        this.store$.dispatch(getScheduledTasks());
        this.store$.dispatch(getNotices());
        this.store$.dispatch(getExtensions());
      }),
      switchMap(() => {
        return forkJoin({
          couriers: this.store$.select(couriersState).pipe(first(s => s.loaded)),
          courierChains: this.store$.select(courierChainsState).pipe(first(s => s.loaded)),
          documents: this.store$.select(documentsState).pipe(first(s => s.loaded)),
          pools: this.store$.select(poolsState).pipe(first(s => s.loaded)),
          goods: this.store$.select(goodsState).pipe(first(s => s.loaded)),
          scheduledTasks: this.store$.select(scheduledTasksState).pipe(first(s => s.loaded)),
          senders: this.store$.select(sendersState).pipe().pipe(first(s => s.loaded)),
          statuses: this.store$.select(statusesState).pipe(first(s => s.loaded)),
          statusChains: this.store$.select(statusChainsState).pipe(first(s => s.loaded)),
          storehouses: this.store$.select(storehousesState).pipe(first(s => s.loaded)),
          orderFieldMap: this.store$.select(orderFieldMapState).pipe(first(s => s.isLoaded)),
          filterTemplates: this.store$.select(filterTemplatesState).pipe(first(s => s.loaded)),
          notices: this.store$.select(noticesState).pipe(first(s => s.loaded)),
          extensions: this.store$.select(extensionsState).pipe(first(s => s.loaded))
        });
      })
    );
  }
}

@Injectable({ providedIn: 'root' })
export class CurrencyResolver implements Resolve<CurrenciesState> {

  constructor(private store$: Store) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CurrenciesState> {
    this.store$.dispatch(getCurrencies());

    return this.store$.select(currenciesState).pipe(first(s => s.isLoaded));
  }
}

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      changelog: NotSawChangelogResolver,
      projects: AssignedProjectsResolver,
      currency: CurrencyResolver
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ProjectsComponent,
        resolve: {
          // На случай если надо вернуть как было
          // projects: ProjectWithDebtsResolver,
          debts: MonthsDebtsResolver
        },
        data: { title: 'Дашборд' }
      },
      {
        path: ':route',
        component: ProjectLayoutComponent,
        resolve: {
          project: ProjectByRoute,
          projectData: ProjectStateResolver
        },
        children: [
          {
            path: '',
            loadChildren: () => import('./modules/order/order.module').then(m => m.OrderModule)
          },
          {
            path: 'progresses',
            loadChildren: () => import('./modules/progresses/progresses.module').then(m => m.ProgressesModule)
          },
          {
            path: 'reconciliation/claims',
            loadChildren: () => import('./modules/reconciliation/claims/claims.module').then(m => m.ClaimsModule)
          },
          {
            path: 'reconciliation/debt',
            loadChildren: () => import('./modules/reconciliation/debt/debt.module').then(m => m.DebtModule)
          },
          {
            path: 'good',
            loadChildren: () => import('./modules/goods/goods.module').then(m => m.GoodsModule)
          },
          {
            path: 'setting/scheduled-tasks',
            loadChildren: () => import('./modules/settings/scheduled-tasks/scheduled-tasks.module').then(m => m.ScheduledTasksModule)
          },
          {
            path: 'setting/notices',
            loadChildren: () => import('./modules/settings/notices/notices.module').then(m => m.NoticesModule)
          },
          {
            path: 'setting/couriers',
            loadChildren: () => import('./modules/settings/couriers/couriers.module').then(m => m.CouriersModule)
          },
          {
            path: 'setting/courier-chains',
            loadChildren: () => import('./modules/settings/courier-chains/courier-chains.module').then(m => m.CourierChainsModule)
          },
          {
            path: 'setting/sequences',
            loadChildren: () => import('./modules/settings/sequences/sequences.module').then(m => m.SequencesModule)
          },
          {
            path: 'setting/documents',
            loadChildren: () => import('./modules/settings/documents/documents.module').then(m => m.DocumentsModule)
          },
          {
            path: 'setting/pools',
            loadChildren: () => import('./modules/settings/pools/pools.module').then(m => m.PoolsModule)
          },
          {
            path: 'setting/extensions',
            loadChildren: () => import('./modules/settings/extension/extension.module').then(m => m.ExtensionModule)
          },
          {
            path: 'setting/statuses',
            loadChildren: () => import('./modules/settings/status/statuses.module').then(m => m.StatusesModule)
          },
          {
            path: 'setting/status-chains',
            loadChildren: () => import('./modules/settings/status-chains/status-chains.module').then(m => m.StatusChainsModule)
          },
          {
            path: 'setting/senders',
            loadChildren: () => import('./modules/settings/senders/senders.module').then(m => m.SendersModule)
          },
          {
            path: 'setting/storehouses',
            loadChildren: () => import('./modules/settings/store-house/store.module').then(m => m.StorehouseModule)
          },
          {
            path: 'setting/current-project',
            loadChildren: () => import('./modules/settings/current-project/current-project.module').then(m => m.CurrentProjectModule)
          },
          {
            path: 'history/printing',
            loadChildren: () => import('./modules/history/printing-history/printing-history.module').then(
              m => m.PrintingHistoryModule)
          },
          {
            path: 'history/unloading',
            loadChildren: () => import('./modules/history/unloading-history/unloading.module').then(
              m => m.UnloadingModule)
          },
          {
            path: 'history/remains-transactions',
            loadChildren: () => import('./modules/history/remains-transactions/remains-transactions.module').then(
              m => m.RemainsTransactionsModule)
          },
          {
            path: 'history/statuses',
            loadChildren: () => import('./modules/history/statuses-history/statuses-history.module').then(
              m => m.StatusesHistoryModule)
          },
          {
            path: 'history/notices-log',
            loadChildren: () => import('./modules/history/notices-log/notices-log.module').then(m => m.NoticesLogModule)
          },
          {
            path: 'task',
            loadChildren: () => import('../task/task.module').then(m => m.TaskModule)
          },
          {
            path: 'analytic/:projectId/debts',
            loadChildren: () => import('./modules/reconciliation/debts-chart/debts-chart.module').then(
              m => m.DebtsChartModule)
          },
          {
            path: 'analytic/:projectId/claims',
            loadChildren: () => import('./modules/reconciliation/claims-chart/claims-chart.module').then(
              m => m.ClaimsChartModule)
          },
          {
            path: '**',
            component: PageNotFoundComponent,
            data: { title: '404 Error' }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectRoutingModule {
}
