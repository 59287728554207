import {Component, Input, OnChanges} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-date',
  styles: [`
               mat-icon {
                   font-size: 14px;
                   margin-right: 5px;
                   height: inherit;
                   width: inherit;
               }

               :host {
                   display: inline-block;
               }

               .heading-container {
                   text-align: inherit;
               }

               .sub-title {
      color: rgba(0, 0, 0, 0.5);
    }

    .center {
      align-self: center;
    }

  `],
  template: `
    <div [matTooltip]="tooltip || null" style="display: flex">
      <mat-icon *ngIf="icon" color="primary">{{icon}}</mat-icon>
      <div class="heading-container">
        <ng-template [ngIf]="isValid" [ngIfElse]="onDateInvalid">
          <div class="center">{{ display }}</div>
          <div *ngIf="needAgo" class="sub-title">{{ dateAgo }}</div>
        </ng-template>
        <ng-template #onDateInvalid>
          <app-colorized-text>{{placeholder}}</app-colorized-text>
        </ng-template>
      </div>
    </div>
  `
})
export class DateComponent implements OnChanges {

  @Input() date!: string;
  @Input() icon?: string;
  @Input() tooltip?: string;
  @Input() needAgo = true;

  @Input() placeholder = 'Нет значения';
  isValid = true;

  dateAgo?: string;
  display!: string;

  ngOnChanges(): void {
    const date = moment.utc(this.date).local();
    this.isValid = date.isValid();

    if (!this.isValid) {
      return;
    }

    this.display = date.format('YYYY-MM-DD H:mm');
    this.dateAgo = date.fromNow();
  }

}
