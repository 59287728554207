import { createSelector } from '@ngrx/store';
import * as fromWebhooks from './webhooks.reducer';
import { RootState, selectRootState } from 'app/store/root/root.state';
import { Uuid } from 'shared/common/types';

export const webhooksState = createSelector(
  selectRootState,
  state => state.webhooks
);

export const webhooks = createSelector(
  selectRootState,
  state => fromWebhooks.selectAll(state.webhooks)
);

export const oneWebhook = createSelector(
  selectRootState,
  (state: RootState, props: { id: Uuid }) => fromWebhooks.selectEntities(state.webhooks)[props.id]
);

export const events = createSelector(
  selectRootState,
  state => state.webhooks.events
);
