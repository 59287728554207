import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserModule } from 'root/user/user.module';
import { RootRoutingModule } from './root-routing.module';
import { SharedModule } from 'shared/shared.module';
import { RootLayoutComponent } from './root-layout.component';
import { WebhooksModule } from 'root/webhooks/webhooks.module';
import { RootServicesModule } from 'core/http/root/root-services.module';
import { ExtensionsModule } from 'root/extensions/extensions.module';
import { AuthServicesModule } from 'core/http/auth/auth-services';
import { InviteModule } from 'root/invite/invite.module';
import { EffectsModule } from '@ngrx/effects';
import { ProjectsEffects } from 'app/store/root/projects/projects.effects';
import { UsersEffects } from 'app/store/root/users/users.effects';
import { DocumentsEffects } from 'app/store/root/documents/documents.effects';
import { WebhooksEffects } from 'app/store/root/webhooks/webhooks.effects';
import { RouterEffects } from 'app/store/common-effects/router.effects';
import { PageNotFoundModule } from 'project/pages/page-not-found/page-not-found.module';
import { CouriersEffects } from 'app/store/root/couriers/couriers.effects';
import { SimpleExtensionsEffects } from 'app/store/root/simple-extensions/simple-extensions.effects';

const effects = [
  ProjectsEffects,
  UsersEffects,
  DocumentsEffects,
  WebhooksEffects,
  RouterEffects,
  CouriersEffects,
  SimpleExtensionsEffects
];

@NgModule({
  declarations: [RootLayoutComponent],
  imports: [
    CommonModule,
    SharedModule,
    RootRoutingModule,
    UserModule,
    WebhooksModule,
    ExtensionsModule,
    AuthServicesModule,
    RootServicesModule,
    InviteModule,
    PageNotFoundModule,
    EffectsModule.forFeature(effects)
  ],
  providers: []
})
export class RootModule {
}
