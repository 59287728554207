import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'shared/modules/material/material.module';
import { AngularModule } from 'shared/modules/angular/angular.module';
import { DynamicFormComponent } from './dynamic-form.component';
import { ControlsModule } from 'shared/modules/controls/controls.module';
import { ListModule } from 'shared/modules/list/list.module';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { SkeletonModule } from 'shared/modules/skeleton/skeleton.module';
import { TypographyModule } from 'shared/modules/typography/typography.module';
import { SubsetViewComponent } from './subset-view/subset-view.component';
import { SubsetDialogComponent } from './subset-dialog/subset-dialog.component';

@NgModule({
  declarations: [
    DynamicFormComponent,
    FileUploadComponent,
    SubsetViewComponent,
    SubsetDialogComponent
  ],
  exports: [
    DynamicFormComponent,
    FileUploadComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    AngularModule,
    ControlsModule,
    ListModule,
    SkeletonModule,
    TypographyModule
  ]
})
export class DynamicFormModule {
}
