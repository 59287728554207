import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NoticeLogOutput } from 'core/http/project/notices-log/notices-log.service';
import { Store } from '@ngrx/store';
import { navigateToProject } from 'app/store/common-effects/router.effects';

@Component({
  selector: 'app-notice-info',
  templateUrl: './notice-info.component.html',
  styleUrls: ['./notice-info.component.scss']
})
export class NoticeInfoComponent {

  readonly notice: NoticeLogOutput = this.route.snapshot.data.notice;

  constructor(
    private route: ActivatedRoute,
    private store$: Store
  ) { }

  goBack(): void {
    this.store$.dispatch(navigateToProject({ path: ['history', 'notices-log'] }));
  }

}
