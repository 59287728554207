import { createAction, props } from '@ngrx/store';
import { ProjectOutput } from 'core/http/dev';
import { Uuid } from 'shared/common/types';
import { Update } from '@ngrx/entity';
import { ProjectInput } from 'core/http/root';

export const getProjects = createAction('[Projects Request] Get Projects');
export const saveProjects = createAction('[Projects Store] Save Projects In Store',
  props<{ projects: ProjectOutput[] }>());
export const markProjectsLoaded = createAction('[Projects Store] Mark Projects Loaded');

export const createRootProject = createAction('[Root Projects Request] Create Project',
  props<{ input: ProjectInput }>());
export const addRootProjectInStore = createAction('[Root Projects Store] Add Project In Store',
  props<{ project: ProjectOutput }>());

export const updateRootProject = createAction('[Root Projects Request] Update Project',
  props<{ id: Uuid, input: ProjectInput }>());
export const updateRootProjectInStore = createAction('[Root Projects Store] Update Project In Store',
  props<{ update: Update<ProjectOutput> }>());

export const activateProject = createAction('[Root Projects Request] Activate Project', props<{ id: Uuid }>());
export const deactivateProject = createAction('[Root Projects Request] Deactivate Project', props<{ id: Uuid }>());
