import { CheckAssociationOutput, TrackReserveOutput } from 'core/http/project';
import { Observable, of } from 'rxjs';
import { ReserveCheckDialogComponent } from 'shared/dialogs/reserve-check-dialog/reserve-check-dialog.component';
import { map } from 'rxjs/operators';
import { AssociationDialogComponent } from 'shared/dialogs/association-dialog/association-dialog.component';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { MatDialog } from '@angular/material/dialog';

@ProjectInjectable()
export class UnloadingCheckDialogsService {

  constructor(private dialog: MatDialog) {}

  checkReservesIfNeed(reserves: TrackReserveOutput[], orderCount: number): Observable<boolean> {
    if (reserves.some(r => r.remainingCount <= orderCount)) {
      const config = { data: { orderCount: orderCount, reserves } };
      const dialogRef = this.dialog.open(ReserveCheckDialogComponent, config);

      return dialogRef.afterClosed().pipe(
        map(result => result === 'RESUME_UNLOADING')
      );
    }

    return of(true);
  }

  checkAssociationsIfNeed(associations: CheckAssociationOutput[]): Observable<boolean> {
    if (!associations.every(association => association.isAssociated)) {
      const config = { width: '400px', data: associations };
      const dialogRef = this.dialog.open(AssociationDialogComponent, config);

      return dialogRef.afterClosed().pipe(
        map(result => result === 'RESUME_UNLOADING')
      );
    }

    return of(true);
  }
}
