import { Action, createReducer, on } from '@ngrx/store';
import * as DocumentsActions from './documents.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { DevDocumentOutput } from 'core/http/dev';

export interface DevDocumentsState extends EntityState<DevDocumentOutput> {
  isLoaded: boolean;
  renderClasses: string[];
}

const adapter: EntityAdapter<DevDocumentOutput> = createEntityAdapter();

export const initialDevDocumentsState = adapter.getInitialState({
  isLoaded: false,
  renderClasses: Array<string>()
});

const reducer = createReducer(
  initialDevDocumentsState,
  on(DocumentsActions.saveDevDocuments, (state, { documents }) => adapter.setAll(documents, state)),
  on(DocumentsActions.saveRenderClasses, (state, { renderClasses }) => ({ ...state, renderClasses })),
  on(DocumentsActions.markDevDocumentsLoaded, state => ({ ...state, isLoaded: true })),
  on(DocumentsActions.addDevDocumentInStore, (state, { document }) => adapter.addOne(document, state)),
  on(DocumentsActions.updateDevDocumentInStore, (state, { update }) => adapter.updateOne(update, state))
);

export function devDocumentsReducer(state: DevDocumentsState | undefined, action: Action): DevDocumentsState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
