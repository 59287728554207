import { Component, Input } from '@angular/core';
import { Choice, DynamicField } from 'shared/modules/dynamic-form/field-types';
import { GoodOutput, SenderOutput } from 'core/http/project';
import { Uuid } from 'shared/common/types';
import { StatusOutput } from 'core/http/project/status/status.service';
import { StorehouseOutput } from 'core/http/project/store-house/storehouse.service';

@Component({
  selector: 'app-subset-view',
  templateUrl: './subset-view.component.html',
  styleUrls: ['./subset-view.component.scss']
})
export class SubsetViewComponent {

  private _subset!: DynamicField;
  private _goods = Array<GoodOutput>();
  private _senders = Array<SenderOutput>();
  private _statuses = Array<StatusOutput>();
  private _storehouses = Array<StorehouseOutput>();

  get subset(): DynamicField {
    return this._subset;
  }

  @Input()
  set subset(value: DynamicField) {
    this._subset = value;
  }

  get goods(): GoodOutput[] {
    return this._goods;
  }

  @Input()
  set goods(value: GoodOutput[]) {
    this._goods = value;
  }

  get senders(): SenderOutput[] {
    return this._senders;
  }

  @Input()
  set senders(value: SenderOutput[]) {
    this._senders = value;
  }

  get storehouses(): StorehouseOutput[] {
    return this._storehouses;
  }

  @Input()
  set storehouses(value: StorehouseOutput[]) {
    this._storehouses = value;
  }

  get statuses(): StatusOutput[] {
    return this._statuses;
  }

  @Input()
  set statuses(value: StatusOutput[]) {
    this._statuses = value;
  }

  getSelectedLabelByValue(choices: Choice[], value: string | number): string {
    const found = choices.find(c => c.value === value);

    if (!found) {
      return 'UNKNOWN';
    }

    return found.description;
  }

  getNameById(id: Uuid | null, entity: 'good' | 'sender' | 'status' | 'storehouse'): string {
    if (!id) {
      return 'Не выбрано';
    }

    let item;

    switch (entity) {
      case 'good':
        item = this.goods.find(good => good.id === id);
        break;
      case 'sender':
        item = this.senders.find(sender => sender.id === id);
        break;
      case 'status':
        item = this.statuses.find(status => status.id === id);
        break;
      case 'storehouse':
        item = this.storehouses.find(storehouse => storehouse.id === id);
        break;

    }

    if (!item) {
      return 'Не найден';
    }

    return item.name;
  }

}
