import { Component } from '@angular/core';

export interface GrouperSettings {
  groupers: Array<string | number>;
  collapse: boolean;
}

@Component({
  selector: 'app-grouper-dialog',
  templateUrl: './grouper-dialog.component.html'
})
export class GrouperDialogComponent {

  groupers: Array<string | number> = [
    'comment',
    'lgStandingData.outProjectId',
    'lgStandingData.outProjectName',
    'status.name',
    'logistic.courier',
    'cart.totalQuantity',
    'recipient.postcode',
    'recipient.region',
    'recipient.city',
    'product.name',
    'additional.additional1',
    'additional.additional2',
    'additional.additional3',
    'additional.additional4',
    'additional.additional5',
    'additional.additional6',
    'additional.additional7',
    'additional.additional8',
    'additional.additional9',
    'additional.additional10',
    'additional.additional11',
    'additional.additional12',
    'additional.additional13',
    'additional.additional14',
    'additional.additional15'
  ];

  selectedGroupers = new Array<string | number>();

}
