import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateRange } from 'shared/common/types';
import * as moment from 'moment';

@Component({
  selector: 'app-datetime-range',
  templateUrl: './datetime-range.component.html',
  styleUrls: ['./datetime-range.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatetimeRangeComponent),
    multi: true,
  }],
})
export class DatetimeRangeComponent implements ControlValueAccessor, OnInit {

  @Input() label = '';
  @Input() disabled = false;

  form = new FormGroup({
    fromDatetime: new FormControl(null),
    toDatetime: new FormControl(null)
  });

  constructor() {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe(() => this.notify());
  }

  registerOnChange(fn: () => {}): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(range?: DateRange): void {
    let from: moment.Moment | undefined;
    let to: moment.Moment | undefined;

    if (range) {
      from = range.from;
      to = range.to;
    }

    this.form.patchValue({ fromDatetime: from ? moment.utc(from) : null }, { emitEvent: false });
    this.form.patchValue({ toDatetime: to ? moment.utc(to) : null }, { emitEvent: false });
  }

  private _onChange = (_: any) => {};
  private _onTouched = () => {};

  private notify(): void {
    this._onChange({ from: this.form.controls.fromDatetime.value, to: this.form.controls.toDatetime.value });
    this._onTouched();
  }

}
