import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { errorNotify } from 'app/store/common-effects/notifier.effects';
import { navigate } from 'app/store/common-effects/router.effects';

@Injectable({ providedIn: 'root' })
export class RecoveryInviteGuard implements CanActivate {

  constructor(private store$: Store) {
  }

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    const recoveryId = next.queryParams.recoveryId;

    if (!recoveryId) {
      this.store$.dispatch(errorNotify({ message: 'Некорректная ссылка для восстановления пароля' }));
      this.store$.dispatch(navigate({ path: ['login'] }));
      return of(false);
    }

    return of(true);
  }

}
