import { createAction, props } from '@ngrx/store';
import { DevCourierInput, DevCourierOutput } from 'core/http/dev';
import { Update } from '@ngrx/entity';
import { Uuid } from 'shared/common/types';

export const getDevCouriers = createAction('[Dev Couriers Request] Get Couriers');
export const saveCouriers = createAction('[Dev Couriers Store] Save Couriers In Store', props<{ couriers: DevCourierOutput[] }>());
export const markCouriersLoaded = createAction('[Dev Couriers Store] Mark Couriers Loaded');

export const createCourier = createAction('[Dev Couriers Request] Create Courier', props<{ input: DevCourierInput }>());
export const addCourierInStore = createAction('[Dev Couriers Store] Add Courier In Store', props<{ courier: DevCourierOutput }>());

export const updateCourier = createAction('[Dev Couriers Request] Update Courier', props<{ id: Uuid, input: DevCourierInput }>());
export const updateCourierInStore = createAction('[Dev Couriers Store] Update Courier In Store', props<{ update: Update<DevCourierOutput> }>());

export const getClasses = createAction('[Dev Couriers Request] Get Classes');
export const saveClasses = createAction('[Dev Couriers Store] Save Classes In Store',
  props<{
    integrationClasses: string[],
    trackerClasses: string[],
    cancellerClasses: string[]
  }>()
);
