import { Component, Input } from '@angular/core';
import * as moment from 'moment';

interface Time {
  hours: number;
  minutes: number;
  seconds: number;
}

@Component({
  selector: 'app-elapsed-time',
  templateUrl: './elapsed-time.component.html',
  styleUrls: ['./elapsed-time.component.scss']
})
export class ElapsedTimeComponent {

  @Input() startedAt = '';
  @Input() stoppedAt?: string | null;
  @Input() elapsedTime?: number;

  get time(): Time {
    const seconds = this.stoppedAt ? moment(this.stoppedAt).diff(moment(this.startedAt)) : this.elapsedTime;
    const duration = moment.duration(seconds!);

    return {
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds()
    };
  }

}
