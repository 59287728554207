import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, takeWhile } from 'rxjs/operators';
import * as AssignedProjectsActions from './assigned-projects.actions';
import { ProjectService } from 'core/http/lg-logistic-rest-api/Auth/project.service';
import { Store } from '@ngrx/store';
import { assignedProjectsState } from 'app/store/login/assigned-projects/assigned-projects.selectors';
import { selectFirst } from 'core/utils/rx-common';

@Injectable()
export class AssignedProjectsEffects {

  getAssignedProjects$ = createEffect(() => this.actions$.pipe(
    ofType(AssignedProjectsActions.getAssignedProjects),
    switchMap(() => this.store$.pipe(selectFirst(assignedProjectsState))),
    takeWhile(projects => !projects.isLoaded),
    switchMap(() => this.projectService.getProjects()),
    switchMap(projects => [
      AssignedProjectsActions.saveAssignedProjectsInState({ projects }),
      AssignedProjectsActions.markAssignedProjectsLoaded()
    ])
  ));

  constructor(
    private actions$: Actions,
    private projectService: ProjectService,
    private store$: Store
  ) { }

}
