import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent {

  @Input() allowCopy = false;
  @Input() removable = false;
  @Input() size: 'large' | 'small' = 'large';
  @Input() backgroundColor: 'primary' | 'muted' = 'primary';
  @Output() removed = new EventEmitter();

}
