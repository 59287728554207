import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Criteria, has } from 'shared/criteria/Criteria';
import { navigateToRoot } from 'app/store/common-effects/router.effects';
import { Store } from '@ngrx/store';
import { createIfPossible } from 'app/store/root/webhooks/webhooks.actions';
import { Observable } from 'rxjs';
import { KeyValue } from 'shared/common/types';
import { events } from 'app/store/root/webhooks/webhooks.selectors';

@Component({
  selector: 'app-create-webhook',
  templateUrl: './create-webhook.component.html',
  styleUrls: ['./create-webhook.component.scss']
})
export class CreateWebhookComponent {

  private _events$ = this.store$.select(events);
  private _webhookForm = this.fb.group({
    url: this.fb.control(null, Validators.required),
    event: this.fb.control(null, Validators.required),
    retries: this.fb.array([]),
    isActivated: this.fb.control(false)
  });

  get webhookForm(): FormGroup {
    return this._webhookForm;
  }

  get events$(): Observable<KeyValue[]> {
    return this._events$;
  }

  get retries(): FormArray {
    return this.webhookForm.get('retries') as FormArray;
  }

  constructor(
    public fb: FormBuilder,
    private store$: Store
  ) { }

  createWebhook(): void {
    const criteria = new Criteria({
      filter: has.values('event.name', [this.webhookForm.value.event])
    });

    this.store$.dispatch(createIfPossible({ input: this.webhookForm.value, criteria }));
  }

  addInterval(): void {
    this.retries.push(this.fb.control(5));
  }

  removeInterval(): void {
    if (this.retries.length > 0) {
      this.retries.removeAt(this.retries.length - 1);
    }
  }

  goBack(): void {
    this.store$.dispatch(navigateToRoot({ path: ['integration', 'webhooks'] }));
  }

}
