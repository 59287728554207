import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { CourierChainOutput } from 'core/http/project';
import {
  CompletedEntityState,
  initialCompletedEntityState
} from 'app/store/completed-entity-state/completed-entity-state';
import * as CourierChainsActions from './courier-chains.actions';

export interface CourierChainsState extends CompletedEntityState<CourierChainOutput> {}

const adapter: EntityAdapter<CourierChainOutput> = createEntityAdapter<CourierChainOutput>();

export const initialCourierChainsState = adapter.getInitialState(initialCompletedEntityState);

const reducer = createReducer(
  initialCourierChainsState,
  on(CourierChainsActions.saveCourierChains, (state, { chains }) => adapter.setAll(chains, state)),
  on(CourierChainsActions.markCourierChainsLoaded, state => ({ ...state, loaded: true })),
  on(CourierChainsActions.addCourierChain, (state, chain) => adapter.addOne(chain, state)),
  on(CourierChainsActions.removeCourierChainFromStore, (state, { id }) => adapter.removeOne(id, state)),
  on(CourierChainsActions.updateCourierChainInStore, (state, { update }) => adapter.updateOne(update, state)),
  on(CourierChainsActions.setLoading, (state, { value }) => ({ ...state, loading: value })),
  on(CourierChainsActions.setError, (state, { value }) => ({ ...state, error: value }))
);

export function courierChainsReducer(state: CourierChainsState | undefined, action: Action): CourierChainsState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
