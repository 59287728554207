import { Rule } from 'angular2-query-builder';
import { Component, Input, OnChanges } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import * as _ from 'lodash';

@Component({
  selector: 'app-chip-string-query-input',
  templateUrl: './chip-string-query-input.component.html',
  styleUrls: ['./chip-string-query-input.component.scss']
})
export class ChipStringQueryInputComponent implements OnChanges {

  private _rule?: Rule;
  private _onChange?: () => void;
  private _separators = [ENTER, COMMA];

  ngOnChanges(): void {
    // Если значение rule уже массив из строк, то ничего не будем делать.
    if (Array.isArray(this.rule.value) && this.rule.value.every(s => typeof s === 'string')) {
      return;
    }

    // Иначе проинициализируем пустым массивом.
    this.rule.value = Array<string>();
  }

  get separators(): number[] {
    return this._separators;
  }

  get rule(): Rule {
    if (!this._rule) {
      throw new Error(`Required input 'rule' is not specified`);
    }

    return this._rule;
  }

  @Input()
  set rule(rule: Rule) {
    this._rule = rule;
  }

  get onChange(): () => void {
    if (!this._onChange) {
      throw new Error(`Required input 'onChange' is not specified`);
    }

    return this._onChange;
  }

  @Input()
  set onChange(onChange: () => void) {
    this._onChange = onChange;
  }

  remove(value: string): void {
    const values: string[] = this.rule.value;
    this.rule.value = values.filter(v => v !== value);

    this.onChange();
  }

  append(mode: 'single' | 'many', input: HTMLInputElement): void {
    const values = mode === 'many'
      ? input.value.split(' ')
      : [input.value];

    this.appendValues(values);
    input.value = '';
  }

  appendValues(values: string[]): void {
    const stringValues = values.map(n => n.trim())
      .filter(n => n !== '');

    const oldValues: string[] = this.rule.value;
    this.rule.value = _.uniq(oldValues.concat(stringValues));

    this.onChange();
  }

}

