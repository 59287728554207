import { Component, Input, HostBinding } from '@angular/core';
var VerticalTimelineCardComponent = /** @class */ (function () {
    function VerticalTimelineCardComponent() {
        this.disabled = false;
        this.isATimelineItem = false;
    }
    VerticalTimelineCardComponent.prototype.ngOnInit = function () {
        this.isATimelineItem = true; // set class `timeline-item` on host `<div>`
        if (this.dateValue === null || this.dateValue === undefined) {
            this.dateValue = new Date();
        }
        if (this.color === null || this.color === undefined) {
            this.color = '#3F51B5';
        }
        if (this.isLight(this.color)) {
            this.textColor = '#000000';
        }
        else {
            this.textColor = '#FFFFFF';
        }
    };
    VerticalTimelineCardComponent.prototype.isLight = function (hexColor) {
        var R = parseInt(hexColor.slice(1, 3), 16);
        var G = parseInt(hexColor.slice(3, 5), 16);
        var B = parseInt(hexColor.slice(5, 7), 16);
        var maxBrightness = this.calculateBrightness(255, 255, 255);
        var brightness = this.calculateBrightness(R, G, B);
        var pBrightness = brightness / maxBrightness;
        return pBrightness > 0.5;
    };
    // HSP rule sqrt( .299 R2 + .587 G2 + .114 B2 ), see http://alienryderflex.com/hsp.html
    // HSP rule sqrt( .299 R2 + .587 G2 + .114 B2 ), see http://alienryderflex.com/hsp.html
    VerticalTimelineCardComponent.prototype.calculateBrightness = 
    // HSP rule sqrt( .299 R2 + .587 G2 + .114 B2 ), see http://alienryderflex.com/hsp.html
    function (R, G, B) {
        return Math.sqrt((0.299 * Math.pow(R, 2)) +
            (0.587 * Math.pow(G, 2)) +
            (0.114 * Math.pow(B, 2)));
    };
    VerticalTimelineCardComponent.decorators = [
        { type: Component, args: [{
                    selector: 'vertical-timeline-card',
                    template: "\n      <div>\n        <div class=\"timeline-img\" [style.background]=\"color\">\n          <p *ngIf=\"!timeString || timeString === ''\" [style.color]=\"textColor\">{{dateValue | date: \"HH:mm\"}}</p>\n          <p *ngIf=\"timeString && timeString != ''\" [style.color]=\"textColor\">{{timeString}}</p>\n        </div>\n\n        <div class=\"timeline-content\" [ngClass]=\"{'disabled-element': disabled}\">\n          <div class=\"date\" [style.background]=\"color\">\n            <p [style.color]=\"textColor\">{{dateValue | date:\"dd MMMM yyyy\"}}</p>\n          </div>\n\n          <div class=\"inner-content\">\n            <ng-content></ng-content>\n          </div>\n        </div>\n      </div>\n    ",
                },] },
    ];
    /** @nocollapse */
    VerticalTimelineCardComponent.propDecorators = {
        "dateValue": [{ type: Input },],
        "timeString": [{ type: Input },],
        "color": [{ type: Input },],
        "disabled": [{ type: Input },],
        "isATimelineItem": [{ type: HostBinding, args: ['class.timeline-item',] },],
    };
    return VerticalTimelineCardComponent;
}());
export { VerticalTimelineCardComponent };
