import { Component, Inject } from '@angular/core';
import { ProjectOutput } from 'core/http/dev';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';

export interface UpdateExtensionProjectsDialogData {
  enabledProjects: ProjectOutput[];
  allProjects: ProjectOutput[];
}

export interface UpdateExtensionProjectsDialogResult {
  enabledProjects: ProjectOutput[];
}

@Component({
  selector: 'app-update-extension-projects-dialog',
  templateUrl: './update-extension-projects-dialog.component.html',
  styleUrls: ['./update-extension-projects-dialog.component.scss']
})
export class UpdateExtensionProjectsDialogComponent {

  name = '';

  get projects(): ProjectOutput[] {
    return this.data.allProjects.filter(p => p.name.toLowerCase().includes(this.name.toLowerCase()));
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: UpdateExtensionProjectsDialogData) { }

  isEnabled(project: ProjectOutput): boolean {
    return !!this.data.enabledProjects.filter((a) => a.id === project.id).length;
  }

  toggleProjectState(event: MatCheckboxChange, project: ProjectOutput): void {
    this.data.enabledProjects = event.checked
      ? this.data.enabledProjects.concat(project)
      : this.data.enabledProjects.filter(p => p.id !== project.id);
  }
}
