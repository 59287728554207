import { Injectable, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProjectInterceptor } from 'core/interceptors/project/project.interceptor';
import { RefreshTokenInterceptor } from 'core/interceptors/token/refresh-token.interceptor';
import { BannedAccountInterceptor } from 'core/interceptors/token/banned-account.interceptor';

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BannedAccountInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProjectInterceptor,
      multi: true
    }
  ]
})
export class ProjectServicesModule {
}

export function ProjectInjectable(): any {
  return Injectable({ providedIn: ProjectServicesModule });
}
