import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { ProjectService } from 'core/http/lg-logistic-rest-api/Auth/project.service';
import * as CurrenciesActions from './currencies.actions';

@Injectable()
export class CurrenciesEffects {

  getCurrencies$ = createEffect(() => this.actions$.pipe(
    ofType(CurrenciesActions.getCurrencies),
    switchMap(() => this.projectService.getRates()),
    switchMap(currencies => [
      CurrenciesActions.saveCurrencies({ currencies }),
      CurrenciesActions.markCurrenciesLoaded()
    ])
  ));

  constructor(
    private actions$: Actions,
    private projectService: ProjectService
  ) { }

}
