import { createSelector } from '@ngrx/store';
import { RootState, selectRootState } from 'app/store/root/root.state';
import { Uuid } from 'shared/common/types';
import * as fromUsers from './users.reducer';

export const usersState = createSelector(
  selectRootState,
  state => state.users
);

export const users = createSelector(
  selectRootState,
  state => fromUsers.selectAll(state.users)
);

export const oneUser = createSelector(
  selectRootState,
  (state: RootState, props: { id: Uuid }) => fromUsers.selectEntities(state.users)[props.id]
);
