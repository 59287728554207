import { Component, Input, ViewChild } from '@angular/core';
import { NavLink } from 'core/types/nav-link';
import { Observable } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavState } from 'shared/modules/skeleton/main-layout/sidenav-state';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {

  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  @Input() navLinks = Array<NavLink>();

  private sidenavStore = new SidenavState();

  get isSidenavOpen(): Observable<boolean> {
    return this.sidenavStore.isOpen;
  }

  get isSidenavOverlapped(): Observable<boolean> {
    return this.sidenavStore.isOverlapped;
  }

  toggleSidenavOpenState(): void {
    this.sidenav.toggle()
      .then((state) => this.sidenavStore.toggleOpenState(state === 'open'));
  }

  toggleOverlappedState(event: MouseEvent): void {
    event.preventDefault();
    this.sidenavStore.toggleOverlapState();
  }

}
