import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard';
import { Store } from '@ngrx/store';
import { notify } from 'app/store/common-effects/notifier.effects';
import { Observable } from 'rxjs';
import { transition, trigger, useAnimation } from '@angular/animations';
import { hideAnimation, showAnimation } from 'shared/common/animations';

@Component({
  selector: 'app-summary-dialog',
  templateUrl: './summary-dialog.component.html',
  styleUrls: ['./summary-dialog.component.scss'],
  animations: [
    trigger('show', [
      transition('void => *', [
        useAnimation(showAnimation)
      ])
    ]),
    trigger('hide', [
      transition('* => void', [
        useAnimation(hideAnimation)
      ])
    ])
  ]
})
export class SummaryDialogComponent {

  readonly html$: Observable<string>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: () => Observable<string>,
    private clipboard: ClipboardService,
    private store$: Store
  ) {
    this.html$ = data();
  }

  copyToClipboard(content: string): void {
    this.clipboard.copy(content);
    this.store$.dispatch(notify({ message: 'Текст скопирован' }));
  }

}
