import { Rule, RuleSet } from 'angular2-query-builder';
import { and, compare, has, not, or } from 'shared/criteria/Criteria';

export const STRING_TYPE = 'string';
export const STRING_OPERATORS = ['=', '!=', 'like', 'not like', 'in', 'not in'];

export const NUMBER_TYPE = 'number';
export const NUMBER_OPERATORS = ['=', '!=', '>', '>=', '<', '<=', 'in', 'not in'];

export const DATETIME_TYPE = 'datetime';
export const DATETIME_OPERATORS = ['=', '!=', '>', '>=', '<', '<='];

export const ENTITY_TYPE = 'entity';
export const ENTITY_OPERATORS = ['=', '!=', 'in', 'not in'];

export const BOOLEAN_TYPE = 'boolean';
export const BOOLEAN_OPERATORS = ['='];

export const OPERATOR_MAP: { [k: string]: string[] } = {
  [STRING_TYPE]: STRING_OPERATORS,
  [NUMBER_TYPE]: NUMBER_OPERATORS,
  [DATETIME_TYPE]: DATETIME_OPERATORS,
  [ENTITY_TYPE]: ENTITY_OPERATORS,
  [BOOLEAN_TYPE]: BOOLEAN_OPERATORS
};

export const OPERATOR_LABELS: { [k: string]: string } = {
  'like': 'Содержит',
  'not like': 'Не содержит',
  'in': 'Входит',
  'not in': 'Не входит'
};

export function toFilter(ruleSetOrRule: RuleSet | Rule): string {
  if (isRuleSet(ruleSetOrRule)) {
    const filters = ruleSetOrRule.rules.map(toFilter);
    return ruleSetOrRule.condition === 'and' ? and(...filters) : or(...filters);
  }

  const rule: Rule = ruleSetOrRule;

  switch (rule.operator) {
    case 'in':
      return has.values(rule.field, rule.value)!;

    case 'not in':
      return not(has.values(rule.field, rule.value))!;

    case 'like':
      return has.text(rule.field, rule.value)!;

    case 'not like':
      return not(has.text(rule.field, rule.value))!;

    case '=':
      return compare.eq(rule.field, rule.value)!;

    case '>':
      return compare.gt(rule.field, rule.value)!;

    case '>=':
      return compare.gte(rule.field, rule.value)!;

    case '<':
      return compare.lt(rule.field, rule.value)!;

    case '<=':
      return compare.lte(rule.field, rule.value)!;

    default:
      throw new Error(`Operator '${rule.operator}' is not supported`);
  }
}

function isRuleSet(toCheck: RuleSet | Rule): toCheck is RuleSet {
  return 'condition' in toCheck;
}
