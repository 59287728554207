import { AfterViewInit, Component, ElementRef, Host, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent implements AfterViewInit {

  @ViewChild('input')
  input!: ElementRef;

  private _name = '';

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get show(): boolean {
    return this.select.options.length > 5;
  }

  constructor(@Host() private select: MatSelect) { }

  ngAfterViewInit(): void {
    // this.input.focus();

    // Задаем эти стили сразу, чтобы при вводе фильтра сразу получить плавное исчезновение
    const options = this.select.options;

    options.forEach(o => {
      o._getHostElement().style.transition = '.3s';
      o._getHostElement().style.maxHeight = '50px';
    });
  }

  onFilterChange(value: string): void {
    const options = this.select.options;

    const hide = options.filter(o => !o.viewValue.toLowerCase().includes(value.toLowerCase().trim()));
    const show = options.filter(o => !hide.includes(o));

    hide.forEach(o => {
      o._getHostElement().style.maxHeight = '0';
      o.disabled = true;
    });
    show.forEach(o => {
      o.disabled = false;
      o._getHostElement().style.maxHeight = '50px';

      // Если использовать app-select-all когда есть скрытые mat-option,
      // то при их появлении они будут selected и будут вести себя как обычные mat-option,
      // но цвет их checkbox'a будет disabled
      const pseudoCheckbox = o._getHostElement().getElementsByClassName('mat-pseudo-checkbox')[0];
      pseudoCheckbox?.classList.remove('mat-pseudo-checkbox-disabled');
    });
  }

}
