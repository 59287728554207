import { createAction, props } from '@ngrx/store';
import { ScheduledTaskInput, ScheduledTaskOutput } from 'core/http/project/scheduled-tasks/scheduled-tasks.service';
import { Uuid } from 'shared/common/types';
import { Update } from '@ngrx/entity';
import { createSetErrorAction, createSetLoadingAction } from 'app/store/completed-entity-state/completed-entity-state';

export const getScheduledTasks = createAction('[Project Request] Get Scheduled Tasks');
export const saveScheduledTasks = createAction('[Project Store] Save Scheduled Tasks', props<{ tasks: ScheduledTaskOutput[] }>());
export const markScheduledTasksLoaded = createAction('[Project Store] Mark Scheduled Tasks Loaded');

export const createScheduledTask = createAction('[Project Request] Create Scheduled Task', props<{ input: ScheduledTaskInput }>());
export const addScheduledTaskInStore = createAction('[Project Store] Add Scheduled Task In Store', props<{ entity: ScheduledTaskOutput }>());

export const updateScheduledTask = createAction('[Project Request] Update Scheduled Task', props<{ id: Uuid, input: ScheduledTaskInput }>());
export const updateScheduledTaskInStore = createAction('[Project Store] Update Scheduled Task In Store', props<{ update: Update<ScheduledTaskOutput> }>());

export const removeScheduledTask = createAction('[Project Request] Remove Scheduled Task', props<{ id: Uuid }>());
export const removeScheduledTaskFromStore = createAction('[Project Store] Remove Scheduled Task From Store', props<{ id: Uuid }>());

export const setLoading = createSetLoadingAction('[Project Store] Set Scheduled Task Loading');

export const setError = createSetErrorAction('[Project Store] Set Scheduled Task Loading');
