import { NgModule } from '@angular/core';
import { AdditionFieldsComponent } from 'shared/modules/addition-fields/addition-fields.component';
import { LayoutModule } from 'shared/modules/layout/layout.module';
import { TableModule } from 'shared/modules/table/table.module';
import { TypographyModule } from 'shared/modules/typography/typography.module';
import { MaterialModule } from 'shared/modules/material/material.module';
import { AdditionAddDialogComponent } from './addition-add-dialog/addition-add-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ControlsModule } from 'shared/modules/controls/controls.module';
import { AngularModule } from 'shared/modules/angular/angular.module';
import { DynamicFormModule } from 'shared/modules/dynamic-form/dynamic-form.module';
import { AdditionFormComponent } from './addition-add-dialog/addition-form/addition-form.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    AdditionFieldsComponent,
    AdditionAddDialogComponent,
    AdditionFormComponent
  ],
  imports: [
    LayoutModule,
    TableModule,
    TypographyModule,
    MaterialModule,
    ReactiveFormsModule,
    ControlsModule,
    AngularModule,
    DynamicFormModule,
    TranslateModule
  ],
  exports: [AdditionFieldsComponent]
})
export class AdditionFieldsModule {
}
