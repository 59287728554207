import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, switchMap } from 'rxjs/operators';
import * as ProjectResourcesActions from './project-resources.actions';
import { ResourceUpdatesService } from 'core/http/project/resource-updates/resource-updates.service';

@Injectable()
export class ProjectResourcesEffects {

  getResources$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectResourcesActions.getProjectResources),
    exhaustMap(() => this.resourceUpdatesService.getResourceChanges()),
    switchMap(resources => [
      ProjectResourcesActions.saveProjectResources({ resources }),
      ProjectResourcesActions.markProjectResourcesLoaded()
    ])
  ));

  constructor(
    private actions$: Actions,
    private resourceUpdatesService: ResourceUpdatesService
  ) {}

}
