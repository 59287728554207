import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, props } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { DispatchCases, NotifierService } from 'core/services/notifier.service';
import { HttpErrorResponse } from '@angular/common/http';

export const notify = createAction('[Notify] Dispatch notify', props<{ message: string }>());
export const errorNotify = createAction('[Notify] Dispatch Error', props<{ message: string | string[] }>());
export const errorHttpNotify = createAction('[Notify] Dispatch Error Http', props<{ response: HttpErrorResponse }>());
export const switchCaseDispatch = createAction('[Notify] Switch Case Dispatch', props<{ err: { type: string, errorCases: DispatchCases } }>());

@Injectable()
export class NotifierEffects {

  notification$ = createEffect(() => this.actions$.pipe(
    ofType(notify),
    tap(({ message }) => this.notifier.dispatchNotification(message))
  ), { dispatch: false });

  switchCaseDispatch$ = createEffect(() => this.actions$.pipe(
    ofType(switchCaseDispatch),
    tap(({ err }) => this.notifier.switchCaseDispatch(err.type, err.errorCases))
  ), { dispatch: false });

  error$ = createEffect(() => this.actions$.pipe(
    ofType(errorNotify),
    tap(({ message }) => this.notifier.dispatchError(message))
  ), { dispatch: false });

  errorHttp$ = createEffect(() => this.actions$.pipe(
    ofType(errorHttpNotify),
    tap(({ response }) => this.notifier.dispatchHttpError(response))
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private notifier: NotifierService
  ) { }

}
