import { environment } from 'environments/environment';
import { Criteria, CriteriaParams, serializeCriteria } from 'shared/criteria/Criteria';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

export function apiUrl(path: string, criteria?: Criteria | CriteriaParams): string {
  const filter = criteria ? serializeCriteria(criteria) : '';
  return `${environment.API_URL}/api${path}${filter ? '?filter=' + encodeURIComponent(filter) : ''}`;
}

export interface HttpError {
  kind: 'HANDLED';
  type: string;
  message: string;
}

export interface UnknownError {
  kind: 'UNKNOWN';
  error: unknown;
}

export type ServiceError = HttpError | UnknownError;

export function handleError(e: any): Observable<never> {
  return throwError(_mapError(e));
}

function _mapError(e: any): ServiceError {
  if (_isHttpError(e) && _isHandledError(e.error)) {
    return { kind: 'HANDLED', type: e.error.error, message: e.error.message };
  }

  return { kind: 'UNKNOWN', error: e };
}

function _isHttpError(err: any): err is HttpErrorResponse {
  return err instanceof HttpErrorResponse;
}

function _isHandledError(err: any): err is { error: string, message: string } {
  return 'error' in err && 'message' in err;
}
