import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';

export type Role = 'ROLE_DEVELOPER' | 'ROLE_MANAGER' | 'ROLE_ROOT_ACCESS';

export interface LoggedUserOutput {
  id: string;
  username: string;
  fullName: string;
  role: Role;
}

export interface TokenPairOutput {
  expiredAt: string;
  accessToken: string;
  refreshToken: string;
  userInfo: LoggedUserOutput;
}

@Injectable()
export class TokenService {

  constructor(private http: HttpClient) {}

  obtain(username: string, password: string): Observable<TokenPairOutput> {
    return this.http.post<TokenPairOutput>(apiUrl('/auth/token/obtain'), { username, password });
  }

  refresh(refreshToken: string): Observable<TokenPairOutput> {
    return this.http.post<TokenPairOutput>(apiUrl('/auth/token/refresh'), { refreshToken });
  }

  revoke(accessToken: string): Observable<Object> {
    return this.http.post<TokenPairOutput>(apiUrl('/auth/token/revoke'), {}, {
      headers: { 'Authorization': `Bearer ${accessToken}` }
    });
  }

}
