import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserComponent } from './user/user.component';
import { SharedModule } from 'shared/shared.module';
import { AssignedProjectsComponent } from './assigned-projects/assigned-projects.component';
import { AssignedStatusesComponent } from './assigned-statuses/assigned-statuses.component';
import { UserRoutingModule } from 'root/user/user-routing.module';


@NgModule({
  declarations: [
    UserComponent,
    AssignedProjectsComponent,
    AssignedStatusesComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    UserRoutingModule
  ],
  exports: [UserComponent]
})
export class UserModule {
}
