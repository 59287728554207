import { Component, OnInit } from '@angular/core';
import {
  UpdateExtensionProjectsDialogComponent,
  UpdateExtensionProjectsDialogData,
  UpdateExtensionProjectsDialogResult
} from 'root/extensions/update-extension-projects-dialog/update-extension-projects-dialog.component';
import { CourierOutput } from 'core/http/root';
import { ProjectOutput } from 'core/http/dev';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { arrayFilter, selectFirst } from 'core/utils/rx-common';
import { rootProjects } from 'app/store/root/projects/projects.selectors';
import { rootCouriers } from 'app/store/root/couriers/couriers.selectors';
import { updateCourierExtensionProjects } from 'app/store/root/couriers/couriers.actions';
import { Subject } from 'rxjs';
import { Criteria } from 'shared/criteria/Criteria';
import { EagerSource } from 'shared/modules/table/table-utils/table-interfaces';

@Component({
  selector: 'app-courier-modules',
  templateUrl: './courier-extensions.component.html',
  styleUrls: ['./courier-extensions.component.scss']
})
export class CourierExtensionsComponent implements OnInit {

  readonly columns: string[] = [
    'courier',
    'projects',
    'matchers'
  ];

  private projects = Array<ProjectOutput>();
  private _reloader = new Subject();

  get reloader(): Subject<unknown> {
    return this._reloader;
  }

  constructor(
    private dialog: MatDialog,
    private store$: Store
  ) { }

  ngOnInit(): void {
    this.store$.pipe(selectFirst(rootProjects))
      .subscribe(data => this.projects = data);
  }

  dataSource: EagerSource<CourierOutput> = (c: Criteria) => {
    return this.store$.select(rootCouriers)
      .pipe(
        arrayFilter(courier => courier.name.toLowerCase().includes(c.filter!.toLocaleLowerCase().trim()))
      );
  };

  openCourierEditor(courier: CourierOutput): void {
    const data: UpdateExtensionProjectsDialogData = {
      allProjects: this.projects,
      enabledProjects: courier.projects
    };

    this.dialog.open(UpdateExtensionProjectsDialogComponent, { data }).afterClosed()
      .subscribe((r: UpdateExtensionProjectsDialogResult | undefined) => {
        if (!r) {
          return;
        }

        this.store$.dispatch(updateCourierExtensionProjects({
          id: courier.id,
          projects: r.enabledProjects.map(c => c.id)
        }));

        this.reloader.next();
      });
  }
}
