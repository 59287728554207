import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerticalTimelineComponent } from './vertical-timeline.component';
import { VerticalTimelineCardComponent } from './vertical-timeline-card.component';
var VerticalTimelineModule = /** @class */ (function () {
    function VerticalTimelineModule() {
    }
    VerticalTimelineModule.forRoot = function () {
        return {
            ngModule: VerticalTimelineModule,
        };
    };
    VerticalTimelineModule.decorators = [
        { type: NgModule, args: [{
                    imports: [
                        CommonModule
                    ],
                    declarations: [
                        VerticalTimelineComponent,
                        VerticalTimelineCardComponent
                    ],
                    exports: [
                        VerticalTimelineComponent,
                        VerticalTimelineCardComponent
                    ]
                },] },
    ];
    return VerticalTimelineModule;
}());
export { VerticalTimelineModule };
