import { Component } from '@angular/core';
import { NoticeLogOutput, NoticesLogService } from 'core/http/project/notices-log/notices-log.service';
import { Criteria } from 'shared/criteria/Criteria';
import { PaginatedData } from 'shared/common/types';
import { Observable } from 'rxjs';
import { getContactLabel, NoticeRecipient } from 'core/http/project/notice/notice.interfaces';
import { Store } from '@ngrx/store';
import { telegramChatsDic } from 'app/store/project/notices/notices.selectors';

@Component({
  selector: 'app-notices-log',
  templateUrl: './notices-log.component.html',
  styleUrls: ['./notices-log.component.scss']
})
export class NoticesLogComponent {

  readonly NOTICES_KEY = 'notices';
  readonly chats = this.store$.select(telegramChatsDic);

  constructor(
    private noticesLogService: NoticesLogService,
    private store$: Store
  ) { }

  dataSource: PaginatedData<NoticeLogOutput> = (c: Criteria) => {
    const criteria = c.clone({ ordering: { field: 'createdAt', ascending: 'desc' } });

    return this.noticesLogService.getNotices(criteria);
  };

  getContactLabel(recipient: NoticeRecipient): Observable<string> {
    return getContactLabel(recipient, this.chats);
  }

}
