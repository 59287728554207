import { createFeatureSelector, createSelector } from '@ngrx/store';
import { projectFeatureKey, ProjectState, selectProjectState } from 'app/store/project/project.state';

export const selectCurrentProjectState = createFeatureSelector<ProjectState>(projectFeatureKey);

export const currentProjectState = createSelector(
  selectProjectState,
  state => state.currentProject
);

export const currentProject = createSelector(
  selectCurrentProjectState,
  (state: ProjectState) => state.currentProject.currentProject
);
