import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-append-query-popover',
  templateUrl: './append-query-popover.component.html',
  styleUrls: ['./append-query-popover.component.scss']
})
export class AppendQueryPopoverComponent {

  @Output() appended = new EventEmitter<string>();

  create(name: string): void {
    this.appended.emit(name);
  }

}
