import { createAction, props } from '@ngrx/store';
import { UserOutput } from 'core/http/root';
import { Uuid } from 'shared/common/types';
import { Update } from '@ngrx/entity';

export const getUsers = createAction('[Users Request] Get Users');
export const saveUsers = createAction('[Users State] Save Users In Store', props<{ users: UserOutput[] }>());
export const markUsersLoaded = createAction('[Users State] Mark Users Loaded');

export const updateUserInStore = createAction('[Users Store] Update User In Store', props<{ update: Update<UserOutput> }>());

export const blockUser = createAction('[Users Request] Block User', props<{ id: Uuid }>());
export const restoreUser = createAction('[Users Request] Restore User', props<{ id: Uuid }>());

export const assignStatus = createAction('[Users Request] Assign Statuses', props<{ userId: Uuid, projectId: Uuid, statusIds: Uuid[] }>());

export const assignProjects = createAction('[Users Request] Assign Projects', props<{ userId: Uuid, projectIds: Uuid[] }>());
