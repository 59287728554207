import { Component, Inject } from '@angular/core';
import {
  CourierOutput,
  OrderedGoodInput,
  OrderInput,
  OrderInputAdditions,
  RecipientOutput,
  SenderOutput,
  StandingDataInput,
  UnloadedOnInput
} from 'core/http/project';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { errorNotify } from 'app/store/common-effects/notifier.effects';

interface CreateOrdersData {
  couriers: CourierOutput[];
  senders: SenderOutput[];
}

type CollectSuccess = { type: 'success', orders: OrderInput[] };
type CollectError = { type: 'error', message: string };
export type CollectResult = CollectError | CollectSuccess;

@Component({
  selector: 'app-create-orders-dialog',
  templateUrl: './create-orders-dialog.component.html',
  styleUrls: ['./create-orders-dialog.component.scss']
})
export class CreateOrdersDialogComponent {

  private _couriers = Array<CourierOutput>();
  private _senders = Array<SenderOutput>();
  private _form = new FormGroup({
    courier: new FormControl('', Validators.required),
    sender: new FormControl('', Validators.required)
  });
  private _ordersInput = Array<OrderInput>();

  get senders(): SenderOutput[] {
    return this._senders;
  }

  get couriers(): CourierOutput[] {
    return this._couriers;
  }

  get form(): FormGroup {
    return this._form;
  }

  get ordersInput(): OrderInput[] {
    return this._ordersInput;
  }

  set ordersInput(value: OrderInput[]) {
    this._ordersInput = value;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) data: CreateOrdersData,
    private store$: Store
  ) {
    this._couriers = data.couriers;
    this._senders = data.senders;
  }

  clearFile(): void {
    this.ordersInput.length = 0;
  }

  addNewFile(targetFile: File, input: HTMLInputElement): void {
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(targetFile);

    reader.onload = (file: any) => {
      const basicString: string = file.target.result;
      const workBook: XLSX.WorkBook = XLSX.read(basicString, { type: 'binary' });

      const workSheetName: string = workBook.SheetNames[0];
      const pageData = workBook.Sheets[workSheetName];

      const data: any[] = XLSX.utils.sheet_to_json(pageData, { header: 'A' });

      const result = this.collectOrderInput(data);

      switch (result.type) {
        case 'error':
          this.store$.dispatch(errorNotify({ message: result.message }));

          // Без этого нельзя выбрать один файл 2 раза подряд
          input.value = '';
          break;
        case 'success':
          this.ordersInput = result.orders;
          break;

      }

      if (this.ordersInput.length > 100) {
        this.store$.dispatch(errorNotify({ message: 'Количество заказов превышает 100' }));
      }
    };
  }

  collectOrderInput(data: any[]): CollectResult {
    try {
      const orders = data.map((row, i) => {
        const recipient: RecipientOutput = {
          country: row.F,
          region: row.G,
          city: row.H,
          address: row.I,
          house: row.J.toString(),
          flat: row.K.toString(),
          postcode: row.L.toString(),
          name: row.M,
          phone: row.N,
          email: row.O
        };

        const unloadedOn: UnloadedOnInput = {
          courierId: this.form.value.courier,
          senderId: this.form.value.sender,
          track: row.P
        };

        const lgStandingData: StandingDataInput = {
          outProjectId: +row.R || null,
          outOrderId: +row.S || null,
          outProjectName: row.T || null,
          operatorLogin: row.U || null,
          operatorId: +row.V || null
        };

        return {
          outsideId: row.A,
          orderedGoods: this.convertToOrderedGoods(row.B, i + 1),
          shippingCost: row.C,
          totalPrice: row.D,
          comment: row.E,
          recipient: recipient,
          additions: this.convertToAdditionFields(row.Q, i + 1),
          unloadedOn: unloadedOn,
          lgStandingData: lgStandingData
        } as OrderInput;
      });
      return {
        type: 'success',
        orders
      };
    } catch (err) {
      return {
        type: 'error',
        message: err.message
      };
    }
  }

  convertToOrderedGoods(str: string, index: number): OrderedGoodInput[] {
    const cart: string[] = str.split(';');

    return cart.map(item => {
      const info = item.trim().split(' ');

      if (info.length > 3) {
        throw new Error(`Invalid column B on row ${index} in excel`);
      }

      return {
        alias: info[0],
        quantity: +info[1],
        price: +info[2]
      } as OrderedGoodInput;
    });
  }

  convertToAdditionFields(str: string, index: number): OrderInputAdditions {
    const arr: string[] = str.split(';');

    return arr.reduce((prev, curr) => {
      const info = curr.trim().split(' ');

      if (info.length > 2) {
        throw new Error(`Invalid column Q on row ${index} in excel`);
      }

      const key = `addition${info[0]}`;
      return {
        ...prev,
        [key]: info[1]
      };
    }, {}) as OrderInputAdditions;
  }

}
