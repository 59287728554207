import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { OrderOutput } from 'core/http/project';
import { Store } from '@ngrx/store';
import { projectLoading } from 'app/store/project/project.reducer';
import { TableColumnDirective } from 'shared/modules/table/table-utils/table-directives';

@Component({
  selector: 'app-order-columns-container',
  templateUrl: './order-columns-container.component.html'
})
export class OrderColumnsContainerComponent {

  readonly projectLoading$ = this.store$.select(projectLoading);

  private _tableLoading = false;
  private _columns = Array<string>();
  private _orderConverter?: (order: any) => OrderOutput;
  private _orderLink = Array<string>();

  @ViewChildren(TableColumnDirective)
  columnDirectives = new QueryList<TableColumnDirective>();

  @Input()
  set tableLoading(value: boolean) {
    this._tableLoading = value;
  }

  @Input()
  set columns(value: string[]) {
    this._columns = value;
  }

  @Input()
  set orderConverter(value: ((order: any) => OrderOutput) | undefined) {
    this._orderConverter = value;
  }

  @Input()
  set orderLink(value: string[]) {
    this._orderLink = value;
  }

  get orderLink(): string[] {
    return this._orderLink;
  }

  get orderConverter(): ((order: any) => OrderOutput) | undefined {
    return this._orderConverter;
  }

  get tableLoading(): boolean {
    return this._tableLoading;
  }

  constructor(private store$: Store) {}

}
