import { createAction, props } from '@ngrx/store';
import { DocumentOutput } from 'core/http/root';
import { Uuid } from 'shared/common/types';
import { Update } from '@ngrx/entity';

export const getDocuments = createAction('[Documents Request] Get Root Documents');
export const saveDocuments = createAction('[Documents State] Save Root Documents In State',
  props<{ documents: DocumentOutput[] }>());
export const markDocumentsLoaded = createAction('[Documents State] Mark Root Documents Loaded');

export const updateDocumentExtensionProjects = createAction(
  '[Root Document Request] Update Document Extension Projects',
  props<{ id: Uuid, projects: Uuid[] }>());
export const updateDocumentExtensionProjectsInStore = createAction(
  '[Root Document Store] Update Document Extension Projects In Store',
  props<{ update: Update<DocumentOutput> }>());
