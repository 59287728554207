import { HttpClient } from '@angular/common/http';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Observable } from 'rxjs';
import { CriteriaParams } from 'shared/criteria/Criteria';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { DynamicField } from 'shared/modules/dynamic-form/field-types';

export interface SenderOutput {
  id: string;
  name: string;
  isActivated: boolean;
  postcode: string;
  country: string;
  region: string;
  city: string;
  address: string;
  house: string;
  flat: string;
  returnAddress: string;
  email: string;
  additionalEmail: string;
  phone: string;
  additionalPhone: string;
  dynamicData: DynamicField[];
}

export interface SenderInput {
  postcode: null | string;
  country: null | string;
  region: null | string;
  city: null | string;
  address: null | string;
  house: null | string;
  flat: null | string;
  returnAddress: null | string;
  name: string;
  email: null | string;
  additionalEmail: null | string;
  phone: null | string;
  additionalPhone: null | string;
  dynamicData: any[];
}

@ProjectInjectable()
export class SenderService {

  constructor(private http: HttpClient) { }

  getAllSenders(criteria?: CriteriaParams): Observable<SenderOutput[]> {
    return this.http.get<SenderOutput[]>(apiUrl(`/project/senders`, criteria));
  }

  createSender(input: SenderInput): Observable<SenderOutput> {
    return this.http.post<SenderOutput>(apiUrl('/project/sender'), input);
  }

  updateSender(senderId: string, input: SenderInput): Observable<SenderOutput> {
    return this.http.put<SenderOutput>(apiUrl(`/project/sender/${senderId}`), input);
  }

  activateSender(senderId: string): Observable<SenderOutput> {
    return this.http.patch<SenderOutput>(apiUrl(`/project/sender/${senderId}/activate`), null);
  }

  deactivateSender(senderId: string): Observable<SenderOutput> {
    return this.http.patch<SenderOutput>(apiUrl(`/project/sender/${senderId}/deactivate`), null);
  }

}
