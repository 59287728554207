import { Component, Input } from '@angular/core';
import { OrderOutput } from 'core/http/project';
import { Store } from '@ngrx/store';
import { courierEntities } from 'app/store/project/couriers/couriers.selectors';
import { senderEntities } from 'app/store/project/senders/senders.selectors';

@Component({
  selector: 'app-unloading-view',
  templateUrl: './unloading-view.component.html',
  styleUrls: ['./unloading-view.component.scss']
})
export class UnloadingViewComponent {

  readonly couriers$ = this.store$.select(courierEntities);
  readonly senders$ = this.store$.select(senderEntities);

  private _order?: OrderOutput;

  @Input() skeletonView = false;

  @Input()
  set order(o: OrderOutput) {
    this._order = o;
  }

  get order(): OrderOutput {
    if (!this._order) {
      throw new Error('Order was not initialized');
    }

    return this._order;
  }

  constructor(private store$: Store) { }

}
