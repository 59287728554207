import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DevRouter, NavigationOptions, ProjectRouter, RootRouter } from 'core/services/project-router.service';
import { createAction, props } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

type NavigationProps = { path: string[], options?: NavigationOptions };

export const navigateToProject = createAction('[Project Router] Navigate To Project', props<NavigationProps>());
export const navigateToRoot = createAction('[Root Router] Navigate To Root', props<NavigationProps>());
export const navigateToDev = createAction('[Dev Router] Navigate To Dev', props<NavigationProps>());
export const navigate = createAction('[Router] Navigate', props<NavigationProps>());

@Injectable()
export class RouterEffects {

  navigateToProject$ = createEffect(() => this.actions$.pipe(
    ofType(navigateToProject),
    tap(({ path, options }) => this.projectRouter.navigateTo(path, options))
  ), { dispatch: false });

  navigateToRoot$ = createEffect(() => this.actions$.pipe(
    ofType(navigateToRoot),
    tap(({ path, options }) => this.rootRouter.navigateTo(path, options))
  ), { dispatch: false });

  navigateToDev$ = createEffect(() => this.actions$.pipe(
    ofType(navigateToDev),
    tap(({ path, options }) => this.devRouter.navigateTo(path, options))
  ), { dispatch: false });

  navigate$ = createEffect(() => this.actions$.pipe(
    ofType(navigate),
    tap(({ path, options }) => this.router.navigate(path, options))
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private projectRouter: ProjectRouter,
    private rootRouter: RootRouter,
    private devRouter: DevRouter,
    private router: Router
  ) { }
}
