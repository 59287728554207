import { Component, Input, OnChanges } from '@angular/core';
import { Option, Rule } from 'angular2-query-builder';
import { Uuid } from 'shared/common/types';

@Component({
  selector: 'app-multiple-entity-query-input',
  templateUrl: './multiple-entity-query-input.component.html',
  styleUrls: ['./multiple-entity-query-input.component.scss']
})
export class MultipleEntityQueryInputComponent implements OnChanges {

  private _rule?: Rule;
  private _onChange?: () => void;
  private _options = Array<Option>();

  ngOnChanges(): void {
    const isValid = Array.isArray(this.rule.value) &&
      this.rule.value.every(v => this.options.some(o => o.value === v));

    if (isValid) {
      return;
    }

    this.rule.value = [];
  }

  changeEntities(entityIds: Uuid[]): void {
    this.rule.value = entityIds;
    this.onChange();
  }

  get rule(): Rule {
    if (!this._rule) {
      throw new Error(`Required input 'rule' is not specified`);
    }

    return this._rule;
  }

  @Input()
  set rule(rule: Rule) {
    this._rule = rule;
  }

  get onChange(): () => void {
    if (!this._onChange) {
      throw new Error(`Required input 'onChange' is not specified`);
    }

    return this._onChange;
  }

  @Input()
  set onChange(onChange: () => void) {
    this._onChange = onChange;
  }

  get options(): Option[] {
    return this._options;
  }

  @Input()
  set options(options: Option[]) {
    this._options = options;
  }

}
