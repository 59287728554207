import { Component, ContentChildren, QueryList } from '@angular/core';
import { SearchOptionComponent } from 'shared/modules/items-search-wrapper/search-option/search-option.component';

@Component({
  selector: 'app-items-search-wrapper',
  templateUrl: './items-search-wrapper.component.html',
  styleUrls: ['./items-search-wrapper.component.scss']
})
export class ItemsSearchWrapperComponent {

  @ContentChildren(SearchOptionComponent)
  options!: QueryList<SearchOptionComponent>;

  private _name = '';

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  filterChange(value: string): void {
    this.options.forEach(o => o.visible = o.label.toLowerCase().includes(value.toLowerCase()));
  }

}
