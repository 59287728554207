import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterModule, Routes } from '@angular/router';
import { NoticesLogComponent } from 'history/notices-log/notices-log.component';
import { NoticeInfoComponent } from 'history/notices-log/notice-info/notice-info.component';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { NoticeLogOutput, NoticesLogService } from 'core/http/project/notices-log/notices-log.service';
import { Observable } from 'rxjs';

@ProjectInjectable()
class NoticeInfoResolver implements Resolve<NoticeLogOutput> {

  constructor(private noticesLogService: NoticesLogService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<NoticeLogOutput> {
    const noticeId = route.params.noticeId;

    return this.noticesLogService.getNotice(noticeId);
  }

}

const routes: Routes = [
  {
    path: '',
    component: NoticesLogComponent,
    data: { title: 'Журнал уведомлений' }
  },
  {
    path: ':noticeId',
    component: NoticeInfoComponent,
    resolve: {
      notice: NoticeInfoResolver
    },
    data: { title: 'Инфо об уведомлении' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NoticesLogRoutingModule {}
