import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard';
import { notify } from 'app/store/common-effects/notifier.effects';
import { Store } from '@ngrx/store';
import { OrderCodeService } from 'core/http/project/orderCodeService';
import { transition, trigger, useAnimation } from '@angular/animations';
import { hideAnimation, showAnimation } from 'shared/common/animations';

@Component({
  selector: 'app-order-code',
  templateUrl: './order-code-dialog.component.html',
  styleUrls: ['./order-code-dialog.component.scss'],
  animations: [
    trigger('show', [
      transition('void => *', [
        useAnimation(showAnimation)
      ])
    ]),
    trigger('hide', [
      transition('* => void', [
        useAnimation(hideAnimation)
      ])
    ])
  ]
})
export class OrderCodeDialogComponent {

  readonly code$ = this.devService.generateOrderCode(this.data);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private devService: OrderCodeService,
    private clipboard: ClipboardService,
    private store$: Store
  ) { }

  copyCode(code: string): void {
    this.clipboard.copy(code);
    this.store$.dispatch(notify({ message: 'Код скопирован в буфер' }));
  }
}
