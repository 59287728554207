import { Action, createReducer, on } from '@ngrx/store';
import * as StorehousesActions from './storehouses.actions';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Department, StorehouseOutput } from 'core/http/project';
import {
  CompletedEntityState,
  initialCompletedEntityState
} from 'app/store/completed-entity-state/completed-entity-state';

export interface StorehousesState extends CompletedEntityState<StorehouseOutput> {
  departments: Department[];
}

const adapter: EntityAdapter<StorehouseOutput> = createEntityAdapter<StorehouseOutput>();

export const initialStorehousesState = adapter.getInitialState({
  ...initialCompletedEntityState,
  departments: Array<Department>()
});

const reducer = createReducer(
  initialStorehousesState,
  on(StorehousesActions.saveStorehouses, (state, payload) => adapter.setAll(payload.storehouses, state)),
  on(StorehousesActions.markStorehouseLoaded, state => ({ ...state, loaded: true })),
  on(StorehousesActions.addStorehouseInStore, (state, storehouse) => adapter.addOne(storehouse, state)),
  on(StorehousesActions.updateStorehouseInStore, (state, { update }) => adapter.updateOne(update, state)),
  on(StorehousesActions.removeStorehouseFromStore, (state, { id }) => adapter.removeOne(id, state)),
  on(StorehousesActions.setLoading, (state, { value }) => ({ ...state, loading: value })),
  on(StorehousesActions.setError, (state, { value }) => ({ ...state, error: value })),
  on(StorehousesActions.saveDepartments, (state, { departments }) => ({ ...state, departments }))
);

export function storehousesReducer(state: StorehousesState | undefined, action: Action): StorehousesState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectEntities,
  selectTotal
} = adapter.getSelectors();
