import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Uuid } from 'shared/common/types';
import { ChainedCourierOutput, CourierOutput, SenderOutput } from 'core/http/project';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Pagination } from 'core/types/pagination';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { BackgroundProgress } from 'core/http/project/background/background-task.service';

export interface NoticeSequence {
  startFrom: number;
  seqName: string;
}

export interface NoticeSequenceVariable {
  type: 'notice_sequence';
  name: string;
  value: NoticeSequence;
}

export interface VariableValue {
  type: 'summary'
    | 'courier_name'
    | 'serial'
    | 'pool'
    | 'sender_name'
    | 'exported_at'
    | 'document_link'
    | 'pool_link';
  name: string;
  value: string;
}

export type NoticeVariable = NoticeSequenceVariable | VariableValue;

export interface NoticeUnitOutput {
  id: Uuid;
  courier: CourierOutput;
  sender: SenderOutput;
  metadata?: string;
}

export interface NoticeOutput {
  id: Uuid;
  chainNoticeId: Uuid | null;
  createdAt: Date;
  units: NoticeUnitOutput[];
  destinations: NoticeDestinationOutput[];
}

export interface NoticeDestinationOutput {
  type: string;
  destination: string;
  reason: string | null;
  isSuccess: boolean;
}

export interface ChainNoticeOutput {
  id: Uuid;
  chainedCouriers: ChainedCourierOutput[];
  destinations: NoticeDestinationOutput[];
  template: string;
  variables: NoticeVariable[];
  isActive: boolean;
  subject: string | null;
  poolIds: Uuid[];
}

export interface ChainNoticeInput {
  destinations: NoticeDestinationInput[];
  subject: string | null;
  chainedCourierIds: Uuid[];
  template: string;
  variables: NoticeVariable[];
  poolIds: Uuid[];
}

export interface NoticeDestinationInput {
  type: string;
  destination: string;
}

export interface TelegramChatOutput {
  id: number;
  name: string;
}

@ProjectInjectable()
export class ChainNoticesService {
  constructor(private http: HttpClient) {
  }

  getChainNotices(courierChainId: Uuid): Observable<Pagination<ChainNoticeOutput[]>> {
    return this.http.get<Pagination<ChainNoticeOutput[]>>(
      apiUrl(`/project/courier-chain/${courierChainId}/chain-notices`));
  }

  getChainNotice(chainId: Uuid, noticeId: Uuid): Observable<ChainNoticeOutput> {
    return this.http.get<ChainNoticeOutput>(apiUrl(`/project/courier-chain/${chainId}/chain-notice/${noticeId}`));
  }

  createChainNotice(courierChainId: Uuid, chainNotice: ChainNoticeInput): Observable<ChainNoticeOutput> {
    return this.http.post<ChainNoticeOutput>(apiUrl(`/project/courier-chain/${courierChainId}/chain-notice`),
      chainNotice);
  }

  changeActivityChainNotice(chainId: Uuid, noticeId: Uuid): Observable<Object> {
    return this.http.patch(apiUrl(`/project/courier-chain/${chainId}/chain-notice/${noticeId}`), {});
  }

  updateChainNotice(courierChainId: Uuid, noticeId: Uuid, chainNotice: ChainNoticeInput): Observable<ChainNoticeOutput> {
    return this.http.put<ChainNoticeOutput>(apiUrl(`/project/courier-chain/${courierChainId}/chain-notice/${noticeId}`),
      chainNotice);
  }

  removeChainNotice(courierChainId: Uuid, noticeId: Uuid): Observable<Object> {
    return this.http.delete(apiUrl(`/project/courier-chain/${courierChainId}/chain-notice/${noticeId}`));
  }

  getUnloadingNotices(unloadingId: Uuid): Observable<Pagination<NoticeOutput[]>> {
    return this.http.get<Pagination<NoticeOutput[]>>(apiUrl(`/project/unloading/${unloadingId}/notices`));
  }

  resendNotice(unloadingId: Uuid, chainNoticeId: Uuid, destinations: string[]): Observable<BackgroundProgress> {
    return this.http.post<BackgroundProgress>(apiUrl(`/project/unloading/${unloadingId}/chain-notice/${chainNoticeId}`),
      { destinations });
  }

  getTelegramChats(): Observable<TelegramChatOutput[]> {
    return this.http.get<TelegramChatOutput[]>(apiUrl('/project/courier-chains/telegram-chats'));
  }

}
