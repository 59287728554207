import { HttpClient } from '@angular/common/http';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Observable } from 'rxjs';
import { Pagination } from 'core/types/pagination';
import { Criteria } from 'shared/criteria/Criteria';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { CartOutput, OrderedGoodInput } from 'core/http/project/good/good.service';
import { BackgroundProgress } from 'core/http/project/background/background-task.service';
import { Uuid } from 'shared/common/types';
import { ChangedData } from 'core/http/project/order/change-interfaces';
import { map } from 'rxjs/operators';
import { LoggedUserOutput } from 'core/http/lg-logistic-rest-api/Auth/token.service';

export interface GroupedFlexibleStatusChangeInput {
  newStatusId: string;
  type: FilterType;
  data: string[];
  orderFilter: string;
  changeProtected: boolean;
}

export interface LogisticOutput {
  createdAt: string;
  courierId: Uuid;
  senderId: Uuid;
  track: string;
  tracking: TrackingOutput | null;
}

export interface Pair {
  key: string | number;
  value: string | number | Pair[];
}

export interface TrackingOutput {
  extra: Pair[];
  createdAt: string;
  lastAssignedAt: string;
  lastEvent: LastTrackingEventOutput;
  lastName: string;
  statuses: TrackingStatusOutput[];
  updatedAt: string;
}

export interface LastTrackingEventOutput {
  isSuccess: boolean;
  statusesCount: null | number;
  error: null | string;
  createdAt: string;
}

export interface TrackingStatusOutput {
  name: string;
  id: string;
  assignedAt: string;
  createdAt: string;
  extra: Array<Pair>;
}

export interface OrderOutput {
  id: string;
  outsideId: string;
  lgStandingData: StandingDataOutput;
  statusId: Uuid;
  statusChangeAt: string;
  state: string;
  recipient: RecipientOutput;
  logistic: LogisticOutput | null;
  cart: CartOutput;
  comment: string | null;
  shippingCost: number;
  reimbursementAmount: number | null;
  storehouseId: Uuid | null;
  additions: {
    addition1: string,
    addition2: string,
    addition3: string,
    addition4: string,
    addition5: string,
    addition6: string,
    addition7: string,
    addition8: string,
    addition9: string,
    addition10: string,
    addition11: string,
    addition12: string,
    addition13: string,
    addition14: string,
    addition15: string
  };
  createdAt: string;
  updatedAt: string;
  returnedAt: string;
  boughtOutAt: string;
  outTotalPrice: number;
}

// RejectedOrderOutput в доке
export interface NotCreatedOrder {
  id: Uuid | null;
  errors: string[];
}

export interface CreateOrderOutput {
  accepted: OrderOutput[];
  rejected: NotCreatedOrder[];
}

export interface OrderInput {
  outsideId: string | null;
  comment: string;
  shippingCost: number;
  totalPrice: number;
  orderedGoods: OrderedGoodInput[];
  additions: OrderInputAdditions;
  recipient: RecipientOutput;
  lgStandingData: StandingDataInput | null;
  unloadedOn: UnloadedOnInput | null;
}

export interface OrderInputAdditions {
  addition1?: string;
  addition2?: string;
  addition3?: string;
  addition4?: string;
  addition5?: string;
  addition6?: string;
  addition7?: string;
  addition8?: string;
  addition9?: string;
  addition10?: string;
  addition11?: string;
  addition12?: string;
  addition13?: string;
  addition14?: string;
  addition15?: string;
}

export interface UnloadedOnInput {
  courierId: Uuid;
  senderId: Uuid;
  track: string;
}

export interface RecipientOutput {
  name: string | null;
  postcode: string | null;
  country: string | null;
  region: string | null;
  city: string | null;
  address: string | null;
  house: string | null;
  flat: string | null;
  email: string | null;
  phone: string | null;
}

export interface StandingDataOutput {
  outProjectId: string;
  outOrderId: string;
}

export interface StandingDataInput {
  outProjectId: number | null;
  outOrderId: number | null;
  outProjectName: string | null;
  operatorLogin: string | null;
  operatorId: number | null;
}

export interface ChangeOutput {
  id: Uuid;
  createdAt: Date;
  user: LoggedUserOutput;
  data: ChangedData[];
}

export type FilterType = 'TRACK' | 'PHONE' | 'ORDER_ID';

export interface AggregateStatusChangePreviewInput {
  toStatusId: Uuid;
  type: 'TRACK' | 'PHONE' | 'ORDER_ID' | 'ADDITIONAL';
  additionalIdx: number | null;
  data: string[];
  changeProtected: boolean;
  courierId: Uuid | null;
  outProjectId: string | null;
}

export type StatusForChangesType = 'COURIER_MISMATCH'
  | 'OUT_PROJECT_ID_MISMATCH'
  | 'IN_RETURNED_STATE'
  | 'IN_BOUGHT_STATE'
  | 'ALREADY_IN_STATUS'
  | 'CAN_CHANGE';

export interface FoundedOutput {
  type: 'FOUND';
  data: string;
  price: number;
  canChange: StatusForChangesType;
  statusId: Uuid;
}

export interface NotFoundedOutput {
  type: 'NOT_FOUND';
  data: string;
}

export type AggregatedPreviewOutput = FoundedOutput | NotFoundedOutput;

@ProjectInjectable()
export class OrderService {

  constructor(private http: HttpClient) {}

  getAllOrders(criteria: Criteria): Observable<Pagination<OrderOutput[]>> {
    return this.http.get<Pagination<OrderOutput[]>>(apiUrl(`/project/orders`, criteria));
  }

  getOrder(orderId: Uuid): Observable<OrderOutput> {
    return this.http.get<OrderOutput>(apiUrl(`/project/order/${orderId}`));
  }

  getOrderChanges(orderId: Uuid): Observable<ChangeOutput[]> {
    const a = apiUrl(`/project/order/${orderId}/changes`);
    return this.http.get<ChangeOutput[]>(a);
  }

  createOrders(input: OrderInput[]): Observable<CreateOrderOutput> {
    return this.http.post<CreateOrderOutput>(apiUrl(`/order-supplier/orders`), { orders: input });
  }

  cancelTransferToCourier(orders: { orderCriteria: string }): Observable<BackgroundProgress> {
    return this.http.patch<BackgroundProgress>(apiUrl(`/project/orders/cancel-transfer`), orders);
  }

  resendTracks(filter: string): Observable<BackgroundProgress> {
    return this.http.post<BackgroundProgress>(apiUrl(`/project/orders/resend-tracks`), { filter });
  }

  groupedFlexibleStatusChange(input: GroupedFlexibleStatusChangeInput): Observable<BackgroundProgress> {
    return this.http.post<BackgroundProgress>(apiUrl(`/project/order/flexible-status-changing`), input);
  }

  aggregatePreview(input: AggregateStatusChangePreviewInput): Observable<AggregatedPreviewOutput[]> {
    return this.http.post<string>(apiUrl('/project/status-change-preview'), input)
      .pipe(
        map(json => JSON.parse(json) as AggregatedPreviewOutput[])
      );
  }

}
