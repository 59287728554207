import { NotifierService } from 'core/services/notifier.service';
import { OrderService } from 'core/http/project/order/order.service';
import { PrintingService } from 'core/http/project/printing/printing.service';
import { UnloadingService } from 'core/http/project/courier/unloading.service';
import { SenderOutput } from 'core/http/project/sender/sender.service';
import { PoolOutput } from 'core/http/project/document/document-pool.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  CourierChainOutput,
  CourierOutput,
  CoverageService,
  DocumentOutput,
  StatusService,
  StorehouseOutput,
  TrackReservationService
} from 'core/http/project';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { BackgroundProgress } from 'core/http/project/background/background-task.service';
import { Uuid } from 'shared/common/types';
import { ChainNoticesService } from 'core/http/project/courier/chain-notices.service';
import { Store } from '@ngrx/store';
import { addBackLink } from 'app/store/project/progresses-back-links/progresses-back-links.actions';
import { StatusChangeGroupInput, StatusesHistoryService } from 'core/http/project/status/statuses-history.service';
import { errorHttpNotify } from 'app/store/common-effects/notifier.effects';
import { navigateToProject } from 'app/store/common-effects/router.effects';

export interface SingleUnloadingArgs {
  type: 'SINGLE_UNLOADING';
  courier: CourierOutput;
  sender: SenderOutput;
  storehouse: StorehouseOutput;
}

export interface ChainUnloadingArgs {
  type: 'CHAIN_UNLOADING';
  chain: CourierChainOutput;
}

export interface SinglePrintingArgs {
  type: 'SINGLE_PRINTING';
  document: DocumentOutput;
  groupers: (string | number)[];
}

export interface PoolPrintingArgs {
  type: 'POOL_PRINTING';
  pool: PoolOutput;
}

export type UnloadingArgs = SingleUnloadingArgs | ChainUnloadingArgs;
export type PrintingArgs = SinglePrintingArgs | PoolPrintingArgs;

@ProjectInjectable()
export class BackgroundTaskRunner {

  constructor(
    private orderService: OrderService,
    private coverageService: CoverageService,
    private notifierService: NotifierService,
    private printingService: PrintingService,
    private unloadingService: UnloadingService,
    private trackReservationService: TrackReservationService,
    private chainNoticesService: ChainNoticesService,
    private statusesHistoryService: StatusesHistoryService,
    private statusService: StatusService,
    private store$: Store
  ) {}

  runCancellation(orderCriteria: string, backLink?: string[]): void {
    this.orderService
      .cancelTransferToCourier({ orderCriteria })
      .subscribe(this.createSubscriber(backLink));
  }

  runStatusChange(groups: StatusChangeGroupInput[], backLink?: string[]): void {
    this.statusesHistoryService
      .beginStatusChange(groups)
      .subscribe(this.createSubscriber(backLink));
  }

  runUnloading(filter: string, args: UnloadingArgs, backLink?: string[]): void {
    if (args.type === 'SINGLE_UNLOADING') {
      this.unloadingService
        .runSingleUnloading(args.courier.id, args.sender.id, args.storehouse.id, filter)
        .subscribe(this.createSubscriber(backLink));
    }
    if (args.type === 'CHAIN_UNLOADING') {
      this.unloadingService
        .runChainUnloading(args.chain.id, filter)
        .subscribe(this.createSubscriber(backLink));
    }
  }

  runPrinting(filter: string, args: PrintingArgs, backLink?: string[]): void {
    if (args.type === 'SINGLE_PRINTING') {
      this.printingService
        .beginPrinting(args.document.id, filter, args.groupers)
        .subscribe(this.createSubscriber(backLink));
    }

    if (args.type === 'POOL_PRINTING') {
      this.printingService
        .beginPoolPrinting(args.pool.id, filter)
        .subscribe(this.createSubscriber(backLink));
    }
  }

  runRemoveCoverage(courierId: Uuid, backLink?: string[]): void {
    this.coverageService.removeServicedCoverage(courierId)
      .subscribe(this.createSubscriber(backLink));
  }

  clearReserve(courierId: Uuid, senderId: Uuid, backLink?: string[]): void {
    this.trackReservationService.clear(courierId, senderId)
      .subscribe(this.createSubscriber(backLink));
  }

  resendNotice(unloadingId: Uuid, chainNoticeId: Uuid, destinations: string[], backLink?: string[]): void {
    this.chainNoticesService.resendNotice(unloadingId, chainNoticeId, destinations)
      .subscribe(this.createSubscriber(backLink));
  }

  resendTracks(filter: string, backLink?: string[]): void {
    this.orderService.resendTracks(filter)
      .subscribe(this.createSubscriber(backLink));
  }

  resendStatuses(filter: string, backLink?: string[]): void {
    this.statusService.resendStatuses(filter)
      .subscribe(this.createSubscriber(backLink));
  }

  reportRejectedOrders(unloadingId: Uuid, passId: Uuid, backLink?: string[]): void {
    this.unloadingService.reportRejectedOrders(unloadingId, passId)
      .subscribe(this.createSubscriber(backLink));
  }

  private createSubscriber(backLink?: string[]): TaskRunnerSubscriber {
    return {
      next: (progress: BackgroundProgress) => {
        backLink && this.store$.dispatch(addBackLink({ progressId: progress.id, link: backLink }));

        this.store$.dispatch(navigateToProject({ path: ['task', progress.id] }));
      },
      error: (response: HttpErrorResponse) => this.store$.dispatch(errorHttpNotify({ response }))
    };
  }

}

type TaskRunnerSubscriber = {
  next: (progress: BackgroundProgress) => void;
  error: (error: HttpErrorResponse) => void
};
