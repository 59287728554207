import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectFirst } from 'core/utils/rx-common';
import { hasDevAccess } from 'app/store/login/logged-user/logged-user.selectors';

@Directive({
  selector: '[isDev]'
})
export class IsDevDirective implements OnInit {

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private store$: Store
  ) {}

  ngOnInit(): void {
    this.store$.pipe(selectFirst(hasDevAccess))
      .subscribe(access => {
        if (!access) {
          this.viewContainerRef.clear();
          return;
        }

        this.viewContainerRef.createEmbeddedView(this.templateRef);
      });
  }

}
