import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from 'shared/modules/search/search/search.component';
import { MaterialModule } from 'shared/modules/material/material.module';
import { AngularModule } from 'shared/modules/angular/angular.module';

@NgModule({
  declarations: [
    SearchComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    AngularModule
  ],
  exports: [
    SearchComponent
  ]
})
export class SearchModule {
}
