import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from 'shared/modules/material/material.module';
import { LayoutModule } from 'shared/modules/layout/layout.module';
import { SkeletonModule } from 'shared/modules/skeleton/skeleton.module';
import { ControlsModule } from 'shared/modules/controls/controls.module';
import { TableModule } from 'shared/modules/table/table.module';
import { OrderTableModule } from 'shared/modules/order-table/order-table.module';
import { TypographyModule } from 'shared/modules/typography/typography.module';
import { DateTimeModule } from 'shared/modules/date-time/date-time.module';
import { PipesModule } from 'shared/modules/pipes/pipes.module';
import { DirectivesModule } from 'shared/modules/directives/directives.module';
import { SearchModule } from 'shared/modules/search/search.module';
import { DebtsModule } from 'shared/modules/debts/debts.module';
import { ConfirmActionModule } from 'shared/dialogs/confirm-action/confirm-action.module';
import { AngularModule } from 'shared/modules/angular/angular.module';
import { ListModule } from 'shared/modules/list/list.module';
import { QueryBuilderModule } from 'shared/modules/query-builder/query-builder.module';
import { TranslateModule } from '@ngx-translate/core';
import { RoutableModalModule } from 'shared/modules/routable-modal/routable-modal.module';
import { DynamicFormModule } from 'shared/modules/dynamic-form/dynamic-form.module';
import { AdditionFieldsModule } from 'shared/modules/addition-fields/addition-fields.module';
import { DataTableModule } from 'shared/modules/data-table/data-table.module';
import { ItemsSearchWrapperModule } from 'shared/modules/items-search-wrapper/items-search-wrapper.module';
import { SummaryDialogComponent } from './dialogs/summary-dialog/summary-dialog.component';
import { WithdrawnSelectComponent } from './modules/withdrawn-select/withdrawn-select.component';

@NgModule({
  imports: [
    MaterialModule,
    LayoutModule,
    SkeletonModule,
    ControlsModule,
    TableModule,
    ListModule,
    OrderTableModule,
    TypographyModule,
    DateTimeModule,
    PipesModule,
    DirectivesModule,
    SearchModule,
    ConfirmActionModule,
    DebtsModule,
    AngularModule,
    CommonModule,
    TranslateModule,
    QueryBuilderModule,
    RoutableModalModule,
    DynamicFormModule,
    AdditionFieldsModule,
    DataTableModule,
    ItemsSearchWrapperModule
  ],
  exports: [
    MaterialModule,
    LayoutModule,
    SkeletonModule,
    ControlsModule,
    TableModule,
    ListModule,
    OrderTableModule,
    TypographyModule,
    DateTimeModule,
    PipesModule,
    DirectivesModule,
    SearchModule,
    ConfirmActionModule,
    DebtsModule,
    AngularModule,
    TranslateModule,
    QueryBuilderModule,
    RoutableModalModule,
    DynamicFormModule,
    AdditionFieldsModule,
    DataTableModule,
    ItemsSearchWrapperModule,
    WithdrawnSelectComponent
  ],
  declarations: [SummaryDialogComponent, WithdrawnSelectComponent]
})
export class SharedModule {
}
