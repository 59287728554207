import { Component, Input } from '@angular/core';
import { OrderOutput } from 'core/http/project';

@Component({
  selector: 'app-order-dates',
  templateUrl: './order-dates.component.html',
  styleUrls: ['./order-dates.component.scss']
})
export class OrderDatesComponent {

  private _order?: OrderOutput;

  @Input() skeletonView = false;

  @Input()
  set order(o: OrderOutput) {
    this._order = o;
  }

  get order(): OrderOutput {
    if (!this._order) {
      throw new Error('Order is not initialized');
    }

    return this._order;
  }

}
