import { ProjectInjectable } from 'core/http/project/project-services.module';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Uuid } from 'shared/common/types';
import { RuleSet } from 'angular2-query-builder';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';

export interface ScheduledTaskInput {
  entityId: Uuid;
  type: 'STATUS_CHAIN' | 'COURIER_CHAIN';
  ruleSet: RuleSet;
  hour: number;
  minutes: number;
  daysOfWeek: string[];
}

export interface ScheduledTaskOutput {
  id: Uuid;
  type: 'STATUS_CHAIN' | 'COURIER_CHAIN';
  entityId: Uuid;
  ruleSet: RuleSet;
  hour: number;
  minute: number;
  daysOfWeek: string[];
}

@ProjectInjectable()
export class ScheduledTasksService {

  constructor(private http: HttpClient) { }

  getAllScheduledTasks(): Observable<ScheduledTaskOutput[]> {
    return this.http.get<ScheduledTaskOutput[]>(apiUrl('/project/scheduled-tasks'));
  }

  createScheduledTask(task: ScheduledTaskInput): Observable<ScheduledTaskOutput> {
    return this.http.post<ScheduledTaskOutput>(apiUrl('/project/scheduled-task'), task);
  }

  editScheduledTask(taskId: Uuid, task: ScheduledTaskInput): Observable<ScheduledTaskOutput> {
    return this.http.put<ScheduledTaskOutput>(apiUrl(`/project/scheduled-task/${taskId}`), task);
  }

  removeScheduledTask(taskId: Uuid): Observable<Object> {
    return this.http.delete<Object>(apiUrl(`/project/scheduled-task/${taskId}`));
  }
}
