import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { RegistrationService } from 'core/http/lg-logistic-rest-api/Auth/registration.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { navigate } from 'app/store/common-effects/router.effects';
import { errorNotify } from 'app/store/common-effects/notifier.effects';

@Injectable({ providedIn: 'root' })
export class RegistrationInviteGuard implements CanActivate {

  constructor(
    private store$: Store,
    private registrationService: RegistrationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const token = route.params.token;

    if (!token) {
      return of(false);
    }

    return this.registrationService.checkInvite(token)
      .pipe(
        map(exist => {
          if (!exist) {
            this.store$.dispatch(navigate({ path: ['/', 'auth', 'login'] }));
            this.store$.dispatch(errorNotify({ message: 'Ваше приглашение не действительно' }));
            return false;
          }

          return true;
        }),
      );
  }

}
