import { DynamicField } from 'shared/modules/dynamic-form/field-types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export function getFormGroup(type: DynamicField['type'], fb: FormBuilder, name?: string, description?: string): FormGroup {
  const common = {
    type: fb.control(type),
    name: fb.control(name, Validators.required),
    description: fb.control(description, Validators.required),
  };

  switch (type) {
    case 'NUMERIC':
      return fb.group({
        ...common,
        value: fb.control(0)
      });

    case 'RULE_SET':
      return fb.group({
        ...common,
        value: fb.control({ condition: 'and', rules: [] })
      });

    case 'SHORT_TEXT':
      return fb.group({
        ...common,
        value: fb.control('')
      });

    case 'SWITCHABLE':
      return fb.group({
        ...common,
        value: fb.control(false)
      });

    case 'FILE':
      return fb.group({
        ...common,
        value: fb.control(null),
        extension: fb.control('image/png')
      });

    case 'ADDITIONS_INDEX':
      return fb.group({
        ...common,
        required: fb.control(false),
        value: fb.control(null)
      });

    case 'ENTITY':
      return fb.group({
        ...common,
        entity: fb.control('good'),
        id: fb.control(null)
      });

    case 'SELECTABLE':
      return fb.group({
        ...common,
        required: fb.control(false),
        selected: fb.control(null),
        values: fb.array([])
      });

    case 'SUBSET':
      return fb.group({
        ...common,
        schema: fb.array([]),
        values: fb.control([])
      });

    case 'GROUP':
      return fb.group({
        ...common,
        schema: fb.array([]),
        values: fb.control({})
      });
  }
}
