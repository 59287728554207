import { Component, OnInit } from '@angular/core';
import { ProjectOutput, RootSimpleExtensionOutput } from 'core/http/root';
import {
  UpdateExtensionProjectsDialogComponent,
  UpdateExtensionProjectsDialogData,
  UpdateExtensionProjectsDialogResult
} from 'root/extensions/update-extension-projects-dialog/update-extension-projects-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { selectFirst } from 'core/utils/rx-common';
import { rootProjects } from 'app/store/root/projects/projects.selectors';
import { Store } from '@ngrx/store';
import { simpleExtensions } from 'app/store/root/simple-extensions/simple-extensions.selectors';
import { Subject } from 'rxjs';
import { EagerSource } from 'shared/modules/table/table-utils/table-interfaces';
import { updateExtensionProjects } from 'app/store/root/simple-extensions/simple-extensions.actions';

@Component({
  selector: 'app-simple-extensions',
  templateUrl: './simple-extensions.component.html',
  styleUrls: ['./simple-extensions.component.scss']
})
export class SimpleExtensionsComponent implements OnInit {

  private projects = Array<ProjectOutput>();
  private _reloader = new Subject();

  get reloader(): Subject<unknown> {
    return this._reloader;
  }

  constructor(
    private dialog: MatDialog,
    private store$: Store
  ) { }

  dataSource: EagerSource<RootSimpleExtensionOutput> = () => this.store$.select(simpleExtensions);

  ngOnInit(): void {
    this.store$.pipe(selectFirst(rootProjects))
      .subscribe(data => this.projects = data);
  }

  openExtensionEditor(extension: RootSimpleExtensionOutput): void {
    const data: UpdateExtensionProjectsDialogData = {
      allProjects: this.projects,
      enabledProjects: extension.projects
    };

    this.dialog.open(UpdateExtensionProjectsDialogComponent, { data }).afterClosed()
      .subscribe((r: UpdateExtensionProjectsDialogResult | undefined) => {
        if (!r) {
          return;
        }

        this.store$.dispatch(updateExtensionProjects({
          id: extension.id,
          projects: r.enabledProjects.map(c => c.id)
        }));

        this.reloader.next();
      });
  }
}
