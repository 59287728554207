import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from 'core/http/lg-logistic-rest-api/Auth/registration.service';
import { compare, CriteriaParams } from 'shared/criteria/Criteria';
import { asyncTrue } from 'core/utils/async-validators';
import { Observable } from 'rxjs';
import { ObserverFactory } from 'shared/common/observers';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {

  private readonly params = this.route.snapshot.params as {
    token: string
  };

  private _form: FormGroup = createForm((c: CriteriaParams) => this.registrationService.hasUser(c));

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private registrationService: RegistrationService,
    private observers: ObserverFactory
  ) {}

  get registryForm(): FormGroup {
    return this._form;
  }

  register(form: FormGroup): void {
    if (form.invalid) {
      return;
    }

    this.registrationService
      .registration({ ...this.registryForm.value, inviteId: this.params.token })
      .subscribe(
        this.observers.withHook({
          onSuccess: () => this.router.navigate(['/']),
          successMsg: 'Регистрация прошла успешно'
        })
      );
  }

}

function createForm(userChecker: (c: CriteriaParams) => Observable<boolean>): FormGroup {
  return new FormGroup({
    login: new FormControl(
      null,
      Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(255)
      ]),
      asyncTrue((current: string) => userChecker({ filter: compare.eq('login', current) }))
    ),
    email: new FormControl(
      null,
      Validators.compose([
        Validators.required,
        Validators.email,
        Validators.maxLength(255)
      ]),
      asyncTrue((current: string) => userChecker({ filter: compare.eq('email', current) }))
    ),
    password: new FormControl(
      null,
      Validators.compose([
        Validators.required,
        Validators.minLength(6)
      ])
    ),
    name: new FormControl(
      null,
      Validators.required
    ),
    phone: new FormControl(
      null
    )
  });
}
