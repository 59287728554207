import { Action, createReducer, on } from '@ngrx/store';
import * as ProjectResourcesActions from './project-resources.actions';
import { ProjectResourceChanges } from 'core/http/project/resource-updates/resource-updates.service';

export interface ProjectResourcesState {
  resources: ProjectResourceChanges;
  isLoaded: boolean;
}

export const initialProjectResourcesState: ProjectResourcesState = {
  resources: {} as ProjectResourceChanges,
  isLoaded: false
};


const reducer = createReducer(
  initialProjectResourcesState,
  on(ProjectResourcesActions.saveProjectResources, (state, { resources }) => ({ ...state, resources })),
  on(ProjectResourcesActions.markProjectResourcesLoaded, state => ({ ...state, isLoaded: true }))
);

export function projectResourcesReducer(state: ProjectResourcesState | undefined, action: Action): ProjectResourcesState {
  return reducer(state, action);
}
