import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Criteria } from 'shared/criteria/Criteria';

export type DateRange = { from?: moment.Moment, to?: moment.Moment };
export type Dic<T> = { [column: string]: T };
export type Uuid = string;

export interface KeyValue {
  key: string | number;
  value: any;
}

export const EMPTY_PAGINATION = { returnedCount: 0, totalCount: 0, output: Array<any>() };

export interface Pagination<T> {
  output: T[];
  totalCount: number;
}

export type PaginatedData<T> = (c: Criteria) => Observable<Pagination<T>>;
export type EagerData<T> = (c: Criteria) => Observable<T[]>;

export type ObservablePage<T> = Observable<Pagination<T>>;
export type ObservableData<T> = Observable<Array<T>>;

export const TimeHelper = {
  fromUtcToLocal: (hour: number, minute: number = 0, second: number = 0) => {
    const currentOffset = moment().utcOffset();
    const utcTime = moment().utcOffset(0).set({ hour, minute, second });
    const localTime = moment(utcTime).utcOffset(currentOffset);

    return {
      hour: localTime.get('hour'),
      minute: localTime.get('minute'),
      second: localTime.get('second')
    };
  },
  fromLocalToUtc: (hour: number, minute: number = 0, second: number = 0) => {
    const currentOffset = moment().utcOffset();
    const localTime = moment().utcOffset(currentOffset).set({ hour, minute, second });
    const utcTime = moment(localTime).utcOffset(0);

    return {
      hour: utcTime.get('hour'),
      minute: utcTime.get('minute'),
      second: utcTime.get('second')
    };
  }
};

export const filterNil = <V>(value: V): value is NonNullable<V> => value !== null && value !== undefined;
