import { NgModule } from '@angular/core';
import { InviteComponent } from 'root/invite/invite.component';
import { InviteRoutingModule } from 'root/invite/invite-routing.module';
import { SharedModule } from 'shared/shared.module';

@NgModule({
  declarations: [InviteComponent],
  exports: [InviteComponent],
  imports: [InviteRoutingModule, SharedModule]
})
export class InviteModule {

}
