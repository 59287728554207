import {
  AfterContentInit,
  Component,
  ContentChildren,
  Directive,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList
} from '@angular/core';
import { MatOption } from '@angular/material/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Directive({ selector: 'app-select-label' })
export class SelectLabelDirective {
}

interface Option {
  label: string;
  value: string;
  disabled: boolean;
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent implements OnInit, AfterContentInit, OnDestroy, ControlValueAccessor {

  @ContentChildren(MatOption)
  matOptionsList!: QueryList<MatOption>;

  private _multiple?: boolean;
  private _required?: boolean;
  private _subscription = new Subscription();

  @Output() valueChange = new EventEmitter<string | string[]>();

  private _control = new FormControl();
  private _options = Array<Option>();

  get control(): FormControl {
    return this._control;
  }

  get options(): Option[] {
    return this._options;
  }

  get multiple(): boolean | undefined {
    return this._multiple;
  }

  @Input()
  set multiple(value: boolean | undefined) {
    this._multiple = value;
  }

  get compareWith(): (a: any, b: any) => boolean {
    return this._compareWith;
  }

  @Input()
  set compareWith(value: (a: any, b: any) => boolean) {
    this._compareWith = value;
  }

  get required(): boolean | undefined {
    return this._required;
  }

  @Input()
  set required(value: boolean | undefined) {
    this._required = value;
  }

  get showSelectAll(): boolean {
    return !!this.multiple && this.options.length > 1;
  }

  private _compareWith: (a: any, b: any) => boolean = (a, b) => a === b;

  ngOnInit(): void {
    this.control.valueChanges.subscribe(() => this.notify());

    // Если мы напишем просто appMultiple (как директиву)
    // то в переменную запишется пустая строка,
    // затем просто превращаем ее в boolean
    this.multiple = this.multiple !== undefined;
    this.required = this.required !== undefined;
  }

  change(value: string | string[]): void {
    this.valueChange.emit(value);
  }

  ngAfterContentInit(): void {
    this._subscription = this.matOptionsList.changes
      // startsWith для того чтобы сработало при первом получении mat-option
      .pipe(startWith(1))
      .subscribe(() => {
        this._options = this.matOptionsList.map(m => ({ label: m.viewValue, value: m.value, disabled: m.disabled }));
      });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(value?: string | string[]): void {
    this.control.setValue(value || '');
    this.notify();
  }

  private notify(): void {
    this._onChange(this.control.value);
    this._onTouched();
  }

  private _onChange = (_: any) => {};
  private _onTouched = () => {};

}
