import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, switchMap } from 'rxjs/operators';
import { CourierService, ExtensionService } from 'core/http/root';
import * as CouriersActions from './couriers.actions';
import { forkJoin, of } from 'rxjs';
import * as NotifyEffects from 'app/store/common-effects/notifier.effects';

@Injectable()
export class CouriersEffects {

  getRootCouriers$ = createEffect(() => this.actions$.pipe(
    ofType(CouriersActions.getRootCouriers),
    switchMap(() => this.courierService.getAllCouriers()),
    switchMap(couriers => [
      CouriersActions.saveRootCouriers({ couriers }),
      CouriersActions.markRootCouriersLoaded()
    ])
  ));

  updateProjectExtensions$ = createEffect(() => this.actions$.pipe(
    ofType(CouriersActions.updateCourierExtensionProjects),
    exhaustMap(({ id, projects }) => forkJoin({
      projects: this.extensionService.updateExtensionProjects(id, projects),
      id: of(id)
    })),
    switchMap(data => [
      CouriersActions.updateCourierExtensionProjectsInStore({
        update: { id: data.id, changes: { projects: data.projects } }
      }),
      NotifyEffects.notify({ message: 'Проекты обновлены' })
    ])
  ));

  constructor(
    private actions$: Actions,
    private courierService: CourierService,
    private extensionService: ExtensionService
  ) {}

}
