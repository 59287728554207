import { Component, Input, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import { TableColumn } from 'shared/modules/table/table-utils/table-interfaces';

@Component({
  selector: 'app-column-select',
  templateUrl: './column-select.component.html',
  styleUrls: ['./column-select.component.scss']
})
export class ColumnSelectComponent implements OnInit {

  private _columns = Array<TableColumn>();
  private _storageKey?: string;
  private _activeColumns = Array<string>();
  private _inactiveColumns = Array<string>();

  get storageKey(): string | undefined {
    return this._storageKey;
  }

  get inactiveColumns(): string[] {
    return this._inactiveColumns;
  }

  set inactiveColumns(value: string[]) {
    this._inactiveColumns = value;
  }

  get activeColumns(): string[] {
    return this._activeColumns;
  }

  set activeColumns(value: string[]) {
    this._activeColumns = value;
  }

  get columns(): TableColumn[] {
    return this._columns;
  }

  @Input()
  set columns(value: TableColumn[]) {
    this._columns = value;
  }

  @Input()
  set storageKey(value: string | undefined) {
    this._storageKey = value;
  }

  ngOnInit(): void {
    if (!this.columns) {
      throw new Error(`Property 'columns' must be initialized`);
    }

    this.activeColumns = _.uniq(this.restoreColumns(this.columns, this.storageKey));
    this.inactiveColumns = this.columns.filter(c => !this.activeColumns.some(a => a === c.name)).map(c => c.name);
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    if (!this.storageKey) {
      return;
    }

    this.storeColumns(this.activeColumns, this.storageKey);
  }

  getLabel(name: string): string {
    const column = this.columns.find(c => c.name === name);

    if (!column || !column.label) {
      return 'Не найдено';
    }

    return column.label;
  }

  private storeColumns(columns: string[], storeKey: string): void {
    localStorage.setItem(storeKey, JSON.stringify(columns));
  }

  private restoreColumns(defaultColumns: TableColumn[], storeKey?: string): string[] {
    const jsonColumns = localStorage.getItem(storeKey || '');

    if (!jsonColumns) {
      return defaultColumns.filter(c => c.visible).map(c => c.name);
    }

    return JSON.parse(jsonColumns) as string[];
  }
}
