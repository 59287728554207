import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Subject, Subscription, timer } from 'rxjs';
import { buffer, debounce, map, tap } from 'rxjs/operators';

export interface ToggleItem {
  id: any;
  name: string;
  count?: number;
}

@Component({
  selector: 'app-toggle-group',
  templateUrl: './toggle-group.component.html',
  styleUrls: ['./toggle-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleGroupComponent),
      multi: true
    }
  ]
})
export class ToggleGroupComponent implements ControlValueAccessor, OnInit, OnDestroy {

  @Input() toggleItems = Array<ToggleItem>();
  @Output() toggled = new EventEmitter<Array<any>>();

  private _toggledIds = Array<any>();
  private _itemToggled$ = new Subject<{ id: any, delayed: boolean }>();
  private _toggledSubscription?: Subscription;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    const clicks$ = this._itemToggled$.pipe(
      debounce(click => timer(click.delayed ? 1000 : 0))
    );

    this._toggledSubscription = this._itemToggled$
      .pipe(
        buffer(clicks$),
        map(clicks => clicks.map(c => c.id)),
        tap(toggledIds => this.toggled.emit(toggledIds)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._itemToggled$.complete();
    this._toggledSubscription && this._toggledSubscription.unsubscribe();
  }

  registerOnChange(fn: () => {}): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this._onTouched = fn;
  }

  writeValue(ids?: Array<any>): void {
    this._toggledIds = ids || [];
    this.cd.detectChanges();
  }

  toggle(id: any, multiple: boolean): void {
    this._toggledIds = multiple
      ? this.selectMany(id)
      : this.selectOne(id);

    this._itemToggled$.next({ id, delayed: multiple });
    this.notify();
  }

  isToggled(id: any): boolean {
    return this._toggledIds.includes(id);
  }

  preventDisabling(event: MatButtonToggleChange, id: any): void {
    event.source.checked = this.isToggled(id);
  }

  private selectOne(id: any): any[] {
    return [id];
  }

  private selectMany(id: any): any[] {
    return this._toggledIds.includes(id)
      ? this._toggledIds.filter(currentId => currentId !== id)
      : this._toggledIds.concat([id]);
  }

  private _onChange = (_: any) => {};
  private _onTouched = () => {};

  private notify(): void {
    this._onChange(this._toggledIds);
    this._onTouched();
  }

}
