import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectFirst } from 'core/utils/rx-common';
import { currentProject } from 'app/store/project/current-project/current-project.selectors';
import { ProjectOutput } from 'core/http/dev';

@Pipe({ name: 'projectCurrency' })
export class ProjectCurrencyPipe implements PipeTransform {

  private _project = {} as ProjectOutput;

  constructor(private store$: Store) {
    this.store$.pipe(selectFirst(currentProject))
      .subscribe(project => this._project = project);
  }

  transform(money: number): string {
    return `${money} ${this._project.currencyCode}`;
  }

}
