import { Component, Input, OnChanges } from '@angular/core';
import { Rule } from 'angular2-query-builder';

@Component({
  selector: 'app-simple-number-query-input',
  templateUrl: './simple-number-query-input.component.html',
  styleUrls: ['./simple-number-query-input.component.scss']
})
export class SimpleNumberQueryInputComponent implements OnChanges {

  private _rule?: Rule;
  private _onChange?: () => void;

  ngOnChanges(): void {
    this.rule.value = typeof this.rule.value === 'number' ? this.rule.value : 0;
  }

  changeValue(value: string): void {
    this.rule.value = +value;
    this.onChange();
  }

  get rule(): Rule {
    if (!this._rule) {
      throw new Error(`Required input 'rule' is not specified`);
    }

    return this._rule;
  }

  @Input()
  set rule(rule: Rule) {
    this._rule = rule;
  }

  get onChange(): () => void {
    if (!this._onChange) {
      throw new Error(`Required input 'onChange' is not specified`);
    }

    return this._onChange;
  }

  @Input()
  set onChange(onChange: () => void) {
    this._onChange = onChange;
  }

}
