import { HttpClient } from '@angular/common/http';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Criteria } from 'shared/criteria/Criteria';
import { SenderOutput } from 'core/http/project/sender/sender.service';
import { StorehouseOutput } from 'core/http/project/store-house/storehouse.service';
import { CourierOutput, GoodOutput, OrderOutput, TrackReserveOutput } from 'core/http/project';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { BackgroundProgress } from 'core/http/project/background/background-task.service';
import { Pagination, Uuid } from 'shared/common/types';
import * as _ from 'lodash';
import { chain } from 'lodash';
import { LoggedUserOutput } from 'core/http/lg-logistic-rest-api/Auth/token.service';
import { RejectedOrderCountByType, RejectedOrderOutput } from 'core/http/project/courier/rejected-error-interfaces';

export interface CompletedPassOutput {
  type: 'COMPLETED';
  unloadedOrderCount: number;
  rejectedOrderCount: number;
}

export interface FailurePassOutput {
  type: 'FAILURE';
  failureReason: string;
}

export interface SkippedPassOutput {
  type: 'SKIPPED';
}

export type UnloadingPass =
  { id: Uuid; courierId: Uuid; senderId: Uuid; }
  & (CompletedPassOutput | FailurePassOutput | SkippedPassOutput);

export type SendingStatus = 'WITHOUT' | 'PENDING' | 'FAILURE' | 'SUCCESS';

export interface UnloadingOutput {
  id: Uuid;
  sequenceNumber: number;
  totalOrderCount: number;
  unloadedOrderCount: number;
  notUnloadedOrderCount: number;
  poolSendingStatus: SendingStatus;
  summarySendingStatus: SendingStatus;
  createdAt: string;
  user: LoggedUserOutput;
  passes: UnloadingPass[];
}

export interface UnloadedOrderOutput {
  order: OrderOutput;
  assignedTrack: string;
}

export interface NotUnloadedOrderOutput {
  order: OrderOutput;
  errors: UnloadingErrorOutput[];
}

export interface UnloadingErrorOutput {
  passId: Uuid;
  courier: CourierOutput;
  sender: SenderOutput;
  reason: string;
}

export interface WriteOffOutput {
  store: StorehouseOutput;
  quantity: number;
}

export interface WriteOffOrderedGoodOutput {
  id: Uuid;
  name: string;
  totalQuantity: number;
  writeOff: WriteOffOutput[];
}

export interface ChargesOutput {
  good: GoodOutput;
  storehouse: StorehouseOutput;
  quantity: number;
}

export interface WriteOffInput {
  goodId: Uuid;
  writeOff: Array<{ storeId: Uuid, quantity: number }>;
}

export interface CheckReserveDeficitOutput {
  with: TrackReserveOutput[];
  without: Array<{ courier: CourierOutput, sender: SenderOutput }>;
}

export interface CheckAssociationOutput {
  courier: CourierOutput;
  isAssociated: boolean;
}

export interface RestoreUnloadingTracksInput {
  unloadingId: Uuid;
  passId: Uuid;
  orderFilter: string;
}

export interface RestoreUnloadingTracksOutput {
  type: 'SUCCESSFULLY_RESTORED' | 'NOT_FROM_UNLOADING' | 'IS_TRANSFERRED_TO_COURIER';
  orderId: Uuid;
}

@ProjectInjectable()
export class UnloadingService {

  constructor(private http: HttpClient) {}

  runSingleUnloading(courierId: Uuid, senderId: Uuid, storehouseId: Uuid, orderFilter: string): Observable<BackgroundProgress> {
    return this.http.post<BackgroundProgress>(apiUrl('/project/courier/unloading'),
      { courierId, senderId, storehouseId, orderFilter });
  }

  runChainUnloading(chainId: Uuid, orderFilter: Uuid): Observable<BackgroundProgress> {
    return this.http.post<BackgroundProgress>(apiUrl('/project/courier/chain-unloading'), { chainId, orderFilter });
  }

  checkCourierAssociation(courierId: Uuid): Observable<CheckAssociationOutput> {
    return this.http
      .post<CheckAssociationOutput[]>(apiUrl('/project/unloading/check-associations'), { courierId })
      .pipe(map(associations => associations[0]));
  }

  checkChainAssociations(chainId: Uuid): Observable<CheckAssociationOutput[]> {
    return this.http.post<CheckAssociationOutput[]>(apiUrl('/project/unloading/check-associations'), { chainId });
  }

  checkCourierReserve(courierId: Uuid, senderId: Uuid): Observable<CheckReserveDeficitOutput> {
    return this.http.post<CheckReserveDeficitOutput>(apiUrl('/project/unloading/check-reserves'), { courierId, senderId });
  }

  checkChainReserves(chainId: Uuid): Observable<CheckReserveDeficitOutput> {
    return this.http.post<CheckReserveDeficitOutput>(apiUrl('/project/unloading/check-reserves'), { chainId });
  }

  getUnloadings(criteria: Criteria): Observable<Pagination<UnloadingOutput>> {
    return this.http.get<Pagination<UnloadingOutput>>(apiUrl(`/project/unloadings`, criteria));
  }

  getUnloading(unloadingId: Uuid): Observable<UnloadingOutput> {
    return this.http.get<UnloadingOutput>(apiUrl(`/project/unloadings/${unloadingId}`));
  }

  getUnloadingPass(unloadingId: Uuid, passId: Uuid): Observable<UnloadingPass> {
    return this.http.get<UnloadingPass>(apiUrl(`/project/unloading/${unloadingId}/pass/${passId}`));
  }

  getUnloadedOrders(unloadingId: Uuid, passId: Uuid, c: Criteria): Observable<Pagination<UnloadedOrderOutput>> {
    return this.http.get<Pagination<UnloadedOrderOutput>>(apiUrl(`/project/unloading/${unloadingId}/${passId}/unloaded-orders`, c));
  }

  getRejectedOrders(unloadingId: Uuid, passId: Uuid, criteria: Criteria): Observable<Pagination<RejectedOrderOutput>> {
    return this.http.get<Pagination<RejectedOrderOutput>>(apiUrl(`/project/unloading/${unloadingId}/${passId}/rejected-orders`, criteria));
  }

  getRejectedOrdersCountByErrorType(unloadingId: Uuid, passId: Uuid): Observable<_.Dictionary<number>> {
    return this.http.get<RejectedOrderCountByType[]>(apiUrl(`/project/unloading/${unloadingId}/${passId}/rejected-orders-count`))
      .pipe(
        map(counts => chain(counts)
          .keyBy(c => c.type)
          .mapValues(c => c.count)
          .value())
      );
  }

  getNotUnloadedOrders(unloadingId: Uuid, criteria: Criteria): Observable<Pagination<NotUnloadedOrderOutput>> {
    return this.http.get<Pagination<NotUnloadedOrderOutput>>(apiUrl(`/project/unloading/${unloadingId}/not-unloaded-orders`, criteria));
  }

  getSummary(orderFilter: string, documentId: Uuid): Observable<BackgroundProgress> {
    return this.http.post<BackgroundProgress>(apiUrl(`/project/document/summary`), { documentId, orderFilter });
  }

  restoreTracks(input: RestoreUnloadingTracksInput): Observable<RestoreUnloadingTracksOutput[]> {
    return this.http.post<RestoreUnloadingTracksOutput[]>(apiUrl('/project/unloading/restore-tracks'), input);
  }

  // todo: индусский код наскоряк, нужно порефакторить.
  getUnloadingChargesPreview(unloadingId: Uuid): Observable<WriteOffOrderedGoodOutput[]> {
    return this.http.get<ChargesOutput[]>(apiUrl(`/project/charges/${unloadingId}`)).pipe(
      map(chargers => {
        const acc: _.Dictionary<WriteOffOrderedGoodOutput> = {};

        for (const c of chargers) {
          if (!(c.good.id in acc)) {
            acc[c.good.id] = { id: c.good.id, name: c.good.name, totalQuantity: 0, writeOff: [] };
          }

          acc[c.good.id].totalQuantity += c.quantity;

          const found = acc[c.good.id].writeOff.find(f => f.store.id === c.storehouse.id);

          if (found) {
            found.quantity += c.quantity;
          } else {
            acc[c.good.id].writeOff.push({ store: c.storehouse, quantity: c.quantity });
          }
        }

        return Object.values(acc);
      })
    );
  }

  reportRejectedOrders(unloadingId: Uuid, passId: Uuid): Observable<BackgroundProgress> {
    return this.http.post<BackgroundProgress>(apiUrl('/project/report-rejected-orders'), { unloadingId, passId });
  }

}
