import { Criteria } from 'shared/criteria/Criteria';
import { ObservableData, ObservablePage, Pagination } from 'shared/common/types';
import { Observable } from 'rxjs';
import { RowClasses, TableData } from 'shared/modules/table/table-utils/table-interfaces';
import { map } from 'rxjs/operators';
import { Directive, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Directive({ selector: 'app-table-row' })
export class TableRowDirective<T = object> {

  @Output() rowClick = new EventEmitter<T>();
  private _classes: RowClasses<T> = {};

  get rowClasses(): RowClasses<T> {
    return this._classes;
  }

  @Input()
  set rowClasses(foobar: RowClasses<T>) {
    this._classes = foobar;
  }

}

@Directive({ selector: '[app-table-column]' })
export class TableColumnDirective {

  private _field = '';

  private _rowConverter?: (row: any) => any;

  constructor(readonly templateRef: TemplateRef<any>) { }

  @Input('app-table-columnName')
  set tableColumnName(name: string) {
    this._field = name;
  }

  get field(): string {
    return this._field;
  }

  get rowConverter(): ((row: any) => any) | undefined {
    return this._rowConverter;
  }

  @Input('app-table-columnConverter')
  set rowConverter(value: ((row: any) => any) | undefined) {
    this._rowConverter = value;
  }

  convertRow(row: any): any {
    return this.rowConverter ? this.rowConverter(row) : row;
  }
}

@Directive({ selector: '[app-table-header]' })
export class TableHeaderDirective {
  @Input() field = '';

  constructor(readonly templateRef: TemplateRef<any>) { }

  @Input('app-table-header')
  set tableColumnName(name: string) {
    this.field = name;
  }
}

@Directive({ selector: 'app-disable-table' })
export class DisableTableDirective {
}

@Directive({ selector: 'app-popover-sort' })
export class PopoverSortDirective {

  @Input() field = '';
  @Input() label = '';

}

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class FilterSource<T = string> {
  @Output() filterChanged = new EventEmitter<T>();
}

export abstract class TableDataLoader<T> {
  abstract load(c: Criteria): Observable<TableData<T>>;
}

export class EagerLoader<T> extends TableDataLoader<T> {

  constructor(private dataSource: (c: Criteria) => ObservableData<T>) {
    super();
  }

  load(c: Criteria): Observable<TableData<T>> {
    const criteria = new Criteria({ filter: c.filter, ordering: c.ordering });

    return this.dataSource(criteria).pipe(
      map((data: T[]) => ({ data, totalCount: data.length }))
    );
  }

}

export class PaginatedLoader<T> extends TableDataLoader<T> {

  constructor(private dataSource: (c: Criteria) => ObservablePage<T>) {
    super();
  }

  load(c: Criteria): Observable<TableData<T>> {
    return this.dataSource(c).pipe(
      map((data: Pagination<T>) => ({ data: data.output, totalCount: data.totalCount }))
    );
  }

}
