import { Component, Input } from '@angular/core';
import { ControlContainer, FormArray, FormGroup } from '@angular/forms';
import { DynamicField, SubsetField } from 'shared/modules/dynamic-form/field-types';
import { mapSubset, toFormGroup } from 'shared/modules/dynamic-form/to-form-group';
import { Observable } from 'rxjs';
import { GoodOutput, SenderOutput, StatusOutput, StorehouseOutput } from 'core/http/project';
import { FieldMap } from 'angular2-query-builder/dist/components';
import { Store } from '@ngrx/store';
import { Dic } from 'shared/common/types';
import { MatDialog } from '@angular/material/dialog';
import { storehouses } from 'app/store/project/storehouses/storehouses.selectors';
import { activatedSenders } from 'app/store/project/senders/senders.selectors';
import { goods } from 'app/store/project/goods/goods.selectors';
import { statuses } from 'app/store/project/statuses/statuses.selectors';
import { orderFieldMap } from 'app/store/project/field-map/field-map.selectors';
import { additionalFieldsWithNumbers } from 'app/store/project/addition-fields/addition-fields.selectors';
import { AdditionalField } from 'app/store/project/addition-fields/addition-fields.reducer';
import {
  SubsetDialogComponent,
  SubsetDialogData
} from 'shared/modules/dynamic-form/subset-dialog/subset-dialog.component';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent {

  private _fields = Array<DynamicField>();
  private _additionalFields$ = this.store$.select(additionalFieldsWithNumbers);
  private _goods$ = this.store$.select(goods);
  private _senders$ = this.store$.select(activatedSenders);
  private _statuses$ = this.store$.select(statuses);
  private _storehouses$ = this.store$.select(storehouses);
  private _orderFieldMap$ = this.store$.select(orderFieldMap);
  private _isParent = true;

  get isParent(): boolean {
    return this._isParent;
  }

  @Input()
  set isParent(value: boolean) {
    this._isParent = value;
  }

  @Input()
  set fields(fields: DynamicField[]) {
    this._fields = fields;
  }

  get fields(): DynamicField[] {
    return this._fields;
  }

  get additionalFields$(): Observable<AdditionalField[]> {
    return this._additionalFields$;
  }

  get senders$(): Observable<SenderOutput[]> {
    return this._senders$;
  }

  get goods$(): Observable<GoodOutput[]> {
    return this._goods$;
  }

  get orderFieldMap$(): Observable<FieldMap> {
    return this._orderFieldMap$;
  }

  get form(): FormGroup {
    return this.container.control as FormGroup;
  }

  get statuses$(): Observable<StatusOutput[]> {
    return this._statuses$;
  }

  get storehouses$(): Observable<StorehouseOutput[]> {
    return this._storehouses$;
  }

  constructor(
    private store$: Store,
    private container: ControlContainer,
    private dialog: MatDialog
  ) { }

  /**
   * Возвращает FormArray для Subset.
   *
   * @param group
   * @param controlName
   *   Наименование FormArray в FormGroup.
   */
  getSubsetControl(group: FormGroup, controlName: string): FormArray {
    const formArray = group.get(controlName);

    if (!(formArray instanceof FormArray)) {
      throw new Error(`FormArray with name '${controlName}' not found in dynamic form`);
    }

    return formArray;
  }

  /**
   * Получает внутрение FormGroup'ы для Subset
   *
   * @param controlName
   *   Имя FormArray контрола для Subset.
   */
  getSubsetForms(controlName: string): FormGroup[] {
    return this.getSubsetControl(this.form, controlName).controls as FormGroup[];
  }

  /**
   * Добавляет в FormArray Subset'а новую FormGroup.
   *
   * @param controlName
   *   Имя FormArray из FromGroup в который нужно добавить новый FormGroup.
   *
   * @param schema
   *   Описание динамических полей для создания FormGroup.
   */
  addSubsetItem(controlName: string, schema: DynamicField[]): void {
    this.getSubsetControl(this.form, controlName).push(toFormGroup(schema));
  }

  /**
   * Удаляет из FormArray Subset'а ранее добавленную FormGroup.
   *
   * @param controlName
   *   Имя FormArray из FormGroup в котором нужно удалить FormGroup.
   *
   * @param index
   *   Индекс FormGroup который нужно удалить.
   */
  removeSubsetItem(controlName: string, index: number): void {
    this.getSubsetControl(this.form, controlName).removeAt(index);
  }

  trackByName(i: number, item: DynamicField): string {
    return item.name;
  }

  trackByIndex(i: number): number {
    return i;
  }

  getSubsets(schema: DynamicField[], forms: Array<Dic<any>>): DynamicField[][] {
    return mapSubset(schema, forms, true);
  }

  openSubsetDialog(subset: SubsetField): void {
    const formArray = this.getSubsetControl(this.form, subset.name);
    const data: SubsetDialogData = { fields: subset.schema };

    this.dialog.open(SubsetDialogComponent, { data, maxHeight: '70vh', minWidth: '30vw' })
      .afterClosed()
      .subscribe((f: FormGroup) => {
        if (!f) {
          return;
        }

        formArray.push(f);
      });
  }

  openEditSubsetDialog(subset: SubsetField, i: number): void {
    const formArray = this.getSubsetControl(this.form, subset.name);
    const data: SubsetDialogData = { fields: subset.schema, value: formArray.at(i).value };

    this.dialog.open(SubsetDialogComponent, { data, maxHeight: '70vh', minWidth: '30vw' })
      .afterClosed()
      .subscribe((f: FormGroup) => {
        if (!f) {
          return;
        }

        formArray.removeAt(i);
        formArray.insert(i, f);
      });
  }

}
