import { Component, Inject } from '@angular/core';
import { ChangeOutput, OrderOutput, OrderService } from 'core/http/project';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { transition, trigger, useAnimation } from '@angular/animations';
import { hideAnimation, showAnimation } from 'shared/common/animations';

export interface OrderInfoData {
  route: ActivatedRoute;
}

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.scss'],
  animations: [
    trigger('show', [
      transition('void => *', [
        useAnimation(showAnimation)
      ])
    ]),
    trigger('hide', [
      transition('* => void', [
        useAnimation(hideAnimation)
      ])
    ])
  ]
})
export class OrderInfoComponent {

  readonly order$: Observable<OrderOutput>;
  readonly changes$: Observable<ChangeOutput[]>;
  readonly tabIndex = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OrderInfoData,
    private route: ActivatedRoute,
    private orderService: OrderService
  ) {
    const orderId = data.route.snapshot.params.orderId;

    this.order$ = this.orderService.getOrder(orderId);
    this.changes$ = this.orderService.getOrderChanges(orderId);
    this.tabIndex = this.route.snapshot.queryParams.tabIndex;
  }

}
