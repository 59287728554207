import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebhookService } from 'core/http/root';
import { Store } from '@ngrx/store';
import { selectFirst, to404PageIfNotFound } from 'core/utils/rx-common';
import * as RouterActions from 'app/store/common-effects/router.effects';
import { oneWebhook } from 'app/store/root/webhooks/webhooks.selectors';

@Component({
  selector: 'app-test-webhook',
  templateUrl: './test-webhook.component.html',
  styleUrls: ['./test-webhook.component.scss']
})
export class TestWebhookComponent implements OnInit {

  readonly webhookId = this.route.snapshot.params.webhookId;
  private _defaultEventExample = '';
  private _eventExample = '';
  private _testResult = '';
  private _loader = false;

  get defaultEventExample(): string {
    return this._defaultEventExample;
  }

  get loader(): boolean {
    return this._loader;
  }

  set loader(value: boolean) {
    this._loader = value;
  }

  get testResult(): string {
    return this._testResult;
  }

  get eventExample(): string {
    return this._eventExample;
  }

  set eventExample(value: string) {
    this._eventExample = value;
  }

  constructor(
    private webhookService: WebhookService,
    private route: ActivatedRoute,
    private store$: Store
  ) { }

  ngOnInit(): void {
    this.store$.pipe(
      selectFirst(oneWebhook, { id: this.webhookId }),
      to404PageIfNotFound(this.store$, 'root')
    ).subscribe(webhook => {
      this._defaultEventExample = JSON.stringify(webhook.eventExample, null, 2);
      this.eventExample = this.defaultEventExample;
    });
  }

  sendTestRequest(): void {
    this.loader = true;
    this.webhookService
      .checkWebhook({ event: JSON.parse(this.eventExample) }, this.webhookId)
      .subscribe(testResult => {
        this._testResult = JSON.stringify(testResult, null, 2);
        this.loader = false;
      });
  }

  resetInputForm(): void {
    this.eventExample = this.defaultEventExample;
  }

  goBack(): void {
    this.store$.dispatch(RouterActions.navigateToRoot({ path: ['integration', 'webhooks'] }));
  }

}
