import { createSelector } from '@ngrx/store';
import { DevState, selectDevState } from 'app/store/dev/dev.state';
import { Uuid } from 'shared/common/types';
import * as fromCouriers from './couriers.reducer';

export const devCouriersState = createSelector(
  selectDevState,
  state => state.couriers
);

export const devCouriers = createSelector(
  selectDevState,
  state => fromCouriers.selectAll(state.couriers)
);

export const oneDevCourier = createSelector(
  selectDevState,
  (state: DevState, props: { id: Uuid }) => fromCouriers.selectEntities(state.couriers)[props.id]
);

export const integrationClasses = createSelector(
  selectDevState,
  state => state.couriers.integrationClasses
);

export const trackerClasses = createSelector(
  selectDevState,
  state => state.couriers.trackerClasses
);

export const cancellerClasses = createSelector(
  selectDevState,
  state => state.couriers.cancellerClasses
);
