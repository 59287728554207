import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Criteria } from 'shared/criteria/Criteria';
import { ProjectOutput } from 'core/http/dev';
import { Role } from 'core/http/lg-logistic-rest-api/Auth/token.service';
import { StatusOutput } from 'core/http/project';
import { Injectable } from '@angular/core';

export interface UserOutput {
  id: string;
  login: string;
  fullName: string;
  email: string;
  phone: string;
  role: string;
  assignedProjects: ProjectOutput[];
  isActivated: boolean;
  registeredAt: string;
  updatedAt: string;
}

export interface Invite {
  id: string;
  role: string;
  projectIds: string[];
}

@Injectable({ providedIn: 'root' })
export class UserService {

  constructor(private http: HttpClient) {}

  invite(role: Role, projectIds: string[]): Observable<Invite> {
    return this.http.post<Invite>(apiUrl('/root/user/invite'), { role, projectIds });
  }

  getUser(userId: string): Observable<UserOutput> {
    return this.http.get<UserOutput>(apiUrl(`/root/user/${userId}`));
  }

  getAllUsers(criteria?: Criteria): Observable<UserOutput[]> {
    return this.http.get<UserOutput[]>(apiUrl('/root/users', criteria));
  }

  getAssignedProjects(userId: string): Observable<ProjectOutput[]> {
    return this.http.get<ProjectOutput[]>(apiUrl(`/root/user/${userId}/assigned-projects`));
  }

  assignProjects(userId: string, projectIds: string[]): Observable<ProjectOutput[]> {
    return this.http.put<ProjectOutput[]>(apiUrl(`/root/user/${userId}/assigned-projects`), { projectIds });
  }

  getAssignedStatuses(userId: string, projectId: string): Observable<StatusOutput[]> {
    return this.http.get<StatusOutput[]>(apiUrl(`/root/user/${userId}/project/${projectId}/assigned-statuses`));
  }

  assignStatuses(userId: string, projectId: string, statusIds: string[]): Observable<StatusOutput[]> {
    return this.http.put<StatusOutput[]>(apiUrl(`/root/user/${userId}/project/${projectId}/assigned-statuses`), { statusIds });
  }

  blockAccount(userId: string): Observable<never> {
    return this.http.patch<never>(apiUrl(`/root/user/${userId}/block-account`), {});
  }

  restoreAccount(userId: string): Observable<never> {
    return this.http.patch<never>(apiUrl(`/root/user/${userId}/restore-account`), {});
  }

}
