import { createSelector } from '@ngrx/store';
import { ProjectState, selectProjectState } from 'app/store/project/project.state';
import * as fromDocuments from './documents.reducer';

export const documentsState = createSelector(
  selectProjectState,
  state => state.documents
);

export const allDocumentEntities = createSelector(
  selectProjectState,
  (state: ProjectState) => fromDocuments.selectEntities(state.documents)
);

export const documents = createSelector(
  selectProjectState,
  (state: ProjectState) => fromDocuments.selectAll(state.documents).filter(item => item.type === 'POST_BLANK')
);

export const summary = createSelector(
  selectProjectState,
  (state: ProjectState) => fromDocuments.selectAll(state.documents).filter(item => item.type === 'SUMMARY')
);

export const revision = createSelector(
  selectProjectState,
  (state: ProjectState) => fromDocuments.selectAll(state.documents).filter(item => item.type === 'REVISION')
);

export const documentsLoading = createSelector(
  selectProjectState,
  state => state.documents.loading
);
