import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, switchMap, tap } from 'rxjs/operators';
import { ProjectService } from 'core/http/root';
import { LoaderService } from 'core/services/loader.service';
import * as ProjectsActions from './projects.actions';
import * as ProjectRouterActions from 'app/store/common-effects/router.effects';
import * as NotifyActions from 'app/store/common-effects/notifier.effects';

@Injectable()
export class ProjectsEffects {

  getProjects$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectsActions.getProjects),
    switchMap(() => this.projectService.getProjects()),
    switchMap(projects => [
      ProjectsActions.saveProjects({ projects }),
      ProjectsActions.markProjectsLoaded()
    ])
  ));

  createProject$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectsActions.createRootProject),
    tap(() => this.loader.begin()),
    exhaustMap(({ input }) => this.projectService.createProject(input)),
    tap(() => this.loader.end()),
    switchMap(project => [
      ProjectsActions.addRootProjectInStore({ project }),
      NotifyActions.notify({ message: 'Проект создан' }),
      ProjectRouterActions.navigateToRoot({ path: ['projects'] })
    ])
  ));

  updateProject$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectsActions.updateRootProject),
    tap(() => this.loader.begin()),
    exhaustMap(({ id, input }) => this.projectService.updateProject(id, input)),
    tap(() => this.loader.end()),
    switchMap(project => [
      ProjectsActions.updateRootProjectInStore({ update: { id: project.id, changes: project } }),
      NotifyActions.notify({ message: 'Проект обновлен' }),
      ProjectRouterActions.navigateToRoot({ path: ['projects'] })
    ])
  ));

  activateProject$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectsActions.activateProject),
    tap(() => this.loader.begin()),
    exhaustMap(({ id }) => this.projectService.activateProject(id)),
    tap(() => this.loader.end()),
    switchMap(project => [
      ProjectsActions.updateRootProjectInStore({ update: { id: project.id, changes: { isActive: project.isActive } } }),
      NotifyActions.notify({ message: 'Проект активирован' })
    ])
  ));

  deactivateProject$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectsActions.deactivateProject),
    tap(() => this.loader.begin()),
    exhaustMap(({ id }) => this.projectService.deactivateProject(id)),
    tap(() => this.loader.end()),
    switchMap(project => [
      ProjectsActions.updateRootProjectInStore({ update: { id: project.id, changes: { isActive: project.isActive } } }),
      NotifyActions.notify({ message: 'Проект деактивирован' })
    ])
  ));

  constructor(
    private actions$: Actions,
    private projectService: ProjectService,
    private loader: LoaderService
  ) { }

}
