import { initialLoggedUserState, LoggedUserState } from 'app/store/login/logged-user/logged-user.state';
import {
  AssignedProjectsState,
  initialAssignedStateState
} from 'app/store/login/assigned-projects/assigned-projects.reducer';
import { createFeatureSelector } from '@ngrx/store';

export const loginFeatureKey = 'login';

export const selectLoginState = createFeatureSelector<LoginState>(loginFeatureKey);

export interface LoginState {
  assignedProjects: AssignedProjectsState;
  loggedUser: LoggedUserState;
}

export const initialLoginState: LoginState = {
  assignedProjects: initialAssignedStateState,
  loggedUser: initialLoggedUserState
};
