import { TokenPairOutput } from 'core/http/lg-logistic-rest-api/Auth/token.service';

export interface LoggedUserState {
  token: TokenPairOutput | null;
  isRefreshing: boolean;
}

const userJson = localStorage.getItem('user');

export const initialLoggedUserState: LoggedUserState = {
  token: userJson ? JSON.parse(userJson) : null,
  isRefreshing: false
};
