import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectFirst, to404PageIfNotFound } from 'core/utils/rx-common';
import { oneWebhook } from 'app/store/root/webhooks/webhooks.selectors';
import { navigateToRoot } from 'app/store/common-effects/router.effects';
import { Uuid } from 'shared/common/types';
import { updateWebhook } from 'app/store/root/webhooks/webhooks.actions';

@Component({
  selector: 'app-update-webhook',
  templateUrl: './update-webhook.component.html',
  styleUrls: ['./update-webhook.component.scss']
})
export class UpdateWebhookComponent implements OnInit {

  readonly webhookId: Uuid = this.route.snapshot.params.webhookId;
  private _webhookForm = new FormGroup({
    url: this.fb.control(null),
    retries: this.fb.array([]),
    isActivated: this.fb.control(false)
  });

  get webhookForm(): FormGroup {
    return this._webhookForm;
  }

  get retries(): FormArray {
    return this.webhookForm.controls.retries as FormArray;
  }

  constructor(
    public fb: FormBuilder,
    private route: ActivatedRoute,
    private store$: Store
  ) { }

  ngOnInit(): void {
    this.store$.pipe(
      selectFirst(oneWebhook, { id: this.webhookId }),
      to404PageIfNotFound(this.store$, 'root')
    ).subscribe(data => {
      this._webhookForm = this.fb.group({
        url: this.fb.control(data.url),
        retries: this.fb.array(data.retries),
        isActivated: this.fb.control(data.isActivated)
      });
    });
  }

  updateWebhook(): void {
    this.store$.dispatch(updateWebhook({ id: this.webhookId, input: this.webhookForm.value }));
  }

  addInterval(): void {
    this.retries.push(this.fb.control(5));
  }

  removeInterval(): void {
    this.retries.removeAt(this.retries.length - 1);
  }

  goBack(): void {
    this.store$.dispatch(navigateToRoot({ path: ['integration', 'webhooks'] }));
  }

}
