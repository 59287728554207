import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { GoodOutput, StorehouseService } from 'core/http/project';
import { LoaderService } from 'core/services/loader.service';
import { HttpError } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Observable, throwError } from 'rxjs';
import * as NotifierActions from 'app/store/common-effects/notifier.effects';
import { switchCaseDispatch } from 'app/store/common-effects/notifier.effects';
import { Store } from '@ngrx/store';
import { CompletedEntityStateService } from 'app/store/completed-entity-state/completed-entity-state.service';
import { createProperties } from 'app/store/completed-entity-state/completed-entity-state';
import * as ProjectRouterActions from 'app/store/common-effects/router.effects';
import * as StorehousesActions from './storehouses.actions';
import * as GoodsActions from '../goods/goods.actions';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { selectFirst } from 'core/utils/rx-common';
import { currentProject } from 'app/store/project/current-project/current-project.selectors';

@Injectable()
export class StorehousesEffects {

  private readonly properties = createProperties(
    StorehousesActions.setLoading,
    StorehousesActions.markStorehouseLoaded,
    StorehousesActions.setError,
    () => this.storehouseService.getStorehouses(),
    'storehouses'
  );

  getStorehouses$ = createEffect(() => this.actions$.pipe(
    ofType(StorehousesActions.getStorehouses),
    exhaustMap(() => this.completedEntityStateService.get(this.properties)),
    switchMap(storehouses => [
      StorehousesActions.getDepartments(),
      StorehousesActions.saveStorehouses({ storehouses })
    ])
  ));

  createStorehouse$ = createEffect(() => this.actions$.pipe(
    ofType(StorehousesActions.createStorehouse),
    tap(() => this.loader.begin()),
    exhaustMap(input => this.storehouseService.createStorehouse(input)),
    tap(() => this.loader.end()),
    catchError((err: HttpError) => {
      this.loader.end();
      const errors = {
        type: err.type,
        errorCases: {
          STOREHOUSE_EXIST_ERROR: `Идентификатор занят`,
          default: err.message
        }
      };

      this.store$.dispatch(switchCaseDispatch({ err: errors }));
      return throwError(err);
    }),
    switchMap(storehouse => [
      StorehousesActions.addStorehouseInStore(storehouse),
      NotifierActions.notify({ message: 'Склад создан' }),
      ProjectRouterActions.navigateToProject({ path: ['setting', 'storehouses'] })
    ]),
    switchMap(() => this.removeGoodsFormDb()),
    map(() => GoodsActions.getGoods())
  ));

  updateStorehouse$ = createEffect(() => this.actions$.pipe(
    ofType(StorehousesActions.updateStorehouse),
    tap(() => this.loader.begin()),
    exhaustMap(({ id, input }) => this.storehouseService.updateStorehouse(id, input)),
    tap(() => this.loader.end()),
    catchError((err: HttpError) => {
      this.loader.end();
      const errors = {
        type: err.type,
        errorCases: {
          STOREHOUSE_EXIST_ERROR: `Идентификатор занят`,
          default: err.message
        }
      };

      this.store$.dispatch(switchCaseDispatch({ err: errors }));
      return throwError(err);
    }),
    switchMap(storehouse => [
      StorehousesActions.updateStorehouseInStore({ update: { id: storehouse.id, changes: storehouse } }),
      NotifierActions.notify({ message: 'Склад обновлен' }),
      ProjectRouterActions.navigateToProject({ path: ['setting', 'storehouses'] })
    ]),
    switchMap(() => this.removeGoodsFormDb()),
    map(() => GoodsActions.getGoods())
  ));

  removeStorehouse$ = createEffect(() => this.actions$.pipe(
    ofType(StorehousesActions.removeStorehouse),
    tap(() => this.loader.begin()),
    exhaustMap(({ id }) => this.storehouseService.removeStorehouse(id).pipe(mapTo(id))),
    tap(() => this.loader.end()),
    switchMap(id => [
      StorehousesActions.removeStorehouseFromStore({ id }),
      NotifierActions.notify({ message: 'Склад удален' })
    ])
  ));

  getDepartments$ = createEffect(() => this.actions$.pipe(
    ofType(StorehousesActions.getDepartments),
    exhaustMap(() => this.storehouseService.getDepartments()),
    map(departments => StorehousesActions.saveDepartments({ departments }))
  ));

  constructor(
    private actions$: Actions,
    private storehouseService: StorehouseService,
    private loader: LoaderService,
    private completedEntityStateService: CompletedEntityStateService,
    private store$: Store,
    private dbService: NgxIndexedDBService
  ) { }

  private removeGoodsFormDb(): Observable<GoodOutput[]> {
    return this.store$.pipe(
      selectFirst(currentProject),
      switchMap(project => this.dbService.delete('project-data', `goods-${project.id}`))
    );
  }

}
