import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { UserService } from 'core/http/root';
import { LoaderService } from 'core/services/loader.service';
import * as NotifyActions from 'app/store/common-effects/notifier.effects';
import * as UsersActions from '../users/users.actions';
import * as RouterActions from 'app/store/common-effects/router.effects';

@Injectable()
export class UsersEffects {

  getUsers$ = createEffect(() => this.actions$.pipe(
    ofType(UsersActions.getUsers),
    switchMap(() => this.userService.getAllUsers()),
    switchMap(users => [
      UsersActions.saveUsers({ users }),
      UsersActions.markUsersLoaded()
    ])
  ));

  blockUser$ = createEffect(() => this.actions$.pipe(
    ofType(UsersActions.blockUser),
    tap(() => this.loader.begin()),
    exhaustMap(({ id }) => this.userService.blockAccount(id).pipe(mapTo(id))),
    tap(() => this.loader.end()),
    switchMap(id => [
      UsersActions.updateUserInStore({
        update: {
          id: id,
          changes: { isActivated: false }
        }
      }),
      NotifyActions.notify({ message: 'Доступ закрыт' })
    ])
  ));

  restoreUser$ = createEffect(() => this.actions$.pipe(
    ofType(UsersActions.restoreUser),
    tap(() => this.loader.begin()),
    exhaustMap(({ id }) => this.userService.restoreAccount(id).pipe(mapTo(id))),
    tap(() => this.loader.end()),
    switchMap(id => [
      UsersActions.updateUserInStore({
        update: {
          id: id,
          changes: { isActivated: true }
        }
      }),
      NotifyActions.notify({ message: 'Доступ восстановлен' })
    ])
  ));

  assignStatuses$ = createEffect(() => this.actions$.pipe(
    ofType(UsersActions.assignStatus),
    tap(() => this.loader.begin()),
    exhaustMap(({ userId, projectId, statusIds }) => this.userService.assignStatuses(userId, projectId, statusIds)),
    tap(() => this.loader.end()),
    switchMap(() => [
      RouterActions.navigateToRoot({ path: ['managers'] }),
      NotifyActions.notify({ message: 'Статусы назначены' })
    ])
  ));

  assignProjects$ = createEffect(() => this.actions$.pipe(
    ofType(UsersActions.assignProjects),
    tap(() => this.loader.begin()),
    exhaustMap(({ userId, projectIds }) => this.userService.assignProjects(userId, projectIds)
      .pipe(
        map(projects => ({ projects: projects, userId: userId }))
      )),
    tap(() => this.loader.end()),
    switchMap(data => [
      UsersActions.updateUserInStore({
        update: {
          id: data.userId,
          changes: { assignedProjects: data.projects }
        }
      }),
      RouterActions.navigateToRoot({ path: ['managers'] }),
      NotifyActions.notify({ message: 'Проекты назначены' })
    ])
  ));

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private loader: LoaderService
  ) {}

}
