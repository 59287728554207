import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({ selector: '[absolute]' })
export class AbsoluteDirective implements AfterViewInit {

  @Input() aHeight = '60px';
  @Input() aMargin = '0';

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  /**
   * Преобразует помеченный директивой элемент в следуйщий html:
   *
   * <div style="height: 60px; margin: 10px;">
   *   <div style="position: relative;">
   *     <div style="position: absolute; z-index: 8; width: 100%;">
   *       <!-- Сюда будет вставлен элемент помеченный директивой --!>
   *     </div>
   *   </div>
   * </div>
   */
  ngAfterViewInit(): void {
    const gapBlock = this.renderer.createElement('div');
    this.renderer.setStyle(gapBlock, 'height', this.aHeight);
    this.renderer.setStyle(gapBlock, 'margin', this.aMargin);

    const relativeBlock = this.renderer.createElement('div');
    this.renderer.setStyle(relativeBlock, 'position', 'relative');

    const absoluteBlock = this.renderer.createElement('div');
    this.renderer.setStyle(absoluteBlock, 'position', 'absolute');
    this.renderer.setStyle(absoluteBlock, 'z-index', 8);
    this.renderer.setStyle(absoluteBlock, 'left', 0);
    this.renderer.setStyle(absoluteBlock, 'width', '100%');

    this.renderer.appendChild(gapBlock, relativeBlock);
    this.renderer.appendChild(relativeBlock, absoluteBlock);

    // Родитель в котором находится блок для оборачивания.
    const parent = this.el.nativeElement.parentNode;

    // Вставляем обертку после элемента для оборачивания.
    this.renderer.insertBefore(parent, gapBlock, this.el.nativeElement);

    // Удаляем элемент из старого родителя...
    this.renderer.removeChild(parent, this.el.nativeElement);

    // ...и вставляем в нового.
    this.renderer.appendChild(absoluteBlock, this.el.nativeElement);
  }

}
