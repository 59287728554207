import { HttpClient } from '@angular/common/http';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Observable } from 'rxjs';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { SenderOutput } from 'core/http/project/sender/sender.service';
import { CourierOutput } from 'core/http/project';
import { BackgroundProgress } from 'core/http/project/background/background-task.service';
import { Criteria } from 'shared/criteria/Criteria';
import { Uuid } from 'shared/common/types';

export interface ClearReserveOutput {
  courierId: Uuid;
  senderId: Uuid;
}

export interface ReservationErrorInput {
  track: string;
  type: number;
}

export interface ReservationResultInput {
  progressId: string;
  reservationErrors: ReservationErrorInput[];
}

export interface TrackReservationOutput {
  courierId: string;
  senderId: string;
  tracks: string[];
}

export interface TrackReserveOutput {
  id: string;
  sender: SenderOutput;
  courier: CourierOutput;
  remainingCount: number;
  patterns: string[];
  canReturn: boolean;
}


@ProjectInjectable()
export class TrackReservationService {

  constructor(private http: HttpClient) {}

  reserve(input: TrackReservationOutput): Observable<BackgroundProgress> {
    return this.http.post<BackgroundProgress>(apiUrl('/project/courier-reserve'), input);
  }

  changeReturnPolicy(courierId: Uuid, senderId: Uuid, canReturn: boolean): Observable<void> {
    return this.http.patch<void>(apiUrl('/project/courier-reserve'), { courierId, senderId, canReturn });
  }

  clear(courierId: Uuid, senderId: Uuid): Observable<BackgroundProgress> {
    return this.http.request<BackgroundProgress>('delete', apiUrl('/project/courier-reserve'), {
      body: { courierId, senderId }
    });
  }

  getAllReserves(criteria: Criteria): Observable<TrackReserveOutput[]> {
    return this.http.get<TrackReserveOutput[]>(apiUrl(`/project/courier-reserve`, criteria));
  }

}
