import { Directive, TemplateRef } from '@angular/core';
var QueryArrowIconDirective = /** @class */ (function () {
    function QueryArrowIconDirective(template) {
        this.template = template;
    }
    QueryArrowIconDirective.decorators = [
        { type: Directive, args: [{ selector: '[queryArrowIcon]' },] },
    ];
    /** @nocollapse */
    QueryArrowIconDirective.ctorParameters = function () { return [
        { type: TemplateRef, },
    ]; };
    return QueryArrowIconDirective;
}());
export { QueryArrowIconDirective };
