import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrouperDialogComponent } from './grouper-dialog.component';
import { SharedModule } from 'shared/shared.module';

@NgModule({
  declarations: [
    GrouperDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    GrouperDialogComponent
  ]
})
export class GrouperDialogModule {
}
