import {TranslateService} from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class LanguageService {
  private readonly LANGUAGE_KEY = 'LANGUAGE';
  DEFAULT_LANGUAGE = this.translate.getBrowserLang();

  constructor(private translate: TranslateService) {
  }

  initialize(): void {
    if (!localStorage.getItem(this.LANGUAGE_KEY)) {
      localStorage.setItem(this.LANGUAGE_KEY, this.DEFAULT_LANGUAGE);
    }

    moment.locale(this.currentLanguage);
    this.translate.use(this.currentLanguage);
  }

  get currentLanguage(): string {
    return localStorage.getItem(this.LANGUAGE_KEY) || this.DEFAULT_LANGUAGE;
  }

}
