import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { NoticeService } from 'core/http/project/notice/notice.service';
import { LoaderService } from 'core/services/loader.service';
import {
  CourierChainNoticeTriggerInput,
  ScheduledNoticeTriggerInput
} from 'core/http/project/notice/notice.interfaces';
import { CompletedEntityStateService } from 'app/store/completed-entity-state/completed-entity-state.service';
import { createProperties } from 'app/store/completed-entity-state/completed-entity-state';
import * as NoticesActions from './notices.actions';
import * as NotifierActions from '../../common-effects/notifier.effects';
import * as RouterActions from '../../common-effects/router.effects';

@Injectable()
export class NoticesEffects {

  private readonly properties = createProperties(
    NoticesActions.setLoading,
    NoticesActions.markNoticesLoaded,
    NoticesActions.setError,
    () => this.noticeService.getTriggers(),
    'noticeTriggers'
  );

  getTelegramChats$ = createEffect(() => this.actions$.pipe(
    ofType(NoticesActions.getTelegramChats),
    exhaustMap(() => this.noticeService.getTelegramChats()),
    map(chats => NoticesActions.saveTelegramChats({ chats }))
  ));

  getNotices$ = createEffect(() => this.actions$.pipe(
    ofType(NoticesActions.getNotices),
    exhaustMap(() => this.completedEntityStateService.get(this.properties)),
    switchMap(notices => [
      NoticesActions.saveNotices({ notices }),
      NoticesActions.getTelegramChats()
    ])
  ));

  createNotice$ = createEffect(() => this.actions$.pipe(
    ofType(NoticesActions.createNotice),
    tap(() => this.loader.begin()),
    exhaustMap(({ noticeType, input }) => {
      return noticeType === 'COURIER_CHAIN_NOTICE_TRIGGER'
        ? this.noticeService.createCourierChainTrigger(input as CourierChainNoticeTriggerInput)
        : this.noticeService.createScheduledTrigger(input as ScheduledNoticeTriggerInput);
    }),
    tap(() => this.loader.end()),
    switchMap(notice => [
      NoticesActions.addNoticeInStore({ notice }),
      NotifierActions.notify({ message: 'Уведомление создано' }),
      RouterActions.navigateToProject({ path: ['setting', 'notices'] })
    ])
  ));

  updateNotice$ = createEffect(() => this.actions$.pipe(
    ofType(NoticesActions.updateNotice),
    tap(() => this.loader.begin()),
    exhaustMap(({ noticeType, id, input }) => {
      return noticeType === 'COURIER_CHAIN_NOTICE_TRIGGER'
        ? this.noticeService.updateCourierChainTrigger(id, input as CourierChainNoticeTriggerInput)
        : this.noticeService.updateScheduledTrigger(id, input as ScheduledNoticeTriggerInput);
    }),
    tap(() => this.loader.end()),
    switchMap(notice => [
      NoticesActions.updateNoticeInStore({
        update: { id: notice.id, changes: notice }
      }),
      NotifierActions.notify({ message: 'Уведомление обновлено' }),
      RouterActions.navigateToProject({ path: ['setting', 'notices'] })
    ])
  ));

  removeNotice$ = createEffect(() => this.actions$.pipe(
    ofType(NoticesActions.removeNotice),
    tap(() => this.loader.begin()),
    exhaustMap(({ id }) => this.noticeService.removeNotice(id).pipe(mapTo(id))),
    tap(() => this.loader.end()),
    switchMap(id => [
      NoticesActions.removeNoticeFromStore({ id }),
      NotifierActions.notify({ message: 'Уведомление удалено' })
    ])
  ));

  constructor(
    private actions$: Actions,
    private noticeService: NoticeService,
    private loader: LoaderService,
    private completedEntityStateService: CompletedEntityStateService
  ) {}

}
