import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GrouperDialogModule } from 'shared/dialogs/grouper-dialog/grouper-dialog.module';
import { UnloadingDataDialogModule } from 'shared/dialogs/sender-select-dialog/unloading-data-dialog.module';
import { AssociationDialogModule } from 'shared/dialogs/association-dialog/association-dialog.module';
import { OrderActionsComponent } from './components/order-actions/order-actions.component';
import { MdePopoverModule } from '@material-extended/mde';
import { OrderPageComponent } from 'order/pages/order-page/components/order-page/order-page.component';
import { OrderFilterComponent } from 'order/pages/order-page/components/order-filter/order-filter.component';
import { ReserveCheckDialogModule } from 'shared/dialogs/reserve-check-dialog/reserve-check-dialog.module';
import { UserFiltersComponent } from 'order/pages/order-page/components/user-filters/user-filters.component';
import { SharedModule } from 'shared/shared.module';
import { OrderInfoModule } from 'order/modules/order-info/order-info.module';
import { CreateOrdersDialogComponent } from './components/create-orders-dialog/create-orders-dialog.component';
import { CreateOrdersResultDialogComponent } from './components/create-orders-dialog/create-orders-result-dialog/create-orders-result-dialog.component';
import { OrderCodeDialogComponent } from 'shared/dialogs/order-code-dialog/order-code-dialog.component';

@NgModule({
  declarations: [
    OrderPageComponent,
    OrderActionsComponent,
    OrderFilterComponent,
    UserFiltersComponent,
    CreateOrdersDialogComponent,
    CreateOrdersResultDialogComponent,
    OrderCodeDialogComponent
  ],
  exports: [
    OrderActionsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    UnloadingDataDialogModule,
    AssociationDialogModule,
    ReserveCheckDialogModule,
    GrouperDialogModule,
    MdePopoverModule,
    SharedModule,
    OrderInfoModule
  ]
})
export class OrderPageModule {
}
