import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationService } from 'core/http/lg-logistic-rest-api/Auth/registration.service';
import { TokenService } from 'core/http/lg-logistic-rest-api/Auth/token.service';
import { SharedModule } from 'shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './components/login/login.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { RecoveryComponent } from './components/recovery/recovery.component';
import { AuthComponent } from './components/auth/auth.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { EffectsModule } from '@ngrx/effects';
import { LoggedUserEffects } from 'app/store/login/logged-user/logged-user.effects';
import { AssignedProjectsEffects } from 'app/store/login/assigned-projects/assigned-projects.effects';
import { RouterEffects } from 'app/store/common-effects/router.effects';

const effects = [
  LoggedUserEffects,
  AssignedProjectsEffects,
  RouterEffects
];

@NgModule({
  declarations: [
    LoginComponent,
    RegistrationComponent,
    RecoveryComponent,
    AuthComponent,
    ForgotComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AuthRoutingModule,
    EffectsModule.forFeature(effects)
  ],
  providers: [
    TokenService,
    RegistrationService,
  ]
})
export class AuthModule {
}
