import { Component, Input } from '@angular/core';
import { OrderOutput, StatusOutput } from 'core/http/project';
import { Observable } from 'rxjs';
import * as fromStatuses from 'app/store/project/statuses/statuses.selectors';
import { Store } from '@ngrx/store';
import { selectFirst } from 'core/utils/rx-common';

@Component({
  selector: 'app-current-status',
  templateUrl: './current-status.component.html',
  styleUrls: ['./current-status.component.scss']
})
export class CurrentStatusComponent {

  private _order?: OrderOutput;

  @Input() skeletonView = false;

  @Input()
  set order(o: OrderOutput) {
    this._order = o;
  }

  get order(): OrderOutput {
    if (!this._order) {
      throw new Error('Order was not initialized');
    }

    return this._order;
  }

  get status$(): Observable<StatusOutput | undefined> {
    if (!this._order) {
      throw new Error('Order was not initialized');
    }

    return this.store$
      .pipe(
        selectFirst(fromStatuses.oneStatus, { id: this._order.statusId })
      );
  }

  constructor(private store$: Store) {}

}
