import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UserOutput } from 'core/http/root';
import { Action, createReducer, on } from '@ngrx/store';
import * as UsersActions from '../users/users.actions';

export interface UsersState extends EntityState<UserOutput> {
  isLoaded: boolean;
}

const adapter: EntityAdapter<UserOutput> = createEntityAdapter();

export const initialUsersState = adapter.getInitialState({
  isLoaded: false
});

const reducer = createReducer(
  initialUsersState,
  on(UsersActions.saveUsers, (state, { users }) => adapter.setAll(users, state)),
  on(UsersActions.markUsersLoaded, state => ({ ...state, isLoaded: true })),
  on(UsersActions.updateUserInStore, (state, { update }) => adapter.updateOne(update, state))
);

export function usersReducer(state: UsersState | undefined, action: Action): UsersState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
