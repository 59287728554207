import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from 'shared/modules/skeleton/not-found/not-found.component';
import { LogoComponent } from 'shared/modules/skeleton/logo/logo.component';
import { MaterialModule } from 'shared/modules/material/material.module';
import { LoaderComponent } from 'shared/modules/skeleton/loader/loader.component';
import { AngularModule } from 'shared/modules/angular/angular.module';
import { FooterComponent } from 'shared/modules/skeleton/footer/footer.component';
import { HeaderComponent } from 'shared/modules/skeleton/header/header.component';
import { MainLayoutComponent } from 'shared/modules/skeleton/main-layout/main-layout.component';
import { PipesModule } from 'shared/modules/pipes/pipes.module';
import { TypographyModule } from 'shared/modules/typography/typography.module';
import { DirectivesModule } from 'shared/modules/directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ItemsSearchWrapperModule } from 'shared/modules/items-search-wrapper/items-search-wrapper.module';
import { ChangeLogComponent } from 'shared/modules/skeleton/change-log/change-log.component';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { SelectSearchModule } from 'shared/modules/controls/select-search/select-search.module';
import { MissedInfoComponent } from './missed-info/missed-info.component';

@NgModule({
  declarations: [
    NotFoundComponent,
    LogoComponent,
    LoaderComponent,
    FooterComponent,
    HeaderComponent,
    MainLayoutComponent,
    ChangeLogComponent,
    SnackbarComponent,
    MissedInfoComponent
  ],
  imports: [
    CommonModule,
    AngularModule,
    PipesModule,
    DirectivesModule,
    TypographyModule,
    TranslateModule,
    NgxSkeletonLoaderModule,
    ItemsSearchWrapperModule,
    MaterialModule,
    SelectSearchModule
  ],
  exports: [
    NotFoundComponent,
    LogoComponent,
    LoaderComponent,
    FooterComponent,
    HeaderComponent,
    MainLayoutComponent,
    NgxSkeletonLoaderModule,
    ChangeLogComponent,
    MissedInfoComponent
  ]
})
export class SkeletonModule {
}
