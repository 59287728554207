import { createSelector } from '@ngrx/store';
import { selectRootState } from 'app/store/root/root.state';
import * as fromSimpleExtensions from './simple-extensions.reducer';

export const simpleExtensionsState = createSelector(
  selectRootState,
  state => state.simpleExtensions
);

export const simpleExtensions = createSelector(
  selectRootState,
  state => fromSimpleExtensions.selectAll(state.simpleExtensions)
);
