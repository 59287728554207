import { Component, Input, OnInit } from '@angular/core';
import { OrderOutput } from 'core/http/project';

type PrettyOrder = OrderOutput & { storehouseName: string };

@Component({
  selector: 'app-storehouse-view',
  templateUrl: './storehouse-view.component.html',
  styleUrls: ['./storehouse-view.component.scss']
})
export class StorehouseViewComponent implements OnInit {

  private _order?: PrettyOrder;

  @Input() skeletonView = false;

  @Input()
  set order(o: PrettyOrder) {
    this._order = o;
  }

  get order(): PrettyOrder {
    if (!this._order) {
      throw new Error('Order was not initialized');
    }

    return this._order;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
