import { Component, Input, OnChanges } from '@angular/core';
import { Uuid } from 'shared/common/types';
import { Option, Rule } from 'angular2-query-builder';

@Component({
  selector: 'app-single-entity-query-input',
  templateUrl: './single-entity-query-input.component.html',
  styleUrls: ['./single-entity-query-input.component.scss']
})
export class SingleEntityQueryInputComponent implements OnChanges {

  private _rule?: Rule;
  private _onChange?: () => void;
  private _options = Array<Option>();

  ngOnChanges(): void {
    if (this.options.some(o => o.value === this.rule.value)) {
      return;
    }

    this.rule.value = this.options[0] ? this.options[0].value : null;
  }

  changeEntity(entityId: Uuid | null): void {
    this.rule.value = entityId;
    this.onChange();
  }

  get rule(): Rule {
    if (!this._rule) {
      throw new Error(`Required input 'rule' is not specified`);
    }

    return this._rule;
  }

  @Input()
  set rule(rule: Rule) {
    this._rule = rule;
  }

  get onChange(): () => void {
    if (!this._onChange) {
      throw new Error(`Required input 'onChange' is not specified`);
    }

    return this._onChange;
  }

  @Input()
  set onChange(onChange: () => void) {
    this._onChange = onChange;
  }

  get options(): Option[] {
    return this._options;
  }

  @Input()
  set options(options: Option[]) {
    this._options = options;
  }

}
