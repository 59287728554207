import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InviteComponent } from 'root/invite/invite.component';

const routes: Routes = [
  {
    path: '',
    component: InviteComponent,
    data: { title: 'Админка - Приглашение пользователей' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InviteRoutingModule {

}
