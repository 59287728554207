import { Directive, ElementRef, HostListener } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { Store } from '@ngrx/store';
import { notify } from 'app/store/common-effects/notifier.effects';

@Directive({
  selector: '[copyable]',
  host: { '[style.cursor]': '"pointer"' }
})
export class CopyableDirective {

  constructor(private clipboardService: ClipboardService, private store$: Store, private el: ElementRef) { }

  @HostListener('click') copy(): void {
    this.clipboardService.copyFromContent(this.el.nativeElement.innerText);
    this.store$.dispatch(notify({ message: 'Скопировано в буфер' }));
  }

}
