import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {

  private _isLoading$ = new BehaviorSubject<boolean>(false);

  begin(): void {
    this._isLoading$.next(true);
  }

  end(): void {
    this._isLoading$.next(false);
  }

  get isLoading$(): Observable<boolean> {
    return this._isLoading$;
  }

}
