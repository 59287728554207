import { NgModule } from '@angular/core';
import { OrderInfoComponent } from 'order/modules/order-info/order-info.component';
import { SharedModule } from 'shared/shared.module';
import { OrderTimelineComponent } from './order-timeline/order-timeline.component';
import { TimelineCardTextComponent } from './timeline-card-text/timeline-card-text.component';
import { OrderDescriptionComponent } from 'order/modules/order-info/order-description/order-description.component';
import { KeyValueViewComponent } from 'order/modules/order-info/tracking-details/key-value-view/key-value-view.component';
import { TrackingDetailsComponent } from 'order/modules/order-info/tracking-details/tracking-details.component';

@NgModule({
  declarations: [
    OrderInfoComponent,
    OrderTimelineComponent,
    TimelineCardTextComponent,
    OrderDescriptionComponent,
    KeyValueViewComponent,
    TrackingDetailsComponent
  ],
  exports: [
    KeyValueViewComponent
  ],
  imports: [
    SharedModule
  ]
})
export class OrderInfoModule {
}
