import { Action, createReducer, on } from '@ngrx/store';
import * as PageCriteriaStateActions from './page-criteria.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

const EMPTY_PAGE_CRITERIA: PageCriteria = {
  key: '',
  formValue: null,
  pageIndex: 0,
  pageSize: 10
};

export interface PageCriteria {
  key: string;
  formValue: object | null;
  pageIndex: number;
  pageSize: number;
}

export interface PageCriteriaState extends EntityState<PageCriteria> {
}

function selectKey(p: PageCriteria): string {
  return p.key;
}

const adapter: EntityAdapter<PageCriteria> = createEntityAdapter({
  selectId: selectKey
});

export const initialPageCriteriaState = adapter.getInitialState();

const reducer = createReducer(
  initialPageCriteriaState,
  on(PageCriteriaStateActions.addEmptyPageCriteria,
    (state, { key }) => adapter.setOne({ ...EMPTY_PAGE_CRITERIA, key }, state)),
  on(PageCriteriaStateActions.addPageCriteria, (state, { criteria }) => adapter.addOne(criteria, state)),
  on(PageCriteriaStateActions.updatePageCriteria, (state, { update }) => adapter.updateOne(update, state)),
  on(PageCriteriaStateActions.resetAllPageCriteria, state => adapter.removeAll(state))
);

export function pageCriteriaReducer(state: PageCriteriaState | undefined, action: Action): PageCriteriaState {
  return reducer(state, action);
}

export const {
  selectEntities
} = adapter.getSelectors();
