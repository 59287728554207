import { createSelector } from '@ngrx/store';
import { selectProjectState } from 'app/store/project/project.state';

export const additionalFieldsWithStrings = createSelector(
  selectProjectState,
  state => state.additionFields.additionFieldsWithStrings
);

export const additionalFieldsWithNumbers = createSelector(
  selectProjectState,
  state => state.additionFields.additionFieldsWithNumbers
);

export const selectedAdditions = createSelector(
  selectProjectState,
  state => state.additionFields.selectedAdditions
);
