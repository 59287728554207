import { createSelector } from '@ngrx/store';
import { ProjectState, selectProjectState } from 'app/store/project/project.state';
import { ProjectResourceType } from 'core/http/project/resource-updates/resource-updates.service';

export const projectResourcesState = createSelector(
  selectProjectState,
  state => state.projectResources
);

export const projectResources = createSelector(
  selectProjectState,
  state => state.projectResources.resources
);

export const projectResource = createSelector(
  selectProjectState,
  (state: ProjectState, key: ProjectResourceType) => state.projectResources.resources[key]
);
