import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.scss']
})
export class ProjectViewComponent {

  private _iso = '';
  private _name = '';

  get name(): string {
    return this._name;
  }

  @Input()
  set name(value: string) {
    this._name = value;
  }

  get iso(): string {
    return this._iso;
  }

  @Input()
  set iso(value: string) {
    this._iso = value;
  }

}
