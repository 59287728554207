import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { QueryBuilderDialogService } from 'shared/modules/query-builder/query-builder-dialog/query-builder-dialog.service';
import { FieldMap, RuleSet } from 'angular2-query-builder/dist/components';
import { FilterType } from 'core/http/project/rule-set/user-templates.service';

@Component({
  selector: 'app-filter-with-popover',
  templateUrl: './filter-with-popover.component.html',
  styleUrls: ['./filter-with-popover.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterWithPopoverComponent),
      multi: true
    }
  ]
})
export class FilterWithPopoverComponent implements ControlValueAccessor, OnInit {

  @Input() orderFieldMap: FieldMap = {};
  @Input() withPreview = false;
  @Input() type: FilterType = 'order';
  @Input() onlyIcon = false;

  get ruleSet(): RuleSet {
    return this.filterControl.value;
  }

  filterControl = new FormControl({ condition: 'and', rules: [] } as RuleSet);

  constructor(private queryBuilder: QueryBuilderDialogService) { }

  private _onChange = (_: any) => {};
  private _onTouched = () => {};

  ngOnInit(): void {
    this.filterControl.valueChanges.subscribe(() => this.notify());
  }

  openFilterDialog(): void {
    this.queryBuilder
      .open(this.type, this.orderFieldMap, this.filterControl.value)
      .subscribe(ruleSet => {
        if (!ruleSet) {
          return;
        }

        this.filterControl.setValue(ruleSet);
      });
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(ruleSet?: RuleSet | null): void {
    this.filterControl.setValue(ruleSet || { condition: 'and', rules: [] } as RuleSet);
    this.notify();
  }

  private notify(): void {
    this._onChange(this.filterControl.value);
    this._onTouched();
  }

}
