import { createSelector } from '@ngrx/store';
import * as fromAssignedProjects from './assigned-projects.reducer';
import { LoginState, selectLoginState } from 'app/store/login/login.state';
import { Uuid } from 'shared/common/types';

export const assignedProjectsState = createSelector(
  selectLoginState,
  state => state.assignedProjects
);

export const allAssignedProjects = createSelector(
  selectLoginState,
  state => fromAssignedProjects.selectAll(state.assignedProjects)
);

export const activeAssignedProjects = createSelector(
  selectLoginState,
  state => fromAssignedProjects.selectAll(state.assignedProjects).filter(p => p.isActive)
);

export const oneActiveAssignedProject = createSelector(
  selectLoginState,
  (state: LoginState, id: Uuid) => {
    const entities = fromAssignedProjects.selectEntities(state.assignedProjects);

    return entities[id]?.isActive ? entities[id] : undefined;
  }
);
