import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterModule, Routes } from '@angular/router';
import { CourierExtensionsComponent } from 'root/extensions/courier-extensions/courier-extensions.component';
import { DocumentExtensionsComponent } from 'root/extensions/document-extensions/document-extensions.component';
import { CourierMatchersComponent } from 'root/extensions/courier-extensions/matchers/courier-matchers.component';
import { RootInjectable } from 'core/http/root/root-services.module';
import { CourierService, StateMatcherOutput } from 'core/http/root';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SimpleExtensionsComponent } from 'root/extensions/simple-extensions/simple-extensions.component';

@RootInjectable()
export class StateMatchersResolver implements Resolve<StateMatcherOutput[]> {
  constructor(private courierService: CourierService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<StateMatcherOutput[]> {
    return this.courierService.getMatchers(route.params.courierId).pipe(
      tap(matchers => {
        const priorities = matchers.map(m => m.priority);
        const emptyPriorities = (Array<number>()).fill(0, 0, priorities.length);

        if (JSON.stringify(priorities) === JSON.stringify(emptyPriorities)) {
          let priority = 0;

          for (const matcher of matchers) {
            matcher.priority = priority++;
          }
        }
      })
    );
  }
}

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'couriers'
  },
  {
    path: 'couriers',
    component: CourierExtensionsComponent,
    data: { title: 'Админка - Курьерские службы' }
  },
  {
    path: 'documents',
    component: DocumentExtensionsComponent,
    data: { title: 'Админка - Документы' }
  },
  {
    path: 'simple-extensions',
    component: SimpleExtensionsComponent,
    data: { title: 'Админка - Простые расширения' }
  },
  {
    path: 'courier/:courierId/state-matchers',
    component: CourierMatchersComponent,
    resolve: {
      matchers: StateMatchersResolver
    },
    data: {
      templateType: 'parcel_status',
      title: 'Админка - Соответствие состояний'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExtensionsRoutingModule {

}
