import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PrettyDebt } from 'core/http/lg-logistic-rest-api/Auth/project.service';
import { Store } from '@ngrx/store';
import { activeAssignedProjects } from 'app/store/login/assigned-projects/assigned-projects.selectors';
import { tap } from 'rxjs/operators';
import { navigate } from 'app/store/common-effects/router.effects';
import { Observable } from 'rxjs';
import { ProjectOutput } from 'core/http/dev';
import * as moment from 'moment';
import { currentProject } from 'app/store/project/current-project/current-project.selectors';
import { Dictionary, keyBy } from 'lodash';

@Component({
  selector: 'app-debts',
  templateUrl: './debts.component.html',
  styleUrls: ['./debts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DebtsComponent {

  @Input() debts = Array<PrettyDebt>();

  @Input() oneProject = false;

  get summary(): Dictionary<PrettyDebt> {
    return keyBy(this.debts, 'projectId');
  }

  private _projects$ = this.store$.select(activeAssignedProjects).pipe(
    tap(p => {
      if (p.length !== 1) {
        return;
      }

      this.store$.dispatch(navigate({ path: ['project', p[0].route] }));
    })
  );

  private _currentProject$ = this.store$.select(currentProject);

  get projects$(): Observable<ProjectOutput[]> {
    return this._projects$;
  }

  get currentProject$(): Observable<ProjectOutput> {
    return this._currentProject$;
  }

  constructor(private store$: Store) { }

  getMonth(offset: number): string {
    const month = moment().subtract(offset, 'months').format('MMMM');
    return month[0].toUpperCase() + month.slice(1);
  }

  trackById(i: number, item: ProjectOutput): string {
    return item.id;
  }

}
