import { GoodOutput } from 'core/http/project';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as GoodsActions from './goods.actions';
import {
  CompletedEntityState,
  initialCompletedEntityState
} from 'app/store/completed-entity-state/completed-entity-state';

export interface GoodsState extends CompletedEntityState<GoodOutput> {}

const adapter: EntityAdapter<GoodOutput> = createEntityAdapter<GoodOutput>();

export const initialGoodsState = adapter.getInitialState(initialCompletedEntityState);

const reducer = createReducer(
  initialGoodsState,
  on(GoodsActions.saveGoods, (state, payload) => adapter.setAll(payload.goods, state)),
  on(GoodsActions.markGoodsLoaded, state => ({ ...state, loaded: true })),
  on(GoodsActions.updateGoodInStore, (state, { update }) => adapter.updateOne(update, state)),
  on(GoodsActions.addGoodInStore, (state, good) => adapter.addOne(good, state)),
  on(GoodsActions.setLoading, (state, { value }) => ({ ...state, loading: value })),
  on(GoodsActions.setError, (state, { value }) => ({ ...state, error: value }))
);

export function goodsReducer(state: GoodsState | undefined, action: Action): GoodsState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
