import { ProjectInjectable } from 'core/http/project/project-services.module';
import { Uuid } from 'shared/common/types';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { ProjectOutput } from 'core/http/dev';
import { Observable } from 'rxjs';
import { ProjectSchedule, Withdrawn } from 'core/http/root';

export interface UpdateCurrentProjectInput {
  currencyCode: string;
  defaultStatusId: Uuid | null;
  defaultStorehouseId: Uuid | null;
  withdrawnType: Withdrawn;
  withdrawnSchedules: ProjectSchedule;
}

@ProjectInjectable()
export class CurrentProjectService {

  constructor(private http: HttpClient) {}

  getCurrentProject(): Observable<ProjectOutput> {
    return this.http.get<ProjectOutput>(apiUrl(`/project/current`));
  }

  updateCurrentProject(input: UpdateCurrentProjectInput): Observable<ProjectOutput> {
    return this.http.put<ProjectOutput>(apiUrl(`/project/current`), input);
  }

}
