import { HttpClient } from '@angular/common/http';
import { Pagination } from 'core/types/pagination';
import { Observable } from 'rxjs';
import { Criteria } from 'shared/criteria/Criteria';
import { SenderOutput } from 'core/http/project/sender/sender.service';
import { DocumentOutput, OrderOutput } from 'core/http/project';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { BackgroundProgress } from 'core/http/project/background/background-task.service';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Uuid } from 'shared/common/types';
import { LoggedUserOutput } from 'core/http/lg-logistic-rest-api/Auth/token.service';

export interface PrintingOutput {
  id: string;
  sender: SenderOutput;
  user: LoggedUserOutput;
  documents: DocumentOutput[];
  printedCount: number;
  printedAt: string;
}

export interface PrintingResultInput {
  fileName: string;
  encodedFile: string;
}

@ProjectInjectable()
export class PrintingService {

  constructor(private http: HttpClient) {
  }

  beginPrinting(documentId: string, orderFilter: string, groupers = Array<string | number>()): Observable<BackgroundProgress> {
    return this.http.post<BackgroundProgress>(apiUrl('/project/document/printing'), {
      orderFilter,
      documentId,
      groupers
    });
  }

  beginPoolPrinting(poolId: string, orderFilter: string): Observable<BackgroundProgress> {
    return this.http.post<BackgroundProgress>(apiUrl('/project/document/pool-printing'), { poolId, orderFilter });
  }

  getPrintings(criteria: Criteria): Observable<Pagination<PrintingOutput[]>> {
    return this.http.get<Pagination<PrintingOutput[]>>(apiUrl(`/project/printings`, criteria));
  }

  getPrinting(printingId: Uuid): Observable<PrintingOutput> {
    return this.http.get<PrintingOutput>(apiUrl(`/project/printing/${printingId}`));
  }

  downloadFile(printingId: string): Observable<PrintingResultInput> {
    return this.http.get<PrintingResultInput>(apiUrl(`/project/printing/${printingId}/download`));
  }

  getPrintedOrders(printingId: string, criteria: Criteria): Observable<Pagination<OrderOutput[]>> {
    return this.http.get<Pagination<OrderOutput[]>>(apiUrl(`/project/printing/${printingId}/orders`, criteria));
  }

}
