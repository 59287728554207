import { Directive, TemplateRef } from '@angular/core';

@Directive({ selector: '[app-data-table-header]' })
export class DataTableHeaderDirective {

  constructor(private _templateRef: TemplateRef<{}>) {}

  get template(): TemplateRef<{}> {
    return this._templateRef;
  }

}
