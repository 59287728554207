import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import { Criteria, Ordering } from 'shared/criteria/Criteria';
import { BehaviorSubject, merge, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { EagerSource, PaginatedSource, TableRef } from 'shared/modules/table/table-utils/table-interfaces';
import {
  DisableTableDirective,
  EagerLoader,
  FilterSource,
  PaginatedLoader,
  TableColumnDirective,
  TableDataLoader,
  TableHeaderDirective,
  TableRowDirective
} from 'shared/modules/table/table-utils/table-directives';
import { OrderColumnsContainerComponent } from 'shared/modules/order-table/order-columns-container/order-columns-container.component';
import { Dictionary } from '@ngrx/entity';
import { filterNil } from 'src/app/shared/common/types';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent<T = object> implements AfterViewInit, OnInit {

  @ContentChild(OrderColumnsContainerComponent)
  orderColumns?: OrderColumnsContainerComponent;

  @ContentChildren(DisableTableDirective)
  disableDirective?: DisableTableDirective;

  @ContentChildren(TableColumnDirective)
  columnDirectives = new QueryList<TableColumnDirective>();

  @ContentChildren(TableHeaderDirective)
  headerDirectives = new QueryList<TableHeaderDirective>();

  @ContentChild(TableRowDirective)
  tableRow?: TableRowDirective<T>;

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;

  @ViewChild(MatTable)
  table!: MatTable<T>;

  /**
   * Если true, то загрузка данных не произойдет при ngOnInit. Функционал недоступен до выяснения зачем ваще это надо
   */
  @Input()
  manualLoading = false;

  /**
   * Если true, то в качестве первой колонки в таблице появится checkbox'ы для выбора записей.
   */
  @Input()
  withSelection = false;

  /**
   * Сбрасывает все выбранные строки в selection.
   */
  @Input()
  selectionResetter?: Observable<any>;

  /**
   * Функция доступа к идентификатору строки.
   * Нужна для selection если имя идентификатора не "id".
   */
  @Input()
  idAccessor?: (item: T) => any;

  /**
   * Происходит всякий раз когда меняются выбранные через selection сущности
   */
  @Output()
  selectionChanged = new EventEmitter<T[]>();

  /**
   * Источник фильтра на который может подписаться таблица. Необязателен.
   */
  @Input()
  filter?: FilterSource;

  /**
   * Провоцирует перезагрузку данных таблицы. Необязателен.
   */
  @Input()
  reloader?: Observable<any>;

  /**
   * Функция получения данных.
   * Принимает критерию и возвращает данные данные для постраничного вывода.
   */
  @Input()
  dataSource!: PaginatedSource<T> | EagerSource<T>;

  /**
   * Имена отображаемых столбцов в таблице.
   */
  @Input()
  columns: string[] = Array<string>();

  /**
   * Количество строк выводимых на одной странице по умолчанию.
   */
  @Input()
  limit = 10;

  @Input()
  loadingType: 'paginated' | 'all' = 'paginated';

  /**
   * Опции для выбора кол-ва записей на странице.
   */
  @Input()
  pageSizeOptions = [5, 10, 25, 100];

  /**
   * Текст который будет отображаться если данных нет.
   */
  @Input()
  noDataText = 'Нет данных';

  /**
   * Текст который будет отображаться при загрузке данных
   */
  @Input()
  dataLoadingText = 'Данные загружаются...';

  /**
   * Отображение app-disable-table
   */
  @Input()
  disable = false;

  /**
   * Указывает на какой странице открыть таблицу
   */
  @Input()
  pageIndex = 0;

  /**
   * Происходит всякий раз когда общее кол-во сущностей меняется.
   */
  @Output()
  totalCountChanged = new EventEmitter<number>();

  /**
   * Происходит каждый раз как меняется индекс или размер страницы
   */
  @Output()
  pageChanged = new EventEmitter<{ pageIndex: number, pageSize: number }>();

  private _filter = '';
  private _ordering?: Ordering;
  private _offset = 0;

  private _pageData = Array<T>();
  private _totalCount = 0;
  private _loading = false;

  private _columnDirectives = new Map<string, TableColumnDirective>();
  private _headerDirectives = new Map<string, TableHeaderDirective>();

  private _dataLoader!: TableDataLoader<T>;

  private _columnRefs = Array<TableRef>();

  private _dataReloader$ = new BehaviorSubject(true);
  private _dataSource$ = new Observable<T[]>();
  private _currentPageData = Array<T>();

  get currentPageData(): T[] {
    return this._currentPageData;
  }

  get dataSource$(): Observable<T[]> {
    return this._dataSource$;
  }

  get columnRefs(): TableRef[] {
    return this._columnRefs;
  }

  get pageData(): Array<T> {
    return this._pageData;
  }

  get totalCount(): number {
    return this._totalCount;
  }

  get loading(): boolean {
    return this._loading;
  }

  get load(): boolean {
    return this.loading && (!this.totalCount || !this.orderColumns);
  }

  get displayedColumns(): string[] {
    const columns = this.columns
      .filter(c => this.hasColumnTemplate(c));

    if (this.withSelection) {
      columns.unshift('select');
    }

    return columns;
  }

  get ordering(): Ordering | undefined {
    return this._ordering;
  }

  set ordering(ordering: Ordering | undefined) {
    this._ordering = ordering;
    this.dataReloader$.next(true);
  }

  get dataReloader$(): BehaviorSubject<boolean> {
    return this._dataReloader$;
  }

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (!this.dataSource) {
      throw new Error(`Input property 'dataSource' is required`);
    }

    this._dataLoader = this.isPaginated(this.dataSource)
      ? new PaginatedLoader(this.dataSource)
      : new EagerLoader(this.dataSource);

    this._offset = this.getOffset(this.pageIndex, this.limit);
    this._dataSource$ = this.getDataSource$();
  }

  ngAfterViewInit(): void {
    const orderCols = this.orderColumns?.columnDirectives.toArray() || [];
    const colDirs = [...this.columnDirectives.toArray(), ...orderCols];
    const allColumns = colDirs.map(c => c.field);

    this._columnDirectives = this.getColumnsMap(colDirs);
    this._headerDirectives = this.getHeadersMap(this.headerDirectives.toArray());

    this._columnRefs = this.getRefs(allColumns, this._columnDirectives, this._headerDirectives);
    this.cd.detectChanges();
  }

  onPage({ pageIndex, pageSize }: PageEvent): void {
    this.limit = pageSize;
    this._offset = this.getOffset(pageIndex, pageSize);

    this.pageChanged.emit({ pageIndex, pageSize });
    this.dataReloader$.next(true);
  }

  trackByIndex(i: number): number {
    return i;
  }

  getRowClasses(row: T): Dictionary<boolean> {
    if (!this.tableRow) {
      return {};
    }

    const all = this.tableRow.rowClasses;

    return Object.keys(all)
      .map(className => ({ [className]: all[className]?.(row) }))
      .reduce((prev, curr) => ({ ...prev, ...curr }));
  }

  private getDataSource$(): Observable<T[]> {
    const filter$ = this.filter?.filterChanged.pipe(
      tap(filter => this._filter = filter),
      tap(() => this.paginator?.firstPage())
    );

    const reloader$ = this.reloader?.pipe(
      tap(() => this.paginator?.firstPage())
    );

    const emitters = [filter$, reloader$, this.dataReloader$].filter(filterNil);

    return merge(...emitters).pipe(
      tap(() => this._loading = true),
      switchMap(() => {
        const criteria = new Criteria({
          filter: this._filter, limit: this.limit, offset: this._offset, ordering: this._ordering
        });

        return this._dataLoader.load(criteria);
      }),
      map(res => {
        this._loading = false;
        this._totalCount = res.totalCount;
        this._currentPageData = res.data;
        this.totalCountChanged.emit(res.totalCount);

        return res.data;
      }),
      catchError(e => {
        this._loading = false;

        return throwError(e);
      })
    );
  }

  private getOffset(pageIndex: number, limit: number): number {
    return pageIndex !== 0 ? limit * pageIndex : pageIndex;
  }

  private hasColumnTemplate(column: string): boolean {
    return this._columnDirectives.has(column);
  }

  private getRefs(columns: string[], cols: Map<string, TableColumnDirective>, headers: Map<string, TableHeaderDirective>): TableRef[] {
    return columns.map(c => ({
      header: headers.get(c) || null,
      column: cols.get(c) || null,
      colName: c
    } as TableRef));
  }

  private isPaginated(source: PaginatedSource<T> | EagerSource<T>): source is PaginatedSource<T> {
    return this.loadingType === 'paginated';
  }

  // Перегоняют данные о столбцах и заголовках в Map для удобной работы.
  private getColumnsMap(columnDirs: TableColumnDirective[]): Map<string, TableColumnDirective> {
    return columnDirs.reduce((columns, row) => columns.set(row.field, row), new Map());
  }

  private getHeadersMap(headerDirs: TableHeaderDirective[]): Map<string, TableHeaderDirective> {
    return headerDirs.reduce((headers, header) => headers.set(header.field, header), new Map());
  }
}
