import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Uuid } from 'shared/common/types';
import { Criteria } from 'shared/criteria/Criteria';
import { StatusOutput } from 'core/http/project';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { RuleSet } from 'angular2-query-builder';

export interface ChainedStatusOutput {
  chainedId: string | null;
  ruleSet: RuleSet;
  status: StatusOutput;
  priority: number;
}

export interface StatusChainOutput {
  id: Uuid;
  name: string;
  chainedStatuses: ChainedStatusOutput[];
}

export interface StatusChainInput {
  name: string;
  chainedStatuses: ChainedStatusInput[];
}

export interface ChainedStatusInput {
  chainedId: string | null;
  statusId: string;
  priority: number;
  ruleSet: RuleSet;
}

@ProjectInjectable()
export class StatusChainService {

  constructor(private http: HttpClient) {}

  getAllStatusChains(criteria?: Criteria): Observable<StatusChainOutput[]> {
    return this.http.get<StatusChainOutput[]>(apiUrl('/project/status-chains', criteria));
  }

  createStatusChain(input: StatusChainInput): Observable<StatusChainOutput> {
    return this.http.post<StatusChainOutput>(apiUrl('/project/status-chain'), input);
  }

  updateStatusChain(chainId: Uuid, input: StatusChainInput): Observable<StatusChainOutput> {
    return this.http.put<StatusChainOutput>(apiUrl(`/project/status-chain/${chainId}`), input);
  }

  removeStatusChain(chainId: Uuid): Observable<Object> {
    return this.http.delete(apiUrl(`/project/status-chain/${chainId}`));
  }

}
