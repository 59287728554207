import { Component, Input } from '@angular/core';
import { OrderOutput, TrackingOutput, TrackingStatusOutput } from 'core/http/project';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent {

  private _link = Array<string>();
  private _order = {} as OrderOutput;

  @Input() skeletonView = false;

  get order(): OrderOutput {
    if (!this._order) {
      throw new Error('Order was not initialized');
    }

    return this._order;
  }

  @Input()
  set order(o: OrderOutput) {
    this._order = o;
  }

  get link(): string[] {
    return this._link;
  }

  @Input()
  set link(value: string[]) {
    this._link = value;
  }

  get tracking(): TrackingOutput | null {
    return this.order.logistic?.tracking || null;
  }

  get firstTrackingStatus(): TrackingStatusOutput | null {
    return this.tracking?.statuses[0] || null;
  }

  get lastTrackingError(): string | null {
    const lastEvent = this.tracking?.lastEvent;
    return lastEvent?.error || null;
  }

  get addedStatuesCount(): string | null {
    const lastEvent = this.tracking?.lastEvent;
    return lastEvent?.statusesCount?.toString() || null;
  }

  constructor() { }

}
