import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoaderService } from 'core/services/loader.service';
import { Store } from '@ngrx/store';
import { logout } from 'app/store/login/logged-user/logged-user.actions';
import { errorHttpNotify } from 'app/store/common-effects/notifier.effects';

@Injectable()
export class BannedAccountInterceptor implements HttpInterceptor {

  constructor(
    private loader: LoaderService,
    private store$: Store
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((e: HttpErrorResponse) => {
        if (e.status === 403 && e.error.error === 'ACCOUNT_BANNED') {
          this.logout(e);
        }

        return throwError(e);
      })
    );
  }

  private logout(response: HttpErrorResponse): void {
    this.loader.end();
    this.store$.dispatch(logout());
    this.store$.dispatch(errorHttpNotify({ response }));
  }

}
