import { createSelector } from '@ngrx/store';
import { selectLoginState } from 'app/store/login/login.state';

export const loggedUserState = createSelector(
  selectLoginState,
  state => state.loggedUser
);

export const loggedUser = createSelector(
  selectLoginState,
  state => state.loggedUser.token
);

export const refreshing = createSelector(
  selectLoginState,
  state => state.loggedUser.isRefreshing
);

export const hasRootAccess = createSelector(
  selectLoginState,
  state => state.loggedUser.token
    ? ['ROLE_ROOT_ACCESS', 'ROLE_DEVELOPER'].includes(state.loggedUser.token.userInfo.role)
    : false
);

export const hasDevAccess = createSelector(
  selectLoginState,
  state => state.loggedUser.token
    ? state.loggedUser.token.userInfo.role === 'ROLE_DEVELOPER'
    : false
);
