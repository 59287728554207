import { Component, Input } from '@angular/core';
import { QueryBuilderConfig, RuleSet } from 'angular2-query-builder';
import { FormControl } from '@angular/forms';
import { FieldMap } from 'angular2-query-builder/dist/components';
import { OPERATOR_LABELS, OPERATOR_MAP } from 'shared/modules/query-builder/query-builder-utils';
import * as _ from 'lodash';

@Component({
  selector: 'app-query-builder',
  templateUrl: './query-builder.component.html',
  styleUrls: ['./query-builder.component.scss']
})
export class QueryBuilderComponent {

  readonly operatorMap = OPERATOR_MAP;
  readonly operatorLabels = OPERATOR_LABELS;
  readonly emptyMessage = 'Группа фильтров не может быть пустой';

  private _query = new FormControl({ condition: 'and', rules: [] });

  get query(): FormControl {
    return this._query;
  }

  private _fields: FieldMap = {};

  @Input() set fields(fields: FieldMap) {
    this._fields = _.cloneDeep(fields);
  }

  @Input() set ruleSet(ruleSet: RuleSet) {
    this._query.setValue(_.cloneDeep(ruleSet));
  }

  get config(): QueryBuilderConfig {
    return { fields: this._fields };
  }

  get value(): RuleSet {
    return this._query.value;
  }

}
