import { Component, Inject } from '@angular/core';
import { CheckAssociationOutput } from 'core/http/project';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export type CheckAssociationResult = 'INTERRUPT_UNLOADING' | 'RESUME_UNLOADING';

@Component({
  selector: 'app-association-dialog',
  templateUrl: './association-dialog.component.html'
})
export class AssociationDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) readonly associations: CheckAssociationOutput[]) {}

  get interruptResult(): CheckAssociationResult {
    return 'INTERRUPT_UNLOADING';
  }

  get resumeResult(): CheckAssociationResult {
    return 'RESUME_UNLOADING';
  }

}
