import { Action, createReducer, on } from '@ngrx/store';
import * as ProgressesBackLinksActions from './progresses-back-links.actions';
import { Uuid } from 'shared/common/types';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface ProgressBackLink {
  progressId: Uuid;
  link: string[];
}

export interface ProgressesBackLinksState extends EntityState<ProgressBackLink> {
}

function selectId(p: ProgressBackLink): Uuid {
  return p.progressId;
}

const adapter: EntityAdapter<ProgressBackLink> = createEntityAdapter({
  selectId: selectId
});

export const initialProgressesBackLinksState = adapter.getInitialState();

const reducer = createReducer(
  initialProgressesBackLinksState,
  on(ProgressesBackLinksActions.addBackLink, (state, data) => adapter.setOne(data, state))
);

export function progressesBackLinksReducer(state: ProgressesBackLinksState | undefined, action: Action): ProgressesBackLinksState {
  return reducer(state, action);
}

export const {
  selectEntities
} = adapter.getSelectors();
