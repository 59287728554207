import { Component, OnInit } from '@angular/core';
import { WebhookOutput } from 'core/http/root';
import { KeyValue } from 'shared/common/types';
import { Store } from '@ngrx/store';
import { selectFirst } from 'core/utils/rx-common';
import { events, webhooks } from 'app/store/root/webhooks/webhooks.selectors';
import { removeWebhook } from 'app/store/root/webhooks/webhooks.actions';
import { notify } from 'app/store/common-effects/notifier.effects';
import { EagerSource } from 'shared/modules/table/table-utils/table-interfaces';

@Component({
  selector: 'app-webhooks',
  templateUrl: './webhooks.component.html',
  styleUrls: ['./webhooks.component.scss']
})
export class WebhooksComponent implements OnInit {

  private _events = Array<KeyValue>();
  private _loading = false;
  readonly columns: string[] = [
    'url',
    'event',
    'retries',
    'actions'
  ];

  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  get events(): KeyValue[] {
    return this._events;
  }

  constructor(private store$: Store) { }

  dataSource: EagerSource<WebhookOutput> = () => this.store$.select(webhooks);

  ngOnInit(): void {
    this.store$.pipe(selectFirst(events))
      .subscribe(data => this._events = data);
  }

  getEventName(eventValue: string): string {
    const event = this.events.find(e => e.key === eventValue);
    return event && event.value;
  }

  removeWebhook(id: string): void {
    this.store$.dispatch(removeWebhook({ id }));
  }

  copyUrl(event: any): void {
    event.target.select();
    document.execCommand('copy');
    this.store$.dispatch(notify({ message: `Url скопирован в буфер обмена` }));
  }

}
