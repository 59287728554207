import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { CreateOrderOutput, OrderOutput, OrderService } from 'core/http/project/order/order.service';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { and, Criteria, has } from 'shared/criteria/Criteria';
import { SenderOutput } from 'core/http/project/sender/sender.service';
import { CourierOutput, GoodOutput, StatusOutput, StatusService, StorehouseOutput } from 'core/http/project';
import { Uuid } from 'shared/common/types';
import { ToggleItem } from 'shared/modules/controls/toggle-group/toggle-group.component';
import { FieldMap } from 'angular2-query-builder/dist/components';
import { Store } from '@ngrx/store';
import { couriers } from 'app/store/project/couriers/couriers.selectors';
import { storehouseEntities, storehouses } from 'app/store/project/storehouses/storehouses.selectors';
import { goods } from 'app/store/project/goods/goods.selectors';
import { allSenders } from 'app/store/project/senders/senders.selectors';
import * as fromStatuses from 'app/store/project/statuses/statuses.selectors';
import { orderFieldMap } from 'app/store/project/field-map/field-map.selectors';
import { first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { arrayFilter, selectFirst } from 'core/utils/rx-common';
import { additionalFieldsWithStrings } from 'app/store/project/addition-fields/addition-fields.selectors';
import { MatDialog } from '@angular/material/dialog';
import { CreateOrdersDialogComponent } from 'order/pages/order-page/components/create-orders-dialog/create-orders-dialog.component';
import { CreateOrdersResultDialogComponent } from 'order/pages/order-page/components/create-orders-dialog/create-orders-result-dialog/create-orders-result-dialog.component';
import { LoaderService } from 'core/services/loader.service';
import { AdditionalField } from 'app/store/project/addition-fields/addition-fields.reducer';
import { updatePageCriteria } from 'app/store/project/page-criteria/page-criteria.actions';
import { PaginatedSource, RowClasses, TableColumn } from 'shared/modules/table/table-utils/table-interfaces';
import { projectLoading } from 'app/store/project/project.reducer';

type PrettyOrder = OrderOutput & { storehouseName: string };

@Component({
  selector: 'app-order-page',
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderPageComponent implements OnInit, OnDestroy {

  readonly ORDER_PAGE_KEY = 'order-page';
  readonly loading$ = this.store$.select(projectLoading);
  private _couriers$ = this.store$.select(couriers);
  private _statuses$ = this.store$.select(fromStatuses.statuses).pipe(arrayFilter(s => !s.isRemoved));

  // Почему-то если селектить activatedSenders, то ,из-за того
  // что в этом селекторе вызывается filter(), значения спамятся бесконечно
  private _senders$ = this.store$.select(allSenders).pipe(arrayFilter(s => s.isActivated));
  private _storehouses$ = this.store$.select(storehouses);
  private _additionalFields$ = this.store$.select(additionalFieldsWithStrings);
  private _orderFieldMap$ = this.store$.select(orderFieldMap);
  private _goods$ = this.store$.select(goods);

  private _filter = '';
  private _userFilter = '';
  private _totalOrderCount = 0;
  private _selectedStatuses$ = new Observable<ToggleItem[]>();
  private _selectedOrders = Array<Uuid>();
  private _pageIndex = 0;
  private _pageSize = 10;

  private _orderReloader = new Subject();
  private _selectionResetter = new Subject();
  private _rowClasses: RowClasses<OrderOutput> = {
    'selected-order': (order: OrderOutput) => this._selectedOrders.includes(order.id)
  };
  private _columns: TableColumn[] = [
    { name: 'id', label: 'ID', visible: true },
    { name: 'cart', label: 'Товары', visible: true },
    { name: 'recipient', label: 'Получатель', visible: true },
    { name: 'dates', label: 'Даты', visible: true },
    { name: 'courier', label: 'Выгрузка', visible: true },
    { name: 'tracking', label: 'Трекинг', visible: true },
    { name: 'status', label: 'Статус', visible: true },
    { name: 'store', label: 'Склад', visible: false },
    { name: 'comment', label: 'Комментарий', visible: true },
    { name: 'additions', label: 'Доп. поля', visible: false }
  ];

  private _statusesSubscription?: Subscription;

  get selectionResetter(): Subject<unknown> {
    return this._selectionResetter;
  }

  get orderReloader(): Subject<unknown> {
    return this._orderReloader;
  }

  get goods$(): Observable<GoodOutput[]> {
    return this._goods$;
  }

  get orderFieldMap$(): Observable<FieldMap> {
    return this._orderFieldMap$;
  }

  get additionalFields$(): Observable<AdditionalField[]> {
    return this._additionalFields$;
  }

  get storehouses$(): Observable<StorehouseOutput[]> {
    return this._storehouses$;
  }

  get senders$(): Observable<SenderOutput[]> {
    return this._senders$;
  }

  get statuses$(): Observable<StatusOutput[]> {
    return this._statuses$;
  }

  get couriers$(): Observable<CourierOutput[]> {
    return this._couriers$;
  }

  get selectedStatuses$(): Observable<ToggleItem[]> {
    return this._selectedStatuses$;
  }

  get rowClasses(): RowClasses<OrderOutput> {
    return this._rowClasses;
  }

  get columns(): TableColumn[] {
    return this._columns;
  }

  set columns(value: TableColumn[]) {
    this._columns = value;
  }

  get selectedOrderCount(): number {
    return this._selectedOrders.length || this._totalOrderCount;
  }

  get procedureFilter(): string {
    return has.values('id', this._selectedOrders) || this.searchFilter;
  }

  get pageSize(): number {
    return this._pageSize;
  }

  get pageIndex(): number {
    return this._pageIndex;
  }

  get columnNames(): string [] {
    return this.columns.map(c => c.name);
  }

  private get searchFilter(): string {
    return and(this._userFilter, this._filter);
  }

  constructor(
    private orderService: OrderService,
    private statusService: StatusService,
    private store$: Store,
    private dialog: MatDialog,
    private loader: LoaderService,
  ) {}

  dataSource: PaginatedSource<PrettyOrder> = (c: Criteria) => {
    const criteria = c.clone({ filter: this.searchFilter });
    return forkJoin({
      orders: this.orderService.getAllOrders(criteria),
      storehousesDic: this.store$.pipe(selectFirst(storehouseEntities))
    })
      .pipe(
        map(({ orders, storehousesDic }) => {
          const output = orders.output.map(o => {
            const storehouseName = o.storehouseId ? storehousesDic[o.storehouseId]?.name : '';
            return { ...o, storehouseName } as PrettyOrder;
          });

          return { ...orders, output };
        })
      );
  };

  ngOnInit(): void {
    // Какой-то костыль, но вроде рабочий
    this._selectedStatuses$ = this.statuses$
      .pipe(
        switchMap(statuses => this.statusService.getStatusCountByOrders()
          .pipe(
            tap(() => {
              // Очистка selection при переходе между проектами.
              this.selectionResetter.next();
            }),
            map((counts) => {
              return statuses.map(status => ({
                id: status.id,
                name: status.name,
                count: counts[status.id]
              }));
            })
          )
        )
      );
  }

  ngOnDestroy(): void {
    if (this._statusesSubscription && !this._statusesSubscription.closed) {
      this._statusesSubscription.unsubscribe();
    }
  }

  onFilterChanged(filter: string): void {
    this._filter = filter;
  }

  onUserFilterChanged(filter: string): void {
    this._userFilter = filter;
    return this.orderReloader.next();
  }

  onOrderCountChanged(count: number): void {
    this._totalOrderCount = count;
  }

  onOrdersSelected(orders: OrderOutput[]): void {
    this._selectedOrders = orders.map(o => o.id);
  }

  openCreateOrderDialog(): void {
    forkJoin({
      couriers: this.couriers$.pipe(first()),
      senders: this.senders$.pipe(first())
    }).pipe(
      mergeMap(data => this.dialog.open(CreateOrdersDialogComponent, { data }).afterClosed()),
      tap(() => this.loader.begin()),
      mergeMap(orders => {
        if (!orders) {
          return of({} as CreateOrderOutput);
        }

        return this.orderService.createOrders(orders);
      })
    ).subscribe(output => {
      this.loader.end();

      if (!output.rejected && !output.accepted) {
        return;
      }

      this.dialog.open(CreateOrdersResultDialogComponent, { data: output, maxWidth: '40vw' });
    });
  }

  updatePageData(pageIndex: number, pageSize: number): void {
    this.store$.dispatch(updatePageCriteria({
      update: {
        id: this.ORDER_PAGE_KEY,
        changes: { pageIndex, pageSize }
      }
    }));
  }
}
