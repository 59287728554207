import { DateRange } from 'shared/common/types';
import { between, compare, has, or } from 'shared/criteria/Criteria';
import * as _ from 'lodash';
import { AbstractControl, FormGroup } from '@angular/forms';
import { SeparatorInputData } from 'shared/modules/controls/separator-input/separator-input.component';

export type DatesCompiler = (range: DateRange) => string | undefined;
export type EqualsCompiler = (value: string | number) => string | undefined;
export type TextCompiler = (value: string) => string | undefined;
export type ArrayCompiler = (values: Array<number | string>) => string | undefined;
export type ValuesFromStringCompiler = (value: SeparatorInputData) => string | undefined;

export type Compiler =
  | DatesCompiler
  | EqualsCompiler
  | ArrayCompiler
  | TextCompiler
  | ValuesFromStringCompiler;

export class Compilers {

  static dates(filed: string): DatesCompiler {
    return (range?: DateRange) => between.dates(filed, range && range.from, range && range.to);
  }

  static equals(field: string, condition?: string): EqualsCompiler {
    return (value?: string | number) => compare.eq(field, value, condition);
  }

  static text(field: string): TextCompiler {
    return (text?: string) => has.text(field, text);
  }

  static specificText(field: string, prefix: string, valueHandler?: (v?: string) => string): TextCompiler {
    return (text?: string) => has.text(field, `${prefix}: ${valueHandler?.(text) || text}`);
  }

  static array(field: string, maxCount?: number): ArrayCompiler {
    return (values?: Array<number | string>) => undefined !== maxCount
      ? maxCount === values?.length ? undefined : has.values(field, values || [])
      : has.values(field, values || []);
  }

  static valuesFromString(field: string): ValuesFromStringCompiler {
    return (source?: SeparatorInputData) => {
      if (!source?.value) {
        return undefined;
      }

      const items = source.value
        .replace(/\s+/g, ' ')
        .trim()
        .split(source.separator);
      const compiler = Compilers.array(field);

      return compiler(items);
    };
  }

  static containValues(field: string): ValuesFromStringCompiler {
    return (source?: SeparatorInputData) => {
      if (!source?.value) {
        return undefined;
      }

      const items = source.value
        .replace(/\s+/g, ' ')
        .trim()
        .split(source.separator);

      const compiler = Compilers.text(field);
      const parts = items.map(i => compiler(i));

      return or(...parts);
    };
  }

  static containOrEqual(field: string): ValuesFromStringCompiler {
    return (source?: SeparatorInputData) => {
      if (!source?.value) {
        return undefined;
      }

      const items = source.value
        .replace(/\s+/g, ' ')
        .trim()
        .split(source.separator);

      if (source.byEntry) {
        const textCompiler = Compilers.text(field);
        const parts = items.map(i => textCompiler(i));

        return or(...parts);
      }

      const arrayCompiler = Compilers.array(field);

      return arrayCompiler(items);
    };
  }

}

export type DatesFormatter = (range?: DateRange) => string;
export type ArrayFormatter<T> = (array?: T[]) => string;
export type ValuesFromStringFormatter = (value?: string) => string;
export type InvisibleFormatter = 'INVISIBLE';
export type JustValueFormatter = (value?: string | number) => string;

export type Formatter =
  DatesFormatter |
  ArrayFormatter<any> |
  ValuesFromStringFormatter |
  InvisibleFormatter;

export class Formatters {

  static invisible(): InvisibleFormatter {
    return 'INVISIBLE';
  }

  static dates(format: string = 'DD.MM.Y'): DatesFormatter {
    return (range?: DateRange) => {
      const left = range && range.from && range.from.local().format(format);
      const right = range && range.to && range.to.local().format(format);

      if (left && right) {
        return `С ${left} по ${right}`;
      }

      if (left) {
        return `С ${left} по текущую дату`;
      }

      if (right) {
        return `С текущей даты по ${right}`;
      }

      return '';
    };
  }

  static array<T>(mapper: (item: T) => string, separator: string = ', '): ArrayFormatter<T> {
    return (items?: T[]) => {
      if (!items || items.length === 0) {
        return '';
      }

      return items.map(mapper).join(separator);
    };
  }

  static valuesFromString(separator: string = ', ', splitter: string = ' '): ValuesFromStringFormatter {
    return (value?: string) => {
      if (!value) {
        return '';
      }

      const items = value.split(splitter).map(s => s.trim());
      return Formatters.array<string>(i => i, separator)(items);
    };
  }

  static justValue<T>(converter?: (obj: T) => string): JustValueFormatter {
    return (value?: T | string | number) => {
      if (!value) {
        return '';
      }

      return converter ? converter(value as T) : (<string | number>value).toString();
    };
  }

}

export interface SearchBarField {
  label: string;
  compiler: Compiler;
  formatter?: Formatter;
}

export interface SearchBarFields {
  [formControlName: string]: SearchBarField;
}

export interface ActivatedSearchBarField {
  name: string;
  label: string;
  value: {
    formatted: string,
    compiled: string
  };
  visible: boolean;
}

export interface FilterChangedEvent {
  type: 'INIT' | 'CHANGED';
  filter: string;
}

function id(parentGroup: string, controlName: string): string {
  return '' !== parentGroup ? [parentGroup, controlName].join('.') : controlName;
}

export function getActiveFields(fields: SearchBarFields, form: FormGroup): ActivatedSearchBarField[] {
  const reducer = (parentGroup: string = '') => {
    return (result: ActivatedSearchBarField[], control: AbstractControl, controlName: string) => {
      if (control instanceof FormGroup) {
        const flatten: ActivatedSearchBarField[] =
          _.reduce(control.controls, reducer(id(parentGroup, controlName)), [])
            .map(field => ({ ...field, name: `${controlName}.${field.name}` }));

        return result.concat(...flatten);
      }

      const searchBarField = fields[id(parentGroup, controlName)];

      return result.concat({
        name: controlName,
        label: searchBarField.label,
        value: {
          formatted: searchBarField.formatter && searchBarField.formatter !== 'INVISIBLE'
            ? searchBarField.formatter(control.value)
            : control.value,
          compiled: searchBarField.compiler(control.value)!
        },
        visible: searchBarField.formatter !== 'INVISIBLE'
      });
    };
  };

  return _.reduce(form.controls, reducer(), Array<ActivatedSearchBarField>())
    .filter(f => !!f.value.formatted);
}
