import { apiUrl, handleError } from 'core/http/lg-logistic-rest-api/api/api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Criteria } from 'shared/criteria/Criteria';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { catchError, map } from 'rxjs/operators';
import { Uuid } from 'shared/common/types';

export interface Department {
  id: Uuid;
  name: string;
}

export interface StorehouseOutput {
  id: Uuid;
  name: string;
  uniqId: string;
  departments: Department[];
}

export interface StorehouseInput {
  name: string;
  uniqId: string;
  departments: Department[];
}

@ProjectInjectable()
export class StorehouseService {

  constructor(private http: HttpClient) { }

  createStorehouse(input: StorehouseInput): Observable<StorehouseOutput> {
    return this.http
      .post<StorehouseOutput>(apiUrl(`/project/storehouse`), input)
      .pipe(catchError(e => handleError(e)));
  }

  updateStorehouse(storehouseId: Uuid, input: StorehouseInput): Observable<StorehouseOutput> {
    return this.http.put<StorehouseOutput>(apiUrl(`/project/storehouse/${storehouseId}`), input)
      .pipe(catchError(e => handleError(e)));
  }

  removeStorehouse(storehouseId: Uuid): Observable<Object> {
    return this.http.delete(apiUrl(`/project/storehouse/${storehouseId}`));
  }

  getStorehouses(c?: Criteria): Observable<StorehouseOutput[]> {
    return this.http.get<StorehouseOutput[]>(apiUrl(`/project/storehouse`, c));
  }

  getDepartments(): Observable<Department[]> {
    return this.http.get<{ departments: Department[] }>(apiUrl('/project/business-ru-departments'))
      .pipe(
        map(r => r.departments)
      );
  }

}
