import { Action, createReducer, on } from '@ngrx/store';
import * as AssignedProjectsActions from './assigned-projects.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProjectOutput } from 'core/http/dev';

export interface AssignedProjectsState extends EntityState<ProjectOutput> {
  isLoaded: boolean;
}

const adapter: EntityAdapter<ProjectOutput> = createEntityAdapter();

export const initialAssignedStateState = adapter.getInitialState({
  isLoaded: false
});

const reducer = createReducer(
  initialAssignedStateState,
  on(AssignedProjectsActions.saveAssignedProjectsInState, (state, { projects }) => adapter.setAll(projects, state)),
  on(AssignedProjectsActions.markAssignedProjectsLoaded, state => ({ ...state, isLoaded: true })),
  on(AssignedProjectsActions.resetAssignedProjects, state => ({ ...adapter.removeAll(state), isLoaded: false }))
);

export function assignedProjectsReducer(state: AssignedProjectsState | undefined, action: Action): AssignedProjectsState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
