import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Criteria } from 'shared/criteria/Criteria';
import { CourierOutput } from 'core/http/project/courier/courier.service';
import { ProjectInjectable } from 'core/http/project/project-services.module';
import { BackgroundProgress } from 'core/http/project/background/background-task.service';
import { Pagination } from 'shared/common/types';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';

export type CoverageType = 'POSTCODE' | 'CITY' | 'CITY_IN_REGION';

export interface UpdateServicedCoverageInput {
  courierId: string;
  locationsCollection: string[];
  type: CoverageType;
}

export interface CoverageOutput {
  id: string;
  type: CoverageType;
  courier: CourierOutput;
  locationsCount: number;
}

export interface CoverageLocationOutput {
  id: string;
  location: string;
}

@ProjectInjectable()
export class CoverageService {

  constructor(private http: HttpClient) {}

  updateCoverage(input: UpdateServicedCoverageInput): Observable<BackgroundProgress> {
    return this.http.post<BackgroundProgress>(apiUrl('/project/courier-coverage'), input);
  }

  getCoverages(): Observable<CoverageOutput[]> {
    return this.http.get<CoverageOutput[]>(apiUrl(`/project/courier-coverages`));
  }

  getCoverageLocations(criteria: Criteria, coverageId: string): Observable<Pagination<CoverageLocationOutput>> {
    return this.http.get<Pagination<CoverageLocationOutput>>(apiUrl(`/project/courier-coverage/${coverageId}`, criteria));
  }

  removeServicedCoverage(courierId: string): Observable<BackgroundProgress> {
    return this.http.delete<BackgroundProgress>(apiUrl(`/project/courier-coverage/${courierId}`));
  }

}
