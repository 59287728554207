import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { ScheduledTasksService } from 'core/http/project/scheduled-tasks/scheduled-tasks.service';
import { LoaderService } from 'core/services/loader.service';
import { CompletedEntityStateService } from 'app/store/completed-entity-state/completed-entity-state.service';
import { createProperties } from 'app/store/completed-entity-state/completed-entity-state';
import * as ScheduledTasksActions from './scheduled-tasks.actions';
import * as NotifierActions from 'app/store/common-effects/notifier.effects';
import * as ProjectRoutingActions from 'app/store/common-effects/router.effects';

@Injectable()
export class ScheduledTasksEffects {

  private readonly properties = createProperties(
    ScheduledTasksActions.setLoading,
    ScheduledTasksActions.markScheduledTasksLoaded,
    ScheduledTasksActions.setError,
    () => this.scheduledTasksService.getAllScheduledTasks(),
    'scheduledTasks'
  );

  getScheduledTasks$ = createEffect(() => this.actions$.pipe(
    ofType(ScheduledTasksActions.getScheduledTasks),
    exhaustMap(() => this.completedEntityStateService.get(this.properties)),
    map(tasks => ScheduledTasksActions.saveScheduledTasks({ tasks }))
  ));

  createScheduledTask$ = createEffect(() => this.actions$.pipe(
    ofType(ScheduledTasksActions.createScheduledTask),
    tap(() => this.loader.begin()),
    exhaustMap(({ input }) => this.scheduledTasksService.createScheduledTask(input)),
    tap(() => this.loader.end()),
    switchMap(entity => [
      ScheduledTasksActions.addScheduledTaskInStore({ entity }),
      NotifierActions.notify({ message: 'Отложенная задача создана' }),
      ProjectRoutingActions.navigateToProject({ path: ['setting', 'scheduled-tasks'] })
    ])
  ));

  updateScheduledTask$ = createEffect(() => this.actions$.pipe(
    ofType(ScheduledTasksActions.updateScheduledTask),
    tap(() => this.loader.begin()),
    exhaustMap(({ id, input }) => this.scheduledTasksService.editScheduledTask(id, input)),
    tap(() => this.loader.end()),
    switchMap(entity => [
      ScheduledTasksActions.updateScheduledTaskInStore({ update: { id: entity.id, changes: entity } }),
      NotifierActions.notify({ message: 'Отложенная задача обновлена' }),
      ProjectRoutingActions.navigateToProject({ path: ['setting', 'scheduled-tasks'] })
    ])
  ));

  removeScheduledTask$ = createEffect(() => this.actions$.pipe(
    ofType(ScheduledTasksActions.removeScheduledTask),
    tap(() => this.loader.begin()),
    exhaustMap(({ id }) => this.scheduledTasksService.removeScheduledTask(id).pipe(mapTo(id))),
    tap(() => this.loader.end()),
    switchMap(id => [
      ScheduledTasksActions.removeScheduledTaskFromStore({ id }),
      NotifierActions.notify({ message: 'Отложенная задача удалена' })
    ])
  ));

  constructor(
    private actions$: Actions,
    private scheduledTasksService: ScheduledTasksService,
    private loader: LoaderService,
    private completedEntityStateService: CompletedEntityStateService
  ) {}

}
