import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavLink } from 'src/app/core/types/nav-link';
import { ProjectOutput } from 'core/http/dev';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { currentProject } from 'app/store/project/current-project/current-project.selectors';

@Component({
  selector: 'app-project-layout',
  template: `
    <app-main-layout [navLinks]="navLinks$ | async">
    </app-main-layout>`
})
export class ProjectLayoutComponent implements OnInit, OnDestroy {

  private _navLinks$ = new BehaviorSubject(Array<NavLink>());
  private _projectChangedSub?: Subscription;

  constructor(private store$: Store) { }

  ngOnInit(): void {
    this._projectChangedSub = this.store$.select(currentProject)
      .subscribe(project => project && this.updateLinks(project));
  }

  ngOnDestroy(): void {
    this._navLinks$.complete();
    this._projectChangedSub && this._projectChangedSub.unsubscribe();
  }

  get navLinks$(): Observable<NavLink[]> {
    return this._navLinks$;
  }

  private updateLinks(project: ProjectOutput): void {
    const route = `/project/${project.route}`;

    this._navLinks$.next([
      {
        path: `${route}/orders`,
        icon: 'business_center',
        linkActiveOptions: { exact: true },
        label: 'SIDENAV.PROJECT.orders_page'
      },
      {
        path: `${route}/good`,
        icon: 'local_convenience_store',
        linkActiveOptions: { exact: true },
        label: 'SIDENAV.PROJECT.products_page'
      },
      {
        path: `${route}/progresses`,
        icon: 'horizontal_split',
        linkActiveOptions: { exact: true },
        label: 'SIDENAV.PROJECT.processes_page'
      },
      {
        path: `${route}/history`,
        icon: 'history',
        linkActiveOptions: { exact: false },
        label: 'SIDENAV.PROJECT.history_section',
        children: [
          {
            path: `${route}/history/unloading`,
            linkActiveOptions: { exact: false },
            label: 'SIDENAV.PROJECT.unloading_page'
          },
          {
            path: `${route}/history/printing`,
            linkActiveOptions: { exact: false },
            label: 'SIDENAV.PROJECT.printing_page'
          },
          {
            path: `${route}/history/remains-transactions`,
            linkActiveOptions: { exact: false },
            label: 'SIDENAV.PROJECT.remains_page'
          },
          {
            path: `${route}/history/statuses`,
            linkActiveOptions: { exact: false },
            label: 'SIDENAV.PROJECT.statuses'
          },
          {
            path: `${route}/history/notices-log`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.notices'
          }
        ]
      },
      {
        path: `${route}/reconciliation`,
        icon: 'money',
        linkActiveOptions: { exact: false },
        label: 'SIDENAV.PROJECT.reconciliation_section',
        children: [
          {
            path: `${route}/reconciliation/debt`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.debts_page'
          },
          {
            path: `${route}/reconciliation/claims`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.claims_page'
          },
          {
            path: `${route}/analytic/${project.id}/debts`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.debts_chart_page'
          },
          {
            path: `${route}/analytic/${project.id}/claims`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.claims_chart_page'
          }
        ]
      },
      {
        path: `${route}/setting`,
        icon: 'settings',
        linkActiveOptions: { exact: false },
        label: 'SIDENAV.PROJECT.settings_section',
        children: [
          {
            path: `${route}/setting/scheduled-tasks`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.scheduled_tasks'
          },
          {
            path: `${route}/setting/notices`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.notices'
          },
          {
            path: `${route}/track-reservation`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.track_reservation_page'
          },
          {
            path: `${route}/courier-coverage`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.courier_coverage_page'
          },
          {
            path: `${route}/setting/couriers`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.couriers_page'
          },
          {
            path: `${route}/setting/courier-chains`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.courier_chains_page'
          },
          {
            path: `${route}/setting/sequences`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.sequences_page'
          },
          {
            path: `${route}/setting/documents`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.documents_page'
          },
          {
            path: `${route}/setting/pools`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.document_pools_page'
          },
          {
            path: `${route}/setting/extensions`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.extensions_page'
          },
          {
            path: `${route}/setting/statuses`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.statuses_page'
          },
          {
            path: `${route}/setting/status-chains`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.status_chains_page'
          },
          {
            path: `${route}/setting/senders`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.senders_page'
          },
          {
            path: `${route}/setting/storehouses`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.storehouses_page'
          },
          {
            path: `${route}/setting/current-project`,
            linkActiveOptions: { exact: true },
            label: 'SIDENAV.PROJECT.project_page'
          }
        ]
      }
    ]);
  }

}
