import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import {
  ActivatedSearchBarField,
  FilterChangedEvent,
  getActiveFields,
  SearchBarFields
} from 'shared/modules/controls/search-bar/search-bar';
import { and } from 'shared/criteria/Criteria';
import { Store } from '@ngrx/store';
import { selectFirst } from 'core/utils/rx-common';
import { pageCriteria } from 'app/store/project/page-criteria/page-criteria.selectors';
import { addEmptyPageCriteria } from 'app/store/project/page-criteria/page-criteria.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { Dictionary, uniq } from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DateRange, filterNil } from 'shared/common/types';
import { flatten } from '@angular/compiler';
import { SeparatorInputData } from 'shared/modules/controls/separator-input/separator-input.component';

// Поля с multiple select
const MULTIPLE = ['statuses', 'couriers', 'senders', 'goods', 'storehouse', 'storehouses', 'triggerId'];
// Поля с возможностью выбора разделителя
const WITH_SEPARATOR = ['outOrderId', 'outProjectId', 'recipient', 'city', 'region', 'postcode', 'phone', 'track', 'tracking'];

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarComponent implements OnChanges, OnInit {

  private _fields: SearchBarFields = {};
  private _active = Array<ActivatedSearchBarField>();
  private _eagerModeFields = Array<string>();
  private _eagerModeHint = '';
  private _defaultFormValues?: any;
  private _key = '';
  private _columnCount = 12;

  @Output() filterChanged = new EventEmitter<FilterChangedEvent>();

  @Input()
  set fields(fields: SearchBarFields) {
    this._fields = fields;
  }

  get active(): ActivatedSearchBarField[] {
    return this._active;
  }

  get visibleFieldCount(): number {
    return this._active
      .filter(field => field.visible)
      .length;
  }

  get form(): FormGroup {
    const group = this.controlContainer.control;

    if (!(group instanceof FormGroup)) {
      throw new Error('Invalid control container given');
    }

    return group;
  }

  get eagerModeFields(): string[] {
    return this._eagerModeFields;
  }

  @Input()
  set eagerModeFields(value: string[]) {
    this._eagerModeFields = value;
  }

  get eagerModeHint(): string {
    return this._eagerModeHint;
  }

  @Input()
  set eagerModeHint(value: string) {
    this._eagerModeHint = value;
  }

  get key(): string {
    if (!this._key) {
      throw new Error('Where is my key, little developer?');
    }

    return this._key;
  }

  @Input()
  set key(value: string) {
    this._key = value;
  }

  get columnCount(): number {
    return this._columnCount;
  }

  @Input()
  set columnCount(value: number) {
    this._columnCount = value;
  }

  constructor(
    private controlContainer: ControlContainer,
    private store$: Store,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    setTimeout(() => this.writeParams());
  }

  ngOnChanges(): void {
    this._defaultFormValues = this.form.value;

    forkJoin({
      criteria: this.store$.pipe(selectFirst(pageCriteria, this.key)),
      params: this.route.queryParams.pipe(first())
    })
      .subscribe(({ criteria, params }) => {
        const formValue = this.collectFormValue(params);

        if (!criteria) {
          this.store$.dispatch(addEmptyPageCriteria({ key: this.key }));

          this.form.patchValue(formValue);
        }

        if (criteria?.formValue && !Object.keys(params).length) {
          this.form.setValue(criteria.formValue);
        }
        else {
          this.form.patchValue(formValue);
        }

        this.emitChanges('INIT', this.form);
      });
  }

  onFilterChanged(ctrl?: boolean): void {
    if (ctrl) {
      this.eagerModeFields.forEach(item => this.form.controls[item].reset());
    }

    this.writeParams();

    this.emitChanges('CHANGED', this.form);
  }

  reset(): void {
    this._active = [];
    this.form.reset(this._defaultFormValues);

    this.store$.dispatch(addEmptyPageCriteria({ key: this.key }));

    this.onFilterChanged();
  }

  resetField(...fieldsName: string[]): void {
    const additionalFields = fieldsName.filter(f => f.includes('additions'));
    this.resetAdditionalFields(additionalFields);

    fieldsName.forEach(f => this.form.patchValue({ [f]: null }));
    this.onFilterChanged();
  }

  prettifyObjectValues(object: any): Dictionary<string[] | string | number> {
    const prettyAdditions = this.prettifyAdditionalValues(object);
    const prettyDates = this.prettifyDateValues(object);
    const prettySeparators = this.prettifySeparatorValues(object);
    const prettyOther = this.prettifyOtherValues(object);

    return { ...prettyOther, ...prettyAdditions, ...prettySeparators, ...prettyDates };
  }

  collectFormValue(object: any): any {
    const additionalValues = this.getAdditionalValues(object);
    const dateValues = this.getDateValues(object);
    const separatorValues = this.getSeparatorValues(object);
    const otherValues = this.getOtherValues(object);

    return { ...otherValues, ...dateValues, ...separatorValues, additions: { ...additionalValues } };
  }

  private resetAdditionalFields(fieldNames: string[]): void {
    fieldNames.forEach(f => {
      const i = f.indexOf('.') + 1;
      const additionKey = f.slice(i);
      const formGroup = this.form.controls.additions as FormGroup;

      formGroup.patchValue({ [additionKey]: null });
    });
  }

  private writeParams(): void {
    const params = this.prettifyObjectValues(this.form.value);

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { ...params }
    });
  }

  private prettifyAdditionalValues(object: any): Dictionary<string> {
    const additions: Dictionary<string> = object['additions'];

    if (!additions) {
      return {};
    }

    const keys = Object.keys(additions);

    const additionValuesArr = keys
      .map(k => additions[k] ? { [k]: additions[k] } : null)
      .filter(filterNil);

    const additionDic = this.reduceValues(additionValuesArr);

    return this.prettifySeparatorValues(additionDic, true);
  }

  private prettifyDateValues(object: any): Dictionary<string> {
    const keys = Object.keys(object).filter(k => k.endsWith('At'));

    const dateValuesArr = keys.map(k => {
      const property = object[k] as DateRange;

      const from = property?.from?.format('YYYY-MM-DD');
      const to = property?.to?.format('YYYY-MM-DD');

      const fromValue = from ? { [`${k}.from`]: from } : null;
      const toValue = to ? { [`${k}.to`]: to } : null;

      return [fromValue, toValue].filter(filterNil);
    });

    const dateValuesFlatten = flatten(dateValuesArr);

    return this.reduceValues(dateValuesFlatten);
  }

  private prettifySeparatorValues(object: any, additions = false): Dictionary<string> {
    const keys = Object.keys(object);
    const separatorKeys = additions ? keys : keys.filter(k => WITH_SEPARATOR.includes(k));

    const separatorValuesArr = separatorKeys
      .map(k => {
        const property: SeparatorInputData = object[k];
        const value: string = property?.value.replace(/\s+/g, '~');

        return value
          ? {
            [`${k}.value`]: value,
            [`${k}.separator`]: property?.separator,
            [`${k}.byEntry`]: `${property?.byEntry ?? true}`
          }
          : null;
      })
      .filter(filterNil);

    return this.reduceValues(separatorValuesArr);
  }

  private prettifyOtherValues(object: any): Dictionary<string[] | string | number> {
    const filteredObject: Dictionary<string[] | string | number> = Object.entries(object)
      .filter(e => !!e[1])
      .reduce((prev, curr) => ({ ...prev, [curr[0]]: curr[1] }), {});

    const keys = Object.keys(filteredObject);
    const otherKeys = keys.filter(k => k !== 'additions' && !k.endsWith('At') && !WITH_SEPARATOR.includes(k));

    const otherValuesArr = otherKeys.map(k => {
      const value = MULTIPLE.includes(k) && !Array.isArray(object[k]) ? [object[k]] : object[k];

      return { [k]: value };
    });

    return this.reduceValues(otherValuesArr);
  }

  private getAdditionalValues(object: any): Dictionary<SeparatorInputData> {
    const keys = Object.keys(object);

    const additionalKeys = keys.filter(k => k.includes('additional'));
    const additionalValuesArr = additionalKeys.map(k => ({ [k]: object[k] }));
    const additionalDic = this.reduceValues(additionalValuesArr);

    return this.getSeparatorValues(additionalDic, true);
  }

  private getDateValues(object: any): Dictionary<{ from: Moment | null, to: Moment | null }> {
    const keys = Object.keys(object);

    const dateKeys = keys.filter(k => k.includes('At'));
    const cutKeys = dateKeys.map(k => {
      const dotIndex = k.indexOf('.');
      return k.slice(0, dotIndex);
    });

    const dateValuesArr = cutKeys.map(k => {
      const range: DateRange = dateKeys
        .filter(d => d.includes(k))
        .map(d => {
          if (d.endsWith('from')) {
            return { from: moment(object[d], 'YYYY-MM-DD') };
          }

          return { to: moment(object[d], 'YYYY-MM-DD') };
        })
        .reduce((prev, curr) => ({ ...prev, ...curr }), {});

      return { [k]: { from: range.from || null, to: range.to || null } };
    });

    return this.reduceValues(dateValuesArr);
  }

  private getSeparatorValues(object: any, additions: boolean = false): Dictionary<SeparatorInputData> {
    const keys = Object.keys(object);
    const separatorKeys = additions ? keys : keys.filter(k => WITH_SEPARATOR.some(w => k.includes(w)));
    const cutKeys = separatorKeys.map(k => {
      const dotIndex = k.indexOf('.');
      return k.slice(0, dotIndex);
    });

    const separatorValuesArr = uniq(cutKeys).map(cutKey => {
      const value = (object[`${cutKey}.value`] as string)?.replace(/~/g, ' ');
      const separator = object[`${cutKey}.separator`];
      const byEntry = object[`${cutKey}.byEntry`] === 'true';

      return { [cutKey]: { value, separator, byEntry } as SeparatorInputData };
    });

    return this.reduceValues(separatorValuesArr);
  }

  private getOtherValues(object: any): Dictionary<string | number> {
    const keys = Object.keys(object);

    const otherKeys = keys.filter(k => !k.includes('additional') && !k.includes('At') && !WITH_SEPARATOR.includes(k));
    const otherValuesArr = otherKeys.map(k => {
      const value = MULTIPLE.includes(k) && !Array.isArray(object[k]) ? [object[k]] : object[k];

      return { [k]: value };
    });

    return this.reduceValues(otherValuesArr);
  }

  private reduceValues<T>(arr: Dictionary<T>[]): Dictionary<T> {
    return arr.reduce((prev, curr) => ({ ...prev, ...curr }), {});
  }

  private emitChanges(type: 'INIT' | 'CHANGED' = 'INIT', form: FormGroup): void {
    const activeFields = getActiveFields(this._fields, form);
    const filter = and(...activeFields.map(f => f.value.compiled));

    this._active = activeFields;
    this.filterChanged.emit({ type, filter });
  }
}
