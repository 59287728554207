import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-option',
  templateUrl: './search-option.component.html',
  styleUrls: ['./search-option.component.scss']
})
export class SearchOptionComponent implements OnInit {

  private _label = '';
  private _value = '';
  private _visible = true;

  get visible(): boolean {
    return this._visible;
  }

  @Input()
  set visible(value: boolean) {
    this._visible = value;
  }

  get value(): string {
    return this._value;
  }

  @Input()
  set value(value: string) {
    this._value = value;
  }

  get label(): string {
    return this._label;
  }

  @Input()
  set label(value: string) {
    this._label = value;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
