import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl } from 'core/http/lg-logistic-rest-api/api/api.service';
import { Observable } from 'rxjs';
import { TokenPairOutput } from 'core/http/lg-logistic-rest-api/Auth/token.service';
import { CriteriaParams } from 'shared/criteria/Criteria';
import { checkExistenceByHeadRequest } from 'core/utils/rx-http';

export interface RegistrationInput {
  login: string;
  password: string;
  name: string;
  phone: string | null;
  email: string;
  inviteId?: string;
}

export interface SendPasswordRecoveryEmailInput {
  email: string;
  locale: string;
  redirectUrl: string;
}

@Injectable()
export class RegistrationService {

  constructor(private http: HttpClient) {}

  checkInvite(inviteId: string): Observable<boolean> {
    return this.http.head<void>(apiUrl(`/auth/register/${inviteId}`), { observe: 'response' })
      .pipe(checkExistenceByHeadRequest());
  }

  registration(input: RegistrationInput): Observable<TokenPairOutput> {
    return this.http.post<TokenPairOutput>(apiUrl('/auth/register'), input);
  }

  hasUser(criteria: CriteriaParams): Observable<boolean> {
    return this.http.head<void>(apiUrl(`/auth/user`, criteria), { observe: 'response' })
      .pipe(checkExistenceByHeadRequest());
  }

  recoveryPassword(newPassword: string, recoveryId: string): Observable<Object> {
    return this.http.post<Object>(apiUrl(`/auth/recovery/${recoveryId}/recover`), { password: newPassword });
  }

  sendRecovery(value: SendPasswordRecoveryEmailInput): Observable<Object> {
    return this.http.post(apiUrl(`/auth/recovery/send`), value);
  }

}
