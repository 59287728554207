import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { ItemSelection } from 'shared/modules/table/selection/item-selection';
import { MatColumnDef, MatTable } from '@angular/material/table';
import { TableComponent } from 'shared/modules/table/table/table.component';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-selection',
  template: `
    <ng-container matColumnDef>
      <th *matHeaderCellDef style="padding-left: 12px !important;" mat-header-cell>
        <button (click)="select()"
                (contextmenu)="$event.preventDefault(); $event.ctrlKey ? deselectAll() : deselectPage()"
                [color]="selectionColor"
                mat-icon-button>
          <mat-icon>select_all</mat-icon>
        </button>
      </th>
      <td *matCellDef="let row" mat-cell>
        <div>
          <mat-checkbox (change)="toggle(row)" [checked]="isSelected(row)">
          </mat-checkbox>
        </div>
      </td>
    </ng-container>
  `
})
export class SelectionComponent<TEntity, TIdentifier> implements OnInit, OnDestroy {

  /**
   * Функция которая описывает как получить доступ к идентификатору сущности.
   * Нужна для функционирования {@see ItemSelection}.
   */
  @Input() idAccessor?: (item: TEntity) => TIdentifier;

  /**
   * Передается если нужно уметь сбрасывать {@see ItemSelection} извне.
   */
  @Input() resetter?: Observable<any>;

  /**
   * Срабатывает всякий раз когда меняется {@see ItemSelection}.
   */
  @Output() changed = new EventEmitter<TEntity[]>();

  /**
   * Ссылка на колонку с checkbox'ами.
   * Нужна чтобы руками засунуть ее в {@see MatTable}.
   */
  @ViewChild(MatColumnDef, { static: true }) selectionColumn!: MatColumnDef;

  readonly subject$ = new Subject();

  private _selection!: ItemSelection<TEntity, TIdentifier>;

  /**
   * @param matTable Нужна для того чтобы внедрить selection
   * @param genericTable Нужна для того чтобы выбирать данные с текущей страницы
   */
  constructor(@Optional() public matTable: MatTable<TEntity>, @Optional() public genericTable: TableComponent<TEntity>) { }

  get selectionColor(): 'primary' | null {
    return this._selection.hasValue() ? 'primary' : null;
  }

  ngOnInit(): void {
    this._selection = new ItemSelection(this.idAccessor);
    this._selection.changed
      .pipe(takeUntil(this.subject$))
      .subscribe((items: TEntity[]) => this.changed.emit(items));

    this.selectionColumn.name = 'select';
    this.matTable.addColumnDef(this.selectionColumn);

    if (this.resetter) {
      this.resetter
        .pipe(takeUntil(this.subject$))
        .subscribe(() => this._selection.clearAll());
    }
  }

  ngOnDestroy(): void {
    this.matTable.removeColumnDef(this.selectionColumn);

    this.subject$.next();
    this.subject$.complete();
  }

  select(): void {
    this._selection.select(...this.genericTable.currentPageData);
  }

  deselectAll(): void {
    this._selection.clearAll();
  }

  deselectPage(): void {
    this._selection.deselect(...this.genericTable.currentPageData);
  }

  toggle(row: TEntity): void {
    this.isSelected(row) ? this._selection.deselect(row) : this._selection.select(row);
  }

  isSelected(row: TEntity): boolean {
    return this._selection.isSelected(row);
  }

}
