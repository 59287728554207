import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {

  get messages(): string[] {
    return typeof this.data === 'string' ? [this.data] : this.data;
  }

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string | string[]) { }

}
