import { Component } from '@angular/core';
import { UserOutput } from 'core/http/root';
import { Criteria } from 'shared/criteria/Criteria';
import { Store } from '@ngrx/store';
import { arrayFilter } from 'core/utils/rx-common';
import { users } from 'app/store/root/users/users.selectors';
import { blockUser, restoreUser } from 'app/store/root/users/users.actions';
import { Uuid } from 'shared/common/types';
import { Subject } from 'rxjs';
import { EagerSource } from 'shared/modules/table/table-utils/table-interfaces';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {

  readonly reloader = new Subject();

  constructor(private store$: Store) {}

  get columns(): string[] {
    return [
      'login',
      'user',
      'phone',
      'projects',
      'registeredAt',
      'isActivated'
    ];
  }

  dataSource: EagerSource<UserOutput> = (c: Criteria) => this.store$.select(users)
    .pipe(
      arrayFilter(user => {
        return user.login.toLowerCase().includes(c.filter!.toLowerCase())
          || user.fullName.toLowerCase().includes(c.filter!.toLowerCase());
      })
    );

  restoreAccount(id: Uuid): void {
    this.store$.dispatch(restoreUser({ id }));

    this.reloader.next();
  }

  blockAccount(id: Uuid): void {
    this.store$.dispatch(blockUser({ id }));

    this.reloader.next();
  }

}
