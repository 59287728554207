import { LoaderService } from 'core/services/loader.service';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpError } from 'core/http/lg-logistic-rest-api/api/api.service';
import { PartialObserver } from 'rxjs';
import { Store } from '@ngrx/store';
import { errorHttpNotify, errorNotify, notify } from 'app/store/common-effects/notifier.effects';

export interface ObserverWithHook {
  showLoading?: boolean;
  successMsg?: string;
  onSuccess?: Function;
  errorMsg?: string;
  onError?: Function;
  onHttpError?: (e: HttpError) => void;
  finally?: Function;
}

export type SuccessNotifier = (message: string) => void;

@Injectable({ providedIn: 'root' })
export class ObserverFactory {

  constructor(private loader: LoaderService, private store$: Store) {}

  simple(successMessage?: string, errorMessage?: string): PartialObserver<any> {
    this.loader.begin();

    return {
      next: () => {
        this.loader.end();
        if (successMessage) {
          this.store$.dispatch(notify({ message: successMessage }));
        }
      },
      error: () => {
        this.loader.end();

        this.store$.dispatch(errorNotify({ message: errorMessage || 'ERROR.server_error' }));
      }
    };
  }

  withHook(hook: ObserverWithHook): PartialObserver<any> {
    if (undefined === hook.showLoading) {
      hook.showLoading = true;
    }

    hook.showLoading && this.loader.begin();

    return {
      next: (...args: any[]) => {
        if (hook.showLoading) {
          this.loader.end();
        }

        if (hook.onSuccess) {
          const notifier: SuccessNotifier = (message: string) => this.store$.dispatch(notify({ message }));
          hook.onSuccess(...args.concat(notifier));
        }

        if (hook.successMsg) {
          this.store$.dispatch(notify({ message: hook.successMsg }));
        }

        if (hook.finally) {
          hook.finally();
        }
      },
      error: (e: Error) => {
        if (hook.showLoading) {
          this.loader.end();
        }

        if (hook.onHttpError && this.isHttpError(e)) {
          hook.onHttpError(e);
        } else if (hook.onError) {
          hook.onError(e);
        } else if (e instanceof HttpErrorResponse) {
          e.status === 500 ? this.notifyUserError('ERROR.server_error') : this.notifyHttpError(e);
        } else {
          this.notifyUserError(hook.errorMsg || 'ERROR.server_error');
        }

        if (hook.finally) {
          hook.finally();
        }
      }
    };
  }

  private isHttpError(e: any): e is HttpError {
    return 'kind' in e && e.kind === 'HANDLED';
  }

  private notifyHttpError(err: HttpErrorResponse): void {
    this.store$.dispatch(errorHttpNotify({ response: err }));
  }

  private notifyUserError(err: string): void {
    this.store$.dispatch(errorNotify({ message: err }));
  }

}
