import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableComponent } from 'shared/modules/data-table/data-table.component';
import { DataTableHeaderDirective } from 'shared/modules/data-table/data-table-header.directive';
import { DataTableColumnDirective } from 'shared/modules/data-table/data-table-column.directive';
import { DataTableRowDirective } from 'shared/modules/data-table/data-table-row.directive';

@NgModule({
  declarations: [
    DataTableComponent,
    DataTableHeaderDirective,
    DataTableRowDirective,
    DataTableColumnDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DataTableComponent,
    DataTableHeaderDirective,
    DataTableRowDirective,
    DataTableColumnDirective
  ]
})
export class DataTableModule {
}
