import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-delete-popover',
  templateUrl: './delete-popover.component.html',
  styleUrls: ['./delete-popover.component.scss']
})
export class DeletePopoverComponent {

  @Input() disabled = false;

  @Output() action = new EventEmitter();

  constructor() { }

}
