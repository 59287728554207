import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StatusesHistoryService } from 'core/http/project/status/statuses-history.service';
import { Uuid } from 'shared/common/types';
import { Criteria, fromRelated, has } from 'shared/criteria/Criteria';
import { Subject } from 'rxjs';
import { OrderOutput } from 'core/http/project';
import { PaginatedSource, TableColumn } from 'shared/modules/table/table-utils/table-interfaces';

@Component({
  selector: 'app-status-change-orders',
  templateUrl: './status-change-orders.component.html',
  styleUrls: ['./status-change-orders.component.scss']
})
export class StatusChangeOrdersComponent implements OnInit {

  readonly changeGroupId: Uuid = this.route.snapshot.params.changeGroupId;
  private _statusChangeId: Uuid = '';
  private _totalCount = 0;
  private _selectedOrders = Array<string>();
  private _reloader = new Subject();
  private _columns: TableColumn[] = [
    { name: 'id', label: 'ID', visible: true },
    { name: 'cart', label: 'Товары', visible: true },
    { name: 'recipient', label: 'Получатель', visible: true },
    { name: 'status', label: 'Статус', visible: true },
    { name: 'dates', label: 'Создано/Обновлено', visible: true },
    { name: 'tracking', label: 'Трек', visible: true },
    { name: 'courier', label: 'Выгрузка', visible: false },
    { name: 'comment', label: 'Комментарий', visible: true },
    { name: 'store', label: 'Склад', visible: false },
    { name: 'additions', label: 'Доп. поля', visible: false }
  ];

  get columns(): TableColumn[] {
    return this._columns;
  }

  get columnNames(): string [] {
    return this._columns.map(c => c.name);
  }

  get reloader(): Subject<unknown> {
    return this._reloader;
  }

  get statusChangeId(): Uuid {
    return this._statusChangeId;
  }

  get filter(): string {
    return has.values('id', this._selectedOrders) || fromRelated.ordersFromStatusChangeGroup(this._statusChangeId);
  }

  get orderCount(): number {
    return this._selectedOrders.length || this._totalCount;
  }

  constructor(
    private route: ActivatedRoute,
    private statusesHistoryService: StatusesHistoryService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(p => {
      this._statusChangeId = p.statusChangeId;
      this.reloader.next();
    });
  }

  dataSource: PaginatedSource<OrderOutput> = (c: Criteria) => {
    return this.statusesHistoryService.getStatusChangeGroupOrders(this.statusChangeId, c);
  };

  onSelectionChanged(unloaded: OrderOutput[]): void {
    this._selectedOrders = unloaded.map(u => u.id);
  }

  onTotalCountChanged(count: number): void {
    this._totalCount = count;
  }

}
