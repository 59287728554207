import { createAction, props } from '@ngrx/store';
import { CourierOutput } from 'core/http/root';
import { Uuid } from 'shared/common/types';
import { Update } from '@ngrx/entity';

export const getRootCouriers = createAction('[Root Couriers Request] Get Root Couriers');
export const saveRootCouriers = createAction('[Root Couriers Store] Save Root Courier',
  props<{ couriers: CourierOutput[] }>());
export const markRootCouriersLoaded = createAction('[Root Couriers Store] Mark Root Couriers Loaded');

export const updateCourierExtensionProjects = createAction('[Root Courier Request] Update Couriers Extension Projects',
  props<{ id: Uuid, projects: Uuid[] }>());
export const updateCourierExtensionProjectsInStore = createAction(
  '[Root Courier Store] Update Courier Extension Projects In Store',
  props<{ update: Update<CourierOutput> }>());
