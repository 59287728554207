import { Component, ContentChild } from '@angular/core';
import { DataTableRowDirective } from 'shared/modules/data-table/data-table-row.directive';
import { DataTableHeaderDirective } from 'shared/modules/data-table/data-table-header.directive';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent<T> {

  @ContentChild(DataTableRowDirective)
  private _tableRow?: DataTableRowDirective<T>;

  @ContentChild(DataTableHeaderDirective)
  private _tableHeader?: DataTableHeaderDirective;

  get tableRow(): DataTableRowDirective<T> {
    if (!this._tableRow) {
      throw new Error('Table row is not initialized');
    }

    return this._tableRow;
  }

  get tableHeader(): DataTableHeaderDirective | undefined {
    return this._tableHeader;
  }

}
